<div class="navigation-wrapper relative
us:w-screen us:min-h-113px us:px-2
us-landscape:min-h-113px
lg-landscape:w-915px lg-landscape:h-268px">
    <div class="relative keen-slider flex overflow-hidden w-full h-full" #sliderRef>
        <div class="relative w-auto h-auto keen-slider__slide" *ngFor=" let image of imagesDirs">
            <a class="relative w-full h-full" [href]="image?.link" target="_blank">
                <img class="relative lg-landscape:h-full lg-landscape:w-full object-contain" src="{{image.url}}" alt="">
                <a *ngIf="image?.button_text" [href]="image?.link" target="_blank" class="absolute cursor-pointer flex items-center text-sm z-10
                us:w-auto us:h-9 us:rounded-3px us:px-2
                lg-landscape:w-auto lg-landscape:h-9 lg-landscape:rounded-3px lg-landscape:px-2"
                    ngClass="{{image?.button_position}}"
                    [ngStyle]="{'color': image?.button_text_color,'background-color':image?.button_bg_color}">
                    {{image?.button_text}}
                </a>
            </a>
        </div>
    </div>
    <div class="dots
    us:mt-1
    lg-landscape:mt-1">
        <button class="
        us:w-2 us:h-2 us:mx-1
        lg-landscape:w-10px lg-landscape:h-10px lg-landscape:mx-1" (click)="slider?.moveToIdx(i)"
            *ngFor="let slide of imagesDirs; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
    </div>

    <!-- <svg [ngClass]="
      'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')
    " (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
    <svg *ngIf="slider" [ngClass]="
      'arrow arrow--right ' +
      (slider.track?.details?.slides?.length - 1 === currentSlide
        ? 'arrow--disabled'
        : '')
    " (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg> -->
</div>
