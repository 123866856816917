import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform<T>(array: T[], attribute: keyof T, value: string): T[] {
    return array.filter(
    // @ts-ignore
      element => element[attribute]?.toString().trim().toLowerCase().includes(value.trim().toLowerCase())
    );
  }

}
