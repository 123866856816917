import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {CartPreviewComponent} from "../../../payment/components/cart-preview/cart-preview.component";
import {InternalNotificationDto} from "../internal-notifications/internalNotificationDto.interface";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-tray',
  templateUrl: './notification-tray.component.html',
  styleUrls: ['./notification-tray.component.scss']
})
export class NotificationTrayComponent implements OnInit {

  notifications: InternalNotificationDto[] = [];

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<CartPreviewComponent>,
    private notificationService: InternalNotificationService
  ) {
  }

  ngOnInit(): void {
    this.loadNotifications()
  }

  loadNotifications(): void {
    this.notificationService.getNotifications(10, false, 1).subscribe((result: {
      data: { items: InternalNotificationDto[] }
    }) => {
      this.notifications = result.data.items;
    });
  }

  closeTray() {
    this.dialogRef.close();
  }

  navigateToFull() {
    this.router.navigate(['/profile/notifications']);
  }

  removeFromList(notificationId: string) {
    this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
  }

  readonly faTimes = faTimes;

  readAndClose($event: string) {
    this.removeFromList($event);
    this.closeTray()
  }

  markAllRead() {
    this.loading = true
    this.notificationService.markAsRead(this.notifications.map(n => n.id)).subscribe(() => {
      this.notifications = [];
      this.loading = false;
    })
  }

  readonly faSpinner = faSpinner;
  loading: boolean = false;
}
