import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "./shared/services/language.service";
import { ProvinceSelectionComponent } from "./modules/shared/components/province-selection/province-selection.component";
import { filter, takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subject, forkJoin, merge, mergeMap } from "rxjs";
import { SelectedZone, User } from "./shared/models";
import { ZonesService } from "./shared/services/zones.service";
import { CONFIG } from "../assets/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DynamicCOnfigService } from './shared/services/dynamic-config.service';
import { OnfidoService } from "./shared/services/onfido.service";
import { AlgoliaService } from './shared/services/algolia.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "./shared/services/auth.service";
import { FRONTEND_FEATURE, FrontendFeatureService } from "./shared/services/frontend-feature.service";
import { CartService } from "./shared/services/cart.service";
import { NewCartService } from "./shared/services/newcart.service";
import { NotificationService } from "./shared/services/notification.service";
import { UserService } from "./shared/services/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'katapulk';
  languages = [
    { id: "es", title: "LANGUAGES.ES", flag: "es" },
    { id: "en", title: "LANGUAGES.EN", flag: "us" }
  ];
  selectedLanguage!: { id: string; title: string; flag: string };

  destroy$: Subject<boolean> = new Subject<boolean>();
  modalOpen = false;

  private readonly config: { version: string };
  first_time = 0;

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private zonesService: ZonesService,
    private httpClient: HttpClient,
    private router: Router,
    private dynamicService: DynamicCOnfigService,
    private onfidoService: OnfidoService,
    private algoliaService: AlgoliaService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private newCartService: NewCartService,
    private cartService: CartService,
    private userService: UserService,
    private frontendFeatureService: FrontendFeatureService,
    private notificationService: NotificationService
  ) {

    this.config = CONFIG;
    this.verifyVersionChanges();
    translateService.addLangs(["en", "es"]);
    // translateService.setDefaultLang('es');
    // translateService.use('es');

    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es'; // this.translateService.getBrowserLang();
      localStorage.setItem("language", JSON.stringify(language));
      translateService.setDefaultLang(language);
      translateService.use(language);
    }
    const findLanguage = this.languages.find(it => it.id === language);
    this.selectedLanguage = findLanguage ? findLanguage : this.languages[0];
    translateService.setDefaultLang(this.selectedLanguage.id);
    translateService.use(this.selectedLanguage.id);

    if (this.languageService.selectedLanguage.getValue() != this.selectedLanguage.id) {
      this.languageService.selectedLanguage.next(this.selectedLanguage.id);
    }

    document.documentElement.addEventListener('click', (event) => {
      if (!localStorage.getItem('first_action') && !this.modalOpen) {
        this.showCountryProvinceModal();
      }
    }, { once: false });
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const code = this.route.snapshot.queryParams['code'];
      if (code) {
        this.authService.getTokensWithCode(code).subscribe(resp => {
          if (resp?.error) {
            this.newCartService.removeCartValuesInStorage();
            this.authService.logout();
            this.router.navigate(['/sign-in']);
            this.dialog.closeAll();
            this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
          } else {
            this.initUser();
          }
        });
      }
    });

    this.languageService.selectedLanguage.subscribe(() => {
      setTimeout(() => {
        this.getDynamicSettings();
      });
    })

    this.zonesService.subjectSelectedZones$.subscribe((data: any) => {
      setTimeout(() => {
        if (this.first_time == 1) {
          this.getDynamicSettings();
        }
      });
    })

    this.onfidoService.subscribeToTopic();
    !navigator.share ? this.cookieService.set('can-share', 'false') : this.cookieService.set('can-share', 'true');
  }

  verifyVersionChanges() {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('x-refresh', 'true');
      this.httpClient
        .get<{ version: string }>("/assets/config.json", { headers })
        .pipe(takeUntil(this.destroy$))
        .subscribe(config => {
          if (config.version !== this.config.version) {
            const reloadUrl = new URL(location.href);
            reloadUrl.searchParams.set('reloadTime', Date.now().toString());
            location.href = reloadUrl.toString();
          }
        });
    });
  }

  showCountryProvinceModal() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      let dialoRef = this.dialog.open(ProvinceSelectionComponent, {
        disableClose: false,
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {
            this.modalOpen = false;
          }),
          error: (err => {
            this.modalOpen = false;
            throw new Error(err);
          })
        })
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  getDynamicSettings() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es'; // this.translateService.getBrowserLang();
      this.aux(language);
    } else {
      this.aux(language);
    }
  }

  private aux(language: string) {
    const VARIABLES: any = {
      locale: language, countryCode: this.getSelectedZone?.area_selected?.toLowerCase() || 'cu',
      env: this.dynamicService.getEnv()
    };
    let HOME_SETTINGS_QUERY_VARIABLES: any = [];
    let global_settings: any = {};

    this.dynamicService.getFrontendSettings(language).subscribe((data: any) => {
      this.first_time = 1;
      global_settings.FRONT_SETTINGS = data;
      localStorage.setItem('GLOBAL_CONFIGS', JSON.stringify(global_settings));
      let home_settings_query = global_settings?.FRONT_SETTINGS?.data[0]?.attributes?.query_discoveries?.data
        .find((element: any) => element?.attributes?.Key == 'country-home');
      const HOME_SETTINGS_QUERY = home_settings_query?.attributes?.Query?.Uri;
      home_settings_query?.attributes?.Query?.Variables.map((element: any) => {
        HOME_SETTINGS_QUERY_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
      });
      HOME_SETTINGS_QUERY_VARIABLES.push({ key: 'query_discovery_key', value: 'country-home' });
      this.searchQuery(HOME_SETTINGS_QUERY, HOME_SETTINGS_QUERY_VARIABLES, global_settings);
    })
  }

  private searchQuery(HOME_SETTINGS_QUERY: any, HOME_SETTINGS_QUERY_VARIABLES: any, global_settings: any) {
    this.dynamicService.doDynamicQuery(HOME_SETTINGS_QUERY, HOME_SETTINGS_QUERY_VARIABLES, '&populate=Grids.Query', '&populate=MiscQueries.Query')
      .subscribe((data: any) => {
        global_settings.HOME_SETTINGS = data;

        localStorage.setItem('GLOBAL_CONFIGS', JSON.stringify(global_settings));
        this.dynamicService.dynamic_settings_subject.next(global_settings);
      });
  }

  initUser(): void {
    this.userService.getUser()
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => {
          return this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.PAYMENT_KATAPULK_PAYMENTS);
          // return forkJoin([
          //   this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.ORDERS_CART_V3),
          //   this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.PAYMENT_KATAPULK_PAYMENTS)
          // ]);
        })

      )
      .subscribe((response: any) => {
        // const cartFeature = response.features;
        const paymentFeature = response.features;
        let user: User = this.authService.getCurrentUser();
        if (paymentFeature.length && paymentFeature[0].enabled) {
          user = { ...user, ...{ useNewPayment: true, readyToUse: true } };
        } else {
          user = { ...user, ...{ useNewPayment: false, readyToUse: true } };
        }
        this.authService.saveUser(user);
      });
  }

}
