import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import {
  Card,
  PaymentMethodConfiguration,
  ProductInCart,
  SelectedZone,
  ShippingRateInterfaceModel, StrapiResponseData,
  WalletResponse
} from "../models";
import { LanguageService } from "./language.service";
import { CartService } from "./cart.service";
import { AddressService } from "./address.service";
import { AddressInterfaceModel, CartCheckoutInterface, CartStoreInterface } from "../models/checkout.model";
import { StoreService } from "./store.service";
import { forkJoin, Observable, retry, from } from "rxjs";
import { PaymentMethodInterfaceModel } from "../models/payment-method.model";
import { CheckoutEnum } from "../enum/checkout.enum";
import { NotificationService } from "./notification.service";
import { Router } from "@angular/router";
import { ZonesService } from "./zones.service";
import { environment } from "../../../environments/environment";
import { FingerprintService } from "./fingerprint.service";
import { TranslateService } from "@ngx-translate/core";
import { EnvironmentEnum } from "../enum/environment.enum";

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private resourceCheckout = '/storefront/checkout';
  private resourceCheckoutComplete = '/storefront/checkout/complete';
  private resourceCart = '/storefront/cart';
  private resourceCheckoutPaymentMethods = '/storefront/checkout/available_payment_methods';
  private resourceCheckoutDefaultCreditCart = '/storefront/account/credit_cards/default';
  private paymentIntentionUrl = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentIntention`;
  private paymentMethodConfiguration = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentClient/Settings/Me`;
  private externalPaymentUrl = `${environment.wallet_api_url}${environment.v1}/Payment/StartExternalPayment`;
  private intentionExtraDataUrl = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentClient/Settings/IntentionExtraData`;
  private laNavePaymentUrl = `${environment.wallet_api_url}${environment.v1}/LaNave/LaNaveOrders/Load`;
  private giftCardPaymentUrl = `${environment.wallet_api_url}${environment.v1}/GiftCardOrders/GiftCardOrders/Place`;
  private giftCardPaymentUrl2 = `${environment.wallet_api_url}${environment.v2}/GiftCardOrders/Pay`;
  private currenciesAvailability = `${environment.wallet_api_url}${environment.v1}/Payment/CurrencyPurchaseAvailability`;
  private cubacelPaymentUrl = `${environment.wallet_api_url}${environment.v1}/CubacelTopUp/CubacelTopUpOrders/Place`;
  private cubacelPaymentUrlV2 = `${environment.wallet_api_url}${environment.v2}/CubacelTopUpOrders/Pay`;
  private payment_requests_payment_url = `${environment.wallet_api_url}${environment.v1}/Payment/PaymentRequest/CompletePaymentIntention`;

  maxRetries = environment.retry_on_fail;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private httpService: HttpClient,
    private cartService: CartService,
    private addressService: AddressService,
    private storeService: StoreService,
    private zonesService: ZonesService,
    private notificationService: NotificationService,
    private router: Router,
    private fingerprintService: FingerprintService,
    private languageService: LanguageService,
  ) { }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  shoppingCartExpired() {
    localStorage.setItem(CheckoutEnum.IS_WAS_SHOW_NOT_FOUND_404, JSON.stringify(true));
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION);
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(CheckoutEnum.DELIVERY);
    localStorage.removeItem(CheckoutEnum.PAYMENT);
    localStorage.removeItem(CheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(CheckoutEnum.CONFIRM);
    localStorage.removeItem(CheckoutEnum.CONFIRM);
    localStorage.removeItem(CheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
    this.notificationService.showAndSubscribe('SHOPPING_CART_EXPIRED_IN_CHECKOUT', 'CLOSE');
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart`]);
  }

  getIntentionExtraData(paymentVariantId: number) {
    const httpParams = new HttpParams()
      .set('PaymentVariantId', paymentVariantId);

    return this.httpService.get(this.intentionExtraDataUrl, { headers: this.getHeader(), params: httpParams });
  }

  getCurrenciesAvailability(captchaToken: string, amount: number, variantId: number) {
    const httpParams = new HttpParams()
      .set('CaptchaToken', captchaToken)
      .set('VariantId', variantId)
      .set('Amount', Math.round(Number(amount.toFixed(2)) * 100));

    return this.httpService.get(this.currenciesAvailability, { headers: this.getHeader(), params: httpParams });
  }

  getDefaultCreditCard() {
    const httpParams = new HttpParams();
    return this.apiService.get(this.resourceCheckoutDefaultCreditCart, httpParams, true, this.cartService.getHeader()).pipe(map(response => {
      const creditCard: Card = {
        id: response.data.id,
        cc_type: response.data.attributes.cc_type,
        default: response.data.attributes.default,
        last_digits: response.data.attributes.last_digits,
        month: response.data.attributes.month,
        name: response.data.attributes.name,
        year: response.data.attributes.year,
        type: response.data.type
      };
      return creditCard;
    }));
  }

  cleanStorage() {
    localStorage.removeItem(CheckoutEnum.IS_WAS_SHOW_NOT_FOUND_404);
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION);
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(CheckoutEnum.DELIVERY);
    localStorage.removeItem(CheckoutEnum.PAYMENT);
    localStorage.removeItem(CheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(CheckoutEnum.CONFIRM);
    localStorage.removeItem(CheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
  }

  complete(zone_id: number, locale: string) {
    const httpParams = new HttpParams()
      .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types')
      .set('zone', zone_id)
      .set('locale', locale);

    return this.apiService.patch(this.resourceCheckoutComplete, null, httpParams, true, this.cartService.getHeader(), false);
  }

  getAvailablePaymentMethods(locale: string) {
    const httpParams = new HttpParams().set('locale', locale);

    return this.apiService.get(this.resourceCheckoutPaymentMethods, httpParams, true, this.cartService.getHeader()).pipe(map(response => {
      return this.mapAvailablePaymentMethods(response.data);
    }));
  }

  mapAvailablePaymentMethods(data: any[] | null): PaymentMethodInterfaceModel[] {
    const payments: PaymentMethodInterfaceModel[] = [];
    if (data) {
      data.forEach(it => {
        payments.push({
          id: it.id,
          description: it.attributes.description,
          name: it.attributes.name,
          type: it.attributes.type,
        });
      });
    }
    return payments;
  }

  updateCheckout(data: any, locale: string, isMappingResponse: boolean = false) {
    const httpParams = new HttpParams()
      .set('locale', locale)
      .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types');

    if (isMappingResponse) {
      return from(
        new Promise((resolve, reject) => {
          this.apiService.patch(this.resourceCheckout, data, httpParams, true, this.cartService.getHeader(), false).subscribe(response => {
            this.mapCartAndStores(response, resolve);
          }, error => {
            if (error.status === 404 || error.status === 500) {
              // this.cartService.removeCartValuesInSessionStorage();
            }
            reject(error)
          });
        })
      );
    } else {
      return this.apiService.patch(this.resourceCheckout, data, httpParams, true, this.cartService.getHeader(), false);
    }
  }

  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders.set('X-Payment-Client', environment.xApiClient)
      .set('ngrok-skip-browser-warning', 'web')
      .set('Accept-Language', this.languageService.selectedLanguage.value);
    return httpHeaders;
  }

  private getHeaderStrapi(): HttpHeaders {
    return new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/json;')
      .set('x-refresh', 'true');
  }

  paymentIntention(data: any) {
    return this.httpService.post(this.paymentIntentionUrl, data, { headers: this.getHeader() });
  }

  getPaymentMethodConfiguration(locale: string, route?: string) {
    let params = new HttpParams()
      .set('locale', locale);
    if (route) params = params.append('PaymentRoute', route);
    return this.httpService.get(this.paymentMethodConfiguration, { headers: this.getHeader(), params })
      .pipe(
        retry(this.maxRetries),
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        map((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
          return <PaymentMethodConfiguration>response.data;
        })
      );
  }

  async getFingerPrint() {
    return await new Promise(async (resolve, reject) => {
      let sign = null;
      const data = await this.fingerprintService.getVisitorData(this.maxRetries);
      if (data) {
        sign = {
          requestId: data.requestId,
          visitorId: data.visitorId,
        };
      }
      resolve(sign);
    });
  }

  getCheckoutNext(zone_id: number, locale: string): Promise<CartCheckoutInterface> {
    return new Promise((resolve, reject) => {
      const httpParams = new HttpParams()
        .set('zone', zone_id)
        .set('locale', locale)
        .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types');

      this.apiService.patch(this.resourceCheckout + '/next', null, httpParams, true, this.cartService.getHeader(), true).subscribe(response => {
        this.mapCartAndStores(response, resolve);
      }, error => {
        if (error.status === 404 || error.status === 500) {
          // this.cartService.removeCartValuesInSessionStorage();
        }
        reject(error)
      });
    });
  }

  private mapCartAndStores(response: any, resolve: any) {
    this.mapStores(response, true).subscribe(stores => {
      const addresses = response.included.filter((obj: any) => obj.type === 'address');
      const addressObj = addresses.length ? addresses.find((address: any) => address.id == response.data.relationships.shipping_address.data.id) : null;
      const shippingAddressString: string = addressObj ?
        addressObj.attributes.firstname + ' ' + addressObj.attributes.lastname + ': ' + addressObj.attributes.address1 + ', ' + addressObj.attributes.city + ', ' + addressObj.attributes.state_name + ', ' + addressObj.attributes.country_name + ', ' + addressObj.attributes.zipcode
        : '';
      const total = stores.reduce((prev, curr) => prev + curr.subTotal, 0);
      const billing_address_aux = addresses.find((it: any) => it.id == response.data.relationships.billing_address?.data?.id);
      const shipping_address = addresses.find((it: any) => it.id == response.data.relationships.shipping_address.data.id);
      const cart: CartCheckoutInterface = {
        id: response.data.id,
        item_count: response.data.attributes.item_count,
        number: response.data.attributes.number,
        total: Number(response.data.attributes.total),
        ship_total: Number(response.data.attributes.ship_total),
        item_total: Number(total.toFixed(2)),
        display_item_total: response.data.attributes.display_item_total,
        token: response.data.attributes.token,
        currency: response.data.attributes.currency,
        shipment_state: response.data.attributes.shipment_state,
        payment_state: response.data.attributes.payment_state,
        state: response.data.attributes.state,
        stores: stores,
        shippingAddress: shippingAddressString,
        billing_address: billing_address_aux ? this.mapAddress(billing_address_aux) : undefined,
        shipping_address: this.mapAddress(shipping_address),
        email: response.data.attributes.email,
        email_receiver: response.data.attributes.email_receiver,
        promo_total: Number(response.data.attributes.promo_total),
        display_promo_total: response.data.attributes.display_promo_total,
        adjustment_payment_method: response.data.attributes.adjustment_payment_method ? Number(response.data.attributes.adjustment_payment_method) : undefined,
        adjustment_payment_method_total: response.data.attributes.adjustment_payment_method_total ? Number(response.data.attributes.adjustment_payment_method_total) : undefined,
        payment_variant_id: response.data.attributes.payment_variant_id ? Number(response.data.attributes.payment_variant_id) : undefined,
      };
      resolve(cart);
    });
  }

  getCartCheckout(zone_id: number, locale: string, isNextCheckout: boolean = false): Promise<CartCheckoutInterface> {
    return new Promise((resolve, reject) => {
      const httpParams = new HttpParams()
        .set('zone_id', zone_id)
        .set('locale', locale)
        .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types,municipality,municipality.state');

      this.apiService.get(this.resourceCart, httpParams, true, this.cartService.getHeader()).subscribe(response => {
        this.mapStores(response, isNextCheckout).subscribe(stores => {
          const cart: CartCheckoutInterface = {
            id: response.data.id,
            item_count: response.data.attributes.item_count,
            number: response.data.attributes.number,
            total: Number(response.data.attributes.total),
            ship_total: Number(response.data.attributes.ship_total),
            item_total: Number(response.data.attributes.item_total),
            display_item_total: response.data.attributes.display_item_total,
            token: response.data.attributes.token,
            currency: response.data.attributes.currency,
            shipment_state: response.data.attributes.shipment_state,
            payment_state: response.data.attributes.payment_state,
            state: response.data.attributes.state,
            stores: stores,
            promo_total: Number(response.data.attributes.promo_total),
            display_promo_total: response.data.attributes.display_promo_total,
          };
          resolve(cart);
        });
      }, error => {
        if (error.status === 404 || error.status === 500) {
          this.cartService.removeCartValuesInStorage();
        }
        reject(error)
      });
    });
  }

  startExternalPayment(data: any) {
    return this.httpService.post(this.externalPaymentUrl + '2', data, { headers: this.getHeader() });
  }

  getNextRequestString(): string {
    return `${environment.base_route}${environment.v2}/storefront/checkout` + '/next';
  }

  mapAddress(address: any): AddressInterfaceModel {
    return {
      id: address.id,
      address1: address.attributes.address1,
      address2: address.attributes.address2,
      city: address.attributes.city,
      company: address.attributes.company,
      country_iso: address.attributes.country_iso,
      country_iso3: address.attributes.country_iso3,
      country_name: address.attributes.country_name,
      firstname: address.attributes.firstname,
      label: address.attributes.label,
      lastname: address.attributes.lastname,
      phone: address.attributes.phone,
      state_code: address.attributes.state_code,
      state_name: address.attributes.state_name,
      zipcode: address.attributes.zipcode,
    };
  }

  mapStores(cartResponse: any, isNextCheckout: boolean = false): Observable<CartStoreInterface[]> {
    const stores: CartStoreInterface[] = [];
    const productsInCart: ProductInCart[] = [];
    const storeIds: number[] = [];

    const shippingRates = cartResponse.included.filter((obj: any) => obj.type === 'shipping_rate'); //son 3
    const shipments = cartResponse.included.filter((obj: any) => obj.type === 'shipment'); //son 2

    cartResponse.data.relationships.line_items.data.forEach((li: any) => {
      const lineItem = cartResponse.included.find((obj: any) => obj.type === 'line_item' && obj.id === li.id);

      if (!storeIds.some(it => it === lineItem.attributes.store_id)) {
        storeIds.push(lineItem.attributes.store_id);
      }

      const usedVariantId = lineItem.relationships.variant.data?.id; // "1056"
      const usedVariantObj = cartResponse.included.find((obj: any) => obj.type === 'variant' && obj.id === usedVariantId);
      let imageId = usedVariantObj?.relationships?.images?.data[0]?.id || null;
      if (imageId === null) {
        const usedProductId = usedVariantObj.relationships.product.data?.id; // "1029"
        const usedProductObj = cartResponse.included.find((obj: any) => obj.type === 'product' && obj.id === usedProductId);
        imageId = usedProductObj?.relationships?.images?.data[0]?.id || null;
      }
      const pic: ProductInCart = {
        id: lineItem.id,
        name: lineItem.attributes.name,
        quantity: lineItem.attributes.quantity,
        currency: lineItem.attributes.currency,
        price: Number(lineItem.attributes.price),
        optionsText: lineItem.attributes.options_text,
        store_id: lineItem.attributes.store_id,
        included_tax_total: Number(lineItem.attributes.included_tax_total),
        total: Number(lineItem.attributes.total),
        imageUrl: this.cartService.findImageFromResponse(imageId, cartResponse.included)
      };
      if (isNextCheckout) {
        const shipment = shipments.find((it: any) => it.id == lineItem.attributes.inventory_unit.shipment_id);
        pic.shipment = {
          id: lineItem.attributes.inventory_unit.shipment_id.toString(),
          display_final_price: shipment.attributes.display_final_price,
          final_price: shipment.attributes.final_price,
          free: shipment.attributes.free,
          number: shipment.attributes.number,
          store_ids: shipment.attributes.store_ids,
          shipping_rates: shipment.relationships.shipping_rates.data, // {id, type}\
        };
      }
      productsInCart.push(pic);
    });

    const storeQueries: any = [];
    storeIds.forEach((storeId) => {
      storeQueries.push(this.storeService.getStoreById(storeId));
    });
    return forkJoin(storeQueries).pipe(
      map((storeArray: any) => {
        let store: CartStoreInterface;
        storeArray.forEach((store: any) => {
          const products = productsInCart.filter((it) => it.store_id == store.id);
          const totalPriceProducts = products.reduce((prev, curr) => prev + (curr.total ? curr.total : 0), 0);
          const included_tax_total = products.reduce((prev, curr) => prev + (curr.included_tax_total ? curr.included_tax_total : 0), 0);
          const totalCountProducts = products.reduce((prev, curr) => prev + curr.quantity, 0);

          let storeAux: CartStoreInterface = {
            id: store.id,
            logoUrl: store.logoUrl,
            name: store.name,
            products: products,
            totalPriceProducts: totalPriceProducts,
            included_tax_total: included_tax_total,
            subTotal: totalPriceProducts,
            shippingRatePriceSelected: 0.0,
            shipments: [],
            totalCountProducts: totalCountProducts,
            favorite_color: store.favorite_color
          };
          if (isNextCheckout) {
            const storeShipmentIds: { id: string, shippingRateIds: string[] }[] = [];
            products.forEach(prod => {
              if (prod.shipment && prod.shipment.id) {
                if (!storeShipmentIds.some(it => prod.shipment?.id == it.id)) {
                  storeShipmentIds.push({
                    id: prod.shipment?.id,
                    shippingRateIds: prod.shipment.shipping_rates.map(it => it.id)
                  });
                }
              }
            });
            storeShipmentIds.forEach(ship => {
              const shippingRatesAux = shippingRates.filter((shi: any) => ship.shippingRateIds.some((predicate: string) => shi.id == predicate));
              const mapListShippingRates: ShippingRateInterfaceModel[] = [];
              if (shippingRatesAux.length) {
                shippingRatesAux.forEach((aux: any) => {
                  mapListShippingRates.push({
                    id: aux.id,
                    cost: aux.attributes.cost,
                    display_cost: aux.attributes.display_cost,
                    display_final_price: aux.attributes.display_final_price,
                    display_tax_amount: aux.attributes.display_tax_amount,
                    final_price: aux.attributes.final_price,
                    free: aux.attributes.free,
                    name: aux.attributes.name,
                    selected: aux.attributes.selected,
                    shipping_method_id: aux.attributes.shipping_method_id,
                    tax_amount: aux.attributes.tax_amount
                  });
                });
              }
              const final_price = shipments.find((it: any) => it.id === ship.id)?.attributes.final_price;
              storeAux.shipments?.push({
                storeId: store.id,
                shipmentId: ship.id,
                final_price: final_price,
                products: products.filter(pro => pro.shipment?.id == ship.id),
                shippingRates: mapListShippingRates
              });
            });
            //Tengo los productos q tienen el shipment.id
            // buscar todos los shippingRates los q tengan id del shipment del producto, sino esta adicionarlo y si esta no hacer nada
            const shipmentIds: string[] = [];
            products.forEach((prod: ProductInCart) => {
              if (prod.shipment && prod.shipment.shipping_rates) {
                prod.shipment.shipping_rates.forEach(it => {
                  shipmentIds.push(it.id);
                });
              }
            });
            const shippingRatesByStore = shippingRates.filter((shipping: any) => shipmentIds.some(ship => ship == shipping.id));
            storeAux.shippingRates = [];
            let shippingRatePriceSelected = 0;

            storeAux.shipments.forEach(shipment => {
              const shippingSelected = shipment.shippingRates.find(shipping => shipping.selected);
              if (shippingSelected) {
                shippingRatePriceSelected += Number(shippingSelected.cost);
              }
            });

            shippingRatesByStore.forEach((elemt: any) => {
              storeAux.shippingRates?.push({
                id: elemt.id,
                cost: elemt.attributes.cost,
                display_cost: elemt.attributes.display_cost,
                display_final_price: elemt.attributes.display_final_price,
                display_tax_amount: elemt.attributes.display_tax_amount,
                final_price: elemt.attributes.final_price,
                free: elemt.attributes.free,
                name: elemt.attributes.name,
                selected: elemt.attributes.selected,
                shipping_method_id: elemt.attributes.shipping_method_id,
                tax_amount: elemt.attributes.tax_amount
              });
            });
            storeAux.shippingRatePriceSelected = shippingRatePriceSelected;
            storeAux.subTotal = totalPriceProducts + shippingRatePriceSelected;
          }
          stores.push(storeAux);
        });
        return stores;
      })
    );
  }

  getPaymentVariantFee(paymentVariantId: number, locale: string): Promise<CartCheckoutInterface> {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
        .set('locale', locale)
        .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types');

      this.apiService.post(
        `${this.resourceCheckout}/update_order_payment_adjustment`,
        { payment_variant_id: paymentVariantId },
        params,
        true,
        this.cartService.getHeader()
      )
        .subscribe({
          next: response => {
            this.mapCartAndStores(response, resolve);
          },
          error: (error) => {
            if (error.status === 404 || error.status === 500) {
              // this.cartService.removeCartValuesInSessionStorage();
            }
            reject(error);
          }
        });
    });
  }

  /**
   *
   * @param agreementQuery
   * @param locale
  */
  getAgreementPaymentVariant(agreementQuery: { uri: string, variables: string[] }, locale: string) {
    let uri = `${environment.strapi_route}/${agreementQuery.uri}`;

    agreementQuery.variables.forEach((variable) => {
      switch (variable) {
        case 'env':// @ts-ignore
          uri = uri.replace('{{env}}', EnvironmentEnum[environment.name]);
          break;
        case 'locale':
          uri = uri.replace('{{locale}}', locale);
          break;
      }
    });

    return this.httpService.get(`${uri}`, { headers: this.getHeaderStrapi() })
      .pipe(
        retry(this.maxRetries),
        // @ts-ignore
        catchError((error) => {
          return this.apiService.handleErrors(error, true);
        }),
        map((response: WalletResponse) => {
          if (response.fails) {
            this.apiService.showError(response.message, true);
          }
          return <StrapiResponseData>(<StrapiResponseData[]>response.data)[0];
        })
      );
  }

  laNavePayment(data: any) {
    return this.httpService.post(this.laNavePaymentUrl, data, { headers: this.getHeader() });
  }

  giftCardPayment(data: any) {
    return this.httpService.post(this.giftCardPaymentUrl, data, { headers: this.getHeader() });
  }

  giftCardPaymentV2(data: any) {
    const queryParams = new HttpParams()
      .set('orderId', data.orderId)
      .set('paymentToken', data.paymentToken)
      .set('paymentVariantId', data.paymentVariantId)
      .set('visitorId', data.visitorId)
      .set('requestId', data.requestId);

    return this.httpService.get(this.giftCardPaymentUrl2, { headers: this.getHeader() , params: queryParams });
  }

  cubacelPayment(data: any) {
    return this.httpService.post(this.cubacelPaymentUrl, data, { headers: this.getHeader() });
  }

  cubacelPaymentV2(data: any) {
    const queryParams = new HttpParams()
      .set('orderId', data.orderId)
      .set('paymentToken', data.paymentToken);
    return this.httpService.get(this.cubacelPaymentUrlV2, { headers: this.getHeader(), params: queryParams });
  }

  placeV2CubacelOrder(data: any) {
    return this.httpService.post(`${environment.wallet_api_url}${environment.v2}/CubacelTopUpOrders/PlaceV2`, data, { headers: this.getHeader() });
  }

  placeV2GiftCardOrder(data: any) {
    return this.httpService.post(`${environment.wallet_api_url}${environment.v2}/GiftCardOrders/PlaceV2`, data, { headers: this.getHeader() });
  }
  paymentRequestPay(data: any) {
    return this.httpService.post(this.payment_requests_payment_url, data, { headers: this.getHeader() });
  }

}
