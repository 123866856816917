import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Product, SelectedZone } from "../../../../shared/models";
import { FormControl, FormGroup } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ZonesService } from "../../../../shared/services/zones.service";
import { SEARCH_FILTER_DEFAULT_VALUE, SEARCH_FILTERS, SearchFiltersType } from "../../../../shared/filters/searc-filters";
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-store-detail-grid',
  templateUrl: './store-detail-grid.component.html',
  styleUrls: ['./store-detail-grid.component.scss']
})
export class StoreDetailGridComponent implements OnInit, OnDestroy, OnChanges {
  show_grid = true;
  @Input() products: any[] = [];
  @Input() skeletons: number[] = [];
  @Input() haveMore: boolean = true;
  @Input() order: any = SEARCH_FILTER_DEFAULT_VALUE;
  @Input() showGrid: boolean = true;
  selected_view = "grid";

  @Output() loadMore = new EventEmitter<any>();
  @Output() changeShowGrid = new EventEmitter<boolean>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  filterForm: FormGroup;
  isInitChange: boolean = true;

  orderByArray: SearchFiltersType[] = SEARCH_FILTERS;


  viewModeArray: { filter: string, text: string }[] = [
    { filter: 'grid', text: 'GRID' },
    { filter: 'list', text: 'LIST' }
  ];

  constructor(
    private zonesService: ZonesService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.order = SEARCH_FILTER_DEFAULT_VALUE;
    this.filterForm = new FormGroup({
      order: new FormControl({ value: this.order, disabled: !this.getValidSelectedZone }),
    });
    this.filterForm.get('order')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.getSelectedZone && !this.isInitChange) {
          this.router.navigate(
            [],
            {
              relativeTo: this.activeRoute,
              queryParams: { order: this.filterForm.get('order')?.value?.text },
              queryParamsHandling: 'merge'
            }
          );
        }
        if (this.isInitChange) {
          this.isInitChange = false;
        }
      })
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (changes.products = null) {
      this.products = [];
    }
    if (changes && changes.order && changes.order.currentValue) {
      this.filterForm.patchValue({
        order: SEARCH_FILTERS.find((filter: any) => filter?.text === changes.order.currentValue || filter?.text === changes.order.currentValue?.text) || SEARCH_FILTER_DEFAULT_VALUE
      });
    }
    if (changes && changes.showGrid) {
      this.selected_view = changes.showGrid.currentValue === true ? 'grid' : 'list';
      ['true', true].includes(changes?.showGrid?.currentValue) ? this.show_grid = true : this.show_grid = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  emitLoadMore(initialLoad = false) {
    this.loadMore.emit({ initialLoad, order: this.filterForm.get('order')?.value });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  get getValidSelectedZone(): boolean {
    const selectedZone = this.getSelectedZone;
    if (!selectedZone) return false;
    if (selectedZone?.area_selected) {
      if (selectedZone?.area_selected === 'CU') {
        return !!selectedZone?.zone && !!selectedZone?.municipality;
      }
      return !!selectedZone?.zone;
    }
    return false;
  }

  viewMode(e: any) {
    const value = e?.value;
    let params: any = {};
    switch (value) {
      case 'grid':
        params.show_grid = true;
        this.router.navigate(
          [],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
        break;
      case value:
        params.show_grid = false;
        this.router.navigate(
          [],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
        break;
      default:
        break;
    }
  }

}
