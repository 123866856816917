import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LineItem, ShopListItem} from "../../../../../shared/models/shop-list.model";
import {LanguageService} from "../../../../../shared/services/language.service";
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CreateShopListComponent} from "../create-shop-list/create-shop-list.component";
import {NotificationService} from "../../../../../shared/services/notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-shopping-list-card',
  templateUrl: './shopping-list-card.component.html',
  styleUrls: ['./shopping-list-card.component.scss']
})
export class ShoppingListCardComponent implements OnInit, OnDestroy {
  @Input('item') shopList!: ShopListItem;
  @Output('reloadList') reloadList = new EventEmitter();

  lang: string = 'es';
  nameLang: 'nameEs' | 'nameEn' = 'nameEs'
  destroy$: Subject<boolean> = new Subject<boolean>();
  groupedLineItems: { item: LineItem, count: number }[] = [];


  constructor(
    private languageService: LanguageService,
    private shopListService: ShopListService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.languageService.selectedLanguage
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lang) => {
          this.lang = lang
          this.nameLang = lang === 'es' ? "nameEs" : "nameEn"
        }
      })

    this.groupLineItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  removeList(id: string) {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        messageUppercase: true,
        action: this.shopListService.removeShopList({id}),
        cancelAction: 'CANCEL',
        message: "ARE_YOU_SURE_ERASE_SHOP_LIST",
        notificationSuccess: "ERASE_SHOP_LIST_SUCCESS",
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          this.reloadList.emit(response.success);
        }
      });
  }

  editShopList(shopList: ShopListItem, isChangeLocation = false) {
    this.dialog.open(CreateShopListComponent, {
      disableClose: true,
      data: {
        shopList,
        isModeEdit: !isChangeLocation,
        isChangeLocation
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          const message = isChangeLocation ? 'SHOP_LIST_CHANGE_LOCATION_SUCCESSFULLY' : 'SHOP_LIST_EDITED_SUCCESSFULLY';
          this.notificationService.showAndSubscribe(message, 'ACCEPT');
          this.reloadList.emit(response.success);
        }
      });
  }

  shopListDetails(shopListId: string) {
    this.router.navigate([shopListId]);
  }

  groupLineItems() {
    const itemMap = new Map<string, { item: LineItem, count: number }>();

    this.shopList.lineItems.forEach(item => {
      if (itemMap.has(item.variantId)) {
        itemMap.get(item.variantId)!.count += item.count;
      } else {
        itemMap.set(item.variantId, {item, count: item.count});
      }
    });

    this.groupedLineItems = Array.from(itemMap.values());
  }

}
