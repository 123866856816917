import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CreateCartRequest, LineItem, ShopListItem} from "../../../../../shared/models/shop-list.model";
import {LanguageService} from "../../../../../shared/services/language.service";
import {filter, finalize, takeUntil} from "rxjs/operators";
import {of, Subject} from "rxjs";
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {CreateShopListComponent} from "../create-shop-list/create-shop-list.component";
import {NotificationService} from "../../../../../shared/services/notification.service";
import {Router} from "@angular/router";
import {SelectedZone} from "../../../../../shared/models";
import {ZonesService} from "../../../../../shared/services/zones.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {NewCartService} from "../../../../../shared/services/newcart.service";
import {StorageService} from "../../../../../shared/services/storage.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-shopping-list-card',
  templateUrl: './shopping-list-card.component.html',
  styleUrls: ['./shopping-list-card.component.scss']
})
export class ShoppingListCardComponent implements OnInit, OnDestroy {
  @Input('item') shopList!: ShopListItem;
  @Output('reloadList') reloadList = new EventEmitter();

  lang: string = 'es';
  nameLang: 'nameEs' | 'nameEn' = 'nameEs'
  destroy$: Subject<boolean> = new Subject<boolean>();
  groupedLineItems: { item: LineItem, count: number }[] = [];

  isCreatingCart: boolean = false;
  readonly faSpinner = faSpinner;



  private readonly cartErrorKeys = {
    ITEMS_HAS_BEEN_UPDATED: 'ITEMS_HAS_BEEN_UPDATED',
    GENERAL_CART_ERROR_MESSAGE: 'GENERAL_CART_ERROR_MESSAGE',
  };


  constructor(
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
    private readonly shopListService: ShopListService,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly zonesService: ZonesService,
    private readonly newCartService: NewCartService,
    private readonly storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.languageService.selectedLanguage
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lang) => {
          this.lang = lang
          this.nameLang = lang === 'es' ? "nameEs" : "nameEn"
        }
      })

    this.groupLineItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  removeList(id: string) {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        messageUppercase: true,
        action: this.shopListService.removeShopList({id}),
        cancelAction: 'CANCEL',
        message: "ARE_YOU_SURE_ERASE_SHOP_LIST",
        notificationSuccess: "ERASE_SHOP_LIST_SUCCESS",
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          this.reloadList.emit(response.success);
        }
      });
  }

  editShopList(shopList: ShopListItem, isChangeLocation = false) {
    this.dialog.open(CreateShopListComponent, {
      disableClose: true,
      data: {
        shopList,
        isModeEdit: !isChangeLocation,
        isChangeLocation
      }
    }).afterClosed()
      .pipe(filter((name) => name))
      .subscribe((response) => {
        if (response.success) {
          const message = isChangeLocation ? 'SHOP_LIST_CHANGE_LOCATION_SUCCESSFULLY' : 'SHOP_LIST_EDITED_SUCCESSFULLY';
          this.notificationService.showAndSubscribe(message, 'ACCEPT');
          this.reloadList.emit(response.success);
        }
      });
  }

  shopListDetails(shopListId: string) {
    this.router.navigate([shopListId]);
  }

  groupLineItems() {
    const itemMap = new Map<string, { item: LineItem, count: number }>();

    this.shopList.lineItems.forEach(item => {
      if (itemMap.has(item.variantId)) {
        itemMap.get(item.variantId)!.count += item.count;
      } else {
        itemMap.set(item.variantId, {item, count: item.count});
      }
    });

    this.groupedLineItems = Array.from(itemMap.values());
  }

  createCart() {
    this.isCreatingCart= true;
    const data: CreateCartRequest = {
      shoppingListId: this.shopList.id,
      cleanShoppingCart: true,
      zoneId: String(this.getSelectedZone.zone),
      municipalityId: String(this.getSelectedZone.municipality)
    }

    const userHaveCart = this.newCartService.haveCart();
    if (!userHaveCart) {
      return this.performCreateCart(data);
    }
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        messageUppercase: true,
        action: of({success: true}),
        confirmAction: 'ADD_TO_CART',
        cancelAction: 'SHOPPING_LIST_CREATE_NEW_CART',
        message: "SHOPPING_CART_CREATE_CART_CONFIRMATION_TEXT",
      }
    }).afterClosed()
      .subscribe((response) => {
        if (response) {
          data.cleanShoppingCart = !response.success;
          this.performCreateCart(data);
        } else {
          this.isCreatingCart = false;
        }
      });
  }

  performCreateCart(data: CreateCartRequest) {
    this.shopListService.createCart(data).pipe(finalize(() => this.isCreatingCart = false)).subscribe({
      next: (result) => {
        const { success, data: {cart, itemHasBeenUpdated} } = result;
        if (success) {
          this.newCartService.saveInSessionAndSubject(cart);
          this.newCartService.cleanStorage();
          this.newCartService.cleanStorageDeliveryAndPayment();

          if (itemHasBeenUpdated) {
            this.storageService.set(this.cartErrorKeys.ITEMS_HAS_BEEN_UPDATED, itemHasBeenUpdated);
            this.storageService.set(this.cartErrorKeys.GENERAL_CART_ERROR_MESSAGE, this.translateService.instant('NEW_CART_IMPORTANT_MESSAGES'));
          }

          this.router.navigate([`/${this.getSelectedZone.area_selected.toLowerCase()}/cart/new`]);
        }
      }
    });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }
}
