import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HttpHeaders, HttpParams, HttpClient } from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
import {BehaviorSubject, Observable, of, take} from "rxjs";
import { Country, SelectedZone, SelectedZoneOther, Zone } from "../models";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import {ExcludeInitResourceEnum} from "../enum/exclude-init-resource.enum";
import {globalCategoriesMock} from "../mocks/global-categories";
import {countriesMock} from "../mocks/countries";

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  private resource = '/zones'; // peticion para /api/v1
  private resourceCountries = '/storefront/countries'; // peticion para /api/v2

  KeyStore = {
    SELECTED_ZONE: 'selected_zone',
  };

  subjectSelectedZones$: BehaviorSubject<SelectedZone | SelectedZoneOther | null> = new BehaviorSubject<SelectedZone | SelectedZoneOther | null>(null);
  banner_images_subject: BehaviorSubject<any> = new BehaviorSubject<any>(true);

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {
  }

  /**
   * Obtener los paises disponibles para la entrega. (shippable countries)
   */
  getShippableCountries(): Observable<Country[] | any> {
    const httpParams = new HttpParams().set('filter[shippable]', true);
    return this.apiService.get(`${this.resourceCountries}`, httpParams, true, this.getHeader(), true, true)
      .pipe(
        map((response): any[] => {
          return response.data.map((sp: any) => {
            return <Country>{
              id: sp.id,
              name: sp.attributes.name,
              iso: sp.attributes.iso,
              iso3: sp.attributes.iso3,
              iso_name: sp.attributes.iso_name,
            };
          });
        }),
        catchError((err: any): Observable<Country[]>  =>  {
          return of(
              countriesMock.data.map((sp: any) => {
                  return <Country>{
                      id: sp.id,
                      name: sp.attributes.name,
                      iso: sp.attributes.iso,
                      iso3: sp.attributes.iso3,
                      iso_name: sp.attributes.iso_name,
                  };
              })
          );
        })
      );
  }

  getBillableCountries(): Observable<Country[]> {
    const httpParams = new HttpParams();
    return this.apiService.get(this.resourceCountries + '/billable_countries', httpParams, true, this.getHeader())
      .pipe(
        map((response): any[] => {
          return response.data.map((sp: any) => {
            return <Country>{
              id: sp.id,
              name: sp.attributes.name,
              iso: sp.attributes.iso,
              iso3: sp.attributes.iso3,
              iso_name: sp.attributes.iso_name,
            };
          });
        })
      );
  }

  /**
   * Obtener pais disponible para la entrega. (shippable country)
   */
  getShippableCountryByIso2(countryIso: string): Observable<Country | undefined> {
    return this.getShippableCountries().pipe(
      map((countries: Country[]) => {
        return countries.find((c) => c.iso === countryIso);
      })
    );
  }

  /**
   * Obtener pais disponible para la entrega. (shippable country)
   */
  getShippableCountryByIso2Resolver(countryIso: string): Observable<Country | undefined> {
    // const useNewCart = this.authService.getCurrentUser()?.useNewCart || false;
    const useNewCart = true;
    return this.getShippableCountries().pipe(
      take(1),
      map((countries: Country[]) => {
        const country = countries.find((c) => c.iso === countryIso);
        if (country) {
          const areaSelected = this.getZonesValuesStorage()?.area_selected || null;
          const baseZone = {
            area_selected: countryIso,
            area_name: country.name // todo chequear en enentorno real si esto se puede cambiar para .iso_name
          };
          if (!areaSelected || areaSelected !== countryIso) {
            if (countryIso !== 'CU') {
              // todo cuando se cierre el modal de seleccion de zonas.. si el pais seleccionado es distinto del pais del local storage redireccionar para el home de ese país (???????)
              // todo si estoy en la vista de detalles del producto verificar si el producto existe en la zona seleccionada, si el producto no existe redireccionar para el home del pais seleccionado
              // todo si estoy en la vista de detalles de una tienda verificar si la existe en la zona seleccionada, si la tienda no existe redireccionar para el home del pais seleccionado
              this.getZonesWithCountries().pipe(take(1)).subscribe({
                next: (zs) => {
                  let countriesZones: any = {};
                  countriesZones = zs.reduce((acum: any, item: Zone) => {
                    if (!acum[item.countries_iso!]) {
                      acum[item.countries_iso!] = [item];
                    } else {
                      acum[item.countries_iso!].push(item);
                    }
                    return { ...acum };
                  }, countriesZones);
                  const zones = countriesZones[countryIso];
                  if (zones.length === 1) {
                    this.saveZoneValuesInStorage(Object.assign(baseZone, {
                      zone: zones[0].id,
                      zone_name: zones[0].name
                    }));
                  } else {
                    this.saveZoneValuesInStorageNoAction(baseZone);
                  }
                  return this.navigateAndReturn(country);
                },
                error: () => {
                  this.saveZoneValuesInStorageNoAction(baseZone);
                  return this.navigateAndReturn(country);
                }
              });
            } else {
              const excludedResources = ExcludeInitResourceEnum;
              const excluded = excludedResources.some((url) => this.router.url.includes(url));
              if (excluded) {
                return country;
              } else {
                this.saveZoneValuesInStorageNoAction(baseZone);
                return this.navigateAndReturn(country);
              }
            }
          }
        } else {
          if (!useNewCart) {
            this.router.navigate(['/', 'cu', 'home']);
          }
        }
        return country;
      })
    );
  }

  private navigateAndReturn(country: Country) {
    this.router.navigate(['/', country.iso.toLowerCase(), 'home']);
    return country;
  }

  /**
   * Obtener zonas (provincias) por paises
   */
  getZonesWithCountries(): Observable<Zone[]> {
    return this.apiService.get(`${this.resource}/zones_with_countries`, new HttpParams(), false, this.getHeader())
      .pipe(
        map((response): Zone[] => {
          return response.zones.map((zone: any) => {
            return {
              id: zone.id,
              name: zone.name,
              zoneableIds: zone.zone_members.map((zm: any) => zm.zoneable_id),
              countries_iso: zone.countries_iso[0]
            }
          });
        })
      );
  }

  getZonesValuesStorage(): SelectedZone {
    const values = JSON.parse(<string>localStorage.getItem(this.KeyStore.SELECTED_ZONE));
    const subjectValues = this.subjectSelectedZones$.getValue();
    if (values?.zone !== subjectValues?.zone && values?.municipality !== subjectValues?.municipality) {
      this.subjectSelectedZones$.next(values);
    }
    return values;
  }

  saveZoneValuesInStorage(values: SelectedZone | SelectedZoneOther) {
    // Para desuscribir la accion del listener del modal la primera vez
    localStorage.setItem('first_action', 'done');
    localStorage.setItem(this.KeyStore.SELECTED_ZONE, JSON.stringify(values));
    this.subjectSelectedZones$.next(values);
  }

  saveZoneValuesInStorageNoAction(values: SelectedZoneOther) {
    this.subjectSelectedZones$.next(null);
    // Para desuscribir la accion del listener del modal la primera vez
    localStorage.removeItem('first_action');
    localStorage.setItem(this.KeyStore.SELECTED_ZONE, JSON.stringify(values));
    this.subjectSelectedZones$.next(values);
  }

  /**
   * Get headers
   * @private
   */
  private getHeader(): HttpHeaders {
    return this.authService.getHeaderNoRefresh();
  }

}

