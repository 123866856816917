import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AddressItem, GetDeliveryCountriesResponse,
  GetDeliveryStatesResponse,
  NewAddressResponse,
  NewCountry, NewState, RestrictedCity
} from "../../../../../shared/models/new-address.model";
import {NewAddressService} from "../../../../../shared/services/new-address.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {SelectedZone} from "../../../../../shared/models";
import {ZonesService} from "../../../../../shared/services/zones.service";
import {
  ShopList,
  ShopListItem,
  ChangeLocationRequest,
  ConvertFromCartRequest
} from "../../../../../shared/models/shop-list.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

type ShopListForm = {
  name: string
  description: string
  city: string
  state_name: string
  country_iso: string
  contact: AddressItem
}

@Component({
  selector: 'app-create-shop-list',
  templateUrl: './create-shop-list.component.html',
  styleUrls: ['./create-shop-list.component.scss']
})
export class CreateShopListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving: boolean = false;
  Page: number = 1;
  PageSize: number = 50;
  TotalPages: number = 0;

  addresses: AddressItem[] = [];

  isLoadingContacts: boolean = false;
  spinLoadMore: boolean = false;


  isLoadingCountries: boolean = true;
  isLoadingStates: boolean = true;
  isLoadingCities: boolean = true;
  faSpinner = faSpinner;

  showSelectInput: boolean = true;
  countryCuIso: string = 'CU';
  countryIsoSelected: string = 'cu';

  countries: NewCountry[] = [];
  states: NewState[] = [];
  cities: RestrictedCity[] = [];

  isAutoComplete: boolean = false;

  isModeEdit: boolean = false;
  isChangeLocation: boolean = false;
  isConvertFromCart: boolean = false;
  isConvertFromOrder: boolean = false;

  shopList: ShopListItem | undefined;

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', []),
    city: new FormControl('', [Validators.required]),
    state_name: new FormControl('', [Validators.required]),
    country_iso: new FormControl('', [Validators.required]),
    contact: new FormControl('', []),
  });

  constructor(
    private readonly dialogref: MatDialogRef<CreateShopListComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly shopListService: ShopListService,
    private readonly newAddressService: NewAddressService,
    private readonly zonesService: ZonesService,
  ) {
  }

  ngOnInit(): void {
    this.contact?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((contact) => {
        this.isAutoComplete = !!contact;
        this.loadCountryStatesData();
      });

    this.isModeEdit = this.data?.isModeEdit;
    this.isChangeLocation = this.data?.isChangeLocation;
    this.isConvertFromCart = this.data?.isConvertFromCart;
    this.isConvertFromOrder = this.data?.isConvertFromOrder;

    if (this.isModeEdit || this.isChangeLocation) {
      this.shopList = this.data.shopList;

      if (this.isModeEdit) {
        // Disabling fields.
        this.setDisableAddress();
        this.contact?.disable();
      }

      if (this.isChangeLocation) {
        // Disabling fields when changing zone.
        this.setDisableNameDescription();
      }
    }

    if (this.isConvertFromCart || this.isConvertFromOrder) {
      this.contact?.setValidators([Validators.required]);
      this.setDisableAddress();
    }

    this.loadAddress();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  submit() {
    this.isSaving = true;

    const formData: ShopListForm = this.form.getRawValue();
    const countrySelected: NewCountry = this.countries.find(country => country.iso2 === formData.country_iso)!;
    const provinceSelected: NewState = this.states.find(state => state.name === formData.state_name.trim())!;
    const citySelected: RestrictedCity = this.cities.find(city => city.name.trim() === formData.city.trim())!;

    const data: ShopList = {
      name: formData.name,
      description: formData.description,
      contactId: formData.contact?.id,
      countryId: countrySelected.id,
      countryName: countrySelected.name,
      zoneId: provinceSelected.zoneId,
      zoneName: provinceSelected.name,
      municipalityId: citySelected.id,
      municipalityName: citySelected.name,
    }

    let request = this.shopListService.addShopList(data);

    if (this.isModeEdit) {
      data['id'] = this.data.shopList.id;
      request = this.shopListService.updateShopList(data);
    }

    if (this.isChangeLocation) {
      const changeLocationData: ChangeLocationRequest = {
        id: this.data.shopList.id,
        contactId: formData.contact?.id,
        countryId: countrySelected.id,
        zoneId: provinceSelected.zoneId,
        municipalityId: citySelected.id,
      }
      request = this.shopListService.changeLocation(changeLocationData);
    }

    if (this.isConvertFromCart || this.isConvertFromOrder) {
      const convertFromCartData: ConvertFromCartRequest = {
        contactId: formData.contact?.id,
        name: formData.name,
        description: formData.description,
      }

      if (this.isConvertFromOrder) {
        const convertFromOrderData = { ...convertFromCartData, orderNumber: this.data.orderNumber};
        request = this.shopListService.createFromOrder(convertFromOrderData);
      } else {
        request = this.shopListService.createFromCart(convertFromCartData)
      }

    }


    request.pipe(takeUntil(this.destroy$))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: (res) => {
          const {success} = res;
          this.isSaving = false;
          this.dialogref.close({success});
        },
        error: () => this.isSaving = false
      })
  }

  loadAddress() {
    this.toggleLoading();
    // const shopList: ShopListItem = this.data?.shopList;
    let dataToPatch: Partial<ShopListForm> = {
      name: this.shopList?.name ?? '',
      description: this.shopList?.description ?? '',
    };
    this.newAddressService.getAddresses({
      OnlyValidForMyCart: false,
      Page: this.Page,
      PageSize: this.PageSize
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (addressesResponse: NewAddressResponse) => {
          this.addresses = addressesResponse.data.items;
          this.TotalPages = addressesResponse.data.totalPages;
          this.toggleLoading();
          if (this.isModeEdit || this.isChangeLocation || this.isConvertFromCart || this.isConvertFromOrder) {
            let contact = undefined;
            if (this.isConvertFromCart || this.isConvertFromOrder) {
              const { cartContact } = this.data;
              if (cartContact) {
                contact = this.addresses.find(address => address.id === cartContact.id)
              }
            } else {
              contact = this.addresses.find(address => address.id === this.shopList?.contact?.id);
            }

            if (!contact) {
              dataToPatch = {
                ...dataToPatch,
                // country_iso: shopList.zoneId,
                city: this.shopList?.municipalityName,
                state_name: this.shopList?.zoneName
              }
            } else {
              dataToPatch = {
                ...dataToPatch,
                contact,
              }
            }

            this.form.patchValue(dataToPatch);
            this.loadCountryStatesData();
          } else {
            this.loadCountryStatesData();
          }
        },
        error: () => {
          this.toggleLoading();
        },
      });
  }

  toggleLoading() {
    this.isLoadingContacts = !this.isLoadingContacts;
  }

  loadCountryStatesData() {
    this.isLoadingCountries = true;
    this.isLoadingStates = true;
    this.isLoadingCities = true;
    this.newAddressService.getDeliveryCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: GetDeliveryCountriesResponse) => {
        this.countries = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.isLoadingCountries = false;
        if (this.isAutoComplete) {
          this.setDisableAddress();
          this.getContactDataAndPatchForm(this.contact?.value)
        } else {
          if (!this.isModeEdit) this.setEnableAddress();
          this.getZoneDataAndPatchForm(this.countries);
        }
      });
  }

  getContactDataAndPatchForm(contact: AddressItem) {
    const countryAux = contact.phoneCountryIso2;
    if (countryAux) {
      const patchData: { country_iso: string, state_name?: string, city?: string } = {country_iso: countryAux};
      this.newAddressService.getDeliveryStates(countryAux).pipe(takeUntil(this.destroy$)).subscribe( {
        next: (statesResponse: GetDeliveryStatesResponse) => {
          patchData['state_name'] = contact.stateName.trim();
          patchData['city'] = contact.city.trim();
          this.form.patchValue(patchData);

          this.showSelectInput = this.countryCuIso == countryAux;

          this.countryIsoSelected = countryAux.toLowerCase();

          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.isLoadingStates = false;
          const stateAbbr = this.form.get('state_name')?.value;
          const stateAux = this.states.find(it => it.name === stateAbbr);
          if (stateAux && this.showSelectInput) {
            this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
            this.isLoadingCities = false;
          } else {
            this.isLoadingCities = false;
          }
        }
      });
    }

  }

  getZoneDataAndPatchForm(countries: NewCountry[]) {
    const selectedZone = this.getSelectedZone;
    let countryAux: NewCountry | undefined = undefined;

    if ((this.isModeEdit || this.isChangeLocation) && this.shopList?.countryId) {
      // const shopList: ShopListItem = this.data.shopList;
      countryAux = countries.find(it => it.id === this.shopList?.countryId);
    } else {
      countryAux = countries.find(it => it.iso2 === selectedZone.area_selected);
    }

    if (countryAux) {
      const patchData: { country_iso: string, state_name?: string, city?: string } = {
        country_iso: countryAux.iso2,
        state_name: selectedZone.zone_name.trim(),
        city: selectedZone.municipality_name.trim()
      };
      this.newAddressService.getDeliveryStates(countryAux.iso2).pipe(takeUntil(this.destroy$)).subscribe( {
        next: (statesResponse: GetDeliveryStatesResponse) => {
          if (this.isModeEdit || this.isChangeLocation) {
            const shopList: ShopListItem = this.data.shopList;
            const { zoneName, municipalityName } = shopList;
            if (zoneName && municipalityName) {
              patchData['state_name'] = shopList.zoneName?.trim();
              patchData['city'] = shopList.municipalityName?.trim();
            }
          }
          this.form.patchValue(patchData);

          this.showSelectInput = this.countryCuIso == countryAux!.iso2;

          this.countryIsoSelected = countryAux!.iso2.toLowerCase();

          this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
          this.isLoadingStates = false;
          const stateAbbr = this.state_name?.value;
          const stateAux = this.states.find(it => it.name === stateAbbr);
          if (stateAux && this.showSelectInput) {
            this.cities = [...stateAux.restrictedCities.sort((a, b) => a.name.localeCompare(b.name))];
          }
          this.isLoadingCities = false;
        }
      });
    }

  }

  setDisableNameDescription() {
    this.name?.disable();
    this.description?.disable();
  }

  setDisableAddress() {
    this.form.get('city')?.disable();
    this.form.get('state_name')?.disable();
    this.form.get('country_iso')?.disable();
  }

  setEnableAddress() {
    this.form.get('city')?.enable();
    this.form.get('state_name')?.enable();
    this.form.get('country_iso')?.enable();
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  changeCountry(event: any) {
    this.isLoadingStates = true;
    this.showSelectInput = this.countryCuIso == event.value;
    this.form.patchValue({
      city: null,
      state_name: null,
    });
    const usCountry = this.countries.find(it => it.iso2 === event.value);
    if (usCountry) {
      this.countryIsoSelected = event.value.toLowerCase();
      this.newAddressService.getDeliveryStates(usCountry.iso2).pipe(takeUntil(this.destroy$)).subscribe((statesResponse: GetDeliveryStatesResponse) => {
        this.states = [...statesResponse.data.sort((a, b) => a.name.localeCompare(b.name))];
        this.cities = [];
        this.isLoadingStates = false;
      });
    }
  }

  changeState(event: any) {
    this.isLoadingCities = true;
    this.form.patchValue({
      city: null,
    });
    const countryIso = this.form.get('country_iso')?.value;
    const usCountry = this.countries.find(it => it.iso2 === countryIso);
    if (usCountry && this.countryCuIso == countryIso) {
      this.cities = [...this.states.find((state) => state.name === event.value)!.restrictedCities.sort((a: RestrictedCity, b: RestrictedCity) => a.name.localeCompare(b.name))];
      this.isLoadingCities = false;
    } else {
      this.isLoadingCities = false;
    }
  }

  get name() { return this.form.get('name'); }
  get description() { return this.form.get('description'); }
  get city() { return this.form.get('city'); }
  get state_name() { return this.form.get('state_name'); }
  get country_iso() { return this.form.get('country_iso'); }
  get contact() { return this.form.get('contact'); }

}
