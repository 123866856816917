import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, takeUntil } from "rxjs";
import { GroupedStore, SelectedZone, Taxonomy } from "../../../../shared/models";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ZonesService } from "../../../../shared/services/zones.service";
import { MenuService } from "../../../../shared/services/menu.service";
import { LanguageService } from 'src/app/shared/services/language.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SEARCH_FILTER_DEFAULT_VALUE } from "../../../../shared/filters/searc-filters";
import { StoreService } from 'src/app/shared/services/store.service';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-store-detail-menu',
  templateUrl: './store-detail-menu.component.html',
  styleUrls: ['./store-detail-menu.component.scss']
})
export class StoreDetailMenuComponent implements OnInit, OnChanges, OnDestroy {
  resp_filters = false;
  // @Input() taxonomies: Taxonomy | null = null;
  @Input() listedProducts: number = 0;
  @Input() totalProducts: number = 0;
  @Input() clearFilters: boolean = true;
  @Input() selectedTaxon: any | null = null;
  @Input() selectedPrice: any | null = null;
  @Input() cat_facets: any = null;
  @Input() selectedText: any | null = null;
  @Input() resetFilterChanges: boolean | null = null;
  @Input() order: any = SEARCH_FILTER_DEFAULT_VALUE;
  @Input() showGrid: boolean = true;
  @Input() delivery_times: any = null;
  @Output() change = new EventEmitter<any>();
  categories: any = [];
  facets: any = [];
  aux_cats: any = null;

  taxonomies!: Taxonomy;

  filterPriceForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  categoriesSubscription!: Subscription;
  selected_filters: any = [];

  widths = [24, 28, 32, 36];
  widthCounts = [6, 7, 8, 9, 10];
  skeletons: Array<{ id: number, widthClass: string, widthCount: string }> = [];
  defaultStores: any = [];
  stores: any = [];
  search_text: string = "";
  close = faClose;
  filter = faFilter;
  selectedStores: any = [];
  router_obs: Subscription = new Subscription();
  is_refresh = false;
  is_store_page = true;
  store_ids: any = null;

  constructor(
    private zonesService: ZonesService,
    private languageService: LanguageService,
    private menuService: MenuService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private storeService: StoreService,
  ) {
    this.filterPriceForm = new FormGroup({
      min: new FormControl(null, {
        validators: []
      }),
      max: new FormControl(null, {
        validators: []
      }),
    });

    if (!this.router_obs.closed) this.router_obs.unsubscribe();
    this.router_obs = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.router?.url?.includes('global-search') ? this.is_store_page = false : this.is_store_page = true;
        this.activeRoute.queryParams.subscribe((data: any) => {
          if (data?.StoreIds) this.store_ids = data?.StoreIds;
        });
      }
    })
  }

  getRandomWidth(isCount: boolean = false): string {
    if (!isCount) {
      const randomIndex = Math.floor(Math.random() * this.widths.length);
      return `w-${this.widths[randomIndex]}`;
    } else {
      const randomIndex = Math.floor(Math.random() * this.widthCounts.length);
      return `w-${this.widthCounts[randomIndex]}`;
    }
  }

  ngOnInit(): void {
    this.initializeSkeletons();
    this.languageService.selectedLanguage.subscribe((data: any) => {
      this.aux_cats = this.menuService.getFinalCategories(data);
    })
    this.getStores();
  }

  initializeSkeletons(): void {
    this.skeletons = Array.from({ length: 16 }, (_, index) => ({
      id: index + 1,
      widthClass: this.getRandomWidth(),
      widthCount: this.getRandomWidth(true)
    }));
  }

  ngOnChanges(changes: any) {

    changes?.cat_facets?.currentValue ? this.facets = changes?.cat_facets?.currentValue : this.facets = [];
    if (this.facets?.length > 0) {
      this.categories = this.addAmmount(this.aux_cats.links);
      setTimeout(() => {
        if (this.selectedTaxon) {
          // Si hubiera un id de categoria, busco la categoria por el id para poder guardar el obj en la lista de filtros
          // seleccionados
          let selected_taxon_obj: any = this.findSelectedTaxonById(this.selectedTaxon, this.categories);
          if (selected_taxon_obj) this.manageTaxonFilterStatus({ text: selected_taxon_obj?.text, id: selected_taxon_obj?.id, type: 'taxon' });
          document.querySelectorAll('.general-store-category').forEach((element: any) => {
            element?.classList.remove('text-orange-500');
            element?.classList.add('text-gray-#252525');
          });
          document.querySelectorAll(`.store-cat${this.selectedTaxon}`).forEach((element: any) => {
            element?.classList.add('text-orange-500');
            element?.classList.remove('text-gray-#252525');
          });
        }
        if (this.selectedPrice) {
          // Si hubiera un precio seleccionado, busco la categoria por el id para poder guardar el obj en la lista de filtros
          // seleccionados
          this.managePriceFilterStatus({ text: `$ ${this.selectedPrice}`, id: this.selectedPrice, type: 'price' });
        }
      });
    }
    this.priceOrTaxonChangeDetected(changes);
  }

  ngOnDestroy(): void {
    this.categoriesSubscription?.unsubscribe();
  }

  closeFilters() {
    this.resp_filters = !this.resp_filters;
    if (this.resp_filters) {
      setTimeout(() => {
        const STORES_ARR = this.store_ids?.split(',');
        console.log(STORES_ARR);
        if (STORES_ARR) {
          this.stores.forEach((element: any) => {
            if (STORES_ARR?.includes(element?.id.toString())) {
              document.querySelectorAll(`.store-check-${element?.id}`).forEach((element: any) => {
                element?.classList.remove('hidden');
              });
            }
          });
        } else {
          document.querySelectorAll(`.store-checkbox`).forEach((element: any) => {
            if (!element?.classList?.contains('hidden')) element?.classList.add('hidden');
          });
        }
      });
    }
  }

  hideShowElement(el: any) {
    // document.getElementById(el)?.classList.contains('h-0') ?
    //   document.getElementById(el)?.classList.remove('h-0') : document.getElementById(el)?.classList.add('h-0');
  }

  emitFilterData(taxon?: any, price?: any, text?: any, only_case?: any) {
    if (this.getSelectedZone) {
      this.closeFilters();
      if (taxon) {
        // aqui necesito el objeto taxon completo para poder mostrar el texto en el listado de filtros seleccionados por eso lo guardo
        // en aux
        let params = { taxon: taxon?.id ?? null, price: this.selectedPrice ?? null, text: this.selectedText ?? null, exclude: true };
        this.manageTaxonFilterStatus({ text: taxon?.text, id: taxon?.id, type: 'taxon' });
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
      if (price) {
        let params = { taxon: this.selectedTaxon ?? null, price: price ?? null, text: this.selectedText ?? null, exclude: false };
        this.managePriceFilterStatus({ text: `$ ${price}`, id: price, type: 'price' });
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
      if (!price && !taxon && text) {
        this.selectedPrice = null;
        this.selectedTaxon = null;
        this.clearCssClass(true, true);
        let params = { taxon: this.selectedTaxon ?? null, price: this.selectedPrice ?? null, text: this.selectedText ?? null, exclude: null };
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
      if (!price && !taxon && !text && this.selectedStores.length == 0) {
        this.selectedPrice = null;
        this.selectedTaxon = null;
        this.clearCssClass(true, true);
        let params = { taxon: this.selectedTaxon ?? null, price: this.selectedPrice ?? null, text: null, StoreIds: null, exclude: null };
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
      if (only_case) {
        let aux_taxon: any = null;
        this.selectedTaxon = taxon;
        let params = { taxon: this.selectedTaxon ?? null, price: this.selectedPrice ?? null, text: this.selectedText ?? null, exclude: false };
        aux_taxon = this.findSelectedTaxonById(this.selectedTaxon, this.categories);
        this.manageTaxonFilterStatus({ text: aux_taxon?.text, id: aux_taxon?.id, type: 'taxon' });
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: params,
            queryParamsHandling: 'merge'
          }
        );
      }
    }
  }

  emitDeliveryFilter(delivery_time: string) {
    if (this.getSelectedZone) {
      this.closeFilters();
      if (delivery_time) {
        this.selected_filters.forEach((f: { text: string, id: any, type: String }, index: any) => {
          if (f?.type == 'delivery') this.selected_filters?.splice(index, 1);
        });
        this.selected_filters.push({ text: delivery_time, id: delivery_time, type: 'delivery' });
        this.router.navigate([],
          {
            relativeTo: this.activeRoute,
            queryParams: { DeliveryTimes: delivery_time },
            queryParamsHandling: 'merge'
          }
        );
      }
    }
  }

  emitByPriceFields() {
    if (this.getSelectedZone) {
      this.closeFilters();
      if (this.filterPriceForm.value.min && this.filterPriceForm.value.max) {
        this.selectedPrice = `${this.filterPriceForm.value.min},${this.filterPriceForm.value.max}`
      } else if (this.filterPriceForm.value.min) {
        this.selectedPrice = `${this.filterPriceForm.value.min}`
      } else if (this.filterPriceForm.value.max) {
        this.selectedPrice = `0,${this.filterPriceForm.value.max}`
      }
      this.emitFilterData(
        this.selectedTaxon ? this.selectedTaxon : undefined,
        this.selectedPrice ? this.selectedPrice : undefined,
        this.selectedText ? this.selectedText : undefined,
        this.selectedStores.length > 0 ?
          this.selectedStores.map((e: any) => e?.id?.toString()).join(',') : undefined);
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  resetFilters() {
    if (this.getSelectedZone) {
      this.selectedPrice = null;
      this.selectedTaxon = null;
      this.selected_filters = [];
      this.selectedStores = [];
      this.filterPriceForm.reset();
      this.closeFilters();
      this.clearCssClass(true, true);
      this.emitFilterData(null, null, null, null);
      setTimeout(() => {
        document.querySelectorAll(`.store-checkbox`).forEach((element: any) => {
          if (!element?.classList?.contains('hidden')) element?.classList.add('hidden');
        });
      });
      // this.resetStoreFilter();
    }
  }

  showSubElement(e: any) {
    let self_element = e?.target?.parentElement?.firstChild as HTMLElement;
    let element = e?.target?.parentElement?.parentElement?.parentElement?.nextSibling as HTMLElement;
    self_element?.classList?.contains('rotate-90') ?
      self_element?.classList?.remove('rotate-90') :
      self_element?.classList?.add('rotate-90');
    element?.classList?.contains('h-0') ?
      (element?.classList?.remove('h-0'),
        element?.classList?.add('h-auto')) :
      (element?.classList?.remove('h-auto'),
        element?.classList?.add('h-0'));
  }

  addAmmount(links: any) {
    let new_categories: any = [];
    for (let i = 0; i < links.length; i++) {
      for (let j = 0; j < this.facets.length; j++) {
        if (links[i].id.toString() === this.facets[j].key) {
          new_categories = [
            ...new_categories,
            {
              "id": links[i]?.id,
              "text": `${links[i]?.text}`,
              "route": `/category/${links[i]?.id}`,
              "ammount": this.facets[j].count,
              "links": links[i]?.links.length > 0 ? this.addAmmount(links[i]?.links) : []
            }
          ]
        }
      }
    }
    return new_categories;
  }

  remove(filter: any) {
    this.selected_filters.forEach((f: { text: string, id: any, type: String }, index: any) => {
      if (f?.type == filter?.type) this.selected_filters?.splice(index, 1);
    });
    if (filter?.type == 'price') {
      this.selectedPrice = null;
      this.clearCssClass(false, true);
      if (this.selected_filters.length == 1 && this.selected_filters[0].type == 'taxon') {
        this.emitFilterData(this.selectedTaxon ? this.selectedTaxon : undefined, undefined, this.selectedText, true);
      } else {
        this.emitFilterData(this.selectedTaxon ? this.selectedTaxon : undefined, undefined, this.selectedText);
      }
    }
    if (filter?.type == 'taxon') {
      this.selectedTaxon = null;
      this.clearCssClass(true, false);
      this.emitFilterData(undefined, this.selectedPrice ? this.selectedPrice : undefined, this.selectedText ? this.selectedText : undefined);
    }
    if (filter.type == 'delivery') {
      this.router.navigate([],
        {
          relativeTo: this.activeRoute,
          queryParams: { DeliveryTimes: null },
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  private manageTaxonFilterStatus(filter: { text: string, id: any, type: String }) {
    this.selected_filters.forEach((f: { text: string, id: any, type: String }, index: any) => {
      if (f?.type == filter?.type) this.selected_filters?.splice(index, 1);
    });
    this.selected_filters.push({ text: filter?.text, id: filter?.id, type: filter.type });
    document.querySelectorAll('.general-store-category')?.forEach((element: any) => {
      element?.classList.remove('text-orange-500');
      element?.classList.add('text-gray-#252525');
    });
    document.querySelectorAll(`.store-cat${filter?.id}`)?.forEach((element: any) => {
      element?.classList.add('text-orange-500');
      element?.classList.remove('text-gray-#252525');
    });
  }

  private managePriceFilterStatus(filter: { text: string, id: any, type: String }) {
    let i = null;

    this.selected_filters.forEach((f: { text: string, id: any, type: String }, index: any) => {
      if (f?.type == filter?.type) this.selected_filters?.splice(index, 1);
    });

    if (filter?.text == "$ 0,25") filter.text = "$ < 25"
    if (filter?.text == "$ 100") filter.text = "$ > 100"
    this.selected_filters.push({ text: filter?.text?.replace(',', '-'), id: filter?.id, type: filter.type });

    document.querySelectorAll('.filter-p').forEach((element: any) => {
      let el = element as HTMLElement;
      if (el?.classList.contains('selected-filter')) {
        el?.classList.remove('selected-filter');
      }
    })
    if (filter?.id !== 100) {
      document.querySelectorAll(`.filter-p-${filter?.id?.replace(',', '-')}`).forEach((element: any) => {
        let el = element as HTMLElement;
        el?.classList.add('selected-filter');
      })
    } else {
      document.querySelectorAll(`.filter-p-${filter?.id}`).forEach((element: any) => {
        let el = element as HTMLElement;
        el?.classList.add('selected-filter');
      })
    }
  }

  clearCssClass(taxons: boolean, prices: boolean) {
    if (taxons) {
      document.querySelectorAll('.general-store-category')?.forEach((element: any) => {
        element?.classList?.remove('text-orange-500');
        element?.classList?.add('text-gray-#252525');
      });
    }
    if (prices) {
      document.querySelectorAll('.filter-p')?.forEach((element: any) => {
        element?.classList?.remove('selected-filter');
      })
    }
  }

  private findSelectedTaxonById(id: number, array: any) {
    let result = null;
    array.forEach((category: any) => {
      if (category?.id == id) {
        result = category;
      }
      if (category?.links?.length > 0) {
        this.findSelectedTaxonById(id, category?.links);
      }
    });
    return result;
  }

  private priceOrTaxonChangeDetected(changes: any) {
    if (changes?.selectedTaxon?.currentValue || changes?.selectedPrice?.currentValue) {
      if (!this.categories.length) return;
      if (this.selectedTaxon) {
        let selected_taxon_obj: any = this.findSelectedTaxonById(this.selectedTaxon, this.categories);
        if (selected_taxon_obj) this.manageTaxonFilterStatus({ text: selected_taxon_obj?.text, id: selected_taxon_obj?.id, type: 'taxon' });
      }
      if (this.selectedPrice) {
        this.managePriceFilterStatus({ text: `$ ${this.selectedPrice}`, id: this.selectedPrice, type: 'price' });
      }
    } else if (
      (changes?.selectedTaxon && changes?.selectedTaxon?.currentValue == null && changes?.selectedPrice && changes?.selectedPrice?.currentValue == null) ||
      (changes?.resetFilterChanges && changes?.resetFilterChanges)) {
      this.selectedPrice = null;
      this.selectedTaxon = null;
      this.selected_filters = [];
      this.filterPriceForm.reset();
      this.closeFilters();
      this.clearCssClass(true, true);
    }
  }

  getStores() {
    this.zonesService.subjectSelectedZones$
      .pipe(takeUntil(this.destroy$))
      .subscribe((zone) => {
        if (zone) {
          this.storeService
            .getStoresByZoneId(zone.zone)
            .pipe(takeUntil(this.destroy$))
            .subscribe((groupedStore: GroupedStore | undefined) => {
              this.defaultStores = groupedStore?.stores || [];
              this.stores = [...this.defaultStores];
              if (this.store_ids) this.loadStoresFromQueryParamsOnInit(this.store_ids);
            });
        }
      });
  }

  alternateStoreFilter(store: any, class_name: any, ref: boolean = false) {
    document.querySelectorAll(`.${class_name}`).forEach((element: any) => {
      element.classList.contains('hidden') ?
        element?.classList.remove('hidden')
        :
        element?.classList.add('hidden');
    });
    this.addOrRemoveSelectedStore(store);
    if (ref) {
      this.applyStoreFilter();
    }
  }

  onsearchTextChanged(event: any) {
    if (event?.target?.value.trim() !== "") {
      // console.log(event?.target?.value);
    }
  }

  addOrRemoveSelectedStore(store: any) {
    if (this.selectedStores.find((s: any) => s.id === store.id)) {
      this.selectedStores.splice(this.selectedStores.findIndex((s: any) => s.id === store.id), 1)
    } else {
      this.selectedStores.push(store);
    }
  }

  applyStoreFilter() {
    let params = {
      StoreIds: this.selectedStores.length > 0 ?
        this.selectedStores.map((e: any) => e?.id?.toString()).join(',') : null
    };
    this.router.navigate([],
      {
        relativeTo: this.activeRoute,
        queryParams: params,
        queryParamsHandling: 'merge'
      }
    );
    const MODAL = document.getElementById('store-modal');
    if (!MODAL?.classList?.contains('hidden')) MODAL?.classList.add('hidden');
    this.closeFilters();
  }

  resetStoreFilter() {
    this.selectedStores = [];
    document.querySelectorAll(`.store-checkbox`).forEach((element: any) => {
      element?.classList.add('hidden');
    });
    let params = {
      StoreIds: this.selectedStores.length > 0 ?
        this.selectedStores.map((e: any) => e?.id?.toString()).join(',') : null
    };
    this.router.navigate([],
      {
        relativeTo: this.activeRoute,
        queryParams: params,
        queryParamsHandling: 'merge'
      }
    );
  }

  loadStoresFromQueryParamsOnInit(stores_ids: string) {
    const STORES_ARR = stores_ids.split(',');
    this.stores.forEach((element: any) => {
      if (STORES_ARR.includes(element?.id.toString())) {
        if (!this.selectedStores.find((s: any) => s.id === element.id)) {
          this.selectedStores.push(element);
          document.querySelectorAll(`.store-check-${element?.id}`).forEach((element: any) => {
            element?.classList.remove('hidden');
          });
        }
      }
    });

  }

  openStoreModal() {
    const MODAL = document.getElementById('store-modal');
    MODAL?.classList.remove('hidden');
    // document.getElementById(`534`)?.classList.remove('hidden');
    // this.loadStoresFromQueryParamsOnInit(this.store_ids);
    this.selectedStores.forEach((element: any) => {
      document.querySelectorAll(`.store-check-${element?.id}`).forEach((element: any) => {
        element?.classList.remove('hidden');
      });
    })
  }

}


