import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedZone } from 'src/app/shared/models';
import { ZonesService } from 'src/app/shared/services/zones.service';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {
  @Input() data: any = [
    { section: 'Section', route: '', params: {} },
    { section: 'SubSection', route: '', params: {} },
  ];

  constructor(
    private router: Router,
    private zonesService: ZonesService
  ) { }

  ngOnInit(): void {
  }

  gotoRoute(link: string) {
    if (link && link !== '/cubacel') {
      this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}${link}`);
    } else {
      this.router.navigateByUrl(`${link}`);
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

}
