import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CubacelService {
  private CUBACEL_PRODUCTS_URL = `${environment.wallet_api_url}${environment.v1}/CubacelTopUp/CubacelTopUpProducts2`;
  private CUBACEL_HISTORY_URL = `${environment.wallet_api_url}${environment.v1}/CubacelTopUp/CubacelTopUpOrders/History`;
  private CUBACEL_CONTACTS_URL = `${environment.wallet_api_url}${environment.v1}/CubacelTopUp/TopUpContacts`;
  private CUBACEL_REMOVE_CONTACTS_URL = `${environment.wallet_api_url}${environment.v1}/LaNave/LaNaveOrders/RemovePrevDestination`;
  public update_cubacel_bag: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) { }

  /**
* Get headers
* @private
*/
  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders
      .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang);
    return httpHeaders;
  }

  /**
* @description get cubacel products
*/
  getCubacelProducts(page: number, page_size: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('pageSize', page_size);
    return this.http.get(`${this.CUBACEL_PRODUCTS_URL}`, { headers: this.getHeader(), params: httpParams });
  }

  /**
* @description get cubacel contacts
*/
  getCubacelHistory(page: number, page_size: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('TopUpFilterTimeIntervalId', 1)
      .set('pageSize', page_size);
    return this.http.get(`${this.CUBACEL_HISTORY_URL}`, { headers: this.getHeader(), params: httpParams });
  }

  /**
* @description get cubacel history
*/
  getCubacelContacts(page: number, page_size: number): Observable<any> {
    const httpParams = new HttpParams()
      .set('page', page)
      .set('sorts', '-CreatedAt')
      .set('TopUpFilterTimeIntervalId', 1)
      .set('pageSize', page_size);
    return this.http.get(`${this.CUBACEL_CONTACTS_URL}`, { headers: this.getHeader(), params: httpParams });
  }

  additemToCubacelShoppingBag(item: any) {
    let cubacel_shopping_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag']) : [];
    console.log(cubacel_shopping_bag);

    const new_arr = [...cubacel_shopping_bag, item]
    localStorage.setItem('cubacel_shopping_bag', JSON.stringify(new_arr));
    this.update_cubacel_bag.next(true);
  }

  removeItemFromCubacelShoppingBag(phone: any) {
    let pos = null;
    let cubacel_shopping_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag']) : [];
    if (cubacel_shopping_bag.length) {
      cubacel_shopping_bag.forEach((element: any, index: number) => {
        if (element?.destination === phone) {
          pos = index;
          console.log(pos);
        }
      });
    }
    if (pos !== null) {
      cubacel_shopping_bag.splice(pos, 1);
      localStorage.setItem('cubacel_shopping_bag', JSON.stringify(cubacel_shopping_bag));
      this.update_cubacel_bag.next(true);
    }
  }

  getTotalCubacelBagPrice() {
    let amount = 0;
    let cubacel_shopping_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag']) : [];
    cubacel_shopping_bag.map((item: any) => {
      amount = amount + item.paymentAmount;
    });
    return amount;
  }

  getTotalCubacelBagDisplayPrice() {
    let amount = 0;
    let cubacel_shopping_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag']) : [];
    cubacel_shopping_bag.map((item: any) => {
      console.log(item.displayPaymentAmount);

      amount = amount + Number(item.displayPaymentAmount);
    });
    return amount;
  }

  clearCubacelBag() {
    localStorage.removeItem('cubacel_shopping_bag');
    this.update_cubacel_bag.next(true);
  }


}
