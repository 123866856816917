<section id="menu" class="fixed top-0 left-0 w-full
us:hidden
lg-landscape:flex lg-landscape:flex-col lg-landscape:justify-center lg-landscape:items-center ">
    <div class="relative bg-white flex justify-center
    lg-landscape:w-full lg-landscape:h-66px">
        <div class="relative h-66px flex justify-start items-center
        lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-8px lg-landscape:pb-8px">
            <img id="logo" [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'home']" class="relative cursor-pointer
               lg-landscape:w-185px lg-landscape:h-52px overflow-hidden" src="../../../assets/menu/logo.svg" alt="">
            <div (click)="showCountryProvinceModal()" class="relative bg-yellow-#FFC35D flex items-center cursor-pointer
               lg-landscape:w-185px lg-landscape:h-41px lg-landscape:ml-15px lg-landscape:rounded-3px">
                <img class="relative
                lg-landscape:w-14.5% lg-landscape:ml-2" src="../../../assets/menu/icon-location.svg" alt="">
                <p class="relative font-Lato-Bold
                lg-landscape:text-13px lg-landscape:leading-4">{{getSelectedZones | translate}}</p>
                <img class="absolute right-2
                lg-landscape:w-14.5%" src="../../../assets/menu/icon-arrowdown.svg" alt="">
            </div>
            <div ngClass="{{cubacel_route ? '-mt-40' : ''}}" class="relative flex flex-1 justify-start items-center w-auto
            lg-landscape:h-41px lg-landscape:ml-11px">
<!--              w-401px-->
                <input #searchInput (keydown)="enterPressed($event)" (input)="getSuggestions($event)" id="inp"
                    class="flex flex-1 relative h-full active:outline-none focus:outline-none active:ring-0 focus:ring-0 font-Lato-Regular
                lg-landscape:border-2 lg-landscape:border-solid lg-landscape:border-yellow-#FF6D03 lg-landscape:text-sm leading-4" type="text" placeholder="{{'SEARCH_FOR_SOMETHING' | translate}}">
                <div (click)="searchGlobalProduct(searchInput.value)" class="relative overflow-hidden cursor-pointer
                lg-landscape:w-44px lg-landscape:h-41px bg-yellow-#FF6D03 flex justify-center items-center">
                    <img class="relative w-2/4 h-auto fill-white" src="../../../assets/menu/icon-search.svg" alt="">
                </div>
                <ul *ngIf="suggestions.length" id="sug-box"
                    class="absolute search-suggestion-box top-full left-0 bg-white rounded-md shadow-md z-50 w-full px-2 py-2 grid grid-cols-1 gap-2">
                    <li (click)="searchInput.value = suggestion; searchGlobalProduct(suggestion); suggestions = []"
                        *ngFor="let suggestion of suggestions"
                        class="relative font-Lato-Bold text-sm cursor-pointer capitalize hover:bg-gray-#dfdfdf px-2">
                        {{suggestion}}
                    </li>
                </ul>
            </div>

            <div class="flex flex-1 flex-row justify-between items-center ml-3 min-w-410px">
                <!--            lg-landscape:ml-15px-->
                <div class="relative cursor-pointer lg-landscape:w-auto" [matMenuTriggerFor]="languageMenu">
                    <p class="relative font-Lato-Regular text-gray-#252525 lg-landscape:text-13px leading-4">{{ 'LANGUAGE' | translate }}</p>
                    <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center
                    lg-landscape:h-4">
                        <p class="relative text-gray-#252525">{{ selectedLanguage.id | uppercase }}</p>
                        <img class="relative w-7" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                    </span>
                </div>

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                        <span class="flex flex-row items-center">
                            <img class="flag mr-3 h-5" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                            <span class="iso">{{lang.title | translate}}</span>
                        </span>
                    </button>
                </mat-menu>

              <div class="relative w-auto">
                <div class="cursor-pointer w-auto pl-2 rounded us:py-2 md:py-0 md:h-42px flex items-center border" [matMenuTriggerFor]="currenciesMenu">
                  <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">
                      <p class="relative text-13px text-gray-#252525">{{ selectedCurrency | uppercase }}</p>
                      <img class="relative w-7" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                  </span>
                </div>
                <mat-menu #currenciesMenu="matMenu" [overlapTrigger]="false">
                  <button mat-menu-item *ngFor="let currency of currencies" (click)="setCurrency(currency.name)">
                    <span class="flex flex-row items-center">
                        <span class="iso">{{currency.name | translate}}</span>
                    </span>
                  </button>
                </mat-menu>
              </div>

                <!--        lg-landscape:w-24 lg-landscape:ml-6-->
                <a routerLink="/auth/signIn" class="relative flex justify-end items-end cursor-pointer"
                    *ngIf="!user?.id">
                    <img class="relative w-8" src="../../../assets/menu/profile-menu.svg" alt="">
                    <p class="relative font-Lato-Regular font-semibold ml-1 text-gray-#252525
                lg-landscape:text-14px leading-tight">{{'LOGIN' | translate}}/<br>{{'SIGN_IN' | translate}}</p>
                </a>
                <!--            items-end lg-landscape:w-119px lg-landscape:ml-5-->
                <a (click)="showprofile = !showprofile;showcat=false;showstores = false;showtravels=false;showsubcat=false;showtsubcat = false;"
                    class="relative flex justify-end cursor-pointer" *ngIf="user?.id">
                    <img class="relative w-8" src="../../../assets/menu/profile-menu.svg" alt="">
                    <p class="flex flex-col relative font-Lato-Regular ml-1 text-gray-#252525 overflow-hidden
                lg-landscape:text-10px leading-tight">
                        <span>{{'HELLO' | translate}}</span>
                        <span>{{user?.firstName ? user?.firstName : emailNoDomain}}</span>
                        <span class="text-14px font-semibold">{{'YOUR_ACCOUNT' | translate}}
                        </span>
                    </p>
                    <div *ngIf="showprofile && user?.readyToUse" class="menu-list absolute top-12 left-0 z-30 cursor-default
                        lg-landscape:w-314px bg-white lg-landscape:pt-24px lg-landscape:pb-24px">
                        <fa-icon (click)="showcat = false"
                            class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                            [icon]="close"></fa-icon>
                        <div class="headers
                            lg-landscape:pl-24px lg-landscape:pr-24px" *ngFor="let header of menu_profile?.headers">
                            <p class="relative font-Lato-Regular font-semibold uppercase
                                lg-landscape:text-sm lg-landscape:tracking-1.75px">{{header.text | translate}}</p>
                            <div class="relative w-full
                                lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                        </div>
                        <div (click)="gotoRoute(link?.route)" *ngFor="let link of menu_profile?.links" class="item cursor-pointer flex justify-between items-center
                            hover:bg-gray-#EEEEEE w-full
                            lg-landscape:h-9 lg-landscape:mt-1 lg-landscape:pl-24px lg-landscape:pr-24px">
                            <a class="item relative font-Lato-Regular font-semibold capitalize
                                lg-landscape:text-sm">{{link.text | translate}}</a>
                            <img *ngIf="link?.links?.length > 0"
                                class="relative
                                lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                                src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                        </div>
                        <div (click)="logout()" class="item cursor-pointer flex justify-between items-center
                            hover:bg-gray-#EEEEEE w-full
                            lg-landscape:h-9 lg-landscape:mt-1 lg-landscape:pl-24px lg-landscape:pr-24px">
                            <a class="item relative font-Lato-Regular font-semibold
                                lg-landscape:text-sm">{{'LOG_OUT' | translate}}</a>
                            <!-- <img *ngIf="link?.links?.length > 0" class="relative
        lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3"
    src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> -->
                        </div>
                    </div>
                </a>


                <!-- <a href="#" (dblclick)="logout()" class="relative flex justify-end items-end cursor-pointer
lg-landscape:w-24 lg-landscape:ml-6" *ngIf="user?.id && user?.readyToUse">
    <img class="relative
    lg-landscape:w-31%" src="../../../assets/menu/icon-profile.svg" alt="">
    <p class="relative font-Lato-Regular font-semibold ml-1 text-gray-#252525
    lg-landscape:text-14px leading-tight">Hola!<br>{{user?.email}}</p>
</a> -->
                <!-- <a href="#" class="relative flex justify-center items-center cursor-pointer
lg-landscape:w-24 lg-landscape:ml-8 lg-landscape:mt-2">
    <img class="relative
    lg-landscape:w-33%" src="../../../assets/menu/icon-wish2.svg" alt="">
    <p class="relative font-Lato-Regular font-semibold ml-1 text-gray-#252525
    lg-landscape:text-14px leading-tight">{{'FAVORITES' | translate}}</p>
</a> -->

                <!--        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'profile/payments', 1]"-->
                <a (click)="navigateToWallet()" class="relative flex justify-end items-center cursor-pointer">
                    <div class="relative w-8
                lg-landscape:h-27px">
                        <fa-icon
                            class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                            [icon]="faCartShopping">
                        </fa-icon>
                    </div>
                </a>



              <a class="relative flex justify-end items-center cursor-pointer" (click)="openNotificationTray()">
                <div class="relative w-8 lg-landscape:h-27px">
                  <fa-icon
                    class="relative flex justify-center items-center bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="faBell">
                  </fa-icon>
                  <div *ngIf="unreadCount > 0" class="absolute -top-3 -right-3 rounded-full w-21px h-21px bg-yellow-#FF6D03 float-right flex
                    justify-center items-center shadow-md">
                    <p class="relative text-white font-Lato-Bold text-xs">{{unreadCount}}
                    </p>
                  </div>
                </div>
              </a>

                <a (click)="seeGiftPreview()" class="relative flex justify-end items-center cursor-pointer">
                    <div class="relative w-8
                lg-landscape:h-27px">
                        <fa-icon
                            class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                            [icon]="fagift">
                        </fa-icon>
                    </div>
                </a>

                <!--            lg-landscape:w-20 lg-landscape:ml-7-->
                <a *ngIf="!cubacel_route" (click)="seeCartPreview()"
                    class="relative flex justify-end items-center cursor-pointer">
                    <!--              lg-landscape:w-2/5-->
                    <!--[routerLink]="cartRoute"-->
                    <div class="relative w-8
                lg-landscape:h-27px">
                        <!-- <img class="relative w-full h-full" src="../../../assets/menu/icon-cart.svg" alt=""> -->
                        <fa-icon
                            class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                            [icon]="cart_icon">
                        </fa-icon>
                        <div *ngIf="cart && (cart.totalItemsCount || cart.item_count)"
                            [ngClass]="cart && (cart.totalItemsCount && cart.totalItemsCount > 99 || cart.item_count && cart.item_count > 99) ? 'w-6 h-6 -top-4' : 'w-21px h-21px -top-3'"
                            class="absolute -right-3 rounded-full bg-yellow-#FF6D03 float-right flex justify-center items-center">
                            <p [ngClass]="cart && (cart.totalItemsCount && cart.totalItemsCount > 99 || cart.item_count && cart.item_count > 99) ? 'text-10px' : 'text-xs'"
                                class="relative text-white font-Lato-Bold">
                                {{cart?.totalItemsCount || cart?.item_count}}
                            </p>
                        </div>
                    </div>
                    <!--                <p class="relative font-Lato-Regular ml-1 text-gray-#252525-->
                    <!--                lg-landscape:text-xs leading-tight self-end" *ngIf="cart?.item_count">-->
                    <!--                    {{cartTime?.minutes}}:{{cartTime?.seconds | number: '2.0-0'}}</p>-->
                </a>

                <a routerLink="/cubacel/bag" *ngIf="cubacel_route"
                    class="relative flex justify-end items-center cursor-pointer">
                    <!--              lg-landscape:w-2/5-->
                    <!--[routerLink]="cartRoute"-->
                    <div class="relative w-8
                lg-landscape:h-27px">
                        <!-- <img class="relative w-full h-full" src="../../../assets/menu/icon-cart.svg" alt=""> -->
                        <fa-icon
                            class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                            [icon]="bag_icon">
                        </fa-icon>
                        <div *ngIf="valid_bag"
                            class="absolute -right-3 w-21px h-21px -top-3 text-xs rounded-full bg-yellow-#FF6D03 float-right flex justify-center items-center">
                            <p class="relative text-white font-Lato-Bold">
                                {{valid_bag}}
                            </p>
                        </div>
                    </div>
                    <!--                <p class="relative font-Lato-Regular ml-1 text-gray-#252525-->
                    <!--                lg-landscape:text-xs leading-tight self-end" *ngIf="cart?.item_count">-->
                    <!--                    {{cartTime?.minutes}}:{{cartTime?.seconds | number: '2.0-0'}}</p>-->
                </a>
            </div>
        </div>
    </div>
    <div class="relative bg-gray-#f2f2f2 flex justify-center border-b border-b-gray-#E0E0E0
    lg-landscape:w-full lg-landscape:h-48px">
        <div class="relative h-48px flex justify-between
        lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px">
            <div class="relative flex flex-1 items-center">
                <div class="relative flex w-auto h-48px items-center">
                    <div appClickOutside (appClickOutside)="onClickOutside()" [ignoreSelectors]="['.hamburger']"
                        class="relative w-auto">
                        <div class="relative w-auto flex justify-start items-center cursor-pointer"
                            (click)="setShowCat()">
                            <img class="relative w-22px h-auto" src="../../../assets/menu/icon-hamburger.svg" alt="">
                            <p class="relative font-Lato-Regular font-bold text-gray-#252525 uppercase
                        lg-landscape:text-sm lg-landscape:ml-9px lg-landscape:tracking-widest">
                                {{'CATEGORIES' | translate}}</p>
                            <img class="relative w-6 h-auto" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                        </div>
                        <div [ngClass]="{'hidden': !showcat}" class="menu-list absolute top-10 left-0 z-30 cursor-default white-menus-heigh
                    lg-landscape:w-314px bg-white lg-landscape:pt-24px lg-landscape:pb-24px">
                            <fa-icon (click)="showcat = false"
                                class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                                [icon]="close"></fa-icon>
                            <div class="headers px-24px" *ngFor="let header of menu_categories?.headers">
                                <p class="relative font-Lato-Regular font-semibold uppercase
                            lg-landscape:text-sm lg-landscape:tracking-1.75px">{{header?.text | translate}}</p>
                                <div class="relative w-full
                            lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                            </div>
                            <ul class="space-y-1 font-medium">
                                <li *ngFor="let link of menu_categories?.links" class="relative group h-auto">
                                    <div class="flex justify-between w-full items-center px-4 p-1">
                                        <a [ngClass]="'general-cat' + link?.id"
                                            (click)="goToCategory(link?.route, link?.id)" class="flex general-category ease-in-out duration-150 cursor-pointer justify-between w-full relative items-center text-gray-#252525 font-Lato-Regular font-semibold rounded-lg
                    hover:-translate-x-1">
                                            {{link?.text | translate}}
                                            <!-- <span (click)="showSubElement($event)"
                class="inline-flex items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium text-white bg-yellow-#FFA101 rounded-full">120</span> -->
                                        </a>
                                        <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                      lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3"
                                            src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                                    </div>
                                    <ul
                                        class="submenu overflow-hidden rounded-sm bg-white h-0 pl-8 pr-4 shadow transition-all relative w-auto transform z-index-1002 min-w-max duration-150">
                                        <li class="my-3" *ngFor="let sublink of link?.links">
                                            <div class="flex justify-between w-full items-center">
                                                <a (click)="goToCategory(sublink?.route, sublink?.id)"
                                                    [ngClass]="'general-cat' + sublink?.id"
                                                    class="text-gray-#252525 general-category cursor-pointer font-normal text-base capitalize transition-all hover:-translate-x-1">{{sublink?.text
                                                    | translate}}
                                                </a>
                                                <img *ngIf="sublink?.links?.length > 0" (click)="showSubElement($event)"
                                                    class="relative cursor-pointer
                    lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3"
                                                    src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                                            </div>
                                            <ul
                                                class="submenu overflow-hidden rounded-sm bg-white h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002 min-w-max duration-150">
                                                <li class="my-3" *ngFor="let subblink of sublink?.links">
                                                    <div class="flex justify-between w-full items-start">
                                                        <a (click)="goToCategory(subblink?.route, subblink?.id)"
                                                            [ngClass]="'general-cat' + subblink?.id"
                                                            class="text-dark general-category cursor-pointer font-normal text-base capitalize transition-all hover:-translate-x-1">{{subblink?.text
                                                            | translate}}
                                                        </a>
                                                        <img *ngIf="subblink?.links?.length > 0"
                                                            (click)="showSubElement($event)" class="relative cursor-pointer
                            lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3"
                                                            src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                                                            alt="">
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <!-- <div (click)="goToCategory(link?.route)" (mouseenter)="detectando(link)"
        *ngFor="let link of menu_categories?.links" class="item cursor-pointer flex justify-between items-center
                  hover:bg-gray-#EEEEEE w-full
                  lg-landscape:h-9 lg-landscape:mt-1 lg-landscape:pl-24px lg-landscape:pr-24px">
        <a class="item relative font-Lato-Regular font-semibold
                      lg-landscape:text-sm">{{link?.text | translate}}</a>
        <img *ngIf="link?.links?.length > 0" class="relative
                      lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3"
          src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
      </div> -->
                            <div *ngIf="showsubcat" class="sub-list absolute top-0 left-full z-30
                        lg-landscape:w-314px bg-white lg-landscape:pt-24px lg-landscape:pb-24px">
                                <fa-icon (click)="showsubcat = false"
                                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                                    [icon]="close"></fa-icon>
                                <div class="headers px-24px" *ngFor="let header of selected_link?.links[0]?.headers">
                                    <p class="relative font-Lato-Regular font-semibold uppercase
                            lg-landscape:text-sm lg-landscape:tracking-1.75px">{{header | translate}}</p>
                                    <div class="relative w-full
                            lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                                </div>
                                <div *ngFor="let link of selected_link?.links" (click)="gotoRoute(link?.route)" class="item flex justify-between items-center cursor-pointer w-full
                            hover:bg-gray-#EEEEEE
                            lg-landscape:h-9 lg-landscape:mt-1 lg-landscape:pl-24px lg-landscape:pr-24px">
                                    <a class="item relative font-Lato-Regular font-semibold
                                lg-landscape:text-sm">{{link?.text | translate}}</a>
                                    <img *ngIf="link?.links?.length > 0"
                                        class="relative
                                lg-landscape:w-7 lg-landscape:ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                                        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sep relative h-60% w-1px bg-gray-#E0E0E0
                lg-landscape:ml-4"></div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="relative w-auto ml-4" *ngIf="!hideDefaultStore">
                        <!--                  routerLink="/mercado-unico"-->
                        <p class="relative font-Lato-Regular font-semibold text-gray-#252525 capitalize cursor-pointer
                              lg-landscape:text-sm lg-landscape:w-auto" (click)="mercadoUnicoNavigate()">
                            mercado único
                        </p>
                    </div>

                    <div *ngIf="!hide_stores" class="relative w-auto flex items-center justify-start ml-4">
                        <div class="relative w-auto flex justify-start items-center cursor-pointer"
                            (click)="showstores = (!showstores && !!getSelectedZone); showcat = false; showtravels = false;showsubcat=false;showtsubcat = false;showprofile=false;">
                            <p class="relative cursor-pointer font-Lato-Regular font-semibold text-gray-#252525 capitalize
                    lg-landscape:text-sm lg-landscape:w-auto">{{ 'STORES' | translate }}</p>
                            <img class="relative w-6 h-auto" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                        </div>
                        <div *ngIf="showstores"
                            class="menu-list absolute top-9 left-0 z-30 cursor-default
                    lg-landscape:w-545px bg-white lg-landscape:pt-24px lg-landscape:pb-24px lg-landscape:max-h-81vh lg-landscape:overflow-y-auto">
                            <fa-icon (click)="showcat = false;showstores = false;"
                                class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                                [icon]="close"></fa-icon>
                            <div class="headers
                        lg-landscape:pl-24px lg-landscape:pr-24px"
                                *ngFor="let header of menu_stores?.headers; let i = index;">
                                <p *ngIf="i > 0" (click)="gotoRoute(header?.link)" class="relative cursor-pointer font-Lato-Regular font-semibold uppercase
                            lg-landscape:text-sm lg-landscape:tracking-1.75px">{{header?.text | translate}}</p>
                                <div *ngIf="i == 1" class="relative w-full
                            lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-3 lg-landscape:mb-3"></div>
                            </div>

                        </div>
                    </div>
                </div>

                <div *ngFor="let item of extra_items" class="relative w-auto ml-4">
                    <p *ngIf="item?.attributes?.Action == 'Navigate'"
                        [routerLink]="item?.attributes?.Route?.data?.attributes?.Route" class="relative cursor-pointer font-Lato-Regular
                                                                                          font-semibold text-gray-#252525 capitalize
                lg-landscape:text-sm lg-landscape:w-auto">{{item?.attributes?.Label}}</p>
                    <a *ngIf="item?.attributes?.Action == 'OpenExternalTab'"
                        [href]="item?.attributes?.Route?.data?.attributes?.Route" target="_blank" class="relative cursor-pointer font-Lato-Regular
                                                                                          font-semibold text-gray-#252525 capitalize
                lg-landscape:text-sm lg-landscape:w-auto">{{item?.attributes?.Label}}</a>
                </div>

                <div *ngIf="others_label" class="relative w-auto flex items-center justify-start ml-4">
                    <div class="relative w-auto flex justify-start items-center cursor-pointer" (click)="showcatnave = (!showcatnave && !!getSelectedZone);
                                    showcat = false; showtravels = false;showsubcat=false;showtsubcat = false;showprofile=false;
                                    showstores=false">
                        <p class="relative cursor-pointer font-Lato-Regular font-semibold text-gray-#252525 capitalize
                                      lg-landscape:text-sm lg-landscape:w-auto">{{ others_label }}</p>
                        <img class="relative w-6 h-auto" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                    </div>
                    <div *ngIf="showcatnave" class="menu-list absolute top-9 left-0 z-30 cursor-default
                                    lg-landscape:w-64 bg-white lg-landscape:pt-24px lg-landscape:pb-24px lg-landscape:max-h-81vh
                                    lg-landscape:overflow-y-auto">
                        <fa-icon (click)="showcat = false;showstores = false; showcatnave = false;"
                            class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                            [icon]="close"></fa-icon>
                        <div class="headers
                        lg-landscape:pl-24px lg-landscape:pr-24px" *ngFor="let other of others; let i = index;">
                            <p (click)="showcatnave = false;"
                                [routerLink]="other?.attributes?.Label == 'La Nave' ? '/cu/la-nave' : other?.attributes?.Route?.data?.attributes?.Route"
                                class="relative cursor-pointer font-Lato-Regular font-semibold uppercase
                            lg-landscape:text-sm lg-landscape:tracking-1.75px">{{other?.attributes?.Label |
                                translate}}
                            </p>
                            <div *ngIf="i == 0" class="relative w-full
                            lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-3 lg-landscape:mb-3"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="relative w-auto flex justify-start items-center">

                <div class="sep relative h-60% w-1.5px bg-gray-#E0E0E0"></div>

                <!-- <p class="relative cursor-pointer font-Lato-Regular text-gray-#252525 capitalize
        lg-landscape:text-sm lg-landscape:ml-9 lg-landscape:w-auto">{{'BLOG' | translate}}</p> -->

                <p *ngFor="let item of trailing_items" [routerLink]="item?.attributes?.Route?.data?.attributes?.Route"
                    class="relative cursor-pointer font-Lato-Regular text-gray-#252525 capitalize
                lg-landscape:text-sm lg-landscape:ml-5 lg-landscape:w-auto">{{item?.attributes?.Label}}</p>
                <!-- <p routerLink="/katapulk/about" class="relative cursor-pointer font-Lato-Regular text-gray-#252525 capitalize
        lg-landscape:text-sm lg-landscape:ml-10 lg-landscape:w-auto">{{'ABOUT' | translate}}</p>

<p routerLink="/customer/help" class="relative cursor-pointer font-Lato-Regular text-gray-#252525 capitalize
        lg-landscape:text-sm lg-landscape:ml-10 lg-landscape:w-auto">{{'HELP' |
            translate}}</p> -->
            </div>
        </div>
    </div>
    <!-- </div> -->
</section>

<section id="responsive-menu" class="fixed top-0 left-0 bg-white overflow-y-visible
us:px-5.3vw us:pb-3 w-screen
us-landscape:flex us-landscape:w-full
lg-landscape:hidden">
    <div class="us:flex us:w-full us:min-h-42px us:pt-4 us:justify-between us:items-center us:flex-wrap">

        <div class="flex gap-2 items-center">
          <div (click)="showcat = !showcat; showstores = false;showtravels=false;showsubcat=false;showtsubcat = false;showprofile=false;"
               id="hamburger" class="hamburger relative cursor-pointer w-auto flex flex-col gap-y-1 order-1">
            <div class="relative w-6 h-2px bg-gray-#252525"></div>
            <div class="relative w-6 h-2px bg-gray-#252525"></div>
            <div class="relative w-6 h-2px bg-gray-#252525"></div>
          </div>

<!--          <img class="relative cursor-pointer h-10 order-2" routerLink="/" src="../../../assets/icons/icon.png" alt="">-->
          <img class="relative cursor-pointer h-10 order-2 us:flex sm:hidden md:flex" routerLink="/" src="../../../assets/icons/icon.png" alt="">
          <img class="relative cursor-pointer h-10 order-2 us:hidden sm:flex md:hidden" routerLink="/" src="../../../assets/menu/logo.svg" alt="">
        </div>

<!--        <div class="relative cursor-pointer-->
<!--            us:order-3-->
<!--            md:order-4" [matMenuTriggerFor]="languageMenu">-->
<!--          <p class="relative font-Lato-Regular text-gray-#252525 text-xs">{{ 'LANGUAGE' | translate }}</p>-->
<!--          <span class="relative flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">-->
<!--            <p class="relative text-gray-#252525 text-13px">{{ selectedLanguage.id | uppercase }}</p>-->
<!--            <img class="relative w-4" src="../../../assets/menu/icon-arrowdown.svg" alt="">-->
<!--          </span>-->
<!--        </div>-->

        <div class="relative w-auto us:order-9 md:order-4 transition-all delay-150 ease-in-out duration-150 us:hidden sm:hidden md:flex">
          <div class="cursor-pointer w-auto pl-2 rounded md:py-0 h-42px flex items-center border us:mt-10px md:mt-0" [matMenuTriggerFor]="languageMenuFlag">
            <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">
              <img class="flag h-5" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
              <img class="relative w-7" src="../../../assets/menu/icon-arrowdown.svg" alt="">
          </span>
          </div>
          <mat-menu #languageMenuFlag="matMenu" [overlapTrigger]="false">
            <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <span class="flex flex-row items-center">
                <img class="flag mr-3 h-5" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                <span class="iso">{{lang.title | translate}}</span>
            </span>
            </button>
          </mat-menu>
        </div>

        <div class="relative w-auto us:order-10 md:order-5 transition-all delay-150 ease-in-out duration-150 us:hidden sm:hidden md:flex">
          <div class="cursor-pointer w-auto pl-2 rounded md:py-0 h-42px flex items-center border us:mt-10px md:mt-0" [matMenuTriggerFor]="currenciesMenu">
            <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">
              <p class="relative text-13px text-gray-#252525">{{ selectedCurrency | uppercase }}</p>
              <img class="relative w-7" src="../../../assets/menu/icon-arrowdown.svg" alt="">
          </span>
          </div>
          <mat-menu #currenciesMenu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item *ngFor="let currency of currencies" (click)="setCurrency(currency.name)">
            <span class="flex flex-row items-center">
                <span class="iso">{{currency.name | translate}}</span>
            </span>
            </button>
          </mat-menu>
        </div>

        <a routerLink="/auth/signIn" *ngIf="!user?.id" class="relative flex justify-end items-end cursor-pointer
            us:order-2
            md:order-5">
            <img class="relative w-8" src="../../../assets/menu/profile-menu.svg" alt="">
            <!-- <p class="relative font-Lato-Regular ml-1 text-gray-#252525 overflow-hidden
           lg-landscape:text-10px leading-tight lg-landscape:w-2/3">Hola {{user?.email}}<br><span
            class="text-14px font-semibold">Tu
            cuenta</span></p> -->
        </a>

        <a (click)="showprofile = !showprofile;showcat=false;showstores = false;showtravels=false;showsubcat=false;showtsubcat = false;"
            class="relative flex justify-end items-end cursor-pointer
            us:order-2
            md:order-5" *ngIf="user?.id">
            <img class="relative w-8" src="../../../assets/menu/profile-menu.svg" alt="">
            <!-- <p class="relative font-Lato-Regular ml-1 text-gray-#252525 overflow-hidden
           lg-landscape:text-10px leading-tight lg-landscape:w-2/3">Hola {{user?.email}}<br><span
            class="text-14px font-semibold">Tu
            cuenta</span></p> -->
        </a>


        <a (click)="navigateToWallet()" class="relative flex justify-end items-center cursor-pointer
        us:order-3
        md:order-6">
            <div class="relative w-8">
                <fa-icon
                    class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="faCartShopping">
                </fa-icon>
            </div>
        </a>

        <a class="relative flex justify-end items-center cursor-pointer us:order-4 md:order-7"
            (click)="openNotificationTray()">
            <div class="relative w-8 lg-landscape:h-27px">
                <fa-icon
                    class="relative flex justify-center items-center bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="faBell">
                </fa-icon>
                <div *ngIf="unreadCount > 0" class="absolute -top-3 -right-3 rounded-full w-21px h-21px bg-yellow-#FF6D03 float-right flex
                    justify-center items-center shadow-md">
                <p class="relative text-white font-Lato-Bold text-xs">{{unreadCount}}
                </p>
              </div>
            </div>
        </a>




        <a (click)="navigateToGiftCards()" class="relative flex justify-end items-center cursor-pointer
        us:order-5
        md:order-7">
            <div class="relative w-8">
                <fa-icon
                    class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="fagift">
                </fa-icon>
            </div>
        </a>



        <a *ngIf="!cubacel_route" (click)="seeCartPreview()" class="relative flex justify-end items-center cursor-pointer
        us:order-6
        md:order-8">
            <div class="relative w-8 lg-landscape:h-27px">
                <!-- <img class="relative w-6" src="../../../assets/menu/icon-cart.svg" alt=""> -->
                <fa-icon
                    class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="cart_icon">
                </fa-icon>
                <div *ngIf="cart?.item_count || cart?.totalItemsCount" class="absolute -top-3 -right-3 rounded-full w-21px h-21px bg-yellow-#FF6D03 float-right flex
                    justify-center items-center">
                    <p class="relative text-white font-Lato-Bold text-xs">{{cart?.item_count || cart?.totalItemsCount}}
                    </p>
                </div>
            </div>
            <!--            <p class="relative font-Lato-Regular ml-1 text-gray-#252525 text-xs leading-tight self-end"-->
            <!--                *ngIf="cart?.item_count">-->
            <!--                {{cartTime?.minutes}}:{{cartTime?.seconds | number: '2.0-0'}}</p>-->
        </a>

        <a routerLink="/cubacel/bag" *ngIf="cubacel_route" class="relative flex justify-end items-center cursor-pointer
        us:order-7
        md:order-8">
            <div class="relative">
                <!-- <img class="relative w-6" src="../../../assets/menu/icon-cart.svg" alt=""> -->
                <fa-icon
                    class="relative flex justify-center items-center  bg-yellow-#FF6D03 rounded-full w-7 h-7 p-1 text-white"
                    [icon]="bag_icon">
                </fa-icon>
                <div *ngIf="valid_bag" class="absolute -top-3 -right-3 rounded-full w-21px h-21px bg-yellow-#FF6D03 float-right flex
                    justify-center items-center">
                    <p class="relative text-white font-Lato-Bold text-xs">{{valid_bag}}
                    </p>
                </div>
            </div>
            <!--            <p class="relative font-Lato-Regular ml-1 text-gray-#252525 text-xs leading-tight self-end"-->
            <!--                *ngIf="cart?.item_count">-->
            <!--                {{cartTime?.minutes}}:{{cartTime?.seconds | number: '2.0-0'}}</p>-->
        </a>



        <div class="relative flex gap-x-2 transition-all ease-in-out duration-150 items-center
                    us:mt-10px us:order-8 us:w-full
                    sm:order-8 sm:mt-10px sm:w-full
                    md:order-3 md:mt-0 md:w-2/5 lg:w-2/4">
            <div ngClass="{{cubacel_route ? 'h-0 overflow-hidden' : ''}}"
                class="relative flex justify-between items-center flex-1">
                <input #searchInputRes (keydown)="enterPressed($event)" (input)="getSuggestions($event)" (focus)="onFocus()" (blur)="onBlur()" id="inpres"
                    class="relative us:w-20 us:flex-1 us:h-42px rounded-l-3px active:outline-none focus:outline-none active:ring-0 focus:ring-0 font-Lato-Regular
                us:border-2 us:border-solid us:border-yellow-#FF6D03 us:text-sm leading-4" type="text"
                    placeholder="{{'SEARCH_FOR_SOMETHING' | translate}}">
                <div (click)="searchGlobalProduct(searchInputRes.value)" class="relative overflow-hidden rounded-r-3px cursor-pointer
                us:w-44px us:h-42px bg-yellow-#FF6D03 flex justify-center items-center">
                    <img class="relative w-2/4 h-auto fill-white" src="../../../assets/menu/icon-search.svg" alt="">
                </div>
                <ul *ngIf="suggestions.length"
                    class="absolute search-suggestion-box top-full left-0 bg-white rounded-md shadow-md z-50 w-full px-2 py-2 grid grid-cols-1 gap-2">
                    <li (click)="searchInputRes.value = suggestion; searchGlobalProduct(suggestion); suggestions = []"
                        *ngFor="let suggestion of suggestions"
                        class="relative font-Lato-Bold text-sm cursor-pointer capitalize hover:bg-gray-#dfdfdf px-2">
                        {{suggestion}}
                    </li>
                </ul>
            </div>
            <div (click)="showCountryProvinceModal()" [ngClass]="{'us:w-100px': isFocused}" class="relative bg-yellow-#FFC35D overflow-hidden flex items-center justify-start rounded-3px
                     sm:w-100px md:w-100px lg:w-auto us:px-1 us:h-42px lg-landscape:rounded-3px">
                <img [ngClass]="{'us:-ml-1': isFocused, 'us:ml-0': !isFocused}"
                  class="relative us:w-31px sm:-ml-1" src="../../../assets/menu/icon-location.svg" alt="">
                <p [ngClass]="{'us:flex': isFocused, 'us:hidden': !isFocused}" class="relative -ml-1 text-xs font-Lato-Regular font-semibold pr-1 sm:flex">{{getSelectedZones | translate}}
                </p>
            </div>

            <div class="relative w-auto us:order-9 md:order-4 transition-all delay-150 ease-in-out duration-150 md:hidden" [ngClass]="{'us:hidden sm:hidden': isFocused, 'us:flex sm:flex': !isFocused}">
                <div class="cursor-pointer w-auto us:px-2 sm:pr-0 sm:pl-2 rounded md:py-0 h-42px flex items-center border" [matMenuTriggerFor]="languageMenuFlag">
                    <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">
                        <img class="flag h-5" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                        <img class="relative w-7 us:hidden sm:flex" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                    </span>
                </div>
                <mat-menu #languageMenuFlag="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                        <span class="flex flex-row items-center">
                            <img class="flag mr-3 h-5" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                            <span class="iso">{{lang.title | translate}}</span>
                        </span>
                  </button>
                </mat-menu>
            </div>


            <div class="relative w-auto us:order-10 md:order-5 transition-all delay-150 ease-in-out duration-150 md:hidden" [ngClass]="{'us:hidden sm:hidden': isFocused, 'us:flex sm:flex': !isFocused}">
                <div class="cursor-pointer w-auto us:px-2 sm:pr-0 sm:pl-2 rounded md:py-0 h-42px flex items-center border" [matMenuTriggerFor]="currenciesMenu">
                    <span class="relative text-base leading-5 flex font-Lato-Regular font-semibold overflow-hidden justify-start items-center h-4">
                        <p class="relative text-13px text-gray-#252525">{{ selectedCurrency | uppercase }}</p>
                        <img class="relative w-7 us:hidden sm:flex" src="../../../assets/menu/icon-arrowdown.svg" alt="">
                    </span>
                </div>
                <mat-menu #currenciesMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item *ngFor="let currency of currencies" (click)="setCurrency(currency.name)">
                        <span class="flex flex-row items-center">
                            <span class="iso">{{currency.name | translate}}</span>
                        </span>
                    </button>
                </mat-menu>
            </div>


        </div>
    </div>
    <div [ngClass]="{'hidden': !showcat}" class="absolute bg-white left-0 w-full h-auto min-h-screen z-40 overflow-y-scroll
    us:top-1/2
    us-landscape:top-full
    md:top-full">
        <p
            class="relative w-auto h-48px py-4 uppercase bg-gray-#EEEEEE text-sm us:px-5.3vw font-Lato-Regular font-bold">
            menú
        </p>
        <div class="relative w-full bg-white h-auto">
            <div class="absolute left-0 z-50 cursor-default w-full us:px-5.3vw bg-white pb-5">
                <fa-icon (click)="showcat = false"
                    class="absolute top-2 right-4 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <div class="headers" *ngFor="let header of menu_categories?.headers">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm
                    h-48px py-4">{{header?.text | translate}}</p>
                    <div class="relative w-full
                              lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>


                <ul class="relative w-full space-y-1 font-medium">
                    <li *ngFor="let link of menu_categories?.links" class="relative group h-auto">
                        <div class="flex justify-between w-full items-center px-4 p-1">
                            <a (click)="goToCategory(link?.route, link?.id)"
                                class="flex cursor-pointer justify-between w-full relative items-center text-gray-#252525 general-category font-Lato-Regular font-semibold rounded-lg"
                                [ngClass]="'general-cat' + link?.id"> {{link?.text | translate}}
                                <!-- <span (click)="showSubElement($event)"
  class="inline-flex items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium text-white bg-yellow-#FFA101 rounded-full">120</span> -->
                            </a>
                            <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                  w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                        </div>
                        <ul
                            class="submenu overflow-hidden rounded-sm bg-white h-0 pl-8 pr-4 transition-all relative w-auto transform z-index-1002 min-w-max duration-300">
                            <li class="my-3" *ngFor="let sublink of link?.links">
                                <div class="flex justify-between w-full items-center">
                                    <a (click)="goToCategory(sublink?.route, sublink?.id)"
                                        [ngClass]="'general-cat' + sublink?.id"
                                        class="text-dark cursor-pointer font-normal text-base capitalize transition-all general-category">{{sublink?.text
                                        | translate}}
                                    </a>
                                    <img *ngIf="sublink?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                                </div>
                                <ul
                                    class="submenu overflow-hidden rounded-sm bg-white h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002 min-w-max duration-300">
                                    <li class="my-3" *ngFor="let subblink of sublink?.links">
                                        <div class="flex justify-between w-full items-start">
                                            <a (click)="goToCategory(subblink?.route, subblink?.id)"
                                                [ngClass]="'general-cat' + subblink?.id"
                                                class="text-dark cursor-pointer font-normal text-base capitalize transition-all general-category">{{subblink?.text
                                                | translate}}
                                            </a>
                                            <img *ngIf="subblink?.links?.length > 0" (click)="showSubElement($event)"
                                                class="relative cursor-pointer
                        w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                                                alt="">
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>


                <!-- <div class="headers" *ngFor="let header of menu_stores?.headers">
    <p class="relative font-Lato-Regular font-semibold uppercase text-sm
    h-48px py-4">{{header?.text}}</p>
    <div class="relative w-full
              lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
</div> -->
                <!-- <div (mouseenter)="detectando(link)" *ngFor="let link of menu_stores?.links" class="item cursor-pointer flex justify-between items-center
            hover:bg-gray-#EEEEEE w-full
              h-48px py-4 capitalize">
    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text}}</a>
    <img *ngIf="link?.links?.length > 0"
        class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"
        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
</div> -->
                <div class="headers border-t border-gray-#ABABAB">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm
                    h-48px py-4">{{'TRENDS' | translate}}</p>
                    <div class="relative w-full
                              lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>

                <!--        <div *ngIf="!hideDefaultStore"-->
                <!--          [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'mercado-unico']" class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">Mercado único</a>-->
                <!--          &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--              class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--              src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        </div>-->

                <!--        <div (click)="detectandoLinkResponsive()" class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">{{'STORES' | translate}}</a>-->
                <!--          <img class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"-->
                <!--            src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">-->
                <!--        </div>-->

                <!--        <div [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores', 'k-wholesaler']"-->
                <!--          class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">{{'WHOLESALERS' | translate}}</a>-->
                <!--          &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--              class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--              src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        </div>-->

                <!--        <div [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'recargas-a-cuba']" class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">{{'RECHARGES' | translate}}</a>-->
                <!--          &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--              class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--              src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        </div>-->

                <!--        &lt;!&ndash; <div routerLink="/travel-services/main" class="headers border-t border-gray-#ABABAB">-->
                <!--            <p class="relative font-Lato-Regular font-semibold uppercase text-sm-->
                <!--            h-48px py-4">{{'CONSULAR_TRAVEL_SERVICES' | translate}}</p>-->
                <!--            <div class="relative w-full-->
                <!--                      lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>-->
                <!--        </div> &ndash;&gt;-->
                <!--        &lt;!&ndash;-->
                <!--        <div [routerLink]="link?.route" *ngFor="let link of menu_travels?.links[0].links" class="item flex justify-between items-center cursor-pointer w-full h-48px py-4-->
                <!--        hover:bg-gray-#EEEEEE">-->
                <!--            <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text | translate}}</a>-->
                <!--            <img *ngIf="link?.links?.length > 0"-->
                <!--                class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--                src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">-->
                <!--        </div> &ndash;&gt;-->

                <!--        &lt;!&ndash; <div (click)="detectandoTravels()" class="item cursor-pointer flex justify-between items-center-->
                <!--                    hover:bg-gray-#EEEEEE w-full-->
                <!--                      h-48px py-4 capitalize">-->
                <!--            <a class="item relative font-Lato-Regular font-semibold text-sm">{{'CONSULAR_TRAVEL_SERVICES' |-->
                <!--                translate}}</a>-->
                <!--            <img *ngIf="true" class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--                src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">-->
                <!--        </div> &ndash;&gt;-->

                <!--        <div class="headers border-t border-gray-#ABABAB">-->
                <!--          <p class="relative font-Lato-Regular font-semibold uppercase text-sm-->
                <!--                    h-48px py-4">Favoritos</p>-->
                <!--          <div class="relative w-full-->
                <!--                              lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>-->
                <!--        </div>-->

                <!--        &lt;!&ndash; <div class="item cursor-pointer flex justify-between items-center-->
                <!--                    hover:bg-gray-#EEEEEE w-full-->
                <!--                      h-48px py-4 capitalize"> &ndash;&gt;-->
                <!--        &lt;!&ndash; <a class="item relative font-Lato-Regular font-semibold text-sm">Blog</a> &ndash;&gt;-->
                <!--        &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--                class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--                src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        &lt;!&ndash; </div> &ndash;&gt;-->

                <!--        <div routerLink="/katapulk/about" class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">About</a>-->
                <!--          &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--              class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--              src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        </div>-->

                <!--        <div routerLink="/customer/help" class="item cursor-pointer flex justify-between items-center-->
                <!--                            hover:bg-gray-#EEEEEE w-full-->
                <!--                              h-48px py-4 capitalize">-->
                <!--          <a class="item relative font-Lato-Regular font-semibold text-sm">Ayuda</a>-->
                <!--          &lt;!&ndash; <img *ngIf="link?.links?.length > 0"-->
                <!--              class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3"-->
                <!--              src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt=""> &ndash;&gt;-->
                <!--        </div>-->
            </div>
        </div>

    </div>


    <div *ngIf="showprofile" class="absolute bg-white left-0 w-full h-auto min-h-screen z-40 overflow-y-scroll
    us:top-1/2
    us-landscape:top-full
    md:top-full">
        <p
            class="relative w-auto h-48px py-4 uppercase bg-gray-#EEEEEE text-sm us:px-5.3vw font-Lato-Regular font-bold">
            menú
        </p>
        <div class="relative w-full bg-white h-auto">
            <div class="absolute left-0 z-50 cursor-default w-full us:px-5.3vw bg-white pb-5">
                <fa-icon (click)="showprofile = false"
                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <div class="headers" *ngFor="let header of menu_profile?.headers">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm
                    h-48px py-4">{{header.text | translate}}</p>
                    <div class="relative w-full
                              lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>
                <div (click)="gotoRoute(link?.route)" *ngFor="let link of menu_profile?.links" class="item cursor-pointer flex justify-between items-center
                            hover:bg-gray-#EEEEEE w-full
                              h-48px py-4 capitalize">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link.text | translate}}</a>
                </div>
                <div (click)="logout()" class="item cursor-pointer flex justify-between items-center
                            hover:bg-gray-#EEEEEE w-full
                              h-48px py-4 capitalize">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{'LOG_OUT' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showsubcat" class="absolute bg-white left-0 w-full h-auto min-h-screen z-40 overflow-y-scroll
    us:top-1/2
    us-landscape:top-full
    md:top-full">
        <p
            class="relative w-auto h-48px py-4 uppercase bg-gray-#EEEEEE text-sm us:px-5.3vw font-Lato-Regular font-bold">
            <span (click)="showsubcat = false; showcat = true;">{{'BACK_TO_MENU' | translate}}</span>
        </p>
        <div class="relative w-full bg-white h-auto">
            <div class="absolute left-0 z-30 cursor-default w-full us:px-5.3vw bg-white">
                <fa-icon (click)="showsubcat = false"
                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <div class="headers" *ngFor="let header of selected_link?.links[0]?.headers">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm h-48px py-4">
                        {{header | translate}}</p>
                    <div class="relative w-full
                 lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>
                <div *ngFor="let link of selected_link?.links" (click)="gotoRoute(link?.route)" class="item flex justify-between items-center cursor-pointer w-full h-48px py-4
                  hover:bg-gray-#EEEEEE">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text | translate}}</a>
                    <img *ngIf="link?.links?.length > 0"
                        class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showsubcattiendas" class="absolute bg-white left-0 w-full h-screen z-40 overflow-y-scroll
    us:top-1/2
    us-landscape:top-full
    md:top-full">
        <p
            class="relative w-auto h-48px py-4 uppercase bg-gray-#EEEEEE text-sm us:px-5.3vw font-Lato-Regular font-bold">
            <span (click)="showsubcattiendas = false; showcat = true;">{{'BACK_TO_MENU' | translate}}</span>
        </p>
        <div class="relative w-full bg-white h-auto">
            <div class="absolute left-0 z-30 cursor-default w-full us:px-5.3vw bg-white">
                <fa-icon (click)="showsubcattiendas = false;"
                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <div class="headers" *ngFor="let header of menu_stores?.links[0]?.headers">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm h-48px py-4">
                        {{header}}</p>
                    <div class="relative w-full
                 lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>
                <div (click)="navigate(link?.id, link?.route)" *ngFor="let link of menu_stores?.links" class="item flex justify-between items-center cursor-pointer w-full h-48px py-4
                  hover:bg-gray-#EEEEEE">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text}}</a>
                    <img *ngIf="link?.links?.length > 0"
                        class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                </div>
                <div (click)="navigate(link?.id, link?.route)" *ngFor="let link of menu_stores?.links2" class="item flex justify-between items-center cursor-pointer w-full h-48px py-4
                  hover:bg-gray-#EEEEEE">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text}}</a>
                    <img *ngIf="link?.links?.length > 0"
                        class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showcatTravels" class="absolute bg-white left-0 w-full h-screen z-40 overflow-y-scroll
    us:top-1/2
    us-landscape:top-full
    md:top-full">
        <p
            class="relative w-auto h-48px py-4 uppercase bg-gray-#EEEEEE text-sm us:px-5.3vw font-Lato-Regular font-bold">
            <span (click)="showcatTravels = false; showcat = true;">{{'BACK_TO_MENU' | translate}}</span>
        </p>
        <div class="relative w-full bg-white h-auto">
            <div class="absolute left-0 z-30 cursor-default w-full us:px-5.3vw bg-white">
                <fa-icon (click)="showcatTravels = false;"
                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-yellow-#FFA101 lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <div class="headers" *ngFor="let header of menu_travels?.links[0]?.headers">
                    <p class="relative font-Lato-Regular font-semibold uppercase text-sm h-48px py-4">
                        {{header | translate}}</p>
                    <div class="relative w-full
                 lg-landscape:border-t border-t-gray-#E0E0E0 lg-landscape:mt-2"></div>
                </div>
                <div [routerLink]="link?.route" *ngFor="let link of menu_travels?.links[0].links" class="item flex justify-between items-center cursor-pointer w-full h-48px py-4
                  hover:bg-gray-#EEEEEE">
                    <a class="item relative font-Lato-Regular font-semibold text-sm">{{link?.text | translate}}</a>
                    <img *ngIf="link?.links?.length > 0"
                        class="relative w-7 ml-8 lg-landscape:transform lg-landscape:translate-x-3 lg-landscape:hidden"
                        src="../../../assets/imgs/home-slider/icon-arrowright.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="fixed left-0 bg-gray-#f2f2f2 flex justify-start items-center overflow-x-scroll z-50
    us:h-47px us:top-116px us:w-screen px-5.3vw
    us-landscape:top-70px us-landscape:w-screen
    md:top-70px
    lg-landscape:hidden">
    <div *ngIf="!hideDefaultStore"
        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'mercado-unico']"
        class="relative w-auto h-full flex justify-center items-center">
        <a class="relative font-Lato-Regular font-semibold text-gray-#252525 w-auto pr-2 whitespace-nowrap
        us:text-sm">Mercado Único</a>
    </div>
    <div *ngIf="!hide_stores"
        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores', 'directory']"
        class="relative w-auto h-full flex justify-center items-center">
        <a class="relative font-Lato-Regular font-semibold text-gray-#252525 w-auto px-2 whitespace-nowrap
        us:text-sm">{{'STORES' | translate}}</a>
    </div>
    <div *ngFor="let item of extra_items" class="relative w-auto h-full flex justify-center items-center">
        <p *ngIf="item?.attributes?.Action == 'Navigate'"
            [routerLink]="item?.attributes?.Route?.data?.attributes?.Route" class="relative font-Lato-Regular
     font-semibold text-gray-#252525 w-auto px-2 whitespace-nowrap us:text-sm">{{item?.attributes?.Label}}</p>
        <a *ngIf="item?.attributes?.Action == 'OpenExternalTab'"
            [href]="item?.attributes?.Route?.data?.attributes?.Route" target="_blank" class="relative font-Lato-Regular
     font-semibold text-gray-#252525 w-auto px-2 whitespace-nowrap us:text-sm">{{item?.attributes?.Label}}</a>
    </div>

    <div *ngFor="let other of others; let i = index;" class="relative w-auto h-full flex justify-center items-center">
        <p [routerLink]="other?.attributes?.Label == 'La Nave' ? '/cu/la-nave' : other?.attributes?.Route?.data?.attributes?.Route"
            class="relative font-Lato-Regular
     font-semibold text-gray-#252525 w-auto px-2 whitespace-nowrap us:text-sm">{{other?.attributes?.Label | translate}}
        </p>
    </div>
</div>
