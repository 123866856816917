import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from "../../../../shared/services/store.service";
import { Product, SelectedZone, Store, Taxonomy } from "../../../../shared/models";
import { TaxonomiesService } from "../../../../shared/services/taxonomies.service";
import { ProductsService } from "../../../../shared/services/products.service";
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";
import { combineLatest, forkJoin, fromEvent, Observable, of, Subject, Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { LanguageService } from "../../../../shared/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { ZonesService } from "../../../../shared/services/zones.service";
import { CookieService } from "ngx-cookie-service";
import { SEARCH_FILTER_DEFAULT_VALUE, SearchFiltersType } from "../../../../shared/filters/searc-filters";
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import { AlgoliaService } from 'src/app/shared/services/algolia.service';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild('searchProducts', { static: true }) myInputSearch!: ElementRef;
  @ViewChild('searchProducts', { static: true }) search_input: any;
  faPlus = faPlus;
  currentStore: Store = {
    id: -1,
    name: '',
    logoUrl: '../../../../../assets/imgs/product-card/no-product-image.png',
    metaKeywords: [],
    code: '',
    description: ''
  };
  storeId: number | null = null;
  code: string = '';

  // taxonomiesObs$: Observable<Taxonomy> | undefined;
  // productsObs$: Observable<Product[]> | undefined;
  loadedProducts: Product[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  actualPage: number = 1;
  totalPages: number = 2;
  perPage: number = 24;
  skeletons = Array(this.perPage);

  listedProducts: number = 0;
  totalProducts: number = 0;

  filters: { taxon?: any, price?: string, name?: string, selectedStores?: string, DeliveryTimes?: String } = {};
  order: any = 'RELEVANCE';
  showGrid = true;
  clearFilters = false;

  src = "";
  products_suscription: any;
  selectedTaxon: number | null = null;
  selectedPrice: string | null = null;
  selectedText: string | null = null;
  selectedStores: string | null = null;
  selectedDelivery: string | null = null;
  resetFilterChanges: boolean | null = null;

  municipalityNumber: number = -1;
  products_observer: any;
  current_page: number = 1;
  hits_per_page: number = 24;
  next_page = false;
  facets: any = null;
  query_params: any = null;
  router_obs: Subscription = new Subscription();
  combine_latest_obs: Subscription = new Subscription();
  is_refresh = false;
  is_store_page = false;
  initComponent: boolean = true;
  private searchSubject = new Subject<string>();
  suggestions: string[] = [];
  lang: any = "";
  selected_sugestion = null;
  delivery_times: any = null;

  constructor(
    private storeService: StoreService,
    private taxonomiesService: TaxonomiesService,
    private productsService: ProductsService,
    private activeRoute: ActivatedRoute,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private zonesService: ZonesService,
    private router: Router,
    public cookieService: CookieService,
    private dynamicService: DynamicCOnfigService,
    private algoliaService: AlgoliaService
  ) {
    // anulo la suscripcion para que se inicie de nuevo debajo en caso de modificar los filtros teniendo en cuenta que vamos a,
    // estar modificando la misma ruta y pueden duplicarse ya que no vamos a pasar por el ondestroy.
    if (!this.router_obs.closed) this.router_obs.unsubscribe();
    this.router_obs = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // Comparo el id y el url para saber si estan haciendo refresh con f5 si es el caso pongo is_refresh en true, para no
        // excluir los faucets sino se me quedan vacios por el init del componente y uno seleccionado por defecto
        (e.id === 1 && e.url === e.urlAfterRedirects) ? this.is_refresh = true : this.is_refresh = false;
        // uso el operador rxjs combineLatest dentro del metodo useCombine para que se suscriba a los dos observables al mismo tiempo
        // y tener los params y los queryparams en una sola respuesta cada vez que se modifica la url y la guardo en la constante
        // DATA y con esa constante llamo al metodo onUrlChange que se encarga de setear todos los valores e inicializar el componente
        const DATA = this.useCombine(this.activeRoute.params, this.activeRoute.queryParams);

        if (this.router?.url?.includes('global-search')) {
          this.is_store_page = false;
          this.onUrlChange(DATA?.params, DATA?.query_params);
        }
        else if (this.router.url.includes('/category/')) {
          this.is_store_page = false;
          this.onUrlChange(DATA?.params, DATA?.query_params);
        }
        else {
          this.is_store_page = true;
          this.onUrlChange(DATA?.params, DATA?.query_params);
        }
      }
    })
  }

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(400)).subscribe((searchValue) => {
      this.algoliaService.searchSuggestions(searchValue, this.lang).subscribe((data: any) => {
        this.suggestions = data?.data;
      });
    });

    document.documentElement.addEventListener('click', (event: any) => {
      const box: any = document.querySelectorAll('.search-suggestion-box-store');
      box?.forEach((element: any) => {
        if (!element?.contains(event?.target)) this.suggestions = [];
      });
    },
    );
  }

  ngOnDestroy(): void {
    if (!this.router_obs.closed) this.router_obs.unsubscribe();
    if (!this.combine_latest_obs.closed) this.combine_latest_obs.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.cookieService.delete('STORAGE_FILTER_BY_STORE_ID_' + this.currentStore?.id,);
    //  You can replace this with this.destroy$.complete() as well
  }

  ngAfterViewInit() {
    if (this.search_input) {
      this.search_input.nativeElement.value = this.filters && this.filters.name ? this.filters.name : '';
    }

    this.activeRoute.fragment.subscribe((fragment: string | null) => {
      this.navigateInternal(fragment!);
    });

    this.moveDownAndUpThrowSuggestionsWithArrows();
  }

  onUrlChange(params: any, queryparams: any) {
    this.goToTop();
    this.skeletons = Array(this.perPage);
    this.loadedProducts = [];
    this.listedProducts = 0;
    this.totalProducts = 0;
    this.actualPage = 1;
    this.filters = {};
    // this.clearFilters = true;
    this.currentStore = {
      id: -1,
      name: '',
      logoUrl: '../../../../../assets/imgs/product-card/no-product-image.png',
      metaKeywords: [],
      code: '',
      description: ''
    };
    // seteo algunos parametros del componente basado en los queryparams, estas variables son elementos de entrada
    // en los componentes hijos que los necesitan como el menu para modificar el estado de los estilos visuales y arr de filtros seleccionados
    this.selectedTaxon = queryparams?.taxon ?? null;
    this.selectedPrice = queryparams?.price ?? null;
    this.selectedText = queryparams?.text ?? null;
    this.selectedStores = queryparams?.StoreIds ?? null;
    this.selectedDelivery = queryparams?.DeliveryTimes ?? null;
    this.order = queryparams?.order ?? 'RELEVANCE';
    queryparams?.show_grid ? this.showGrid = queryparams?.show_grid : this.showGrid = true;

    // seteo los filtros categoria seleccionada y el precio en caso de haber algun queryparam con estos datos
    this.filters.price = queryparams?.price ?? null;
    this.filters.taxon = queryparams?.taxon ?? null;
    this.filters.selectedStores = queryparams?.StoreIds ?? null;
    this.filters.name = queryparams?.text ?? queryparams?.global_search ?? null;
    this.filters.DeliveryTimes = queryparams?.DeliveryTimes ?? null;
    // el codigo de la tienda es para buscarla en el servicio de tiendas con ese código
    // this.code = queryparams?.store ?? null;
    this.code = params['code'] ?? null;

    if (this.is_store_page) {
      this.isStore(queryparams);
    } else {
      this.isGlobalSearch(queryparams);
    }
  }


  private isGlobalSearch(queryparams: any) {
    this.zonesService.subjectSelectedZones$
      .pipe(takeUntil(this.destroy$))
      .subscribe((zone: any) => {
        if (this.municipalityNumber != zone?.municipality) {
          this.actualPage = 1;
          this.totalPages = 2;
        }
        this.skeletons = Array(this.perPage);
        this.loadedProducts = [];
        this.languageService.selectedLanguage
          .pipe(takeUntil(this.destroy$))
          .subscribe(lang => {
            this.lang = lang;
            let exclude = false;
            if (!this.is_refresh) {
              // si estoy refrescando la pagina con f5 y tengo seleccionada una categoria como ultimo filtro
              // no aplico el exlude porque sino no cargan las categorias
              if (this.selectedTaxon !== null && queryparams?.exclude == 'true') exclude = true;
            } else {
              exclude = false;
            }
            const ZONE = this.getSelectedZone?.zone;
            const MUNICIPALITY_ID = this.getSelectedZone?.municipality;
            this.current_page = 1;
            if (this.products_observer) {
              this.products_observer?.unsubscribe();
              this.fetch_products(null, lang, `${ZONE}`, MUNICIPALITY_ID, exclude);
            } else {
              this.fetch_products(null, lang, `${ZONE}`, MUNICIPALITY_ID, exclude);
            }
          });
      });
  }

  private isStore(queryparams: any) {
    this.storeService.getStoreByCode(this.code!)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((store: any) => {
        if (store == undefined) {
          this.router.navigateByUrl('/katapulk/not-found');
        } else {
          this.currentStore = store;
          this.loadDynamicBanner(store?.code);
          this.zonesService.subjectSelectedZones$
            .pipe(takeUntil(this.destroy$))
            .subscribe((zone: any) => {
              if (this.municipalityNumber != zone?.municipality) {
                this.actualPage = 1;
                this.totalPages = 2;
              }
              this.skeletons = Array(this.perPage);
              this.loadedProducts = [];
              this.languageService.selectedLanguage
                .pipe(takeUntil(this.destroy$))
                .subscribe(lang => {
                  this.lang = lang;
                  let exclude = false;
                  if (!this.is_refresh) {
                    // si estoy refrescando la pagina con f5 y tengo seleccionada una categoria como ultimo filtro
                    // no aplico el exlude porque sino no cargan las categorias
                    if (!this.initComponent && this.selectedTaxon !== null && queryparams?.exclude == 'true') {
                      exclude = true;
                    }
                    if (this.initComponent) {
                      this.initComponent = false;
                    }
                  } else {
                    exclude = false;
                  }
                  const ZONE = this.getSelectedZone?.zone;
                  const MUNICIPALITY_ID = this.getSelectedZone?.municipality;
                  this.current_page = 1;
                  if (this.products_observer) {
                    this.products_observer?.unsubscribe();
                    this.fetch_products(store.id, lang, `${ZONE}`, MUNICIPALITY_ID, exclude);
                  } else {
                    this.fetch_products(store.id, lang, `${ZONE}`, MUNICIPALITY_ID, exclude);
                  }
                });
            });
        }
      });
  }

  private fetch_products(store_id: any, lang: string, zone?: string, municipality?: number, exclude_facet?: boolean) {
    this.skeletons = Array(this.perPage);
    let FILTERS: any = { StoreIds: store_id, };
    if (this.filters.name) FILTERS['SearchText'] = this.filters.name;
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (this.filters.taxon) FILTERS['CategoryIds'] = `${this.filters.taxon}`;
    if (this.filters.DeliveryTimes) FILTERS['DeliveryTimes'] = `${this.filters.DeliveryTimes}`;
    if (this.filters.selectedStores) FILTERS['StoreIds'] = `${this.filters.selectedStores}`;
    if (zone && zone !== 'undefined') FILTERS['ZoneIds'] = zone;
    if (municipality) FILTERS['MunicipalityId'] = municipality;
    this.order == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    this.order == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    this.order == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    this.order == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;
    // exclude_facet ? FILTERS['ExcludeFacets'] = true : FILTERS['ExcludeFacets'] = false;
    FILTERS['ExcludeFacets'] = false;
    this.products_observer = this.algoliaService.searchProducts(this.hits_per_page, this.current_page, lang, FILTERS).subscribe({
      next: (products: any) => {
        products?.data == null ? this.next_page = false : null;
        this.loadedProducts = products?.data !== null ? products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            defaultPrice: Number(String(product.defaultPrice).replace(/,/g, "")),
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: (product?.stores?.[0]?.id && !this.is_store_page) ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined),
            productType: !!product?.productType ? product?.productType : 0,
          }
        }) : [];
        this.facets = products?.data?.facets?.categories ? products?.data?.facets?.categories : this.facets;
        this.delivery_times = products?.data?.facets?.deliveryTime ? products?.data?.facets?.deliveryTime : this.delivery_times;
        this.next_page = products?.data?.hasNext;
        this.totalProducts = products?.data?.totalCount;
        this.listedProducts = products?.data?.totalCount > this.hits_per_page ?
          this.loadedProducts?.length : products?.data?.totalCount;
        // this.setStorage();
        this.hideSkeletons();
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  hideSkeletons() {
    this.skeletons = [];
  }


  goToTop() {
    window.scroll({ top: 0 });
  }

  getProducts(zone: any, store: any, lang: any) {
    this.products_suscription = this.productsService.getProducts(
      this.actualPage,
      this.perPage,
      zone?.zone || 6,
      store?.id!,
      zone?.municipality || 37,
      lang,
      'default_variant,variants,images,variants.images,variants.option_values,data_extra_types,product_properties',
      this.order,
      this.filters
    )
      .pipe(
        takeUntil(this.destroy$),
        map((pdts): Product[] => {
          console.log({ pdts })
          this.skeletons = [];
          this.totalPages = pdts.meta.total_pages;
          this.totalProducts = pdts.meta.total_count;
          this.listedProducts = pdts.meta.count;
          return this.productsService.mapProductsFromResponse(pdts);
        })
      )
      .subscribe((products: Product[]) => {
        this.loadedProducts = products;
        this.products_suscription = null;
      });
  }

  findProductsByFilter(filter?: { data: { taxon: any, price: string, exclude_facet: boolean } }) {
    this.loadedProducts = [];
    this.current_page = 1
    let exclude = false;
    if (filter?.data) {
      this.selectedTaxon = filter?.data?.taxon;
      this.selectedPrice = filter?.data.price;
      this.filters.taxon = filter?.data?.taxon;
      this.filters.price = filter?.data.price;
      filter.data?.exclude_facet ? exclude = true : exclude = false;
      this.clearFilters = false;
      this.updateRouteParameters(true);
    } else {
      this.reset_filters();
    }
    this.navigateInternal('products-grid');
    if (this.products_observer) {
      this.products_observer?.unsubscribe();
      this.fetch_products(
        `${this.currentStore?.id}`,
        this.translateService.currentLang,
        `${this.getSelectedZones?.zone}`,
        this.getSelectedZones?.municipality,
        exclude
      );
    } else {
      this.fetch_products(
        `${this.currentStore?.id}`,
        this.translateService.currentLang,
        `${this.getSelectedZones?.zone}`,
        this.getSelectedZones?.municipality,
        exclude
      );
    }
    // this.setStorage();
  }

  private reset_filters() {
    this.filters = {};
    if (this.search_input) {
      this.search_input.nativeElement.value = '';
    }
    this.clearFilters = true;
    this.totalProducts = 0;
    this.listedProducts = 0;
    this.selectedTaxon = null;
    this.selectedPrice = null;
    this.selectedStores = null;
    this.updateRouteParameters(false);
  }

  findProductsByName(filterText: string | null, resetFilter: boolean = false) {
    if (filterText) {
      this.selectedText = filterText;
    }
    this.current_page = 1;
    // this.loadedProducts = [];
    this.listedProducts = 0;
    this.totalProducts = 0;
    this.suggestions = [];

    if (resetFilter) {
      this.resetFilterChanges = !this.resetFilterChanges;
      let params = { taxon: null, price: null, text: null, StoreIds: null, exclude: false };
      this.router.navigate([],
        {
          relativeTo: this.activeRoute,
          queryParams: params,
          queryParamsHandling: 'merge'
        }
      );
    } else {
      let params = { text: filterText ?? null, exclude: false };
      this.router.navigate([],
        {
          relativeTo: this.activeRoute,
          queryParams: params,
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  changeShowGrid(event: boolean) {
    this.showGrid = event;
    this.updateRouteParameters(true);
  }

  fetch_more(event: { initialLoad: boolean, order: SearchFiltersType }) {
    this.skeletons = Array(25);
    let FILTERS: any = { StoreIds: this.currentStore?.id };
    const ZONE = this.getSelectedZone?.zone;
    const MUNICIPALITY_ID = this.getSelectedZone?.municipality;
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (this.filters.taxon) FILTERS['CategoryIds'] = `${this.filters.taxon}`;
    if (ZONE && ZONE !== undefined) FILTERS['ZoneIds'] = ZONE;
    if (MUNICIPALITY_ID) FILTERS['MunicipalityId'] = MUNICIPALITY_ID;
    if (this.filters.name) FILTERS['SearchText'] = this.filters.name;
    if (this.filters.selectedStores) FILTERS['StoreIds'] = `${this.filters.selectedStores}`;
    if (this.filters.DeliveryTimes) FILTERS['DeliveryTimes'] = `${this.filters.DeliveryTimes}`;
    this.order = event?.order;

    if (event?.initialLoad) {
      this.current_page = 0;
      this.loadedProducts = [];
      this.listedProducts = 0;
      this.totalProducts = 0;
    }
    event?.order?.text == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    event?.order?.text == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    event?.order?.text == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    event?.order?.text == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;

    this.algoliaService.searchProducts(this.hits_per_page, this.current_page += 1, this.translateService.currentLang, FILTERS).subscribe({
      next: (products: any) => {
        // this.loadedProducts = products?.data !== null ? [...this.loadedProducts, ...products?.data?.items] : [...this.loadedProducts];
        this.loadedProducts = products?.data !== null ? [...this.loadedProducts,
        ...products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            defaultPrice: Number(String(product.defaultPrice).replace(/,/g, "")),
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: (product?.stores?.[0]?.id && !this.is_store_page) ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined)
          }
        })] : [...this.loadedProducts];
        products?.data == null ? this.next_page = false : this.next_page = products?.data?.hasNext;
        this.listedProducts = this.loadedProducts?.length;
        this.hideSkeletons();
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  setBannerSrc(code: any) {
    switch (code) {
      case "mercado-unico":
        this.src = '../../../../../assets/imgs/banners/banner-mercado-unico.jpg';
        break;
      case "la-criolla":
        this.src = '../../../../../assets/imgs/banners/banner-criolla.jpg';
        break;
      case "la-gran-cesta-mercado-agropecuario":
        this.src = '../../../../../assets/imgs/banners/banner-la-gran-cesta.jpg';
        break;
      case "la-motorina":
        this.src = '../../../../../assets/imgs/banners/banner-motoria-3.jpg';
        break;
      case "eriode-mercado-agroindustrial":
        this.src = '../../../../../assets/imgs/banners/banner-eriode.jpg';
        break;
      default:
        this.src = '../../../../../assets/imgs/banners/baner-tiendas.jpg';
        break;
    }

  }

  get getSelectedZones(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  get getValidSelectedZone(): boolean {
    const selectedZone = this.getSelectedZones;
    if (!selectedZone) return false;
    if (selectedZone?.area_selected) {
      if (selectedZone?.area_selected === 'CU') {
        return !!selectedZone.zone && !!selectedZone?.municipality;
      }
      return !!selectedZone.zone;
    }
    return false;
  }

  navigateInternal(fragment: string = 'details') {
    const elements = document.getElementById(fragment);
    elements?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  clearFiltersAndNavigateTop() {
    this.findProductsByFilter();
  }

  loadDynamicBanner(slug: string) {
    const VARIABLES: any = { storeSlug: slug };
    let store_query_variables: any = [];
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      let store_settings_query = JSON.parse(aux)['FRONT_SETTINGS']?.data[0]?.attributes?.query_discoveries?.data
        .find((element: any) => element?.attributes?.Key == 'store-banner');
      store_settings_query?.attributes?.Query?.Variables.map((element: any) => {
        store_query_variables.push({ key: element, value: VARIABLES[`${element}`] });
      });
      store_query_variables.push({ key: 'query_discovery_key', value: 'store-banner' });
      const HOME_SETTINGS_QUERY = store_settings_query?.attributes?.Query?.Uri;
      this.searchQuery(HOME_SETTINGS_QUERY, store_query_variables);
    }
  }

  private searchQuery(settings_query: any, query_variables: any) {
    this.dynamicService.doDynamicQuery(settings_query, query_variables)
      .subscribe((data: any) => {
        this.src = data?.data[0]?.attributes?.Image?.data?.attributes?.url ?
          data?.data[0]?.attributes?.Image?.data?.attributes?.url : '../../../../../assets/imgs/banners/baner-tiendas.jpg';
      });
  }

  updateRouteParameters(clear: boolean) {
    const params = clear ? {
      taxon: this.filters.taxon?.id,
      price: this.filters.price,
      storeId: this.currentStore?.id,
      order: this.order?.text,
      showGrid: this.showGrid,
      name: this.filters && this.filters.name ? this.filters.name?.trim() : undefined,
      StoreIds: this.filters.selectedStores
    } : null;

    this.router.navigate(
      [],
      {
        relativeTo: this.activeRoute,
        queryParams: params,
        queryParamsHandling: 'merge'
      }
    );
  }

  searchProductsInsideStore(text: string) {
    if (text && text?.length >= 2 && text?.trim() !== '') {
      return this.findProductsByName(text);
    }
    if (text?.trim() == '') {
      return this.findProductsByName(null);
    }
  }

  enterPressed(event: any) {
    if (this.selected_sugestion) {
      this.filters.name = this.selected_sugestion;
      this.selected_sugestion = null;
      if (event?.keyCode == 13) this.searchProductsInsideStore(this.filters.name);
    } else {
      if (event?.keyCode == 13) this.searchProductsInsideStore(event?.target?.value);
    }
  }

  useCombine(params: any, queryparams: any): { params: any, query_params: any } {
    let result: any = null;
    combineLatest([params, queryparams],
      (params: any, queryParams: any) => (
        {
          params: params,
          query_params: queryParams
        })).subscribe({
          next: (data: any) => {
            result = data;
          },
          error: (err) => {
            console.log(err);
          }
        });
    return result;
  }

  clearTextFilter(element: any, resetFilter: boolean = false) {
    if (
      (element.value?.trim() !== '' && (this.selectedText && this.selectedText !== '')) ||
      ((this.selectedPrice || this.selectedTaxon) && resetFilter)
    ) {
      element.value = '';
      this.findProductsByName(null, resetFilter);
    } else {
      element.value = '';
    }
  }

  getSuggestions(e: any) {
    if (e?.target?.value?.trim() == '' || e?.target?.value?.trim().length < 2) return this.suggestions = [];
    if (e?.inputType == 'deleteContentBackward') return null;
    return this.searchSubject.next(e?.target?.value);
  }

  moveDownAndUpThrowSuggestionsWithArrows() {
    let inp: any = document.getElementById("inp-store");
    let liSelected: any;
    let index = -1;
    let next: any;

    inp?.addEventListener('keydown', (event: any) => {
      let ul: any = document.getElementById('sug-box-store');
      if (!ul) return;
      let len = ul?.getElementsByTagName('li').length - 1;
      if (event.which === 40) {
        index++;
        //down
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index <= len) {

            liSelected = next;
            this.selected_sugestion = liSelected?.innerText;
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName('li')[0];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[0];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      } else if (event.which === 38) {

        //up
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          index--;
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== undefined && index >= 0) {
            liSelected = next;
          } else {
            index = len;
            liSelected = ul.getElementsByTagName('li')[len];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[len];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      }
      else if (event.which === 13) {
        inp.value = liSelected?.innerText;
        this.enterPressed({ target: liSelected?.innerText, keyCode: 13 });
        this.suggestions = [];
        this.selected_sugestion = null;
      }
    }, false);
  }

  removeClass(el: any, className: any) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  addClass(el: any, className: any) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  };

}

