import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { BehaviorSubject, empty, tap } from "rxjs";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Cart, ProductInCart, Store } from "../models";
import { catchError, map } from "rxjs/operators";
import * as moment from "moment";
import { environment } from "../../../environments/environment";
import { CartStoreInterface } from "../models/checkout.model";
import { StoreService } from "./store.service";
import { CheckoutEnum } from "../enum/checkout.enum";
import { FingerprintService } from "./fingerprint.service";
import { MessageInterface } from "../models/message.model";

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private resource = '/storefront/cart'; // peticion para /api/v2
  private resourceMessage = '/storefront/bulk-update-messages'; // peticion para /api/v2

  isBlockAddToCard = new BehaviorSubject<boolean>(false);
  isBlockDeleteFrom = new BehaviorSubject<boolean>(false);

  private KeyStore = {
    HAVE_CART: 'have_cart',
    CART_TOKEN: 'cart_token',
    CART: 'cart',
    CART_EXPIRATION_DATE: 'cart_expiration_date',
    FINGER_PRINT: 'fingerPrint',
    IS_BOLSA_TRANSFER_ENUM: 'isBolsaTransfer'
  };

  cartSubject$: BehaviorSubject<Cart | null> = new BehaviorSubject<Cart | null>(this.getCartFromSession());

  maxRetries = environment.retry_on_fail;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private httpService: HttpClient,
    private storeService: StoreService,
    private fingerprintService: FingerprintService,
  ) {
    if (!authService.getCurrentUser()) {
      this.cleanStorage();
      this.clearCartSubject();
    }
  }

  blockAddToCardEvent(value: boolean) {
    this.isBlockAddToCard.next(value);
  }

  setBlockDeleteFromCartEvent(value: boolean) {
    this.isBlockDeleteFrom.next(value);
  }

  setMessageAsRead(ids: string, old: boolean = true) {
    return this.apiService.post(this.resourceMessage, { ids: ids, old: old }).subscribe();
  }

  /**
   * @description Create a cart
   * @param locale
   * @param municipality
   */
  async createCart(locale: string = 'es', municipality: number) {
    this.cleanStorage();
    return new Promise(async (resolve, reject) => {
      let sign = null;
      const data = await this.fingerprintService.getVisitorData(this.maxRetries);
      if (data) {
        sign = {
          requestId: data.requestId,
          visitorId: data.visitorId,
          visitorFound: data.visitorFound,
          confidence: data.confidence.score,
          incognito: data.incognito,
          ip: data.ip || '',
          latitude: (data.ipLocation && data.ipLocation.latitude) ? data.ipLocation.latitude : '',
          longitude: (data.ipLocation && data.ipLocation.longitude) ? data.ipLocation.longitude : '',
          postalCode: (data.ipLocation && data.ipLocation.postalCode) ? data.ipLocation.postalCode : '',
          timeZone: (data.ipLocation && data.ipLocation.timezone) ? data.ipLocation.timezone : '',
          city: (data.ipLocation && data.ipLocation.city && data.ipLocation.city.name) ? data.ipLocation.city.name : '',
          continent: (data.ipLocation && data.ipLocation.continent && data.ipLocation.continent.name) ? data.ipLocation.continent.name : '',
          country: (data.ipLocation && data.ipLocation.country && data.ipLocation.country.name) ? data.ipLocation.country.name : '',
          state: (data.ipLocation && data.ipLocation.subdivisions && data.ipLocation.subdivisions[0]) ? data.ipLocation.subdivisions[0].name : '',
          browserName: data.browserName || '',
          browserVersion: data.browserVersion || '',
          osName: data.os || '',
          osVersion: data.osVersion || '',
          device: data.device || '',
          botProbability: (data.bot && data.bot.probability) ? data.bot.probability : '',
          botSaf: (data.bot && data.bot.safe) ? data.bot.safe : '',
        };
      }
      const httpParams = new HttpParams().set('locale', locale).set('municiapily', municipality);
      this.apiService.post(this.resource, { sign }, httpParams).pipe(
        tap((response: any) => {
          this.mapDataAndSaveCartValues(response);
        })
      ).subscribe({
        next: (data) => {
          resolve(data);
        }, error: (error) => reject(error)
      });
    });
  }

  // todo... Implementar resto de integracioens del carrito como cupones de descuento
  /**
   * Obtener Carrito
   * @param zone_id Zona a la que pertenece el carrito
   * @param locale Idioma para los datos de la peticion
   */
  getCart(zone_id: number, locale: string, renewCart: boolean = true) {
    const httpParams = new HttpParams()
      .set('zone_id', zone_id)
      .set('locale', locale)
      // .set('include','line_items,line_items.images,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions');
      .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types,municipality,municipality.state,line_item_changes');

    return this.apiService.get(this.resource, httpParams, true, this.getHeader(), false).pipe(
      map((cartResponse) => {
        return this.mapCartData(cartResponse, renewCart);
      }),
      catchError((error: any) => {
        if (error.status === 404) {
          this.removeCartValuesInStorage();
        }
        // return throwError(error);
        return empty();
      })
    );
  }

  private mapCartData(cartResponse: any, renewCart: boolean = true): Cart {
    const cart: Cart = {
      id: cartResponse.data.id,
      item_count: cartResponse.data.attributes.item_count,
      number: cartResponse.data.attributes.number,
      total: cartResponse.data.attributes.total,
      item_total: cartResponse.data.attributes.item_total,
      token: cartResponse.data.attributes.token,
      currency: cartResponse.data.attributes.currency,
      shipment_state: cartResponse.data.attributes.shipment_state,
      payment_state: cartResponse.data.attributes.payment_state,
      state: cartResponse.data.attributes.state,
      created_at: cartResponse.data.attributes.created_at,
      updated_at: cartResponse.data.attributes.updated_at,
      items: this.mapItemsInCart(cartResponse),
      coupons: this.mapCouponsFromResponse(cartResponse.data.relationships.promotions.data, cartResponse),
      adjustment_total: Math.abs(cartResponse?.data?.attributes?.adjustment_total || 0),
      expiration_date: new Date(cartResponse.data.attributes.expiration_date),
      messages: this.mapMessagesInCart(cartResponse)
    };
    if (renewCart) {
      this.cartSubject$.next(cart);
    }
    this.saveCartValuesInStorage(this.KeyStore.CART, JSON.stringify(cart));
    return cart;
  }

  private mapMessagesInCart(cartResponse: any): MessageInterface[] {
    const messages: MessageInterface[] = [];
    const messagesResponse = cartResponse.included.filter((obj: any) => obj.type === 'line_item_change');
    if (messagesResponse && messagesResponse.length) {
      messagesResponse.forEach((message: any) => {
        if (!message.attributes.old) {
          messages.push({
            id: message.id,
            message: message.attributes.message,
            variant_id: message.attributes.variant_id,
            old: message.attributes.old,
          });
        }
      });
    }
    return messages;
  }

  private async mapItemsInCart(cartResponse: any): Promise<CartStoreInterface[]> {
    const productsInCart: ProductInCart[] = [];

    cartResponse.data.relationships?.line_items?.data.forEach((li: any) => {
      const lineItem = cartResponse?.included?.find((obj: any) => obj.type === 'line_item' && obj.id === li.id);

      const usedVariantId = lineItem?.relationships?.variant?.data?.id; // "1056"
      const usedVariantObj = cartResponse?.included?.find((obj: any) => obj.type === 'variant' && obj.id === usedVariantId);
      let imageId = usedVariantObj?.relationships?.images?.data[0]?.id || null;
      if (imageId === null) {
        const usedProductId = usedVariantObj?.relationships?.product?.data?.id; // "1029"
        const usedProductObj = cartResponse?.included?.find((obj: any) => obj.type === 'product' && obj.id === usedProductId);
        imageId = usedProductObj?.relationships?.images?.data[0]?.id || null;
      }


      const pic: ProductInCart = {
        id: lineItem?.id,
        name: lineItem?.attributes?.name,
        quantity: lineItem?.attributes?.quantity,
        currency: lineItem?.attributes?.currency,
        price: lineItem?.attributes?.price,
        optionsText: lineItem?.attributes?.options_text,
        store_id: lineItem?.attributes?.store_id,
        imageUrl: this.findImageFromResponse(imageId, cartResponse.included),
        slug: lineItem?.attributes?.slug,
        data_extras: this.mapDataExtrasFromResponse(lineItem?.attributes?.slug, lineItem?.relationships?.data_extras.data, cartResponse),
        total: lineItem?.attributes?.total,
      };
      productsInCart.push(pic);
    });

    // @ts-ignore
    return productsInCart.reduce(async (acumP: Promise<CartStoreInterface[]>, item) => {
      const acum = await acumP;
      const cartStore = acum?.find((it: CartStoreInterface) => it?.id === item?.store_id);
      if (!cartStore) {
        // @ts-ignore
        const store: Store = <Store>await this.storeService.getStoreById(item?.store_id!).toPromise();
        // @ts-ignore
        acum.push({ id: store?.id, name: store?.name, logoUrl: store?.logoUrl, favorite_color: store?.favorite_color, products: [item] });
      } else {
        // @ts-ignore
        cartStore.products.push(item);
      }

      return [...acum];
    }, []);
  }

  findImageFromResponse(imageId: string, dataArray: any[]) {
    if (imageId) {
      return dataArray.find((obj: any) => obj.type === 'image' && obj.id === imageId)?.attributes?.styles[9]?.url
        || '../../../../../assets/imgs/product-card/no-product-image.png';
    }
    return '../../../../../assets/imgs/product-card/no-product-image.png';
  }

  private mapDataExtrasFromResponse(slug: string, data_extras: any[], cartResponse: any) {
    if (data_extras?.length === 0) {
      return [];
    } else {
      const dataExtras: { id: number, value: string }[] = [];
      data_extras?.forEach((de) => {
        const dataExtra = cartResponse?.included?.find((obj: any) => obj.type === 'data_extra' && obj.id === de.id);
        dataExtras.push({ id: dataExtra.id, value: dataExtra.attributes.value });
      });
      return dataExtras;
    }
  }

  private mapCouponsFromResponse(promotions: any[], cartResponse: any) {
    if (promotions.length === 0) {
      return [];
    } else {
      const coupons: string[] =
        cartResponse.included?.filter((obj: any) => obj.type === 'promotion' && promotions.some(p => p.id === obj.id))
          .map((coupon: any) => coupon.attributes.code) || [];
      return coupons;
    }
  }

  /**
   * Empty cart
   * @description Vacia el carrito y acto seguido lo elimina
   */
  emptyCart() {
    this.cleanStorage();
    return this.apiService.patch(`${this.resource}/empty`, {}, new HttpParams(), true, this.getHeader())
      .pipe(
        // map(() => {}this.deleteCart()), // todo poner una variante u otra dependiendo de lo que respondan los jefes
        tap(() => this.removeCartValuesInStorage()),
        catchError((error: any) => {
          if (error.status === 404 || error.status === 500) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   * Delete cart
   */
  deleteCart() {
    return this.apiService.delete(this.resource, new HttpParams(), true, this.getHeader())
      .pipe(
        tap(() => this.removeCartValuesInStorage()),
        catchError((error: any) => {
          if (error.status === 404) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   * Add producto al carrito
   * @param product
   * @param locale
   */
  addItemToCart(product: { variant_id: number, quantity: number, zone_id: number, data_extras?: object }, locale = 'es') {
    this.cleanStorageDeliveryAndPayment();
    return this.apiService.post(`${this.resource}/add_item`, product, new HttpParams().set('locale', locale), true, this.getHeader())
      .pipe(
        tap((cartResponse) => {
          // this.mapCartAndSaveInSession(cartResponse);
          this.mapDataAndSaveCartValues(cartResponse, true);
        }),
        catchError((error: any) => {
          if (error.status === 404) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   * Modificar la cantidad de un producto existente en el carrito
   * @param product
   */
  setLineItemQuantity(product: { line_item_id: number, quantity: number, zone_id: number }) {
    this.cleanStorageDeliveryAndPayment();
    return this.apiService.patch(`${this.resource}/set_quantity`, product, new HttpParams(), true, this.getHeader())
      .pipe(
        tap((cartResponse) => {
          this.mapCartAndSaveInSession(cartResponse);
        }),
        catchError((error: any) => {
          // if (error.status === 422) {
          //   this.removeCartValuesInStorage();
          // }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   * Eliminar un producto del carrito
   * @param productId
   */
  removeLineItem(productId: number, isCleanStorageDeliveryAndPayment: boolean = true) {
    this.setBlockDeleteFromCartEvent(true);
    if (isCleanStorageDeliveryAndPayment) {
      this.cleanStorageDeliveryAndPayment();
    }
    return this.apiService.delete(`${this.resource}/remove_line_item/${productId}`, new HttpParams(), true, this.getHeader())
      .pipe(
        tap((cartResponse) => {
          this.mapCartAndSaveInSession(cartResponse);
        }),
        catchError((error: any) => {
          if (error.status === 404) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        }));
  }

  /**
   * Guardar datos en la session storage
   * @param key
   * @param value
   * @private
   */
  saveCartValuesInStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  saveFingerPrintValuesInStorage(value: any) {
    localStorage.setItem(this.KeyStore.FINGER_PRINT, value);
  }

  /**
   * Eliminar valores relacionados con el carrito del local storage
   * @private
   */
  removeCartValuesInStorage() {
    this.cleanStorage();
    localStorage.removeItem(this.KeyStore.CART_TOKEN);
    localStorage.removeItem(this.KeyStore.HAVE_CART);
    localStorage.removeItem(this.KeyStore.CART);
    localStorage.removeItem(this.KeyStore.CART_EXPIRATION_DATE);
    localStorage.removeItem(this.KeyStore.IS_BOLSA_TRANSFER_ENUM);
    // localStorage.removeItem(this.KeyStore.FINGER_PRINT);
    this.cartSubject$.next(null);
    this.setBlockDeleteFromCartEvent(false);
  }

  /**
   * Saber si hay un carrito creado
   * @private
   */
  haveCart() {
    return localStorage.getItem(this.KeyStore.HAVE_CART) === 'true';
  }

  /**
   * Get headers
   * @private
   */
  getHeader(): HttpHeaders {
    let headers = this.authService.getHeader();

    if (this.haveCart()) {
      headers = headers.set('X-Spree-Order-Token', this.getCartToke());
    }

    return headers;
  }

  /**
   * get Cart Token
   * @private
   */
  getCartToke() {
    return <string>localStorage.getItem(this.KeyStore.CART_TOKEN);
  }

  /**
   * Get Cart from session storage
   */
  getCartFromSession() {
    return <Cart>JSON.parse(<string>localStorage.getItem(this.KeyStore.CART));
  }

  getFingerPrintFromSession() {
    return JSON.parse(<string>localStorage.getItem(this.KeyStore.FINGER_PRINT));
  }

  /**
   * Mapear los datos del carrito y actualizar los valores en la session
   * @param cartResponse
   * @private
   */
  private mapCartAndSaveInSession(cartResponse: any) {
    const cart: Cart = {
      id: cartResponse.data.id,
      item_count: cartResponse.data.attributes.item_count,
      number: cartResponse.data.attributes.number,
      total: cartResponse.data.attributes.total,
      item_total: cartResponse.data.attributes.item_total,
      token: cartResponse.data.attributes.token,
      currency: cartResponse.data.attributes.currency,
      created_at: cartResponse.data.attributes.created_at,
      state: cartResponse.data.attributes.state
    };
    this.cartSubject$.next(cart);
    this.saveCartValuesInStorage(this.KeyStore.CART, JSON.stringify(cart));
  }

  /**
   * Crear la fecha de expiracion del carrito(12 minutos) y guardando esa fecha en el session storage
   * @private
   */
  private createAndSaveCartExpirationDate(createdAt: Date) {
    // const expirationDate = moment(createdAt).add(environment.cart_time, 'minutes');
    const expirationDate = moment(createdAt);
    this.saveCartValuesInStorage(this.KeyStore.CART_EXPIRATION_DATE, expirationDate.toISOString());
  }

  /**
   * Obtener la fecha de expiracion del carrito de la session storage
   */
  getCartExpirationDate() {
    return <string>localStorage.getItem(this.KeyStore.CART_EXPIRATION_DATE);
  }

  /**
   * Limpiar datos del subject del cart
   */
  clearCartSubject() {
    this.cartSubject$.next(null);
  }

  /**
   *
   * @description Aplicar coupon de descuento al cart
   * @param coupon_code
   * @param locale
   */
  applyCouponCart(coupon_code: string, locale = 'es') {
    const httpParams = new HttpParams()
      .set('include', 'line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions')
      .set('locale', locale);
    return this.apiService.patch(`${this.resource}/apply_coupon_code`, { coupon_code }, httpParams, true, this.getHeader())
      .pipe(
        // todo verificar si llegan los coupons en el response
        map((cartDataWithCoupon) => {
          this.mapCartAndSaveInSession(cartDataWithCoupon);
          return this.mapCartData(cartDataWithCoupon);
        }),
      );
  }

  /**
   *
   * @description Remover coupon de descuento del cart
   * @param coupon_code
   * @param locale
   */
  removeCouponCart(coupon_code: string, locale = 'es') {
    const httpParams = new HttpParams()
      .set('include', 'line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions')
      .set('locale', locale);
    return this.apiService.delete(`${this.resource}/remove_coupon_code/${coupon_code}`, httpParams, true, this.getHeader())
      .pipe(
        // todo verificar si llegan los coupons en el response
        map((cartDataWithCoupon) => {
          this.mapCartAndSaveInSession(cartDataWithCoupon);
          return this.mapCartData(cartDataWithCoupon);
        }),
      );
  }

  /**
   *
   * @description Remover todos los coupons de descuento del cart
   * @param locale
   */
  removeAllCouponsCart(locale = 'es') {
    const httpParams = new HttpParams()
      .set('include', 'line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions')
    return this.apiService.delete(`${this.resource}/remove_coupon_code`, httpParams, true, this.getHeader())
      .pipe(
        // todo verificar si llegan los coupons en el response
        map((cartDataWithCoupon) => {
          this.mapCartAndSaveInSession(cartDataWithCoupon);
          return this.mapCartData(cartDataWithCoupon);
        }),
      );
  }

  mapDataAndSaveCartValues(response: any, pure = true) {
    this.saveCartValuesInStorage(this.KeyStore.HAVE_CART, true);
    let cart: Cart;
    if (pure) {
      this.saveCartValuesInStorage(this.KeyStore.CART_TOKEN, response.data.attributes.token);
      cart = {
        id: response.data.id,
        item_count: response.data.attributes.item_count,
        number: response.data.attributes.number,
        total: response.data.attributes.total,
        item_total: response.data.attributes.item_total,
        token: response.data.attributes.token,
        currency: response.data.attributes.currency,
        shipment_state: response.data.attributes.shipment_state,
        payment_state: response.data.attributes.payment_state,
        state: response.data.attributes.state,
        created_at: response.data.attributes.created_at,
        updated_at: response.data.attributes.updated_at,
        items: this.mapItemsInCart(response),
        coupons: this.mapCouponsFromResponse(response.data.relationships.promotions.data, response),
        adjustment_total: Math.abs(response?.data?.attributes?.adjustment_total || 0),
        expiration_date: new Date(response.data.attributes.expiration_date),
      };
    } else {
      this.saveCartValuesInStorage(this.KeyStore.CART_TOKEN, response.token);
      cart = response;
    }

    this.cartSubject$.next(cart);
    this.saveCartValuesInStorage(this.KeyStore.CART, JSON.stringify(cart));
    this.createAndSaveCartExpirationDate(cart.expiration_date!);
  }

  cleanStorage() {
    localStorage.removeItem(CheckoutEnum.IS_WAS_SHOW_NOT_FOUND_404);
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION);
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(CheckoutEnum.DELIVERY);
    localStorage.removeItem(CheckoutEnum.PAYMENT);
    localStorage.removeItem(CheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(CheckoutEnum.CONFIRM);
    localStorage.removeItem(CheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
  }

  cleanStorageDeliveryAndPayment() {
    localStorage.removeItem(CheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(CheckoutEnum.DELIVERY);
    localStorage.removeItem(CheckoutEnum.PAYMENT);
    localStorage.removeItem(CheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(CheckoutEnum.CONFIRM);
  }

  /**
   * Empty cart
   * @description Vacia el carrito y acto seguido lo elimina
   */
  updateCartExpirationDate(locale: string = 'es') {
    // this.cleanStorageDeliveryAndPayment();
    const httpParams = new HttpParams()
      .set('include', 'line_items,variants,variants.images,variants.product.images,billing_address,shipping_address,user,payments,shipments,promotions,shipments.shipping_rates,line_items.data_extras,variants.product.data_extra_types,municipality,municipality.state,line_item_changes')
      .set('locale', locale);
    return this.apiService.post(`${this.resource}/update-expiration-date`, {}, httpParams, true, this.getHeader())
      .pipe(
        tap((cartResponse) => {
          // this.mapCartAndSaveInSession(cartResponse);
          this.mapDataAndSaveCartValues(cartResponse, true);
        }),
        catchError((error: any) => {
          if (error.status === 404) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }
}
