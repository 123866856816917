<cdk-virtual-scroll-viewport itemSize="50" [ngClass]="{'tray': !allNotifications}" class="mx-auto p-1 flex flex-col gap-4 bg-transparent main" (scrolledIndexChange)="onScroll($event)">
  <div *cdkVirtualFor="let notification of notifications" class="flex flex-col gap-2 space-y-4">
    <app-notification-card
      [notification]="notification"
      [showFullDetails]="true"
      (delete)="deleteNotification($event)"
    >

<!--      [showRead]="allNotifications"-->
<!--      [showActions]="allNotifications">-->
    </app-notification-card>
  </div>
</cdk-virtual-scroll-viewport>
