import {Injectable, Injector} from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

// import {NgxPermissionsService} from 'ngx-permissions';
import {BehaviorSubject, empty, mergeMap, Observable, of, retry, tap} from 'rxjs';

import { environment } from '../../../environments/environment';

import {Permissions, SelectedZone, Token, User} from '../models';
// import {RoleEnum} from '../enum/role.enum';
import {catchError, map, mapTo, switchMap, takeUntil} from "rxjs/operators";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "./language.service";
import {UserService} from "./user.service";
import {PaymentRoutesEnum} from "../enum/payment-routes.enum";
import {Router} from "@angular/router";


export const KeyStore = {
  CURRENT_USER: 'current_user',
  IS_AUTH: 'is_auth',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  ACCESS_TOKEN_OBJECT: 'auth',
  TOKEN_EXPIRATION_DATE: 'token_expiration_date'
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  // @BlockUI() blockUI: NgBlockUI;

  public currentUserSubject: BehaviorSubject<User> = new BehaviorSubject({});
  public currentUser$: Observable<User> = this.currentUserSubject.asObservable();
  public currentTokenSubject: BehaviorSubject<Token> = new BehaviorSubject(new Token());
  public currentDecodeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  permissions = new BehaviorSubject<Permissions[]>([]);


  constructor(
    public http: HttpClient,
    public cookieService: CookieService,
    // private permissionsService: NgxPermissionsService,
    private languageService: LanguageService,
    private router: Router
  ) {
  }

  public login(loginData: { username: string, password: string, grant_type: string }): Observable<Token> {
    // this.blockUI.start();
    return this.accessToken(loginData)
      .pipe(
        map((token: Token) => {
          // this.blockUI.stop();
          return of(true);
        }),
        catchError((error: any) => {
          // this.blockUI.stop();
          return of(error);
        })
      );
  }

  public logout(): void {
    this.cookieService.delete(KeyStore.IS_AUTH, '/');
    this.cookieService.delete(KeyStore.ACCESS_TOKEN, '/');
    this.cookieService.delete(KeyStore.REFRESH_TOKEN, '/');
    this.cookieService.delete(KeyStore.CURRENT_USER, '/');
    this.cookieService.delete(KeyStore.ACCESS_TOKEN_OBJECT, '/');
    this.cookieService.delete(KeyStore.TOKEN_EXPIRATION_DATE, '/');
    this.cookieService.delete('valid_age', '/');
    this.currentUserSubject.next({});
    // });
  }

  public loadUserIfIsAutenticate(): Observable<any> {
    if (this.isAuthenticated()) {
      const url = environment.base_route + '/usuario/' + JSON.parse(<string>this.cookieService.get(KeyStore.CURRENT_USER)).id;

      return this.http.get(url, { headers: this.getHeader() })
        .pipe(
          mapTo((user: User) => {
            this.saveUser(user);
            // this.setPermissions([user.role]);
            return of(user);
          }),
          catchError((response: HttpErrorResponse) => {
            if (response.status === 401) {


              return this.refreshToken()
                .pipe(
                  catchError(() => {
                    this.logout();
                    return empty();
                  })
                );
            }

            return empty();
          })
        );
    }
    return empty();
  }

  public getCurrentUser(): User {
    if (!this.currentUserSubject.getValue()?.id) {
      // console.log('entro a buscar el objeto');
      const user: User = this.cookieService.check(KeyStore.CURRENT_USER) ? JSON.parse(<string>this.cookieService.get(KeyStore.CURRENT_USER)) : null;
      this.currentUserSubject.next(user);
    }
    return this.currentUserSubject.getValue();
  }

  public getCurrentToken(): string {
    return <string>this.cookieService.get(KeyStore.ACCESS_TOKEN);
  }

  public getCurrentRefreshToken(): string {
    return <string>this.cookieService.get(KeyStore.REFRESH_TOKEN);
  }

  public getTokenExpirationDate(): string {
    return <string>this.cookieService.get(KeyStore.TOKEN_EXPIRATION_DATE);
  }

  public getCurrentTokenObject(): User {
    return this.currentUserSubject.getValue();
  }

  public isAuthenticated(): boolean {
    return this.cookieService.get(KeyStore.IS_AUTH) === 'true';
  }

  public isSuperAdmin() {
    // return this.permissionsService.getPermission(RoleEnum.SUPER_ADMIN);
  }

  public hasRole(role = null) {
    // return this.permissionsService.getPermission(role);
  }

  public accessToken(loginData: { username: string, password: string, grant_type: string }): Observable<Token> {

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Accept', 'application/json');

    const url = this.getBaseUrl() + environment.token_resource;

    return this.getToken(url, loginData, headers);
  }

  public refreshToken() {

    const data = { accessToken: this.getCurrentToken(), refreshToken: this.getCurrentRefreshToken() };

    const url = environment.services_api_url + environment.v1 + '/identity/account/auth/refresh';
    return this.http.post(url, data , { headers: this.getHeader(data) })
        .pipe(
            tap((resp: any) => {
                this.saveToken({
                    access_token: resp?.data?.tokens?.accessToken,
                    refresh_token: resp?.data?.tokens?.refreshToken,
                });
            }),
            retry(environment.retry_on_fail)
        );
  }

  /**
   * Retorna los header para las peticiones
   *
   * @author Hernan Antonio Sanchez Guzmán <sanchezhernan3@gmail.com>
   * @returns HttpHeaders cabeceras a retornar
   */
  public getHeader(body?: any): HttpHeaders {
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/json;')
      .set('x-refresh', 'true');
    if (body) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    return headers;
  }

  /**
   * Retorna los header para las peticiones sin x-refresh
   *
   * @author Hernan Antonio Sanchez Guzmán <sanchezhernan3@gmail.com>
   * @returns HttpHeaders cabeceras a retornar
   */
  public getHeaderNoRefresh(body?: any): HttpHeaders {
    let headers =  new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Accept', 'application/json;');
    if (body) {
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    }
    return headers;
  }

    setApiUrlInStorage() {
        localStorage.setItem('api_base_route', environment.base_route);
    }

    get getApiUrlInStorage() {
        return localStorage.getItem('api_base_route');
    }

    private getBaseUrl(): String {
        let url: String = environment.base_route;
        if (!environment.production) {
            const base_route = this.getApiUrlInStorage;
            if (!base_route) {
                this.setApiUrlInStorage();
            } else {
                url = base_route;
            }
        }
        return url;
    }

    private getToken(url: string, data: any, headers: HttpHeaders): Observable<Token> {
        const params = new HttpParams()
            .set('locale', this.languageService.selectedLanguage.value);

    return this.http.post(url, data, { headers, params })
      .pipe(
        switchMap((token: Token) => {
          this.saveToken(token);
          // this.saveUser(token);
          // this.setPermissions([token?.user?.role]);
          return of(token);
        })
      );
  }

  private saveToken(token: Token): void {
    this.cookieService.set(KeyStore.IS_AUTH, 'true', { path: '/' });

    this.currentTokenSubject.next(token);
    this.cookieService.set(KeyStore.ACCESS_TOKEN_OBJECT, JSON.stringify(token), { path: '/' });

    if (token.access_token) {
      this.cookieService.set(KeyStore.ACCESS_TOKEN, token.access_token, { path: '/' });
    }
    if (token.refresh_token) {
      this.cookieService.set(KeyStore.REFRESH_TOKEN, token.refresh_token, { path: '/' });
    }
    if (token.expires_in) {
      this.cookieService.set(KeyStore.TOKEN_EXPIRATION_DATE, moment(new Date()).add(token.expires_in, 'seconds').toISOString(), { path: '/' });
    }
  }

  public saveUser(user: any): void {
    this.currentUserSubject.next(user);
    this.cookieService.set(KeyStore.CURRENT_USER, JSON.stringify(user), { path: '/' });
  }

  private setPermissions(roles: (string | undefined)[]): void {
    // this.permissionsService.loadPermissions(roles);
  }

  private addPermissions(rol: any): void {
    // this.permissionsService.addPermission(rol);
  }

  currentSession(): any {
    return JSON.parse(<string>this.cookieService.get('userData')) || null;
  }

  hasPermission(permissionKey: string) {
    let hasPermission = false;

    if (
      this.currentUserSubject &&
      this.currentUserSubject.value &&
      this.currentUserSubject.value.permissions &&
      this.permissions.value
    ) {
      const objPermission = this.permissions.value.find(
        (it) =>
          it.key.toLowerCase() === permissionKey.toLowerCase()
      );
      if (objPermission) {
        const permissionFound = this.currentUserSubject.value.permissions.find(
          (x) =>
            x.toLowerCase() ===
            objPermission.permission.toLowerCase()
        );
        if (permissionFound) {
          hasPermission = true;
        }
      }
    }

    return hasPermission;
  }

  getPermissions() {
    const localUser = JSON.parse(<string>this.cookieService.get('userData'));
    return localUser &&
      localUser.permissions
      ? localUser.permissions
      : [];
  }

  isTokenExpired() {
    return this.isAuthenticated() && moment(new Date()).diff(new Date(this.getTokenExpirationDate())) >= 0;
  }

  getTokensWithCode(code: string ): Observable<any> {
    const url  = environment.services_api_url + environment.v1 + '/identity/account/auth/login/redeemcode';

    const headers = new HttpHeaders({
        'accept': 'application/json',
        'x-refresh': 'true'
    });
    return this.http.get(url, { headers: headers , params: {Code: code}})
      .pipe(
        switchMap( async (resp: any ) => {
            this.saveToken({
                access_token: resp?.data?.tokens?.accessToken,
                refresh_token: resp?.data?.tokens?.refreshToken,
            });

            // await this.userService.initUser();
            this.currentDecodeSubject.next(resp);
            return of(resp);
        }),
        catchError( (err, caught) => {
            this.currentDecodeSubject.next(err);
            return of(err);
        })
      )
  }

    getAuthCode():Observable<any> {
        const url = environment.services_api_url + environment.v1 + '/identity/account/getaccesscode';

        return this.http.get(url, {headers: this.getHeader()}).pipe(
            tap(resp => {
                return of(resp);
            }),
            catchError(err => {
                return of(err);
            })
        );
    }

    getPaymentLink(data: any):Observable<any>{
        const url = environment.services_api_url + environment.v1 + '/Payment/PaymentLink';
        let headers = this.getHeader();
        headers = headers.set('X-Payment-Client', environment.xApiClient)
          .set('Accept-Language', this.languageService.selectedLanguage.value);
        return this.http.post(url, data, {headers: headers}).pipe(
            tap(resp => {
                return of(resp);
            }),
            catchError(err => {
                return of(err);
            })
        );
    }

  async getPaymentUrl(total: any , paymentRoute: string, userId:string, orderId?: string) : Promise<any>{
    const resp = await this.getAuthCode().toPromise();
    const code = resp?.data?.code;
    if (code) {
      const url = new URL(`${environment.payments_resource}/`);
      const redirect = new URL(`${location.origin + this.router.url}`);
      if (redirect.searchParams.get('code')) {
        redirect.searchParams.delete('code');
      }
      url.searchParams.append('redirect', redirect.href);
      url.searchParams.append('code', code);
      url.searchParams.append('total', String(total));
      url.searchParams.append('paymentClient', environment.xApiClient);
      url.searchParams.append('paymentRoute', paymentRoute);
      url.searchParams.append('locale', this.languageService.selectedLanguage.value);
      url.searchParams.append('userId', userId);
      if (!!orderId) {
        url.searchParams.append('paymentRef', orderId);
      }

      return url.href;
    }
  }

  async goToPaymentsProfile(link: string, tab?: string){
    const resp = await this.getAuthCode().toPromise();
    const code = resp?.data?.code;
    if (code) {
      const url = new URL(`${environment.payments_resource}/${this.getRoute(link)}`);
      const redirect = new URL(`${location.origin + this.router.url}`);
      if (redirect.searchParams.get('code')) {
        redirect.searchParams.delete('code');
      }
      url.searchParams.append('redirect', redirect.href);
      url.searchParams.append('code', code);
      url.searchParams.append('paymentClient', environment.xApiClient);
      url.searchParams.append('locale', this.languageService.selectedLanguage.value);
      if (!!tab) {
        url.searchParams.append('tab', tab);
      }
      location.href = url.href;
    }
  }

  getRoute( link: string){
    switch (link) {
      case '/profile/payments/cards':
        return 'profile';
      case '/profile/payment-requests':
        return 'payment-requests'
      case '/profile/user-payments':
        return 'user-payments';
      default:
          return 'profile';
    }
  }
}
