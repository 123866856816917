<div class="navigation-wrapper relative
us:w-screen us:h-86vw us:px-5.3vw us:py-5.3vw
us-landscape:min-h-113px
lg-landscape:w-915px lg-landscape:h-268px">
    <div class="relative keen-slider flex overflow-hidden w-full h-full" #sliderRef>
        <div *ngFor="let img of imgs" class="relative w-full h-full keen-slider__slide flex justify-center">
            <img class="relative lg-landscape:h-full lg-landscape:w-full object-cover" src="{{img}}" alt="">
        </div>
    </div>
    <div class="dots
    us:mt-1
    lg-landscape:mt-1">
        <button class="
        us:w-2 us:h-2 us:mx-1
        lg-landscape:w-10px lg-landscape:h-10px lg-landscape:mx-1" (click)="slider?.moveToIdx(i)"
            *ngFor="let slide of imgs; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
    </div>

    <!-- <svg [ngClass]="
      'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')
    " (click)="slider.prev()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
    <svg *ngIf="slider" [ngClass]="
      'arrow arrow--right ' +
      (slider.track?.details?.slides?.length - 1 === currentSlide
        ? 'arrow--disabled'
        : '')
    " (click)="slider.next()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg> -->
</div>
