// click-outside.directive.ts
import {Directive, ElementRef, HostListener, Output, EventEmitter, Input} from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() public appClickOutside = new EventEmitter<MouseEvent>();
  @Input() ignoreSelectors: string[] = []; // Array de selectores a ignorar

  constructor(private _elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    const clickedInside = this._elementRef.nativeElement.contains(event.target);
    const target = event.target as HTMLElement;
    const clickedIgnored = this.ignoreSelectors.some(selector => {
      const ignoredElements = document.querySelectorAll(selector);
      return Array.from(ignoredElements).some(ignoredElement => ignoredElement.contains(target));
    });

    if (!clickedInside && !clickedIgnored) {
      this.appClickOutside.emit(event);
    }
  }
}
