import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { PropagationGuard } from "./shared/guards/propagation.guard";
import { ConfirmComponent } from "./modules/auth/auth_components/confirm/confirm.component";
import { PasswordRecoveryComponent } from './modules/auth/auth_components/password-recovery/password-recovery.component';
import { ConfirmPasswordRecoverComponent } from './modules/auth/auth_components/confirm-password-recover/confirm-password-recover.component';
import { StoreDetailComponent } from './modules/stores/components/store-detail/store-detail.component';
import { AuthGuard } from "./shared/guards/auth.guard";
import { ShippableCountryResolver } from "./shared/services/shippable-country.resolver";
import { ProductPageComponent } from "./modules/shared/components/product-page/product-page.component";
import { GlobalSearchGridComponent } from './modules/shared/components/global-search-grid/global-search-grid.component';
import { CategoriesSearchGridComponent } from './modules/shared/components/categories-search-grid/categories-search-grid.component';
import { ClearRoutesStackGuard } from "./shared/guards/clear-routes-stack.guard";
import {
  FeaturedProductsGridComponent
} from "./modules/shared/components/featured-products-grid/featured-products-grid.component";
import { LaNaveGridComponent } from './modules/nave/components/la-nave-grid/la-nave-grid.component';
import { GiftGridComponent } from './modules/gift/components/gift-grid/gift-grid.component';
import { SharedPaymentMethodsComponent } from './modules/shared/components/shared-payment-methods/shared-payment-methods.component';
import {GiveawayComponent} from "./modules/shared/components/giveaway/giveaway.component";
import {GiveawayPageComponent} from "./modules/shared/components/giveaway-page/giveaway-page.component";

const routes: Routes = [
  { path: '', redirectTo: 'cu/home', pathMatch: 'full' },
  { path: 'c/:register_token', component: ConfirmComponent, canActivate: [ClearRoutesStackGuard] },
  { path: 'password-recovery', component: PasswordRecoveryComponent, canActivate: [ClearRoutesStackGuard] },
  { path: 'p/:token', component: ConfirmPasswordRecoverComponent, canActivate: [ClearRoutesStackGuard] },
  // { path: 'cart', canActivate: [PropagationGuard], loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule) },
  // { path: 'stores', loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule) },
  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'gift-cards', canActivate: [AuthGuard], loadChildren: () => import('./modules/gift/gift-card.module').then(m => m.GiftCardModule) },
  { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'search', canActivate: [PropagationGuard, ClearRoutesStackGuard], loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule) },
  // { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
  // { path: 'search', canActivate: [PropagationGuard, ClearRoutesStackGuard], loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule) },
  // { path: ':code', component: StoreDetailComponent },
  { path: 'remittance', canActivate: [AuthGuard], loadChildren: () => import('./modules/remittance/remittance.module').then(m => m.RemittanceModule) },
  { path: 'cubacel', loadChildren: () => import('./modules/cubacel/cubacel.module').then(m => m.CubacelModule) },
  { path: 'giveaway-info', component: GiveawayComponent},
  { path: 'giveaway', component: GiveawayPageComponent},
  {
    path: ':country_iso2',
    resolve: {
      country: ShippableCountryResolver
    },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: MainComponent, canActivate: [ClearRoutesStackGuard] },
      { path: 'products/:slug', component: ProductPageComponent, canActivate: [ClearRoutesStackGuard] },
      { path: 'stores', loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule), canActivate: [ClearRoutesStackGuard] },
      { path: 'cart', canActivate: [PropagationGuard, ClearRoutesStackGuard], loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule) },
      // { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
      // { path: 'remittance', loadChildren: () => import('./modules/remittance/remittance.module').then(m => m.RemittanceModule) },
      { path: 'global-search/:filter', canActivate: [PropagationGuard, ClearRoutesStackGuard], component: GlobalSearchGridComponent },
      { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) },
      { path: 'global-search', canActivate: [PropagationGuard, ClearRoutesStackGuard], component: StoreDetailComponent },
      { path: 'featured-products', canActivate: [PropagationGuard, ClearRoutesStackGuard], component: FeaturedProductsGridComponent },
      { path: 'category/:id', component: StoreDetailComponent, canActivate: [ClearRoutesStackGuard] },
      { path: 'la-nave', loadChildren: () => import('./modules/nave/nave.module').then(m => m.NaveModule) },
      { path: 'payment', component: SharedPaymentMethodsComponent, canActivate: [AuthGuard] },
      { path: ':code', component: StoreDetailComponent, canActivate: [ClearRoutesStackGuard] },
    ]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'katapulk/not-found' }, // Added
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // scrollPositionRestoration: 'top',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
