import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {faShoppingCart, faSpinner, faTrash, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {CartService} from "../../../../../shared/services/cart.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {SelectedZone, User} from "../../../../../shared/models";
import {ZonesService} from "../../../../../shared/services/zones.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {CutomQuantityModalComponent} from '../../cutom-quantity-modal/cutom-quantity-modal.component';
import {AgeModalComponent} from '../../age-modal/age-modal.component';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from "../../../../../shared/services/auth.service";
import {LoginToAddCartDialogComponent} from "../../login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import {NewCartService} from "../../../../../shared/services/newcart.service";
import {
  ExtraData
} from "../../../../../shared/models/new-cart.model";
import {PendingOrdersConfirmationComponent} from "../../pending-orders-confirmation/pending-orders-confirmation.component";
import {Router} from "@angular/router";
import {NewCheckoutEnum} from "../../../../../shared/enum/checkout.enum";
import {ErrorsEnum} from "../../../../../shared/enum/errors.enum";
import * as moment from 'moment';
import {ApiService} from "../../../../../shared/services/api.service";
import {ShoppingListQueryParams} from "../../../../../shared/models/shopping-list-query-params";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {NotificationService} from "../../../../../shared/services/notification.service";
import {AdjustRequest} from "../../../../../shared/models/shop-list.model";

@Component({
  selector: 'app-product-card-shopping-list',
  templateUrl: './product-card-shopping-list.component.html',
  styleUrls: ['./product-card-shopping-list.component.scss']
})
export class ProductCardShoppingListComponent implements OnInit, OnDestroy {
  @Input() productId: string = '';
  @Input() imageUrl: string = '../../../../../assets/imgs/product-card/no-product-image.png';
  @Input() name: string = 'Jamón barra Bravo (2kg/ 4,4lb)';
  @Input() price: string = '';
  @Input() currency: string = 'USD';
  @Input() quantity: number = 0;
  @Input() variantId: any = 0;
  @Input() resize: boolean = false;
  @Input() turn: boolean = false;
  @Input() slug: any = '';
  @Input() store: string = '';
  @Input() shoppingListId: string = '';
  @Input() isAddProduct: boolean = false;
  @Output() onRemoveItem: EventEmitter<{ productId: string }> = new EventEmitter();

  cart_icon = faShoppingCart;
  faTrash = faTrashCan;
  faSpinner = faSpinner;
  spin = false;
  spinDeleting = false;
  spinAddingProduct = false;
  isBlockAddToCard: boolean = false;
  blockAddToCardSubscription: any;
  needAuthentication: boolean = true;
  urlZoneDependant: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  user: User | undefined;

  queryParams: ShoppingListQueryParams = {};

  isAdjustingProduct: boolean = false;

  constructor(
    private readonly cartService: CartService,
    private readonly newCartService: NewCartService,
    private readonly zonesService: ZonesService,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly cookieService: CookieService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly apiService: ApiService,
    private readonly shopListService: ShopListService,
    private readonly notificationService: NotificationService,
  ) {
    this.authService.currentUserSubject.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    if (!this.quantity) {
      this.quantity = 1;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    this.blockAddToCardSubscription?.unsubscribe();
  }

  prepareAdjustmentData(): AdjustRequest {
    this.isAdjustingProduct = true;
    return {
      id: this.shoppingListId,
      lineItemId: this.productId,
      quantity: this.quantity,
    };
  }

  increaseQuantity() {
    this.quantity += 1;
    if (!this.isAddProduct) {
      this.shopListService.adjust(this.prepareAdjustmentData())
        .pipe(takeUntil(this.destroy$), finalize(() => this.isAdjustingProduct = false))
        .subscribe({
          next: (response) => {

          },
          error: (error) => {
            this.quantity--;
            return this.apiService.handleErrors(error, true);
          },
        });
    }
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.quantity -= 1;
      if (!this.isAddProduct) {
        this.shopListService.adjust(this.prepareAdjustmentData())
          .pipe(takeUntil(this.destroy$), finalize(() => this.isAdjustingProduct = false))
          .subscribe({
            next: (response) => {

            },
            error: (error) => {
              this.quantity++;
              return this.apiService.handleErrors(error, true);
            },
          });
      }

    }
  }


  addToCart() {
    if (this.authService.isAuthenticated()) {
      if (this.quantity !== 0 && this.getValidSelectedZone) {
        this.generalAddToCart();
      }
    } else {
      this.showLoginToAddModal();
    }
  }

  private showLoginToAddModal() {
    this.dialog.open(LoginToAddCartDialogComponent, {
      width: '400px',
      disableClose: true,
      data: {
        messageUppercase: true,
        cancelAction: 'CANCEL',
        confirmAction: 'GO_TO_LOGIN',
        message: "TO_PERFORM_THIS_ACTION_YOU_MUST_LOG_IN",
      }
    });
  }

  private getVerificationDate(timeAdd: number): Date {
    return moment(Date.now()).add(timeAdd, 'hours').toDate()
  }

  private generalAddToCart(data_extras?: ExtraData) {
    if (!this.isBlockAddToCard) {
      this.spin = true;
      if (this.user?.id) {
        this.addItemToCart(data_extras);
      }
    }
  }

  private addItemToCart(data_extras?: ExtraData, isCartUrl: boolean = false) {
    if (this.user?.id) {
      this.newCartService.addItemToCart({
        variantId: String(this.variantId),
        count: this.quantity,
        zoneId: `${this.getSelectedZone.zone}`,
        municipalityId: `${this.getSelectedZone.municipality}`,
        extraData: data_extras ? [data_extras] : [],
        // queryId: this.queryId,
        // queryPosition: this.queryPosition,
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.spin = false;
            // this.quantity = 1
            this.newCartService.blockAddToCardEvent(false);
            if (!response.data && response.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
              this.showPendingOrderModal();
            } else {
              if (isCartUrl) {
                this.navigateToNewCart();
              }
            }
          },
          error: (error) => {
            this.spin = false;
            this.newCartService.blockAddToCardEvent(false);
            if (error?.error?.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
              return this.showPendingOrderModal();
            }
            return this.apiService.handleErrors(error, true);
          },
          complete: () => {
            this.spin = false;
            this.newCartService.blockAddToCardEvent(false);
          }
        });
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  get getValidSelectedZone(): boolean {
    const selectedZone = this.getSelectedZone;
    if (selectedZone?.area_selected) {
      if (selectedZone.area_selected === 'CU') {
        return !!selectedZone.zone && !!selectedZone.municipality;
      }
      return !!selectedZone.zone;
    }
    return false;
  }

  setCustomQuantity() {
    // if (this.getValidSelectedZone) {
    let dialoRef = this.dialog.open(CutomQuantityModalComponent, {
      disableClose: false,
      // position: { top: '60px' }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data) {
            const oldQuantity = this.quantity;
            this.quantity = data;
            if (!this.isAddProduct) {
              this.shopListService.adjust(this.prepareAdjustmentData())
                .pipe(takeUntil(this.destroy$), finalize(() => this.isAdjustingProduct = false))
                .subscribe({
                  next: (response) => {

                  },
                  error: (error) => {
                    this.quantity = oldQuantity;
                    return this.apiService.handleErrors(error, true);
                  },
                });
            }
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      });
    // }
  }

  get getFirstAction() {
    return localStorage.getItem('first_action');
  }

  showAgeModal() {
    if (this.getFirstAction === 'done') {
      let dialoRef = this.dialog.open(AgeModalComponent, {
        disableClose: false,
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        // .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {
            if (data) this.cookieService.set('valid_age', 'true', {path: '/'});
          }),
          error: (err => {
            throw new Error(err);
          })
        });
    }
  }

  loadedImg(e: any) {
    let img = (e?.target as HTMLElement) as HTMLElement;
    let mask = (e?.target as HTMLElement)?.previousSibling as HTMLElement;
    img?.classList?.remove('opacity-0')
    img?.classList?.add('opacity-1');
    mask?.classList?.remove('opacity-70', 'min-h-100px');
    mask?.classList?.add('opacity-0');
  }

  showPendingOrderModal() {
    let dialoRef = this.dialog.open(PendingOrdersConfirmationComponent, {
      disableClose: false,
      // position: { top: '60px' }
    });
    dialoRef.afterClosed()
      // .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
          if (data?.success && data?.type) {
            switch (data.type) {
              case 'payment':
                // const paymentData = undefined;
                // if (!paymentData) {
                this.newCartService.removeCartValuesInStorage(() => {
                  localStorage.setItem(NewCheckoutEnum.PAYMENT, JSON.stringify({data: false}));
                  this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/checkout/payment`]);
                });
                // }
                break;
              case 'cart':
                this.navigateToNewCart();
                break;
              case 'home':
                this.navigateToHome();
                break;
            }
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      });
  }

  private navigateToHome() {
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/`]);
  }

  private navigateToNewCart() {
    this.router.navigate(['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new']);
  }

  cardNavigation() {
    return this.getSelectedZone?.area_selected?.toLowerCase() ? `/${this.getSelectedZone?.area_selected?.toLowerCase()}/products/${this.slug}` : `/cu/products/${this.slug}`;
  }

  removeFromShoppingList() {
    this.spinDeleting = true;
    if (this.authService.isAuthenticated()) {
      this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          messageUppercase: true,
          action: this.shopListService.removeItem({
            shoppingListId: this.shoppingListId,
            itemId: this.productId
          }),
          cancelAction: 'CANCEL',
          message: "ARE_YOU_SURE_ERASE_PRODUCT_SHOP_LIST",
          notificationSuccess: "ERASE_PRODUCT_SHOP_LIST_SUCCESS",
        }
      }).afterClosed()
        .pipe(takeUntil(this.destroy$), finalize(() => this.spinDeleting = false))
        .subscribe((response) => {
          if (response.success) {
            this.onRemoveItem.emit({productId: this.productId});
          }
        });
    } else {
      this.showLoginToAddModal();
    }
  }

  addProductToShoppingList() {
    this.spinAddingProduct = true;
    if (this.authService.isAuthenticated()) {
      this.shopListService.addToShopList({id: this.shoppingListId, count: this.quantity, variantId: `${this.variantId}`})
        .pipe(takeUntil(this.destroy$), finalize(() => this.spinAddingProduct = false))
        .subscribe((response) => {
          this.quantity = 1;
          this.notificationService.showAndSubscribe('ADDED_TO_SHOP_LIST_SUCCESS', 'ACCEPT');
        });
    } else {
      this.showLoginToAddModal();
    }
  }
}
