import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-loading-with-image-modal',
  templateUrl: './loading-with-image-modal.component.html',
})
export class LoadingWithImageModalComponent implements OnInit {
  faSpinner = faSpinner;
  message: string = '';
  title: string = '';
  img: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  )
  {
    this.message = data.message;
    this.title = data.title;
    this.img = data.img;
  }

  ngOnInit(): void {
  }

}
