import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "./shared/services/language.service";
import { ProvinceSelectionComponent } from "./modules/shared/components/province-selection/province-selection.component";
import { filter, takeUntil, take } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Subject, forkJoin, mergeMap} from "rxjs";
import {SelectedZone, User} from "./shared/models";
import { ZonesService } from "./shared/services/zones.service";
import { CONFIG } from "../assets/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DynamicCOnfigService } from './shared/services/dynamic-config.service';
import { OnfidoService } from "./shared/services/onfido.service";
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "./shared/services/auth.service";
import { FRONTEND_FEATURE, FrontendFeatureService } from "./shared/services/frontend-feature.service";
import { CartService } from "./shared/services/cart.service";
import { NewCartService } from "./shared/services/newcart.service";
import { NotificationService } from "./shared/services/notification.service";
import { UserService } from "./shared/services/user.service";
import {isPlatformBrowser} from "@angular/common";
import {CurrencyService} from "./shared/services/currency.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'katapulk';
  languages = [
    { id: "es", title: "LANGUAGES.ES", flag: "es" },
    { id: "en", title: "LANGUAGES.EN", flag: "us" }
  ];
  currencies: any[] = [
    { id: 1, name: 'USD' },
    { id: 4, name: 'EUR' }
  ];
  selectedCurrency!: string;
  selectedLanguage!: { id: string; title: string; flag: string };

  destroy$: Subject<boolean> = new Subject<boolean>();
  modalOpen = false;

  private readonly config: { version: string };
  first_time = 0;
  isHaveSettingCurrency: boolean = false;

  constructor(
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly dialog: MatDialog,
    private readonly zonesService: ZonesService,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly dynamicService: DynamicCOnfigService,
    private readonly onfidoService: OnfidoService,
    private readonly cookieService: CookieService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly newCartService: NewCartService,
    private readonly cartService: CartService,
    private readonly userService: UserService,
    private readonly frontendFeatureService: FrontendFeatureService,
    private readonly notificationService: NotificationService,
    @Inject(PLATFORM_ID) private readonly platformID: Object
  ) {

    this.config = CONFIG;
    this.verifyVersionChanges();
    translateService.addLangs(["en", "es"]);

    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es';
      localStorage.setItem("language", JSON.stringify(language));
      translateService.setDefaultLang(language);
      translateService.use(language);
    }
    const findLanguage = this.languages.find(it => it.id === language);
    this.selectedLanguage = findLanguage || this.languages[0];
    translateService.setDefaultLang(this.selectedLanguage.id);
    translateService.use(this.selectedLanguage.id);

    if (this.languageService.selectedLanguage.getValue() != this.selectedLanguage.id) {
      this.languageService.selectedLanguage.next(this.selectedLanguage.id);
    }

    document.documentElement.addEventListener('click', () => {
      if (!localStorage.getItem('first_action') && !this.modalOpen) {
        this.showCountryProvinceModal();
      }
    }, { once: false });
  }

  setCurrency(currencyQuery: string | null = null) {
    const currencyAux = localStorage.getItem("currency");
    let currency = currencyAux ? JSON.parse(currencyAux) : null;

    if (!currency && !currencyQuery) {
      this.isHaveSettingCurrency = false;
      currency = this.currencies[0].name;
    } else if (!this.isHaveSettingCurrency && currencyQuery) {
      currency = currencyQuery;
      this.isHaveSettingCurrency = true;
    } else if (currency && !currencyQuery) {
      this.isHaveSettingCurrency = true;
    }

    localStorage.setItem("currency", JSON.stringify(currency));
    this.selectedCurrency = currency ?? this.currencies[0].name;
    if (this.currencyService.selectedCurrency.getValue() != this.selectedCurrency) {
      this.currencyService.selectedCurrency.next(this.selectedCurrency);
    }
  }

  initCurrencies() {
    this.route.queryParams.pipe(
      filter((params) => params['currency'])
    ).subscribe(params => {
      // Aquí obtienes el parámetro 'currency'
      const currency = params['currency'];
      this.setCurrency(currency);
    });

    this.setCurrency();
  }

  ngOnInit(): void {
    this.initCurrencies();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const code = this.route.snapshot.queryParams['code'];
      if (code && isPlatformBrowser(this.platformID)) {
        this.authService.getTokensWithCode(code).subscribe(resp => {
          if (resp?.error) {
            this.newCartService.removeCartValuesInStorage();
            this.authService.logout();
            this.router.navigate(['/sign-in']);
            this.dialog.closeAll();
            this.notificationService.showAndSubscribe(resp?.error?.message, 'ACCEPT');
          } else {
            this.initUser();
          }
        });
      }
    });

    this.languageService.selectedLanguage.subscribe(() => {
      setTimeout(() => {
        this.getDynamicSettings();
      });
    })

    this.zonesService.subjectSelectedZones$.subscribe(() => {
      setTimeout(() => {
        if (this.first_time == 1) {
          this.getDynamicSettings();
        }
      });
    })

    this.onfidoService.subscribeToTopic();
    !navigator.share ? this.cookieService.set('can-share', 'false') : this.cookieService.set('can-share', 'true');
  }

  verifyVersionChanges() {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache')
        .set('x-refresh', 'true');
      this.httpClient
        .get<{ version: string }>("/assets/config.json", { headers })
        .pipe(takeUntil(this.destroy$))
        .subscribe(config => {
          if (config.version !== this.config.version) {
            const reloadUrl = new URL(location.href);
            reloadUrl.searchParams.set('reloadTime', Date.now().toString());
            location.href = reloadUrl.toString();
          }
        });
    });
  }

  showCountryProvinceModal() {
    if (!this.modalOpen) {
      this.modalOpen = true;
      let dialoRef = this.dialog.open(ProvinceSelectionComponent, {
        disableClose: false,
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (() => {
            this.modalOpen = false;
          }),
          error: (err => {
            this.modalOpen = false;
            throw new Error(err);
          })
        })
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  getDynamicSettings() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = 'es';
      this.aux(language);
    } else {
      this.aux(language);
    }
  }

  private aux(language: string) {
    const VARIABLES: any = {
      locale: language, countryCode: this.getSelectedZone?.area_selected?.toLowerCase() || 'cu',
      env: this.dynamicService.getEnv()
    };
    let HOME_SETTINGS_QUERY_VARIABLES: any = [];
    let global_settings: any = {};

    this.dynamicService.getFrontendSettings(language).subscribe((data: any) => {
      this.first_time = 1;
      global_settings.FRONT_SETTINGS = data;
      localStorage.setItem('GLOBAL_CONFIGS', JSON.stringify(global_settings));
      let home_settings_query = global_settings?.FRONT_SETTINGS?.data?.[0]?.attributes?.query_discoveries?.data
        .find((element: any) => element?.attributes?.Key == 'country-home');
      const HOME_SETTINGS_QUERY = home_settings_query?.attributes?.Query?.Uri;
      home_settings_query?.attributes?.Query?.Variables?.map((element: any) => {
        HOME_SETTINGS_QUERY_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
      });
      HOME_SETTINGS_QUERY_VARIABLES.push({ key: 'query_discovery_key', value: 'country-home' });
      this.searchQuery(HOME_SETTINGS_QUERY, HOME_SETTINGS_QUERY_VARIABLES, global_settings);
    })
  }

  private searchQuery(HOME_SETTINGS_QUERY: any, HOME_SETTINGS_QUERY_VARIABLES: any, global_settings: any) {
    this.dynamicService.doDynamicQuery(HOME_SETTINGS_QUERY, HOME_SETTINGS_QUERY_VARIABLES, '&populate=Grids.Query', '&populate=MiscQueries.Query')
      .subscribe((data: any) => {
        global_settings.HOME_SETTINGS = data;

        localStorage.setItem('GLOBAL_CONFIGS', JSON.stringify(global_settings));
        this.dynamicService.dynamic_settings_subject.next(global_settings);
      });
  }

  initUser(): void {
    this.userService.getUser()
      .pipe(
        takeUntil(this.destroy$),
        mergeMap(() => {
          return forkJoin([
            this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.ORDERS_CART_V3),
            this.frontendFeatureService.getFrontendFeature(FRONTEND_FEATURE.PAYMENT_KATAPULK_PAYMENTS)
          ]);
        })

      )
      .subscribe((response: any) => {
        const cartFeature = response[0].features;
        const paymentFeature = response[1].features;
        let user: User = this.authService.getCurrentUser();
        if (paymentFeature.length && paymentFeature[0].enabled) {
          user = {...user, ...{useNewPayment: true}};
        } else {
          user = {...user, ...{useNewPayment: false}};
        }

        if (cartFeature.length && cartFeature[0].enabled) {
          user = {...user, ...{useNewCart: true,  readyToUse: true}};
          this.authService.saveUser(user);
        } else {
          user = {...user, ...{useNewCart: false,  readyToUse: true}};
          this.authService.saveUser(user);
          this.cartService.getCart(this.getSelectedZone.zone || 6, this.translateService.currentLang)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: cart => {
                this.cartService.mapDataAndSaveCartValues(cart, false);
              }
            });
        }
      });
  }

}
