import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: 'root'
})
export class AlgoliaService {

    query = '';

    constructor(private http: HttpClient, private authService: AuthService) { }

    searchProducts(page_size: number, page: number, lang: string, filters: any, url?: string) {

        let headers = new HttpHeaders()
            .set('Accept-Language', lang)
            .set('Access-Control-Allow-Origin', '*')
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('x-refresh', 'true');

        let httpParams = new HttpParams()
            .set('Page', page)
            .set('PageSize', page_size)
        if (filters) {
            if (filters.SearchText) httpParams = httpParams.set('SearchText', filters.SearchText);
            if (filters.Price) httpParams = httpParams.set('Price', filters.Price);
            if (filters.CategoryIds) httpParams = httpParams.set('CategoryIds', filters.CategoryIds);
            if (filters.ZoneIds) httpParams = httpParams.set('ZoneIds', filters.ZoneIds);
            if (filters.MunicipalityId) httpParams = httpParams.set('MunicipalityId', filters.MunicipalityId);
            if (filters.StoreIds && filters.StoreIds !== -1) httpParams = httpParams.set('StoreIds', filters.StoreIds);
            if (filters.SortBy) httpParams = httpParams.set('SortBy', filters.SortBy);
            if (filters.ExcludeFacets) httpParams = httpParams.set('ExcludeFacets', filters.ExcludeFacets);
            if (filters.selectedStores) httpParams = httpParams.set('StoreIds', filters.selectedStores);
            if (filters.DeliveryTimes) httpParams = httpParams.set('DeliveryTimes', filters.DeliveryTimes);
        }
        if (url) {
            return this.http.get(`${environment.wallet_api_url}${url}`, {
                headers: headers,
            });
        } else {
            return this.http.get(`${environment.smart_search_url}${environment.v1}/Catalog/Search/Products2`, {
                headers: headers,
                params: httpParams
            });
        }

    }

    searchSuggestions(value: string, lang: string) {
        let headers = new HttpHeaders()
            .set('Accept-Language', lang)
            .set('Access-Control-Allow-Origin', '*')
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('x-refresh', 'true');
        let httpParams = new HttpParams()
            .set('Query', value)
        return this.http.get(`${environment.smart_search_url}${environment.v1}/Catalog/Search/Suggestions`, {
            headers: headers,
            params: httpParams
        });
    }
}
