import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ShopListService} from "../../../../../shared/services/shop-list.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  AddressItem
} from "../../../../../shared/models/new-address.model";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {SelectedZone} from "../../../../../shared/models";
import {ZonesService} from "../../../../../shared/services/zones.service";
import {
  ShopListItem,GetShopListsResponse, AddToShoppingListRequest
} from "../../../../../shared/models/shop-list.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-to-shop-list',
  templateUrl: './add-to-shop-list.component.html',
  styleUrls: ['./add-to-shop-list.component.scss']
})
export class AddToShopListComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();

  isSaving: boolean = false;
  Page: number = 1;
  PageSize: number = 50;
  TotalPages: number = 0;

  shoppingLists: ShopListItem[] = [];

  isLoadingShoppingList: boolean = false;
  faSpinner = faSpinner;

  form: FormGroup = new FormGroup({
    shopping_list: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly dialogref: MatDialogRef<AddToShopListComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly shopListService: ShopListService,
    private readonly zonesService: ZonesService,
  ) {
  }

  ngOnInit(): void {
    this.loadShoppingLists();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  submit() {
    this.isSaving = true;

    const data: AddToShoppingListRequest = {
      id: this.shopping_list?.value,
      variantId: this.data.variantId,
      count: this.data.count,
    }

    this.shopListService.addToShopList(data)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.isSaving = false)
      )
      .subscribe({
        next: (res) => {
          const {success} = res;
          this.isSaving = false;
          this.dialogref.close({success});
        },
        error: () => this.isSaving = false
      })
  }

  loadShoppingLists() {
    this.toggleLoading();
    this.shopListService.getShopList({ page: this.Page, pageSize: this.PageSize})
      .pipe(takeUntil(this.destroy$), finalize(() => this.toggleLoading()))
      .subscribe({
        next: (getShopListsResponse: GetShopListsResponse) => {
          this.shoppingLists = [...this.shoppingLists, ...getShopListsResponse.data.items];
          this.TotalPages = getShopListsResponse.data.totalPages;
        },
        error: () => {
          this.toggleLoading();
        },
      });
  }

  toggleLoading() {
    this.isLoadingShoppingList = !this.isLoadingShoppingList;
  }

  get shopping_list() { return this.form.get('shopping_list'); }

}
