import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ProductsService } from 'src/app/shared/services/products.service';
import { ZonesService } from 'src/app/shared/services/zones.service';
import { of, Subject, Subscription } from 'rxjs';
import { Product, SelectedZone } from 'src/app/shared/models';
import { map, takeUntil } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { StoreService } from "../../../../shared/services/store.service";
import { FormControl, FormGroup } from "@angular/forms";
import { SEARCH_FILTER_DEFAULT_VALUE, SEARCH_FILTERS, SearchFiltersType } from "../../../../shared/filters/searc-filters";
import { AlgoliaService } from 'src/app/shared/services/algolia.service';
import { MenuService } from 'src/app/shared/services/menu.service';


@Component({
  selector: 'app-global-search-grid',
  templateUrl: './global-search-grid.component.html',
  styleUrls: ['./global-search-grid.component.scss']
})
export class GlobalSearchGridComponent implements OnInit, OnDestroy {
  searchSkeletons: any = [];
  show_search = false;
  filter: any;
  languageServiceSubscription!: Subscription;
  langu = 'es';
  destroy$: Subject<boolean> = new Subject<boolean>();
  product_page = 1;
  totalPages: number = 2;
  actualPage: number = 1;
  products: any = [];
  filterForm: FormGroup;
  order: SearchFiltersType = SEARCH_FILTER_DEFAULT_VALUE;
  filters: { taxon?: any, price?: any, name?: any } = {};
  products_observer: any;
  curren_page: number = 1;
  hits_per_page: number = 24;
  next_page = false;
  categories: any = [];
  resp_filters = false;

  orderByArray: SearchFiltersType[] = SEARCH_FILTERS;
  selectedTaxon: any = null;
  facets: any = [];
  aux_cats: any = null;

  constructor(
    private productsService: ProductsService,
    private languageService: LanguageService,
    private activeRoute: ActivatedRoute,
    private zonesService: ZonesService,
    private algoliaService: AlgoliaService,
    private storeService: StoreService,
    private menuService: MenuService
  ) {
    this.order = SEARCH_FILTER_DEFAULT_VALUE;
    this.filterForm = new FormGroup({
      order: new FormControl({ value: this.order, disabled: !this.getSelectedZone }, {
        validators: []
      }),
    });

    this.filterForm.get('order')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.getSelectedZone) {
          this.order = this.filterForm.get('order')?.value;
          this.search(this.filter);
        }
      });
  }

  ngOnInit(): void {
    this.filter = this.activeRoute.snapshot.params['filter'];
    this.search(this.filter);
    this.activeRoute.params.subscribe(routeParams => {
      this.filter = routeParams['filter'];
      this.search(this.filter);
    });

    this.languageServiceSubscription = this.languageService.selectedLanguage.subscribe(lang => {
      this.langu = lang;
      this.search(this.filter);
      this.aux_cats = this.menuService.getFinalCategories(lang);
    })

    this.zonesService.subjectSelectedZones$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.search(this.filter);
        if (this.getSelectedZone) {
          this.filterForm.get('order')?.enable();
        } else {
          this.filterForm.get('order')?.disable();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
  }

  search(query: string) {
    let aux;
    const ZONE = this.getSelectedZone.zone;
    const MUNICIPALITY_ID = this.getSelectedZone.municipality;
    this.products = [];
    this.curren_page = 1;
    this.resp_filters = false;
    window.scroll({ top: 0 });
    if (query.length > 0) {
      this.show_search = true;
      this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
      if (this.products_observer) {
        this.products_observer?.unsubscribe();
        this.fetch_products(query, `${ZONE}`, MUNICIPALITY_ID);
      } else {
        this.fetch_products(query, `${ZONE}`, MUNICIPALITY_ID);
      }
    } else {
      this.show_search = false;
    }
  }

  private fetch_products(query: string, zone?: string, municipality?: number) {
    let FILTERS: any = { SearchText: query, };
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (this.filters.taxon) FILTERS['CategoryIds'] = `${this.filters.taxon}`;
    if (zone && zone !== 'undefined') FILTERS['ZoneIds'] = zone;
    if (municipality) FILTERS['MunicipalityId'] = municipality;
    this.order?.text == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    this.order?.text == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    this.order?.text == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    this.order?.text == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;

    this.products_observer = this.algoliaService.searchProducts(this.hits_per_page, this.curren_page, this.langu, FILTERS).subscribe({
      next: (products: any) => {
        this.products = products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            defaultPrice: Number(String(product.defaultPrice).replace(/,/g, "")),
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: product?.stores[0]?.id ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined)
          }
        });
        products?.data?.facets?.categories?.length > 0 && !this.filters.taxon ? this.facets = products?.data?.facets?.categories : this.facets = [];
        this.facets?.length > 0 ? this.categories = this.addAmmount(this.aux_cats.links) : null;
        this.next_page = products?.data?.hasNext;
        this.hideSkeletons();
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  fetch_more() {
    this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    const FILTERS: any = { SearchText: this.filter };
    const ZONE = this.getSelectedZone?.zone;
    const MUNICIPALITY_ID = this.getSelectedZone?.municipality;
    if (this.filters.price) FILTERS['Price'] = this.filters.price;
    if (this.filters.taxon) FILTERS['CategoryIds'] = `${this.filters.taxon}`;
    if (ZONE) FILTERS['ZoneIds'] = ZONE;
    if (ZONE && ZONE !== undefined) FILTERS['ZoneIds'] = ZONE;
    if (MUNICIPALITY_ID) FILTERS['MunicipalityId'] = MUNICIPALITY_ID;
    this.order?.text == 'LOWEST_PRICE' ? FILTERS['SortBy'] = 'price_asc' : null;
    this.order?.text == 'HIGHEST_PRICE' ? FILTERS['SortBy'] = 'price_desc' : null;
    this.order?.text == 'DISCOUNT' ? FILTERS['SortBy'] = 'discount_desc' : null;
    this.order?.text == 'RELEVANCE' ? delete FILTERS['SortBy'] : null;
    this.resp_filters = false;

    this.algoliaService.searchProducts(this.hits_per_page, this.curren_page += 1, this.langu, FILTERS).subscribe({
      next: (products: any) => {
        this.products = [...this.products,
        ...products?.data?.items?.map((product: any, index: number) => {
          return {
            ...product,
            defaultPrice: Number(String(product.defaultPrice).replace(/,/g, "")),
            ...{ queryId: products?.data?.queryId, queryPosition: (index + 1) },
            currency: product.currency || 'USD',
            store: product?.stores[0]?.id ? this.storeService.getStoreById(product?.stores[0]?.id) : of(undefined)
          }
        })];
        this.next_page = products?.data?.hasNext;
        this.hideSkeletons();
      },
      error: (err) => {
        this.hideSkeletons();
      },
    });
  }

  loadMore() {
    this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    this.fetch_more();
  }

  searchProducts(lang: string) {
    this.searchSkeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
    this.products = [];
    this.productsService.searchProducts(
      this.product_page,
      24,
      this.getSelectedZone.zone || 6,
      // 1,
      this.getSelectedZone.municipality || 37,
      lang,
      `default_variant,variants,images,variants.images,variants.option_values,data_extra_types`,
      this.order,
      this.filters
    )
      .pipe(
        map((pdts): Product[] => {
          this.searchSkeletons = [];
          this.totalPages = pdts.meta.total_pages;
          return this.productsService.mapProductsFromResponse(pdts);
        })
      ).subscribe((products: Product[]) => {
        this.products = [...products];
      });
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  findProductsByFilter(filter: any) {
    if (filter.data.price) {
      filter?.data?.price ? this.filters.price = filter?.data?.price : this.filters = { name: this.filter };
    } else {
      this.resetFilters();
    }
    this.search(this.filter);
    // this.searchProducts(this.langu);

  }

  hideSkeletons() {
    this.show_search = false;
    this.searchSkeletons = [];
  }

  emitFilterData(taxon: any) {
    if (this.getSelectedZone) {
      // this.closeFilters();
      if (taxon) {
        this.selectedTaxon = taxon?.id;
        this.filters.taxon = this.selectedTaxon;
        this.search(this.filter);
        document.querySelectorAll('.general-store-category').forEach((element: any) => {
          element?.classList.remove('text-orange-500');
          element?.classList.add('text-gray-#252525');
        });
        document.querySelectorAll(`.store-cat${taxon?.id}`).forEach((element: any) => {
          element?.classList.add('text-orange-500');
          element?.classList.remove('text-gray-#252525');
        });
        this.resp_filters = false;
      }
    }
  }

  showSubElement(e: any) {
    let self_element = e?.target as HTMLElement;
    let element = e?.target?.parentElement?.nextSibling as HTMLElement;
    self_element?.classList?.contains('rotate-90') ?
      self_element?.classList?.remove('rotate-90') :
      self_element?.classList?.add('rotate-90');
    element?.classList?.contains('h-0') ?
      (element?.classList?.remove('h-0'),
        element?.classList?.add('h-auto')) :
      (element?.classList?.remove('h-auto'),
        element?.classList?.add('h-0'));
  }

  resetFilters() {
    if (this.getSelectedZone) {
      document.querySelectorAll('.general-store-category').forEach((element: any) => {
        element?.classList.remove('text-orange-500');
        element?.classList.add('text-gray-#252525');
      });
      this.filters.taxon = null;
      this.filters.price = null;
      this.clearCssClass();
      this.search(this.filter);
      this.resp_filters = false;
    }
  }

  clearCssClass() {
    let filters_p = document.querySelectorAll('.filter-p');
    filters_p.forEach((element: any) => {
      let el = element as HTMLElement;
      if (el?.classList.contains('selected-filter')) {
        el?.classList.remove('selected-filter');
      }
    })
  }

  closeFilters() {
    this.resp_filters = !this.resp_filters;
  }

  addAmmount(links: any) {
    let new_categories: any = [];
    for (let i = 0; i < links.length; i++) {
      for (let j = 0; j < this.facets.length; j++) {
        if (links[i].id.toString() === this.facets[j].key) {
          new_categories = [
            ...new_categories,
            {
              "id": links[i]?.id,
              "text": `${links[i]?.text}`,
              "route": `/category/${links[i]?.id}`,
              "ammount": this.facets[j].count,
              "links": links[i]?.links.length > 0 ? this.addAmmount(links[i]?.links) : []
            }
          ]
        }
      }
    }
    return new_categories;
  }

}
