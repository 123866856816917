import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../../shared/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {CountryGiveawayModel} from "../../../shared/models/country-giveaway.model";
import {map} from "rxjs/operators";

const baseUrl = `${environment.services_api_url}${environment.v1}/Shared`

@Injectable({
  providedIn: 'root'
})
export class GiveawayService {

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly translateService: TranslateService
  ) {
  }

  private getHeader(): HttpHeaders {
    let httpHeaders = this.authService.getHeader();
    httpHeaders = httpHeaders
      .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang);
    return httpHeaders;
  }

  getGiveawayEventAvailable(reference: string){
    const queryParams = {reference: reference}
    return this.http.get(baseUrl + '/GiveawayEvent/GetAvailable', {headers: this.getHeader(), params: queryParams})
  }

  submitGiveaway(data: any){
    return this.http.post(baseUrl + '/Giveaway/Submit',data, {headers: this.getHeader()})
  }

  getCountriesFromResource(): Observable<CountryGiveawayModel[]> {
    return this.http.get<{countries: CountryGiveawayModel[]}>('/assets/countries.json', {headers: this.authService.getHeaderNoRefresh(true)})
      .pipe(map(({countries}) => countries));
  }
}
