<div class="relative w-full flex flex-col gap-6 overflow-hidden box-border z-40
          us:hidden
          xl:flex xl:px-0 xl:min-h-screen xl:relative xl:bg-transparent">

    <div class="relative w-full">
        <p class="relative font-Lato-Regular font-semibold text-gray-#727272 text-sm">
            {{'PAGINATION_TEXT' | translate: {listed: listedProducts, total: totalProducts} }}
        </p>
    </div>


    <div *ngIf="selected_filters.length > 0" class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'SELECTED_FILTERS'| translate}}
        </p>
        <mat-chip-list>
            <mat-chip class="relative text-sm font-Lato-Regular font-medium" *ngFor="let filter of selected_filters"
                [removable]="true" (removed)="remove(filter)">
                {{ filter?.text }}
                <mat-icon class="" matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <!--        <p (click)="resetFilters()"-->
        <!--           class="relative font-Lato-Regular hover:underline font-semibold text-blue-#18396C cursor-pointer capitalize-->
        <!--                  text-13px tracking-0.7px text-sm my-2">-->
        <!--            {{'CLEAR_ALL'| translate}}-->
        <!--        </p>-->

        <button (click)="resetFilters()" class="relative w-full bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                text-base h-41px rounded-3px px-3 mt-2">
            {{'RESET_FILTERS' | translate}}
        </button>
    </div>


    <div class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'CATEGORIES'| translate}}
        </p>
        <ul class="relative w-full font-medium">
            <li *ngFor="let link of categories" class="relative w-full group h-auto my-2">
                <div class="flex justify-between w-full items-center pl-1 pr-1">
                    <a (click)="emitFilterData(link)" [ngClass]="'store-cat' + link?.id" class="flex general-store-category w-3/4 text-sm cursor-pointer justify-between relative items-center text-gray-#252525 font-Lato-Regular
                         font-medium rounded-lg"> {{link?.text | translate}}
                    </a>
                    <span class="relative flex">
                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-1 text-xs font-Lato-Regular font-medium
                             text-white bg-yellow-#FFA101 rounded-full">{{link?.ammount}}</span>
                        <div *ngIf="link?.links?.length > 0"
                            class="relative z-10 flex justify-center items-center w-4 h-4 cursor-pointer">
                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                            </div>
                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                            <div (click)="showSubElement($event)" class="absolute w-full h-full z-10"></div>
                        </div>
                    </span>
                    <!-- <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                    w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                        alt=""> -->
                </div>
                <ul class="submenu overflow-hidden rounded-sm h-0 pl-2 pr-4 transition-all relative w-full transform z-index-1002
                    duration-300">
                    <li class="my-2" *ngFor="let sublink of link?.links">
                        <div class="flex justify-between w-full items-center">
                            <a (click)="emitFilterData(sublink)" [ngClass]="'store-cat' + sublink?.id"
                                class="text-dark general-store-category cursor-pointer w-3/4 text-sm font-Lato-Regular font-normal capitalize transition-all">
                                {{sublink?.text | translate}}
                            </a>
                            <span class="relative flex ml-2">
                                <span class="inline-flex mr-1 items-center justify-center px-2 ml-1 text-xs font-Lato-Regular font-medium
                                     text-white bg-yellow-#FFA101 rounded-full">{{sublink?.ammount}}</span>
                                <div *ngIf="sublink?.links?.length > 0"
                                    class="relative z-10 flex justify-center items-center w-4 h-4 cursor-pointer">
                                    <div
                                        class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                                    </div>
                                    <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                                    <div (click)="showSubElement($event)" class="absolute w-full h-full z-10"></div>
                                </div>
                            </span>
                        </div>
                        <!-- <ul class="submenu overflow-hidden rounded-sm h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002
                             min-w-max duration-300">
                            <li class="my-3" *ngFor="let subblink of sublink?.links">
                                <div class="flex justify-between w-full items-start">
                                    <a (click)="emitFilterData(subblink)" [ngClass]="'store-cat' + subblink?.id"
                                        class="text-dark font-Lato-Regular general-store-category cursor-pointer font-normal text-sm capitalize transition-all">
                                        {{subblink?.text | translate}}
                                    </a>
                                    <span class="relative flex">
                                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium
                                             text-white bg-yellow-#FFA101 rounded-full">{{subblink?.ammount}}</span>
                                        <div *ngIf="subblink?.links?.length > 0"
                                            class="relative z-10 flex justify-center items-center w-5 h-5 cursor-pointer">
                                            <div
                                                class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                                            </div>
                                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                                            <div (click)="showSubElement($event)" class="absolute w-full h-full z-10">
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </li>
                        </ul> -->
                    </li>
                </ul>
            </li>
        </ul>
        <div *ngIf="!categories || categories?.length == 0">
            <div class="relative w-full" *ngFor="let skeleton of skeletons">
                <div class="flex justify-between my-3">
                    <div class="rounded h-4 bg-gray-300 animate-pulse" [ngClass]="skeleton.widthClass"></div>
                    <div class="flex">
                        <div class="rounded-2xl bg-gray-300 h-4 animate-pulse" [ngClass]="skeleton.widthCount"></div>
                        <div class="relative z-10 flex justify-center items-center w-4 h-4">
                            <div class="absolute w-3 h-2px z-0 bg-gray-300 rotate-90 ease-in-out duration-100"></div>
                            <div class="absolute w-3 h-2px z-0 bg-gray-300"></div>
                            <div class="absolute w-full h-full z-10"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'SHIPPING_CATEGORIES'| translate}}
        </p>
        <ul class="relative w-full font-medium">
            <li *ngFor="let shipping of delivery_times" class="relative w-full group h-auto my-2">
                <div class="flex justify-between w-full items-center pl-1 pr-1">
                    <a (click)="emitDeliveryFilter(shipping?.key)" class="flex general-store-category w-3/4 text-sm cursor-pointer justify-between relative items-center text-gray-#252525 font-Lato-Regular
                         font-medium rounded-lg"> {{shipping?.key | translate}}
                    </a>
                    <span class="relative flex">
                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-1 text-xs font-Lato-Regular font-medium
                             text-white bg-yellow-#FFA101 rounded-full">{{shipping?.count}}</span>
                    </span>
                    <!-- <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                    w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                        alt=""> -->
                </div>
            </li>
        </ul>
        <div *ngIf="!delivery_times || delivery_times?.length == 0">
            <div class="relative w-full" *ngFor="let skeleton of skeletons">
                <div class="flex justify-between my-3">
                    <div class="rounded h-4 bg-gray-300 animate-pulse" [ngClass]="skeleton.widthClass"></div>
                    <div class="flex">
                        <div class="rounded-2xl bg-gray-300 h-4 animate-pulse" [ngClass]="skeleton.widthCount"></div>
                        <div class="relative z-10 flex justify-center items-center w-4 h-4">
                            <div class="absolute w-3 h-2px z-0 bg-gray-300 rotate-90 ease-in-out duration-100"></div>
                            <div class="absolute w-3 h-2px z-0 bg-gray-300"></div>
                            <div class="absolute w-full h-full z-10"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div #mask
            class="fixed flex hidden justify-center items-start left-0 top-0 w-screen h-screen pt-32 bg-black bg-opacity-30 zhigh"
            id="store-modal">
            <div class="relative w-55vw h-3/4 bg-white overflow-hidden rounded-md pb-24 pt-5">
                <fa-icon (click)="mask?.classList?.add('hidden')"
                    class="absolute top-2 right-2 cursor-pointer flex justify-center items-center  text-black lg-landscape:text-base"
                    [icon]="close"></fa-icon>
                <label class="relative font-Lato-Bold text-base pl-3 uppercase w-full text-center">{{'STORE_FILTERS' |
                    translate}}</label>
                <div class="relative flex p-3 justify-between">
                    <input type="text" class="relative w-47%" placeholder="{{'SEARCH_STORE' | translate}}"
                        (input)="onsearchTextChanged($event)" [(ngModel)]="search_text">
                    <div class="relative flex gap-3">
                        <button (click)="applyStoreFilter()" class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer
                        lg-landscape:w-28 lg-landscape:h-41px lg-landscape:rounded-3px" type="button">
                            <p class="relative font-Lato-Bold
                       lg-landscape:text-15px lg-landscape:leading-4">{{'APPLY' | translate}}</p>
                        </button>
                        <button *ngIf="selectedStores.length > 0" (click)=" resetStoreFilter()" class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer
                                   lg-landscape:w-28 lg-landscape:h-41px lg-landscape:rounded-3px" type="button">
                            <p class="relative font-Lato-Bold
                                  lg-landscape:text-15px lg-landscape:leading-4">{{'RESET' | translate}}</p>
                        </button>
                    </div>
                </div>
                <div
                    class="relative flex justify-center items-center flex-wrap us:gap-3 lg-landscape:gap-2 xl-landscape:gap-3 p-3 w-full max-h-full overflow-y-auto">
                    <div [ngClass]="search_text === '' || store?.name?.toLowerCase().includes(search_text.toLocaleLowerCase()) ? '':'hidden'"
                        *ngFor="let store of stores; let i=index"
                        class="relative us:w-28 md-landscape:w-7.8rem lg-landscape:w-18% xl-landscape:w-32 h-auto shrink-0 land flex flex-col justify-start items-center">
                        <div class="mask absolute w-full h-full z-20 cursor-pointer"
                            (click)="alternateStoreFilter(store, 'store-check-'+store?.id)">
                        </div>
                        <input [id]="store?.id" [ngClass]="'store-check-'+store?.id" type="checkbox" checked="true"
                            class="absolute store-checkbox hidden top-0 right-0 z-10 outline-none checked:outline-none checked:border-none checked:ring-0 ring-0 bg-yellow-#FF6D03">
                        <img class="relative w-2/3 h-auto object-contain aspect-1 rounded-full border-2 border-gray-400"
                            [src]="store.logoUrl" alt="">
                        <p class="relative text-yellow-#FF6D03 text-sm font-Lato-Regular line-clamp-1">
                            {{store.name}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -----<AKI>----- -->

    <div class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px">
            {{ 'PRICE' | translate }}
        </p>

        <div class="list">
            <p class="relative filter-p filter-p-0-25 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '0,25')">
                {{ 'LESS_THAN' | translate }} $25</p>
            <p class="relative filter-p filter-p-25-50 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '25,50')">
                $25-$50</p>
            <p class="relative filter-p filter-p-50-75 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '50,75')">
                $50-$75</p>
            <p class="relative filter-p filter-p-75-100 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '75,100')">
                $75-$100</p>
            <p class="relative filter-p filter-p-100 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '100')">
                {{ 'MORE_OF' | translate }} $100</p>
            <div class="relative flex
              us:mt-2
              lg-landscape:mt-2" [formGroup]="filterPriceForm">
                <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7
                us:text-sm lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Min" formControlName="min">
                <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7 lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Max" formControlName="max">
                <button class="relative bg-gray-#979797 text-white font-Lato-Regular
                    us:w-9 us:text-sm
                    lg-landscape:w-9 lg-landscape:text-sm" type="button" (click)="emitByPriceFields()"
                    [disabled]="!filterPriceForm.value.min && !filterPriceForm.value.max">Ir</button>
            </div>
            <!--            <button (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold-->
            <!--            text-base w-auto h-41px rounded-3px px-3 mt-4">-->
            <!--                {{'RESET_FILTERS' | translate}}-->
            <!--            </button>-->
        </div>
    </div>

    <div *ngIf="!is_store_page" class="relative w-full overflow-hidden us:hidden lg-landscape:inline">
        <p (click)="openStoreModal()"
            class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px cursor-pointer">
            {{ 'STORES' | translate }}
            <fa-icon class="relative text-gray-#363636 lg-landscape:text-base cursor-pointer ml-3"
                [icon]="filter"></fa-icon>
        </p>
        <!-- [logoUrl]="store.logoUrl" [storeName]="store.name"
        [metaKeywords]="store.metaKeywords" [id]="store.id" [code]="store.code" -->
        <div class="relative grid gap-3 mt-2
        grid-cols-3">
            <div *ngFor="let store of selectedStores; let i=index" class="relative w-full h-auto">
                <div class="mask absolute w-full h-full z-20 cursor-pointer"
                    (click)="alternateStoreFilter(store, 'store-check-'+store?.id, true)">
                </div>
                <input [ngClass]="'store-check-'+store?.id" type="checkbox" checked="true"
                    class="absolute store-checkbox top-0 right-0 z-10 outline-none checked:outline-none checked:border-none checked:ring-0 ring-0 bg-yellow-#FF6D03">
                <img class="relative w-full h-auto object-contain aspect-1 rounded-full border-2 border-gray-400"
                    [src]="store.logoUrl" alt="">
                <p class="relative text-yellow-#FF6D03 text-sm font-Lato-Regular line-clamp-1">
                    {{store.name}}</p>
            </div>
        </div>
    </div>
    <button (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
              text-base w-full h-41px rounded-3px px-3">
        {{'RESET_FILTERS' | translate}}
    </button>
</div>



<div class="relative w-full flex flex-col gap-6 overflow-hidden box-border z-40
         us:relative us:bg-gray-#f2f2f2 py-5
         xl:hidden">

    <div class="relative flex justify-end items-center">
        <div (click)="closeFilters()"
            class="relative h-10 w-auto px-3 flex justify-center items-center border border-gray-#9D9D9D">
            <label class="relative text-gray-#727272 font-Lato-Regular font-semibold
                            us:text-sm
                            lg-landscape:text-sm">{{'FILTERS' | translate}}:</label>
        </div>
    </div>

    <div *ngIf="resp_filters" class="relative w-auto flex justify-end items-center
        us:flex
        xl:mt-0 xl:hidden">
        <app-stores-filters-sort-and-view [order]="order" [showGrid]="showGrid"></app-stores-filters-sort-and-view>
    </div>

    <div *ngIf="resp_filters && selected_filters?.length > 0" class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'SELECTED_FILTERS'| translate}}
        </p>
        <mat-chip-list>
            <mat-chip class="relative text-sm font-Lato-Regular font-medium" *ngFor="let filter of selected_filters"
                [removable]="true" (removed)="remove(filter)">
                {{ filter?.text }}
                <mat-icon class="" matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>

        <button (click)="resetFilters()" class="relative w-full bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                  text-base h-41px rounded-3px px-3 mt-2">
            {{'RESET_FILTERS' | translate}}
        </button>
    </div>

    <div [ngClass]="{'hidden': !resp_filters}" class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'CATEGORIES'| translate}}
        </p>
        <ul class="relative w-full space-y-1 font-medium">
            <li *ngFor="let link of categories" class="relative group h-auto">
                <div class="flex justify-between w-full items-center pl-1 pr-1">
                    <a (click)="emitFilterData(link)" [ngClass]="'store-cat' + link?.id" class="flex general-store-category cursor-pointer justify-between w-full relative items-center text-gray-#252525 font-Lato-Regular
                         font-medium rounded-lg"> {{link?.text | translate}}
                    </a>
                    <span class="relative flex">
                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium
                             text-white bg-yellow-#FFA101 rounded-full">{{link?.ammount}}</span>
                        <div *ngIf="link?.links?.length > 0"
                            class="relative z-10 flex justify-center items-center w-5 h-5 cursor-pointer">
                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                            </div>
                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                            <div (click)="showSubElement($event)" class="absolute w-full h-full z-10"></div>
                        </div>
                    </span>
                    <!-- <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                    w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                        alt=""> -->
                </div>
                <ul class="submenu overflow-hidden rounded-sm h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002
                    min-w-max duration-300">
                    <li class="my-3" *ngFor="let sublink of link?.links">
                        <div class="flex justify-between w-full items-center">
                            <a (click)="emitFilterData(sublink)" [ngClass]="'store-cat' + sublink?.id"
                                class="text-dark general-store-category cursor-pointer font-Lato-Regular font-normal text-base capitalize transition-all">
                                {{sublink?.text | translate}}
                            </a>
                            <span class="relative flex">
                                <span class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium
                                     text-white bg-yellow-#FFA101 rounded-full">{{sublink?.ammount}}</span>
                                <div *ngIf="sublink?.links?.length > 0"
                                    class="relative z-10 flex justify-center items-center w-5 h-5 cursor-pointer">
                                    <div
                                        class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                                    </div>
                                    <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                                    <div (click)="showSubElement($event)" class="absolute w-full h-full z-10"></div>
                                </div>
                            </span>
                        </div>
                        <ul class="submenu overflow-hidden rounded-sm h-0 pl-4 pr-4 transition-all relative w-auto transform z-index-1002
                             min-w-max duration-300">
                            <li class="my-3" *ngFor="let subblink of sublink?.links">
                                <div class="flex justify-between w-full items-start">
                                    <a (click)="emitFilterData(subblink)" [ngClass]="'store-cat' + subblink?.id"
                                        class="text-dark font-Lato-Regular general-store-category cursor-pointer font-normal text-base capitalize transition-all">
                                        {{subblink?.text | translate}}
                                    </a>
                                    <span class="relative flex">
                                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium
                                             text-white bg-yellow-#FFA101 rounded-full">{{subblink?.ammount}}</span>
                                        <div *ngIf="subblink?.links?.length > 0"
                                            class="relative z-10 flex justify-center items-center w-5 h-5 cursor-pointer">
                                            <div
                                                class="absolute w-3 h-2px z-0 bg-gray-#252525 rotate-90 ease-in-out duration-100">
                                            </div>
                                            <div class="absolute w-3 h-2px z-0 bg-gray-#252525"></div>
                                            <div (click)="showSubElement($event)" class="absolute w-full h-full z-10">
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <div *ngIf="!categories || categories?.length == 0">
            <div class="relative w-full" *ngFor="let skeleton of skeletons">
                <div class="flex justify-between my-3">
                    <div class="rounded h-4 bg-gray-300 animate-pulse" [ngClass]="skeleton.widthClass"></div>
                    <div class="flex">
                        <div class="rounded-2xl bg-gray-300 h-4 animate-pulse" [ngClass]="skeleton.widthCount"></div>
                        <div class="relative z-10 flex justify-center items-center w-4 h-4">
                            <div class="absolute w-3 h-2px z-0 bg-gray-300 rotate-90 ease-in-out duration-100"></div>
                            <div class="absolute w-3 h-2px z-0 bg-gray-300"></div>
                            <div class="absolute w-full h-full z-10"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px mb-2">
            {{'SHIPPING_CATEGORIES'| translate}}
        </p>
        <ul class="relative w-full space-y-1 font-medium">
            <li *ngFor="let shipping of delivery_times" class="relative group h-auto">
                <div class="flex justify-between w-full items-center pl-1 pr-1">
                    <a (click)="emitDeliveryFilter(shipping?.key)" [ngClass]="'store-cat' + shipping?.key" class="flex general-store-category cursor-pointer justify-between w-full relative items-center text-gray-#252525 font-Lato-Regular
                         font-medium rounded-lg"> {{shipping?.key | translate}}
                    </a>
                    <span class="relative flex">
                        <span class="inline-flex mr-1 items-center justify-center px-2 ml-3 text-sm font-Lato-Regular font-medium
                             text-white bg-yellow-#FFA101 rounded-full">{{shipping?.count}}</span>
                    </span>
                    <!-- <img *ngIf="link?.links?.length > 0" (click)="showSubElement($event)" class="relative cursor-pointer
                    w-7 ml-8 transform translate-x-3" src="../../../assets/imgs/home-slider/icon-arrowright.svg"
                        alt=""> -->
                </div>
            </li>
        </ul>
    </div>


    <div *ngIf="resp_filters" class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px">
            {{ 'PRICE' | translate }}
        </p>

        <div class="list">
            <p class="relative filter-p filter-p-0-25 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '0,25')">
                {{ 'LESS_THAN' | translate }} $25</p>
            <p class="relative filter-p filter-p-25-50 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '25,50')">
                $25-$50</p>
            <p class="relative filter-p filter-p-50-75 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '50,75')">
                $50-$75</p>
            <p class="relative filter-p filter-p-75-100 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '75,100')">
                $75-$100</p>
            <p class="relative filter-p filter-p-100 cursor-pointer w-full font-Lato-Regular text-gray-#5d5d5d
                us:text-sm us:mt-2 us:h-5 us:pl-1 border-l-3px border-l-gray-#f2f2f2
                lg-landscape:text-sm lg-landscape:mt-2 lg-landscape:h-5 lg-landscape:pl-1"
                (click)="emitFilterData(undefined, '100')">
                {{ 'MORE_OF' | translate }} $100</p>
            <div class="relative flex
              us:mt-2
              lg-landscape:mt-2" [formGroup]="filterPriceForm">
                <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7
                us:text-sm lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Min" formControlName="min">
                <input class="relative border border-gray-#979797 bg-transparent font-Lato-Regular
                us:w-67px us:h-7 lg-landscape:w-67px lg-landscape:h-7
                lg-landscape:text-sm active:ring-0 focus:ring-0" type="text" placeholder="$ Max" formControlName="max">
                <button class="relative bg-gray-#979797 text-white font-Lato-Regular
                    us:w-9 us:text-sm
                    lg-landscape:w-9 lg-landscape:text-sm" type="button" (click)="emitByPriceFields()"
                    [disabled]="!filterPriceForm.value.min && !filterPriceForm.value.max">Ir</button>
            </div>
            <!--            <button (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold-->
            <!--            text-base w-auto h-41px rounded-3px px-3 mt-4">-->
            <!--                {{'RESET_FILTERS' | translate}}-->
            <!--            </button>-->
        </div>
    </div>
    <div *ngIf="resp_filters && !is_store_page" class="relative w-full overflow-hidden">
        <p class="relative font-Lato-Regular font-semibold text-gray-#363636 uppercase text-15px tracking-0.7px">
            {{ 'STORES' | translate }}
        </p>
        <!-- [logoUrl]="store.logoUrl" [storeName]="store.name"
        [metaKeywords]="store.metaKeywords" [id]="store.id" [code]="store.code" -->
        <div class="relative flex p-3 justify-between">
            <!-- <input type="text" class="relative w-47%" placeholder="Buscar tienda" (input)="onsearchTextChanged($event)"
                [(ngModel)]="search_text"> -->
            <div class="relative flex gap-3">
                <button (click)="applyStoreFilter()" class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer
                h-41px rounded-3px px-3" type="button">
                    <p class="relative font-Lato-Bold
               lg-landscape:text-15px lg-landscape:leading-4">{{'APPLY' | translate}}</p>
                </button>
                <button *ngIf="selectedStores.length > 0" (click)="resetStoreFilter()" class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer
                           h-41px rounded-3px px-3" type="button">
                    <p class="relative font-Lato-Bold
                          lg-landscape:text-15px lg-landscape:leading-4">{{'RESET' | translate}}</p>
                </button>
            </div>
        </div>
        <div class="relative grid gap-3 px-2 mt-2 max-h-40vh overflow-y-auto
        us:grid-cols-3
        us-landscape:grid-cols-7">
            <div *ngFor="let store of stores; let i=index"
                class="relative w-full h-auto flex flex-col justify-start items-center shrink-0">
                <div class="mask absolute w-full h-full z-20"
                    (click)="alternateStoreFilter(store, 'store-check-'+store?.id)">
                </div>
                <input [ngClass]="'store-check-'+store?.id" type="checkbox" checked="true"
                    class="absolute hidden store-checkbox top-0 right-0 z-10 outline-none checked:outline-none checked:border-none checked:ring-0 ring-0 bg-yellow-#FF6D03">
                <img class="relative w-2/3 h-auto object-contain aspect-1 rounded-full border-2 border-gray-400"
                    [src]="store.logoUrl" alt="">
                <p class="relative text-yellow-#FF6D03 text-sm font-Lato-Regular line-clamp-1">
                    {{store.name}}</p>
            </div>
        </div>
    </div>
    <button *ngIf="resp_filters && selected_filters?.length > 0" (click)="resetFilters()" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                text-base w-full h-41px rounded-3px px-3">
        {{'RESET_FILTERS' | translate}}
    </button>
</div>