<div class="container">
  <!-- Skeleton para el título -->
  <h1 *ngIf="isLoading" class="skeleton-title"></h1>
  <h1 *ngIf="!isLoading" class="flex flex-col text-center items-center font-bold my-10">
    {{ title }}
  </h1>

  <section class="flex flex-col items-center">
    <div
      class="content-wrapper flex flex-col-reverse xl:flex-row items-center xl:items-start justify-center w-full gap-8">

      <!-- Skeleton Loader -->
      <div *ngIf="isLoading" class="skeleton-wrapper flex flex-col gap-4">
        <div class="skeleton-row"></div>
        <div class="skeleton-row"></div>
        <div class="skeleton-row"></div>
        <div class="skeleton-row"></div>
        <div class="skeleton-row short"></div>
        <div class="w-full flex justify-center">
          <div class="skeleton-button"></div>
        </div>
      </div>

      <!-- Contenido real -->
      <div *ngIf="!isLoading" class="flex flex-col gap-4">
        <span class="text-content max-w-xl" [innerHTML]="content"></span>
        <div class="w-full flex justify-center">
          <button (click)="navigate()"
                  [ngClass]="isNavigated ? 'pl-6 pr-3 opacity-50 cursor-not-allowed' : 'px-6'"
                  class="bg-yellow-#FF6D03 font-Lato-Regular text-white flex items-center text-center
                                   w-auto max-w-[300px] h-10 lg:h-[49px] rounded-3xl text-md lg:text-[19px] justify-center">
            <p>{{ 'PARTICIPATE_NOW' | translate }}</p>
            <fa-icon *ngIf="isNavigated"
                     class="relative flex justify-center items-center animate-spin rounded-full w-9 h-9 text-xl text-white"
                     [icon]="faSpinner">
            </fa-icon>
          </button>
        </div>
        <h1 class="text-lg font-bold text-center text-gray-800 pt-5">{{ 'WINNERS' | translate }}</h1>
        <h1 class="text-lg font-bold text-center text-gray-800">{{ 'WINNERS_RECENT_PERIOD' | translate }}</h1>
        <ul class="list-disc list-inside bg-gray-100 p-4 rounded-lg shadow-md text-gray-700 text-left justify-start">
          <li class="py-1">Dayana Lianet Fernandez Solano</li>
          <li class="py-1">Rosa Rodriguez</li>
          <li class="py-1">Yeneila Lázara Campos Calero</li>
          <li class="py-1">Maria Gonzalez</li>
          <li class="py-1">Aliurky Perez García</li>
          <li class="py-1">Gaby Carmona</li>
          <li class="py-1">Jessicah Colas</li>
          <li class="py-1">Dayana Mandina</li>
          <li class="py-1">Adis J. Lombana García</li>
          <li class="py-1">Tania Quijano del Toro
          </li>
          <li class="py-1">Iliam Suárez
          </li>
          <li class="py-1">Jorge Santana
          </li>
          <li class="py-1">Alina Batista
          </li>
          <li class="py-1">Lilliam Naranjo
          </li>
          <li class="py-1">Loraima Díaz Mendive
          </li>
          <li class="py-1">María de la Caridad Portela Nuñez
          </li>
          <li class="py-1">Yasmanis Herrera Rodríguez
          </li>
          <li class="py-1">Arianne Rivalta Gonzalez
          </li>
          <li class="py-1">Jose Tabarres
          </li>
          <li class="py-1">Regla M. Gonzalez García
          </li>
          <li class="py-1">Heidy Martinez
          </li>
          <li class="py-1">Frank Fernandez Gonzalez
          </li>
          <li class="py-1">Dianela Montano
          </li>
          <li class="py-1">Zadyk Vega Garcia
          </li>
          <li class="py-1">Ruth Machado Morales
          </li>
          <li class="py-1">Oraldo Abreu
          </li>
          <li class="py-1">Gabriela Pelegrino Valdés
          </li>
          <li class="py-1">Eric Aguila Avila
          </li>
          <li class="py-1">Zoila Díaz Salazar
          </li>
          <li class="py-1">Elsa Guerrero
          </li>
          <li class="py-1">Patricia Aguilar Hernández
          </li>
          <li class="py-1">Raúl Cristobal Mesa Santacruz
          </li>
          <li class="py-1">Taimir Flores Armas
          </li>
          <li class="py-1">Yusmila Roldán González
          </li>
          <li class="py-1">Cleger Pino Jukzany
          </li>
          <li class="py-1">Daniuska Lago
          </li>
          <li class="py-1">Lidia Costanzo
          </li>
          <li class="py-1">Ismelky Plasencia Borges
          </li>
          <li class="py-1">Alfonso de Jesús Fraga Gámez
          </li>
          <li class="py-1">Kenia Abreu
          </li>
          <li class="py-1">Yamelia Abreu González
          </li>
          <li class="py-1">Jorge Gonzalez
          </li>
          <li class="py-1">Eyda Lopez
          </li>
          <li class="py-1">Ana Isis Lara Sánchez
          </li>
          <li class="py-1">Josue Gomez
          </li>
          <li class="py-1">Daysi Rodríguez
          </li>
          <li class="py-1">Asdruball Lorenzo Landin
          </li>
          <li class="py-1">Mileidys Martinez
          </li>
          <li class="py-1">Karina Silverio
          </li>
          <li class="py-1">María Nila Santos Lagresa
          </li>
        </ul>
      </div>

      <!-- Skeleton Image -->
      <div *ngIf="isLoading" class="skeleton-image"></div>

      <img *ngIf="!isLoading" class="image" src="assets/imgs/giveaway_img.webp" alt="giveaway_img">
    </div>
  </section>
</div>
