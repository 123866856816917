<!--<mat-toolbar color="primary" mat-dialog-title class="flex items-center justifu-center">-->
<!--  <span>{{title | translate | uppercase}}</span>-->
<!--</mat-toolbar>-->

<!--<form class="form-class" [ngClass]="{'form-saving-class': isLoading}" (ngSubmit)="onSubmit()">-->
<!--  <mat-spinner [diameter]="40" class="saving-spinner-class" *ngIf="isLoading" mode="indeterminate"></mat-spinner>-->

<!--  <mat-dialog-content class="flex flex-col gap-1">-->
<!--      <div *ngIf="message !== ''" [ngClass]="{'text-center': textCenterTitle}">-->
<!--          <h4 *ngIf="messageBlack" class="weight-bold-class" fxLayoutAlign="center center">{{ message | translate }}</h4>-->
<!--          <h4 *ngIf="!messageBlack" fxLayoutAlign="center center">{{ message | translate }}</h4>-->
<!--      </div>-->

<!--      <h5 *ngIf="subMessage != ''" class="sub-message-class" class="flex items-center justify-center">{{ subMessage | translate }}</h5>-->
<!--  </mat-dialog-content>-->

<!--  <mat-dialog-actions class="flex items-center justify-center">-->
<!--    <button mat-raised-button type="submit" color="primary" *ngIf="!isWarnBotton" [disabled]="isLoading">-->
<!--      {{confirmAction | translate}}-->
<!--    </button>-->
<!--    <button mat-raised-button type="submit" color="warn" *ngIf="isWarnBotton" [disabled]="isLoading">-->
<!--      {{confirmAction | translate}}-->
<!--    </button>-->
<!--    <button [disabled]="isLoading" mat-raised-button mat-dialog-close="close">{{cancelAction | translate}}</button>-->
<!--  </mat-dialog-actions>-->
<!--</form>-->


<section class="relative bg-white
us:w-80 us:min-h-144px us:h-auto us:p-4
lg-landscape:w-410px lg-landscape:min-h-144px lg-landscape:h-auto lg-landscape:p-4">
  <fa-icon *ngIf="!hiddenCloseModal" (click)="close()"
           class="absolute top-2 right-3 cursor-pointer flex justify-center items-center text-yellow-#ff4103 lg-landscape:text-base"
           [icon]="faClose"></fa-icon>
  <p class="relative font-Lato-Regular text-sm font-semibold px-2" [ngClass]="{'uppercase': messageUppercase}" >{{data.message | translate}}</p>
  <div class="relative w-full h-px bg-gray-300 my-4"></div>
  <div class="relative flex justify-start items-center mt-6 gap-4">
    <button (click)="onSubmit()" [disabled]="isLoading" [ngClass]="{'opacity-50': isLoading}"
            class="flex items-center justify-center relative bg-yellow-#FFC35D font-Lato-Regular font-semibold text-sm border-none active:ring-0
            active:border-none focus:ring-0 focus:border-none py-2 rounded-3px focus:outline-none">
      <div [ngClass]="{'px-6': !isLoading, 'pl-6': isLoading}">{{confirmAction | translate}}</div>
      <fa-icon *ngIf="isLoading"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <button *ngIf="!hiddenCancelAction" [disabled]="isLoading" mat-dialog-close="close" [ngClass]="{'opacity-50': isLoading, 'hover:bg-gray-#EEEEEE': !isLoading}"
            class="relative bg-white font-Lato-Regular font-semibold text-sm border-none active:ring-0 active:border-none focus:ring-0 focus:border-none px-3 py-2 rounded-3px">
      {{cancelAction | translate}}
    </button>

<!--    <button mat-raised-button type="submit" color="primary" *ngIf="!isWarnBotton" [disabled]="isLoading">-->
<!--      {{confirmAction | translate}}-->
<!--    </button>-->
<!--    <button mat-raised-button type="submit" color="warn" *ngIf="isWarnBotton" [disabled]="isLoading">-->
<!--      {{confirmAction | translate}}-->
<!--    </button>-->
<!--    <button [disabled]="isLoading" mat-raised-button mat-dialog-close="close">{{cancelAction | translate}}</button>-->

  </div>
</section>
