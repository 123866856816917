import { Component, Input, Output, EventEmitter } from '@angular/core';
import {InternalNotificationDto} from "../internal-notifications/internalNotificationDto.interface";
import {notificationActions} from "../internal-notifications/notification-actions";
import {faBell, faBookReader, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {
  @Input() notification!: InternalNotificationDto;
  @Input() showFullDetails: boolean = false;
  @Output() delete = new EventEmitter<string>();
  @Output() toggleRead = new EventEmitter<string>();

  constructor(private notificationService: InternalNotificationService, private router: Router) {}

  onToggleRead(): void {
    this.notification.readAt = new Date();
    this.notificationService.markAsRead([this.notification.id]).subscribe(() => {
      this.toggleRead.emit(this.notification.id);
    });
  }

  onPrimaryAction() {
      this.onToggleRead();
      const actions = notificationActions[this.notification.type.name];
      if (actions && actions.length > 0) {
        if (actions[0].isLink) {
          this.router.navigate([actions[0].handler(this.notification)]).then();
        } else {
          actions[0].handler(this.notification);
        }
      }
    }

  onDelete() {
    this.notificationService.deleteNotifications([this.notification.id]).subscribe(() => {
      this.delete.emit(this.notification.id);
    });
  }


  // @Input() notification!: InternalNotificationDto;
  // @Input() showRead: boolean = false;
  // @Input() showActions: boolean = false;
  // @Output() primaryAction = new EventEmitter<any>();
  // @Output() deleteNotification = new EventEmitter<string>();
  // @Output() readNotification = new EventEmitter<string>();
  //
  // constructor(private notificationService: InternalNotificationService, private router: Router) {
  // }
  //
  // onPrimaryAction(notification: InternalNotificationDto) {
  //
  //   this.onRead(notification.id);
  //   const actions = notificationActions[notification.type.name];
  //   if (actions && actions.length > 0) {
  //     if (actions[0].isLink) {
  //       this.router.navigate([actions[0].handler(notification)]).then();
  //     } else {
  //       actions[0].handler(notification);
  //     }
  //   }
  //
  //   this.primaryAction.emit(notification);
  // }
  //

  //
  // onRead(notificationId: string) {
  //   this.notification.readAt = new Date();
  //   this.notificationService.markAsRead([notificationId]).subscribe(() => {
  //     this.readNotification.emit(notificationId);
  //   });
  // }
  //
  readonly notificationActions = notificationActions;
  readonly trash = faTrashCan;
  readonly faBell = faBell;
  readonly faBookReader = faBookReader;
}
