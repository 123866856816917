import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {BehaviorSubject, empty, Observable, of, tap, throwError} from "rxjs";
import {AuthService} from "./auth.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, map, takeUntil} from "rxjs/operators";
import * as moment from "moment";
import {environment} from "../../../environments/environment";
import {StoreService} from "./store.service";
import {CheckoutEnum, NewCheckoutEnum} from "../enum/checkout.enum";
import {FingerprintService} from "./fingerprint.service";
import {TranslateService} from "@ngx-translate/core";
import {
    AcceptCartChangesResponse,
    AddCartRequest,
    AddCartResponse,
    ChangeCountRequest,
    ChangeCountResponse,
    ChangeLocationRequest,
    ChangeLocationResponse,
    ChangeLocationValidationRequest,
    ChangeLocationValidationResponse,
    CleanCartResponse,
    NewCart,
    NewCartResponse, NewCheckoutPaymentRequestResponse,
    RemoveItemCartRequest,
    RemoveItemCartResponse, RevertPlacementResponse, Root,
    ShipmentRequest,
    ShipmentResponse,
    ShippingInfoRequest,
    ShippingInfoResponse, ValidateCartResponse
} from "../models/new-cart.model";
import {RemovePromoRequest, RemovePromoResponse} from '../models/store-order.model';
import {NotificationService} from "./notification.service";
import {Router} from "@angular/router";
import {SelectedZone} from "../models";
import {ZonesService} from "./zones.service";
import {
    AddPromoCodeRequest, ApplyPromoCodeResponse,
    Order,
    PendingPayable,
    PlaceRequest,
    SetPaymentVariantResponse, StoreOrderDetailsResponse,
    StoreOrderResponse
} from "../models/store-order.model";
import {NewCartExternalPaymentResponse} from "../models/new-cart-external-payment.model";

@Injectable({
  providedIn: 'root'
})
export class NewCartService {
  private resource: string = `${environment.wallet_api_url}${environment.v1}/Orders/ShoppingCart`;  // api/v1
  private resourceStoreOrder: string = `${environment.wallet_api_url}${environment.v1}/Orders/StoreOrder`;  // api/v1
  private resourceStoreOrderV2: string = `${environment.wallet_api_url}${environment.v2}/Orders/StoreOrder`;  // api/v2
  private resourceMessage = '/storefront/bulk-update-messages'; // peticion para /api/v2
  private externalPaymentUrl = `${environment.wallet_api_url}${environment.v1}/Payment/StartExternalPayment`;

  isBlockAddToCard = new BehaviorSubject<boolean>(false);
  isBlockDeleteFrom = new BehaviorSubject<boolean>(false);

  private KeyStore = {
    HAVE_CART: 'have_new_cart',
    CART_TOKEN: 'cart_token',
    NEW_CART: 'new_cart',
    NEW_CART_ORDER: 'new_cart_order',
    CART_EXPIRATION_DATE: 'cart_expiration_date',
    FINGER_PRINT: 'fingerPrint',
    IS_BOLSA_TRANSFER_ENUM: 'isBolsaTransfer',
    BOLSA_TRANSFER_DATA: 'BolsaTransferData',
  };

  newCartSubject$: BehaviorSubject<NewCart | undefined> = new BehaviorSubject<NewCart | undefined>(undefined);
  newCartOrderSubject$: BehaviorSubject<Order | undefined> = new BehaviorSubject<Order | undefined>(undefined);

  maxRetries = environment.retry_on_fail;

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private httpService: HttpClient,
    private storeService: StoreService,
    private fingerprintService: FingerprintService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private router: Router,
    private zonesService: ZonesService
  ) {
    if (authService.getCurrentUser()) {
        this.newCartSubject$.next(undefined);
        this.newCartOrderSubject$.next(undefined);
    } else {
        this.cleanStorage();
        this.clearCartSubject();
    }
  }

  blockAddToCardEvent(value: boolean) {
    this.isBlockAddToCard.next(value);
  }

  setBlockDeleteFromCartEvent(value: boolean) {
    this.isBlockDeleteFrom.next(value);
  }

  setMessageAsRead(ids: string, old: boolean = true) {
    return this.apiService.post(this.resourceMessage, { ids: ids, old: old}).subscribe();
  }

  /**
   * Obtener Carrito new approach
   * @param zone_id
   * @param municipality_id
   */
  getShoppingCart(zone_id: number, municipality_id: number): Observable<NewCart | undefined> {
      const params: HttpParams = new HttpParams().set('ZoneId', zone_id);
      if (municipality_id) {
          params.set('MunicipalityId', municipality_id);
      }
    // @ts-ignore
      return this.httpService.get<NewCartResponse>(this.resource, {
      headers: this.getHeader(),
      params
    }).pipe(
      map((cartResponse: NewCartResponse): NewCart | undefined => {
        if (cartResponse.success) {
          const newCart = cartResponse.data;
          this.saveInSessionAndSubject(newCart)
          this.saveCartValuesInStorage(this.KeyStore.HAVE_CART, true);
          return newCart;
        }
        return undefined;
      }),
      tap((newCart: NewCart | undefined) => {
          if (newCart && newCart?.totalItemsCount && newCart?.totalItemsCount > 0) {
              if (zone_id !== Number(newCart.zoneId) || municipality_id !== Number(newCart.municipalityId)) {
                  const values = {zoneId: String(zone_id), municipalityId: String(municipality_id)};
                  this.changeCartLocationValidation(
                      values
                  )
                      .pipe(
                          tap((response: ChangeLocationValidationResponse) => {
                              if (response.data && response.data.itemToBeRemoved) {
                                this.showConfirmationMessage(
                                    response.data.message,
                                    'ACCEPT',
                                    () => {
                                        this.changeCartLocation(values).subscribe(() => {
                                            this.showConfirmationMessage(
                                                this.translateService.instant(
                                                    'RELOCATION_NEW_CART_CHANGE_TEXT',
                                                    {
                                                        city: this.getSelectedZone?.municipality_name,
                                                        state: this.getSelectedZone?.zone_name,
                                                        country: this.getSelectedZone?.area_selected,
                                                    }
                                                ),
                                                'ACCEPT'
                                            )
                                        });
                                    });
                              } else {
                                  this.changeCartLocation(values).subscribe(() => {
                                      this.showConfirmationMessage(
                                          this.translateService.instant(
                                              'RELOCATION_NEW_CART_CHANGE_TEXT',
                                              {
                                                  city: this.getSelectedZone?.municipality_name,
                                                  state: this.getSelectedZone?.zone_name,
                                                  country: this.getSelectedZone?.area_selected,
                                              }
                                          ),
                                          'ACCEPT'
                                      )
                                  });
                              }
                          })
                      )
                      .subscribe();
              }
          }
      }),
      catchError((error: any) => {
          return this.apiService.handleErrors(error, true);
      })
    );
  }

  private showConfirmationMessage(modalMessage: string, acceptButtonText: string, callback?: () => void, disableClose?: false) {
      this.notificationService.showAndSubscribe(modalMessage, acceptButtonText, undefined, disableClose)
          .afterClosed()
          .subscribe({
              next: ((data: any) => {
                  if (data) {
                      if (callback) {
                          callback();
                      }
                  }
              }),
              error: (err => {
                  throw new Error(err);
              })
          });
  }

  /**
   * Add producto al carrito
   * @param data
   */
  addItemToCart(data: AddCartRequest): Observable<AddCartResponse> {
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
    return this.httpService.post<AddCartResponse>(`${this.resource}/Add`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: AddCartResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
          this.cleanStorage();
          this.cleanStorageDeliveryAndPayment();
        }),
        // catchError((error: any) => {
          // if (error.status === 400) {
          //   // this.removeCartValuesInStorage();
          //   return throwError(error);
          // } else {
            // todo verificar la dudad de que hacer en caso de que el error sea need relocation.
            // return this.apiService.handleErrors(error, true);
          // }
          // return throwError(error);
          // return empty();
          // return this.apiService.handleErrors(error, true);
        // })
      );
  }

  /**
   * Apply promo code  al carrito
   * @param data
   */
  applyPromoToCart(data: AddPromoCodeRequest): Observable<ApplyPromoCodeResponse> {
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
      return this.httpService.post<ApplyPromoCodeResponse>(`${this.resourceStoreOrder}/ApplyPromo`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ApplyPromoCodeResponse) => {
          this.saveOrderInSession(response.data);
        }),
        catchError((error: any) => {
            // if (error.status === 400) {
            //     // this.removeCartValuesInStorage();
            //     return throwError(error);
            // } else {
                return this.apiService.handleErrors(error, true);
            // }
        })
      );
  }

  /**
   * Modificar la cantidad de un producto existente en el carrito
   * @param data
   */
  setLineItemQuantity(data: ChangeCountRequest): Observable<ChangeCountResponse> {
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
      return this.httpService.post<ChangeCountResponse>(`${this.resource}/ChangeCount`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ChangeCountResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
          this.cleanStorage();
          this.cleanStorageDeliveryAndPayment();
        }),
        catchError((error: any) => {
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   * Modificar ubicacion del carrito
   * @param data
   */
  changeCartLocation(data: ChangeLocationRequest): Observable<ChangeLocationResponse> {
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
      return this.httpService.post<ChangeLocationResponse>(`${this.resource}/ChangeLocation`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ChangeLocationResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
            return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   * Modificar validacion de ubicacion del carrito
   * @param data
   */
  changeCartLocationValidation(data: ChangeLocationValidationRequest): Observable<ChangeLocationValidationResponse> {
    this.cleanStorage();
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
      return this.httpService.post<ChangeLocationValidationResponse>(`${this.resource}/ChangeLocationValidation`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ChangeLocationValidationResponse) => {
          // this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
            return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   * Create shipment
   * @param data
   */
  createShipment(data: ShipmentRequest): Observable<ShipmentResponse> {
    // this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
    return this.httpService.post<ShipmentResponse>(`${this.resource}/Checkout/Shipments`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ShipmentResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   * Create shipping info
   * @param data
   */
  createShippingInfo(data: ShippingInfoRequest): Observable<ShippingInfoResponse> {
    this.cleanStorageDeliveryAndPayment();
    // @ts-ignore
    return this.httpService.post<ShippingInfoResponse>(`${this.resource}/Checkout/ShippingInfo`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ShippingInfoResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   * Clean cart
   * @description Vacia el carrito y acto seguido lo elimina
   */
  cleanCart(): Observable<CleanCartResponse> {
    this.cleanStorage();
    return this.httpService.post<CleanCartResponse>(`${this.resource}/Clean`, null, {
      headers: this.getHeader()
    })
      .pipe(
        // map(() => {}this.deleteCart()),
        tap(() => {
          this.removeCartValuesInStorage();
          this.cleanStorageDeliveryAndPayment();
        }),
        catchError((error: any) => {
          if (error.status === 400 || error.status === 500) {
            this.cleanStorage();
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   *
   * Remove Item Cart
   * @param data
   * @param isCleanStorageDeliveryAndPayment
   */
  removeItemCart(data: RemoveItemCartRequest, isCleanStorageDeliveryAndPayment?: boolean): Observable<RemoveItemCartResponse> {
    if (isCleanStorageDeliveryAndPayment) {
      this.cleanStorageDeliveryAndPayment();
    }
    return this.httpService.post<RemoveItemCartResponse>(`${this.resource}/Remove`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: RemoveItemCartResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
          if (error.status === 400 && (!error.error || !error.error.responseCode || error.error.responseCode != 'ShoppingCartError')) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return of(error);
        })
      );
  }

  /**
   *
   * Remove Promo
   * @param data
   */
  removePromo(data: RemovePromoRequest): Observable<RemovePromoResponse> {
    // @ts-ignore
      return this.httpService.post<RemovePromoResponse>(`${this.resourceStoreOrder}/RemovePromo`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: RemovePromoResponse) => {
            this.saveOrderInSession(response.data);
        }),
        catchError((error: any) => {
          // if (error.status === 400) {
          //   this.removeCartValuesInStorage();
          // }
          // // return throwError(error);
          // return empty();
            return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Accept Cart Changes
   */
  acceptCartChanges(): Observable<AcceptCartChangesResponse> {
    return this.httpService.post<AcceptCartChangesResponse>(`${this.resource}/Checkout/AcceptCartChanges`, null, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: AcceptCartChangesResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   *
   * Validate Cart
   */
  validateCart(updateCart = true): Observable<ValidateCartResponse> {
    return this.httpService.post<ValidateCartResponse>(`${this.resource}/Checkout/Validate`, null, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: ValidateCartResponse) => {
            if (updateCart) {
                this.saveInSessionAndSubject(response.data.cart);
            }
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            this.removeCartValuesInStorage();
          }
          // return throwError(error);
          return empty();
        })
      );
  }

  /**
   *
   * Place Order
   */
  placeOrder(data: PlaceRequest): Observable<StoreOrderResponse> {
    // @ts-ignore
    return this.httpService.post<StoreOrderResponse>(`${this.resourceStoreOrder}/Place`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: StoreOrderResponse) => {
          this.saveInSessionAndSubject(response.data.cart);
          this.saveOrderInSession(response.data.order);
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          // return throwError(error);
          // return empty();
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Get Order pending payable
   */

  getOrderPendingPayable(): Observable<PendingPayable> {
    // @ts-ignore
    return this.httpService.get<PendingPayable>(`${this.resourceStoreOrder}/PendingPayable`, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: PendingPayable) => {
          // this.saveInSessionAndSubject(response.data.cart);
          // this.saveOrderInSession(response.data.order);
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          return throwError(error);
          // return empty();
          // return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Get Order pending payable
   */

  getOrderDetails(StoreOrderId: string): Observable<Order | undefined> {
    // @ts-ignore
    return this.httpService.get<StoreOrderDetailsResponse>(`${this.resourceStoreOrder}/Details`, {
      params: new HttpParams().set('StoreOrderId', StoreOrderId),
      headers: this.getHeader()
    })
      .pipe(
        map((response: StoreOrderDetailsResponse) => {
          // this.saveInSessionAndSubject(response.data.cart);
          this.saveOrderInSession(response.data);
          return response.data;
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          // return throwError(error);
          // return empty();
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Cancel Order pending payable
   */

  cancelOrderPendingPayable(storeOrderId: string): Observable<Root> {
    // @ts-ignore
    return this.httpService.put<Root>(`${this.resourceStoreOrder}/Cancel`, {storeOrderId}, {
      headers: this.getHeader()
    })
      .pipe(
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          // return throwError(error);
          // return empty();
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * RevertPlacement
   */

  revertPlacementOrder(storeOrderId: string): Observable<RevertPlacementResponse> {
    // @ts-ignore
    return this.httpService.post<RevertPlacementResponse>(`${this.resourceStoreOrder}/RevertPlacement`, {storeOrderId}, {
      headers: this.getHeader()
    })
      .pipe(
          tap((response: RemoveItemCartResponse) => {
              this.saveInSessionAndSubject(response.data.cart);
          }),
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          // return throwError(error);
          // return empty();
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Set Payment Variant
   */

  setPaymentVariant(data: {storeOrderId: string, variantId: number}): Observable<SetPaymentVariantResponse> {
    // @ts-ignore
    return this.httpService.put<SetPaymentVariantResponse>(`${this.resourceStoreOrder}/SetPaymentVariant`, data, {
      headers: this.getHeader()
    })
      .pipe(
        tap((response: SetPaymentVariantResponse) => {
          this.saveOrderInSession(response.data);
        }),
        catchError((error: any) => {
          if (error.status === 400) {
            // this.removeCartValuesInStorage();
            // todo add remover order cart de la session
          }
          // return throwError(error);
          // return empty();
          return this.apiService.handleErrors(error, true);
        })
      );
  }

  /**
   *
   * Pay Order
   */

  payOrder(data: {storeOrderId: string, paymentToken: string}): Observable<NewCheckoutPaymentRequestResponse> {
    const httpParams = new HttpParams().set('storeOrderId', data.storeOrderId).set('paymentToken', encodeURIComponent(data.paymentToken));
    // @ts-ignore
    return this.httpService.get<NewCheckoutPaymentRequestResponse>(`${this.resourceStoreOrderV2}/Pay`, {
      headers: this.getHeader(),
      params: httpParams
    })
      .pipe(
        tap((response: Root) => {

        }),
        // catchError((error: any) => {
        //   if (error.status === 400) {
        //     // this.removeCartValuesInStorage();
        //     // todo add remover order cart de la session
        //   }
        //   // return throwError(error);
        //   // return empty();
        //   return this.apiService.handleErrors(error, true);
        // })
      );
  }

  /**
   * Guardar datos en la session storage
   * @param key
   * @param value
   * @private
   */
  saveCartValuesInStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  saveFingerPrintValuesInStorage(value: any) {
    localStorage.setItem(this.KeyStore.FINGER_PRINT, value);
  }

  /**
   * Eliminar valores relacionados con el carrito del local storage
   * @private
   */
  removeCartValuesInStorage(callback?: any) {
    // this.cleanStorage();
    localStorage.removeItem(this.KeyStore.CART_TOKEN);
    localStorage.removeItem(this.KeyStore.HAVE_CART);
    localStorage.removeItem(this.KeyStore.NEW_CART);
    localStorage.removeItem(this.KeyStore.CART_EXPIRATION_DATE);
    localStorage.removeItem(this.KeyStore.IS_BOLSA_TRANSFER_ENUM);
    // localStorage.removeItem(this.KeyStore.FINGER_PRINT);
    this.newCartSubject$.next(undefined);
    this.setBlockDeleteFromCartEvent(false);

    if (callback) {
      callback();
    }
  }

  /**
   * Saber si hay un carrito creado
   * @private
   */
  haveCart() {
    const cart: NewCart | undefined = this.newCartSubject$.value;
    return localStorage.getItem(this.KeyStore.HAVE_CART) === 'true' && cart && cart.totalItemsCount !== 0;
  }

  /**
   * Get headers
   * @private
   */
  getHeader(body?: any): HttpHeaders {
    let headers = this.authService.getHeader(body);
    headers = headers
      .set('X-Payment-Client', environment.xApiClient)
      .set('Accept-Language', this.translateService.currentLang);

    // if (this.haveCart()) {
    //   headers = headers.set('X-Spree-Order-Token', this.getCartToken());
    // }

    return headers;
  }

  /**
   * Actualizar los valores en la session
   * @param cart
   * @private
   */
  saveInSessionAndSubject(cart: NewCart) {
      this.newCartSubject$.next(cart);
      // this.saveCartValuesInStorage(this.KeyStore.NEW_CART, JSON.stringify(cart));
  }

  /**
   * Actualizar los valores de la orden en la session
   * @param order
   * @private
   */
  private saveOrderInSession(order: Order) {
    this.newCartOrderSubject$.next(order);
      // this.saveCartValuesInStorage(this.KeyStore.NEW_CART_ORDER, JSON.stringify(order));
  }

  /**
   * Obtener la fecha de expiracion del carrito de la session storage
   */
  getCartExpirationDate() {
    return <string>localStorage.getItem(this.KeyStore.CART_EXPIRATION_DATE);
  }

  /**
   * Limpiar datos del subject del cart
   */
  clearCartSubject() {
    this.newCartSubject$.next(undefined);
  }

  cleanStorage() {
    localStorage.removeItem(NewCheckoutEnum.IS_WAS_SHOW_NOT_FOUND_404);
    localStorage.removeItem(NewCheckoutEnum.PERSONAL_INFORMATION);
    localStorage.removeItem(NewCheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(NewCheckoutEnum.DELIVERY);
    localStorage.removeItem(NewCheckoutEnum.PAYMENT);
    localStorage.removeItem(NewCheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(NewCheckoutEnum.CONFIRM);
    localStorage.removeItem(NewCheckoutEnum.IS_BOLSA_TRANSFER_ENUM);
    localStorage.removeItem(NewCheckoutEnum.BOLSA_TRANSFER_DATA);
  }

  cleanStorageDeliveryAndPayment() {
    localStorage.removeItem(NewCheckoutEnum.PERSONAL_INFORMATION_CART);
    localStorage.removeItem(NewCheckoutEnum.DELIVERY);
    localStorage.removeItem(NewCheckoutEnum.PAYMENT);
    localStorage.removeItem(NewCheckoutEnum.PAYMENT_CARDS);
    localStorage.removeItem(NewCheckoutEnum.CONFIRM);
    localStorage.removeItem(NewCheckoutEnum.BOLSA_TRANSFER_DATA);
  }

  shoppingCartExpired() {
    this.cleanStorage();
    localStorage.setItem(NewCheckoutEnum.IS_WAS_SHOW_NOT_FOUND_404, JSON.stringify(true));

    this.notificationService.showAndSubscribe('NEW_CART_SHOPPING_CART_EXPIRED_IN_CHECKOUT', 'CLOSE');
    this.router.navigate([`/profile/orders`]);
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  startExternalPayment(data: any): Observable<NewCartExternalPaymentResponse> {
      return this.httpService.post<NewCartExternalPaymentResponse>(this.externalPaymentUrl + '3', data, { headers: this.getHeader() });
  }
}
