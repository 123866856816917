<app-slider></app-slider>

<section *ngFor="let grid of grids"
         class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5 us:mt-5 us:pt-5 lg-landscape:mt-0">
    <div class="relative overflow-hidden
        us:w-full us:px-5.3vw
        lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:pt-7">
<!--        <div class="relative w-full flex justify-between items-center-->
<!--        us:px-3-->
<!--        lg-landscape:px-0">-->
<!--            <p class="relative flex flex-row font-Lato-Regular font-semibold text-gray-#252525-->
<!--            us:text-sm-->
<!--            us-landscape:text-base-->
<!--            md:text-base-->
<!--                    lg-landscape:text-19px">{{grid.attributes.Title | translate}}</p>-->
<!--            <p (click)="navegateTo(grid.attributes.ButtonWebNav)"-->
<!--               class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold uppercase-->
<!--            us:text-xs hover:cursor-pointer-->
<!--            lg-landscape:text-sm">{{grid.attributes.ButtonText | translate}} ></p>-->
<!--        </div>-->
        <app-products-grid [grid]="grid"></app-products-grid>
    </div>
</section>
