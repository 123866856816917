import {Component, Input, OnInit} from '@angular/core';
import {InternalNotificationDto} from "./internalNotificationDto.interface";
import {InternalNotificationService} from "../../services/internal-notification.service";
import {notificationActions} from "./notification-actions";

@Component({
  selector: 'app-internal-notifications',
  templateUrl: './internal-notifications.component.html',
  styleUrls: ['./internal-notifications.styles.scss']
})
export class InternalNotificationsComponent implements OnInit {


  @Input() pageSize: number = 10;
  @Input() allNotifications: boolean = true;
  notifications: InternalNotificationDto[] = [];
  private page = 1;
  private loading = false;

  constructor(
    private internalNotificationService: InternalNotificationService,
  ) {
  }

  ngOnInit(): void {
    this.loadNotifications();
  }

  loadNotifications(): void {
    if (this.loading) return;
    this.loading = true;
    this.internalNotificationService.getNotifications(this.pageSize, this.allNotifications, this.page).subscribe((result: {
      data: { items: InternalNotificationDto[] }
    }) => {
      this.notifications = [...this.notifications, ...result.data.items];
      this.page++;
      this.loading = false;
    });
  }

  onScroll(index: number): void {
    if (!this.allNotifications) {
      return;
    }

    if (index >= this.notifications.length - 9) {
      this.loadNotifications();
    }
  }

  deleteNotification(notificationId: string): void {
    this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
  }

}
