import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ApiService } from './api.service';
import { Subject, catchError, map, retry, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { frontendSettingsMock } from '../mocks/front-settings';
import { homeSettingsMock } from "../mocks/home-settings";
import {takeUntil} from "rxjs/operators";

export enum ENVS {
  DEV = 'Development',
  STG = 'Staging',
  PROD = 'Production'
}


@Injectable({
  providedIn: 'root'
})
export class DynamicCOnfigService {
  dynamic_settings_subject: Subject<any> = new Subject();
  API_SERVICE = environment.services_api_url;
  API_URL: string = environment.base_route;


  constructor(private apiService: ApiService, private http: HttpClient) { }


  getFrontendSettings(lang: String) {
    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('x-refresh', 'true');
    return this.http.request(
      'GET',
      `${this.API_SERVICE}/api/proxy/cms/api/frontend-settings?filters[$or][0][Env][$eq]=${this.getEnv()}&filters[$or][1][Env][$eq]=All&sort=Env%3Adesc&pagination[limit]=1&locale=${lang}&populate=global_contents&populate=global_contents.front_route&populate=global_images&populate=global_images.Image&populate=query_discoveries&populate=query_discoveries.Query&populate=footer_definition&populate=footer_definition.text_cmp_columns&populate=footer_definition.text_cmp_columns.text_components&populate=footer_definition.text_cmp_columns.text_components.Route&populate=footer_definition.FollowButtons&populate=footer_definition.FollowButtons.Image&populate=footer_definition.DownloadAppButtons&populate=footer_definition.DownloadAppButtons.Image`,
      { headers })
      .pipe(
        map((response: any) => {
          this.messageFromServer(response);
          // this.blockUI.stop();
          return response;
        }),
        retry(environment.retry_on_fail),
        catchError((err: any) => {
          this.messageFromServer(frontendSettingsMock);
          // this.blockUI.stop();
          // return frontendSettingsMock;
          return of(frontendSettingsMock);
        }),
        // catchError((error: any) => this.handleErrors(error, showError))
      );
  }

  getCountryHomeConfig(lang: String) {
    // const httpParams = new HttpParams()
    //   .set('payment_method_id', paymentMethodId);
    return this.http.request(
      'GET',
      `https://strapi.katapulk.com/api/country-homes?filters[CountryCode][$eq]=cu&locale=${lang}&populate=StoresDirBanner&populate=StoresDirBanner.Button.Leading&populate=StoresDirBanner.Button.Trailing&populate=StoresDirBanner.Button.Route&populate=StoresDirBanner.Image.Image&populate=MainBanner&populate=MainBanner.Buttons.Leading&populate=MainBanner.Buttons.Trailing&populate=MainBanner.Buttons.Route&populate=MainBanner.Images.Image&populate=TopBar&populate=TopBar.ExtraItems&populate=TopBar.ExtraItems.Route&populate=TopBar.TrailingItems&populate=TopBar.TrailingItems.Route`)
      .pipe(
        map((response: any) => {
          this.messageFromServer(response);
          // this.blockUI.stop();
          return response;
        }),
        retry(environment.retry_on_fail),
        // catchError((error: any) => this.handleErrors(error, showError))
      );
  }

  doDynamicQuery(query: string, variables: { key: string, value: string }[], gridHomeParam?: string, specialOffersGrid?: string, needs_proxy?: boolean) {
    const queryDiscoveryKey = variables.find(val => val.key =='query_discovery_key')?.value ?? null;
    this.API_URL = this.getUrlFromContext( queryDiscoveryKey );
    let modified_query: string = '';
    variables.forEach((element: { key: string, value: string }) => {
      modified_query == '' ?
        modified_query = query?.replace(`{{${element.key}}}`, element.value) :
        modified_query = modified_query?.replace(`{{${element.key}}}`, element.value);
    });
    if (gridHomeParam) {
      modified_query = `${modified_query}${gridHomeParam}`;
    }
    if (specialOffersGrid) {
      modified_query = `${modified_query}${specialOffersGrid}`;
    }

    (needs_proxy && !this.API_URL.includes('strapi')) ? this.API_URL = this.API_URL + '/api/proxy/cms' : null;

    let headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('x-refresh', 'true');
    return this.http.request(
      'GET',
      `${this.API_URL}/${modified_query}`,
      { headers })
      .pipe(
        map((response: any) => {
          this.messageFromServer(response);
          // this.blockUI.stop();
          return response;
        }),
        retry(environment.retry_on_fail),
        catchError(err => {
          this.messageFromServer(homeSettingsMock);
          return of(homeSettingsMock);
        }),
        // catchError((error: any) => this.handleErrors(error, showError))
      );
  }

  private messageFromServer(response: any): void {
    if (response?.msg_success) {
      // this.notificationService.success(response.msg_success, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_info) {
      // this.notificationService.info(response.msg_info, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_warn) {
      // this.notificationService.warn(response.msg_warn, true);
      window.scrollTo(0, 0);
    }

    if (response?.msg_error) {
      // this.notificationService.error(response.msg_error, true);
      window.scrollTo(0, 0);
    }
  }

  replaceVarValueInQuery(query: string, variable: string) {

  }

  getEnv() {
    const ENV = environment.name;
    switch (ENV) {
      case 'dev':
        return ENVS.DEV;
      case 'dev_cloud':
        return ENVS.DEV;
      case 'prod':
        return ENVS.PROD;
      case 'staging':
        return ENVS.STG;
      default:
        return null;
    }
  }
  getUrlFromContext( key:any ) {
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let countryHomeContext;
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      countryHomeContext = JSON.parse(aux)['FRONT_SETTINGS']?.data[0]?.attributes?.query_discoveries?.data
        .find((element: any) => element?.attributes?.Key == key)?.attributes?.Query?.Context;
      switch (countryHomeContext) {
        case 'Services':
          return environment.services_api_url;
        case 'Strapi':
          return environment.strapi_route;
        default:
          return environment.base_route;
      }
    } else return environment.base_route;
  }

  getGlobalConfigs(): any[] {
    let productsList = [];
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      const auxObject = JSON.parse(aux);
      let specialOferts = auxObject?.['HOME_SETTINGS']?.data[0]?.attributes?.MiscQueries?.data.filter((obj: any) => obj.attributes.Key == "deals_profile");
      if (specialOferts) {
        productsList = specialOferts[0]?.attributes;
      }
    }
    return productsList
  }

  getDynamicSettingsFromSubject(response: any): any[] {
    let aux: any = response['HOME_SETTINGS'];
    const productList = aux?.data[0]?.attributes?.MiscQueries?.data[1]?.attributes;
    if (productList) {
      return productList;
    }
    return [];
  }

}

