<section *ngIf="is_store_page" class="relative w-full h-auto flex flex-wrap justify-center items-center bg-white">
    <div class="relative us:w-full box-border
                us:px-5
                md:px-14
                lg:px-14
                xl:px-16 xl:w-1291px">
        <!--      lg-landscape:h-268px us:w-screen-->
        <div class="relative flex flex-col justify-center items-center py-5
        lg-landscape:px-0
        us:h-auto
        lg-landscape:w-full">
            <div class="relative flex justify-center items-center overflow-hidden
            us:h-104px us:w-full us:py-2
            lg-landscape:h-116px lg-landscape:w-full">
                <img class="relative bg-gray-300 object-cover
                us:w-full us:h-full
                lg-landscape:h-116px lg-landscape:w-full" [src]="src" alt="">
            </div>

            <div class="relative flex justify-start items-center box-border
                us:w-full us:mt-2 us:flex-col us:h-[218px]
                us-landscape:flex-row
                md:flex-row
                md-landscape:justify-between
                lg-landscape:w-full lg-landscape:h-[192px] lg-landscape:mt-5 lg-landscape:flex-row">
                <div class="relative flex
                us:justify-start us:w-full
                lg-landscape:justify-start lg-landscape:items-center">
                    <img class="relative rounded-full object-cover
                    us:w-69px us:h-69px us:mr-19px
                    lg-landscape:w-120px lg-landscape:h-120px lg-landscape:mr-19px border-2"
                        src="{{currentStore?.logoUrl}}" alt="">
                    <div class="relative flex flex-col justify-center">
                        <p class="relative text-gray-#252525 font-Lato-Regular font-semibold
                        us:pb-1 lg-landscape:text-base">
                            {{currentStore?.name}}</p>
                        <div class="relative flex justify-start items-center">
                            <!--    <button class="relative flex justify-center items-center bg-white border text-gray-#727272 border-gray-#979797 border-solid font-Lato-Regular font-semibold
                            us:text-sm us:w-24 us:h-9
                            lg-landscape:text-sm lg-landscape:w-24 lg-landscape:h-9">
                                <fa-icon class="relative flex justify-center items-center
                                text-gray-#727272
                                us:text-xs us:mr-1
                                lg-landscape:text-xs lg-landscape:mr-1" [icon]="faPlus">
                                </fa-icon>
                                Follow
                            </button>
                            <p class="relative uppercase cursor-pointer text-gray-#5d5d5d underline font-Lato-Regular font-bold
                            us:text-sm us:ml-7
                            lg-landscape:text-sm lg-landscape:ml-7">
                                shop
                            </p> -->
                            <p class="relative uppercase cursor-pointer hover:underline text-blue-#18396C font-Lato-Regular font-bold text-sm"
                                (click)="navigateInternal()">
                                {{ 'DETAILS' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="relative flex flex-col
                us:w-full
                us-landscape:w-auto">
                    <div class="flex justify-end">
                        <p class="realtive cursor-pointer font-Lato-Regular font-semibold hover:underline text-blue-#18396C
                                us:hidden
                                lg-landscape:inline lg-landscape:text-sm lg-landscape:mb-2"
                            [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'stores','directory']">
                            {{'SEE_ALL_STORES' | translate}}</p>
                    </div>
                    <div class="relative h-auto flex justify-start items-center
                    us:w-full us:mt-19px
                    us-landscape:w-331px us-landscape:mt-0
                    md-landscape:w-331px md-landscape:mt-0
                    lg-landscape:w-331px lg-landscape:mt-0">
                        <div class="relative w-full">
                            <input id="inp-store" #searchInput (input)="getSuggestions($event)"
                                placeholder="{{ 'STORE_SEARCH' | translate: { storeName: currentStore?.name } }}" (keydown)="enterPressed($event)"
                                [(ngModel)]="filters.name" [ngModelOptions]="{standalone: true}" type="text" class="h-41px relative border-2 rounded-3px border-solid border-gray-#ABABAB bg-white font-Lato-Regular
                             focus:outline-none active:outline-none focus:ring-0 active:ring-0 pr-11
                                      us:w-full
                                      lg-landscape:w-full" #searchProducts
                                [ngClass]="{'cursor-not-allowed': !getValidSelectedZone}"
                                [disabled]="!getValidSelectedZone">
                            <ul *ngIf="suggestions.length" id="sug-box-store"
                                class="absolute search-suggestion-box-store top-full left-0 bg-white rounded-md shadow-md w-full px-2 py-2 grid grid-cols-1 gap-2">
                                <li (click)="searchInput.value = suggestion; enterPressed({target:{value:suggestion}, keyCode:13}); suggestions = []"
                                    *ngFor="let suggestion of suggestions"
                                    class="relative font-Lato-Bold text-sm cursor-pointer capitalize hover:bg-gray-#dfdfdf px-2">
                                    {{suggestion}}
                                </li>
                            </ul>
                        </div>
                        <button mat-icon-button class="absolute cursor-pointer fill-white right-10"
                            *ngIf="filters.name && filters.name.length" (click)="clearTextFilter(searchProducts)">
                            <mat-icon class="items-center flex text-gray-#5d5d5d">close</mat-icon>
                        </button>
                        <img (click)="searchProductsInsideStore(searchProducts.value)"
                            class="absolute cursor-pointer w-7 h-auto fill-white right-3"
                            src="../../../../../assets/menu/icon-search.svg" alt=""
                            [ngClass]="{'cursor-not-allowed': !getValidSelectedZone}">
                    </div>
                    <div class="flex w-full mt-2">
                        <button (click)="clearTextFilter(searchProducts, true)" class="relative bg-yellow-#FFC35D flex justify-center capitalize items-center cursor-pointer font-Lato-Regular font-semibold
                                  text-base w-auto h-41px rounded-3px px-5">
                            {{'RESET_FILTERS' | translate}}
                        </button>
                    </div>
                    <!--                    <p (click)="clearTextFilter(searchProducts)" class="relative font-Lato-Regular hover:underline font-semibold text-blue-#18396C cursor-pointer capitalize-->
                    <!--                    us:text-13px us:tracking-0.7px us:mb-5 us:mt-5-->
                    <!--                    lg-landscape:text-sm lg-landscape:my-3">-->
                    <!--                        {{'CLEAR'| translate}}-->
                    <!--                    </p>-->
                </div>
            </div>
        </div>
    </div>
</section>


<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-gray-#f2f2f2 pb-12">
    <div class="relative us:w-full overflow-hidden box-border
                us:px-5
                md:px-14
                lg:px-14
                xl:px-16 xl:w-1291px">
        <div class="relative w-full flex items-start bg-gray-#f2f2f2 flex-col gap-3
                 xl:pt-6 xl:justify-between xl:flex-row">
            <!--            <span id="products-grid"></span>-->
            <app-store-detail-menu class="w-full" [listedProducts]="listedProducts" [totalProducts]="totalProducts"
                [resetFilterChanges]="resetFilterChanges" [selectedPrice]="selectedPrice"
                (change)="findProductsByFilter($event)" [selectedTaxon]="selectedTaxon" [selectedText]="selectedText"
                [clearFilters]="clearFilters" [cat_facets]="facets" [order]="this.order" [showGrid]="showGrid"
                [delivery_times]="delivery_times"></app-store-detail-menu>
            <!--            <app-store-detail-menu class="w-full" [taxonomies]="(taxonomiesObs$|async) || null"-->
            <!--                [listedProducts]="listedProducts" [totalProducts]="totalProducts" [selectedPrice]="selectedPrice"-->
            <!--                 (change)="findProductsByFilter($event)"-->
            <!--                [clearFilters]="clearFilters"></app-store-detail-menu>-->
            <app-store-detail-grid class="w-full" [products]="loadedProducts" [skeletons]="skeletons"
                [order]="this.order" [showGrid]="showGrid" (changeShowGrid)="changeShowGrid($event)"
                [haveMore]="this.next_page" (loadMore)="fetch_more($event)">
            </app-store-detail-grid>
        </div>
    </div>
</section>

<section class="relative w-full h-auto flex flex-wrap justify-center items-start bg-white" id="details">
    <div
        class="relative us:w-screen us:px-5.3vw us:pt-5 us:pb-5  us:flex-col  md-landscape:flex-row lg:flex-row lg-landscape:w-1291px lg-landscape:pl-60px lg-landscape:pr-60px lg-landscape:flex-row overflow-hidden box-border flex justify-between items-center">
        <div class="relative us:w-full lg-landscape:w-673px">
            <p class="relative w-full font-Lato-Regular font-semibold text-gray-#252525
            us:text-19px
            lg-landscape:text-19px">{{'STORE' | translate}} {{currentStore?.name}}</p>
            <p class="relative text-gray-#5d5d5d font-Lato-Regular font-normal
            us:text-sm
            lg-landscape:text-sm" [innerHtml]="currentStore.description | escape"></p>
            <!--            <p class="relative text-gray-#5d5d5d font-Lato-Regular font-normal-->
            <!--            us:text-sm us:mt-4-->
            <!--            lg-landscape:text-sm lg-landscape:mt-4">Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna-->
            <!--                aliquam erat-->
            <!--                volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut-->
            <!--                aliquip ex ea com</p>-->
            <p class="relative text-blue-#18396C underline cursor-pointer font-Lato-Regular font-normal
            us:text-sm us:mt-4
            lg-landscape:text-sm lg-landscape:mt-4" routerLink="/contents/delivery-policy">{{'SHIPPING_POLICY' |
                translate}}</p>
        </div>

        <div class="relative us:w-full lg-landscape:w-auto h-52 bg-white flex justify-between items-center cursor-pointer"
            routerLink="/stores/k-seller">
            <div class="relative fixedbtn flex justify-start items-center box-border overflow-hidden
            us:w-full us:h-116px
            lg-landscape:w-369px h-116px">
                <img src="../../../../../assets/imgs/home-slider/icon3-tiendas2.svg" class="relative w-67px h-67px
                us:ml-17px
                lg-landscape:ml-17px" alt="">
                <p class="relative font-Lato-Regular font-bold uppercase spaced text-gray-#252525
                us:tracking-1.75px us:text-sm us:ml-13px
                lg-landscape:tracking-1.75px lg-landscape:text-sm lg-landscape:ml-13px">{{'IWANT_TO_SELL_IN_KATAPULK' |
                    translate}}<br>
                    <span class="relative cursor-pointer font-Lato-Regular text-blue-#18396C tracking-normal font-normal underline capitalize
                    us:text-sm
                    lg-landscape:text-sm">{{'CONTACT_US' | translate}}</span>
                </p>
            </div>
        </div>
    </div>
</section>
