import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ApiService} from "./api.service";
import {AuthService} from "./auth.service";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {
  GenericShopListResponse,
  GetShopListDetailsResponse,
  GetShopListsResponse,
  ShopList,
  ChangeLocationRequest,
  ValidateChangeLocationResponse,
  ConvertFromCartRequest,
  ConvertFromOrderRequest,
  AddToShoppingListRequest, RemoveItemRequest
} from "../models/shop-list.model";
import {Observable, of, switchMap, take, tap} from "rxjs";
import {catchError, map, takeUntil} from "rxjs/operators";
import {NotificationService} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class ShopListService {

    private resource = `${environment.services_api_url}${environment.v1}/Orders/ShoppingList`;  // api/v1

    constructor(
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly notificationService: NotificationService,
    ) {
    }

    getShopList(data: { filters?: string, sorts?: string, page: number, pageSize: number }): Observable<GetShopListsResponse> {
      return this.http.get<GetShopListsResponse>(this.resource, {
            headers: this.getHeaders(),
            params: new HttpParams({fromObject: data})
        })
          .pipe(
            tap((getShopListsResponse: GetShopListsResponse) => {
              if (getShopListsResponse.fails) {
                this.apiService.handleErrors(new Error(getShopListsResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GetShopListsResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    addShopList(data: ShopList): Observable<GenericShopListResponse> {
      return this.http.post<GenericShopListResponse>(this.resource, data, {
            headers: this.getHeaders()
        })
          .pipe(
            tap((createShopListResponse: GenericShopListResponse) => {
              if (createShopListResponse.fails) {
                this.apiService.handleErrors(new Error(createShopListResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GenericShopListResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    validateChangeLocation(data: ChangeLocationRequest): Observable<ValidateChangeLocationResponse> {
      return this.http.post<ValidateChangeLocationResponse>(`${this.resource}/ValidateChangeLocation`, data, {
            headers: this.getHeaders()
        })
          .pipe(
            tap((validateChangeLocationResponse: ValidateChangeLocationResponse) => {
              if (validateChangeLocationResponse.fails) {
                this.apiService.handleErrors(new Error(validateChangeLocationResponse.message), true);
              }
            }),
            catchError((error: any): Observable<ValidateChangeLocationResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    changeLocation(data: ChangeLocationRequest): Observable<GenericShopListResponse> {
      const changeLocationEndpoint = this.http.post<GenericShopListResponse>(`${this.resource}/ChangeLocation`, data, {
        headers: this.getHeaders()
      });
      return this.validateChangeLocation(data).pipe(
        // @ts-ignore
        switchMap(validateChangeLocationResponse => {
          if (validateChangeLocationResponse.success) {
            const { itemToBeRemoved, message } = validateChangeLocationResponse.data;
            if (itemToBeRemoved) {
              this.notificationService.showAndSubscribe(message, 'ACCEPT', 'CANCEL')
                .afterClosed().pipe(take(1))
                .subscribe({
                  next: ((data: any) => {
                    if (data) {
                      return changeLocationEndpoint;
                    } else {
                      return of({});
                    }
                  }),
                  error: (err => {
                    throw new Error(err);
                  })
                });
            } else {
              return changeLocationEndpoint;
            }
          }
          this.apiService.handleErrors(new Error(validateChangeLocationResponse.message), true);
        }),
        tap((genericShopListResponse: GenericShopListResponse) => {
          if (genericShopListResponse.fails) {
            return this.apiService.handleErrors(new Error(genericShopListResponse.message), true);
          }
        }),
        catchError((error: any): Observable<GenericShopListResponse> => {
          return this.apiService.handleErrors(error, true);
        })
      );
    }

    updateShopList(data: ShopList): Observable<GenericShopListResponse> {
      return this.http.put<GenericShopListResponse>(this.resource, data, {
            headers: this.getHeaders()
        })
          .pipe(
            tap((createShopListResponse: GenericShopListResponse) => {
              if (createShopListResponse.fails) {
                this.apiService.handleErrors(new Error(createShopListResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GenericShopListResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    removeShopList(data: {id: string}): Observable<GenericShopListResponse> {
      return this.http.delete<GenericShopListResponse>(this.resource, {
          headers: this.getHeaders(),
          body: data
        })
          .pipe(
            tap((removeShopListResponse: GenericShopListResponse) => {
              if (removeShopListResponse.fails) {
                this.apiService.handleErrors(new Error(removeShopListResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GenericShopListResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    shopListDetails(data: {Id: string}): Observable<GetShopListDetailsResponse> {
      return this.http.get<GetShopListDetailsResponse>(`${this.resource}/Detail`, {
          headers: this.getHeaders(),
          params: new HttpParams({fromObject: data})
        })
          .pipe(
            tap((getShopListDetailsResponse: GetShopListDetailsResponse) => {
              if (getShopListDetailsResponse.fails) {
                this.apiService.handleErrors(new Error(getShopListDetailsResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GetShopListDetailsResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    createFromCart(data: ConvertFromCartRequest): Observable<GenericShopListResponse> {
      return this.http.post<GenericShopListResponse>(`${this.resource}/CreateFromCart`, data, { headers: this.getHeaders() })
          .pipe(
            tap((genericShopListResponse: GenericShopListResponse) => {
              if (genericShopListResponse.fails) {
                this.apiService.handleErrors(new Error(genericShopListResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GenericShopListResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    createFromOrder(data: ConvertFromOrderRequest): Observable<GenericShopListResponse> {
      return this.http.post<GenericShopListResponse>(`${this.resource}/CreateFromOrder`, data, { headers: this.getHeaders() })
          .pipe(
            tap((genericShopListResponse: GenericShopListResponse) => {
              if (genericShopListResponse.fails) {
                this.apiService.handleErrors(new Error(genericShopListResponse.message), true);
              }
            }),
            catchError((error: any): Observable<GenericShopListResponse> => {
              return this.apiService.handleErrors(error, true);
            })
          );
    }

    addToShopList(data: AddToShoppingListRequest): Observable<GenericShopListResponse> {
      return this.http.post<GenericShopListResponse>(`${this.resource}/Add`, data, {
        headers: this.getHeaders()
      })
        .pipe(
          tap((addToShoppingListResponse: GenericShopListResponse) => {
            if (addToShoppingListResponse.fails) {
              this.apiService.handleErrors(new Error(addToShoppingListResponse.message), true);
            }
          }),
          catchError((error: any): Observable<GenericShopListResponse> => {
            return this.apiService.handleErrors(error, true);
          })
        );
    }

    removeItem(data: RemoveItemRequest): Observable<GenericShopListResponse> {
      return this.http.post<GenericShopListResponse>(`${this.resource}/RemoveItem`, data, {
        headers: this.getHeaders()
      })
        .pipe(
          tap((removeItemResponse: GenericShopListResponse) => {
            if (removeItemResponse.fails) {
              this.apiService.handleErrors(new Error(removeItemResponse.message), true);
            }
          }),
          catchError((error: any): Observable<GenericShopListResponse> => {
            return this.apiService.handleErrors(error, true);
          })
        );
    }

    private getHeaders(): HttpHeaders {
        let headers = this.authService.getHeader();
        headers = headers
            .set('X-Payment-Client', environment.xApiClient)
            .set('Accept-Language', this.translateService.currentLang);
        return headers;
    }
}
