import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faShoppingCart, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ProductsService } from "../../../../shared/services/products.service";
import { ActivatedRoute, Router } from "@angular/router";
import { map, takeUntil, tap } from "rxjs/operators";
import { Product, SelectedZone, User } from "../../../../shared/models";
import { Taxon } from "../../../../shared/models";
import { CartService } from "../../../../shared/services/cart.service";
import { Observable, of, Subject } from "rxjs";
import { LanguageService } from "../../../../shared/services/language.service";
import { ZonesService } from "../../../../shared/services/zones.service";
import { PhoneRechargeSelectionComponent } from "../phone-recharge-selection/phone-recharge-selection.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from "../../../../shared/services/store.service";
import { AuxRoutesService } from 'src/app/shared/services/aux-routes.service';
import { CutomQuantityModalComponent } from '../cutom-quantity-modal/cutom-quantity-modal.component';
import { Location } from '@angular/common';
import { AgeModalComponent } from '../age-modal/age-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { LoginToAddCartDialogComponent } from "../login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import { AuthService } from "../../../../shared/services/auth.service";
import { NewCartService } from "../../../../shared/services/newcart.service";
import { ErrorsEnum } from "../../../../shared/enum/errors.enum";
import { ExtraData } from "../../../../shared/models/new-cart.model";
import { PendingOrdersConfirmationComponent } from "../pending-orders-confirmation/pending-orders-confirmation.component";
import { NewCheckoutEnum } from "../../../../shared/enum/checkout.enum";
import * as moment from "moment/moment";
import { FRONTEND_FEATURE, FrontendFeatureService } from "../../../../shared/services/frontend-feature.service";
import { environment } from "../../../../../environments/environment";
import { CommonKeysEnum } from "../../../../shared/enum/common.keys.enum";
import { StorageService } from "../../../../shared/services/storage.service";
import { NotificationService } from "../../../../shared/services/notification.service";
import { ApiService } from "../../../../shared/services/api.service";
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';


@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit, OnDestroy {
    cart_icon = faShoppingCart;
    faArrowLeft = faArrowLeft;
    quantity = 1;
    productSlug!: string;
    productId!: number;
    defaultProduct: any;
    product: Product = {
        id: 0,
        imageUrl: '../../../../../assets/imgs/product-card/no-product-image.png',
        isFavorite: false,
        name: '',
        price: 0,
        isLowStock: false,
        inStock: false,
        quantity: 1,
        currency: '',
        discount: 0,
        slug: '',
        data_extra_types: [],
        top: 0,
        compare_at_price: 0,
        previous_price: 0,
        age_valid: false
    };
    products: Product[] = [];
    productImages: string[] = [];

    relatedObs$: Observable<Product[]> | undefined;

    destroy$: Subject<boolean> = new Subject<boolean>();


    actualPage: number = 1;
    totalPages: number = 2;
    // listedProducts: number = 0;
    // totalProducts: number = 0;
    perPage: number = 24;
    skeletons: number[] = [];

    relatedProducts: any[] = [];

    productTaxons: string | null = null;
    isLoadingProduct: boolean = true;
    faSpinner = faSpinner;
    isLoadingCart = false;
    previousRoute: any;
    compare_top: any = 0;
    compare_min: any = 1;

    currentUser: User | undefined;

    queryId: string | undefined;
    queryPosition: number | undefined;
    bought_together_products: any[] = [];

    constructor(
        private productsService: ProductsService,
        private activeRoute: ActivatedRoute,
        private cartService: CartService,
        private languageService: LanguageService,
        private translateService: TranslateService,
        private zonesService: ZonesService,
        private dialog: MatDialog,
        private storeService: StoreService,
        private auxRouter: AuxRoutesService,
        private location: Location,
        private cookieService: CookieService,
        private authService: AuthService,
        private newCartService: NewCartService,
        private router: Router,
        private apiService: ApiService,
        private dynamicConfigService: DynamicCOnfigService
    ) {
        // this.productId = this.activeRoute.snapshot.params['id'];
    }

    back() {
        this.location.back();
    }

    ngOnInit(): void {
        // console.log(this.auxRouter.getActualUrl());
        this.activeRoute.params.subscribe((routeParams: any) => {
            window.scroll({ top: 0 });
            this.isLoadingProduct = true;
            // this.quantity = 1;
            // this.productId = routeParams['id'];
            this.productSlug = routeParams['slug'];
            this.languageService.selectedLanguage
                .pipe(takeUntil(this.destroy$))
                .subscribe((lang) => {
                    console.log(lang);

                    this.productsService.getProductBySlugNew(this.productSlug).subscribe((prod: any) => {
                        this.defaultProduct = { ...prod?.data };

                        this.defaultProduct.min = this.defaultProduct?.min === 0 ? 1 : this.defaultProduct?.min;

                        this.compare_top = this.defaultProduct?.top;
                        this.compare_min = this.defaultProduct.min;
                        this.quantity = this.defaultProduct.min > 1 ? this.defaultProduct.min : 1;
                        // @ts-ignore
                        this.products = [...prod?.data?.variants];
                        this.product = this.products[0];
                        this.productImages = [...this.defaultProduct?.imageUrl || [], ...this.product?.images || []];
                        this.defaultProduct.imageUrl = this.productImages[0];
                        this.isLoadingProduct = false;
                        if (this.defaultProduct?.objectID) {
                            this.loadDynamicQueriesByKey('bought-together');
                            this.loadDynamicQueriesByKey('related-products');
                        }
                    })
                })
        })
        this.activeRoute.queryParams
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(({ queryId, queryPosition }) => {
                if (queryId && queryPosition) {
                    this.queryId = queryId;
                    this.queryPosition = queryPosition;
                }
            });
        this.currentUser = this.authService.getCurrentUser();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }

    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    increaseQuantity() {
        if (this.getValidSelectedZone) {
            if ((this.quantity < this.compare_top && this.compare_top !== null)) {
                this.quantity += 1;
            }
            if (this.compare_top == null) {
                this.quantity += 1;
            }
        }
    }

    decreaseQuantity() {
        if (this.quantity > 1 && this.compare_min < this.quantity && this.getValidSelectedZone) this.quantity -= 1;
    }

    changeProductVariant(product: Product) {

        // this.product = product;
        // this.productImages = this.product.images;
        // this.defaultProduct.imageUrl = this.productImages[0];
        this.product = product;
        this.productImages = [...this.product?.images || []];
        // @ts-ignore
        this.defaultProduct?.imageUrl = this.productImages[0] || '../../../../../assets/imgs/product-card/no-product-image.png';
    }

    changeSelectedImage(imageUrl: string) {
        // @ts-ignore
        this.defaultProduct?.imageUrl = imageUrl;
    }

    /**
     * Obtner las categorias asociadas al producto
     * @param taxons
     * @param dataArray
     */
    private findTaxonsFromResponse(taxons: any[], dataArray: any[]): Taxon[] {
        if (taxons.length > 0) {
            return dataArray
                .filter((obj: any) => obj.type === 'taxon' && taxons.findIndex(taxon => taxon.id === obj.id) !== -1)
                .map(
                    (taxon: any) => ({
                        id: taxon?.id,
                        name: taxon?.attributes?.name,
                        permalink: taxon?.attributes?.permalink
                    })
                )
        }
        return [];
    }


    addToCart() {
        if (this.authService.isAuthenticated()) {
            if (this.quantity !== 0 && this.getValidSelectedZone) {
                // todo cambair la zone id cuando esté echa la gestión adecuanda guardarla y obtenerla
                if (this.defaultProduct?.extraData?.length !== 0) {
                    this.showPhoneNumberModal();
                } else if (this.defaultProduct?.age_valid && !this.cookieService.get('valid_age')) {
                    this.showAgeModal();
                } else {
                    this.generalAddToCart();
                }
            }
        } else {
            this.dialog.open(LoginToAddCartDialogComponent, {
                width: '400px',
                disableClose: true,
                data: {
                    messageUppercase: true,
                    cancelAction: 'CANCEL',
                    confirmAction: 'GO_TO_LOGIN',
                    message: "TO_PERFORM_THIS_ACTION_YOU_MUST_LOG_IN",
                }
            });
        }
    }

    get getSelectedZones(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }

    get getValidSelectedZone(): boolean {
        const selectedZone = this.getSelectedZones;
        if (selectedZone?.area_selected) {
            if (selectedZone.area_selected === 'CU') {
                return !!selectedZone.zone && !!selectedZone.municipality;
            }
            return !!selectedZone.zone;
        }
        return false;
    }

    private getVerificationDate(timeAdd: number): Date {
        return moment(Date.now()).add(timeAdd, 'hours').toDate()
    }

    private generalAddToCart(data_extras?: ExtraData) {
        this.isLoadingCart = true;
        if (!this.currentUser?.id) {
          this.currentUser = this.authService.getCurrentUser();
        }
        if (this.currentUser?.id) {
            this.addItemToCart(data_extras);
        }
    }

    private addToOldCartLogic(data_extras?: ExtraData, isCartUrl: boolean = false) {
        if (this.cartService.haveCart()) {
            this.addItemToCart(data_extras);
        } else {
            this.cartService.blockAddToCardEvent(true);
            this.cartService.createCart(this.translateService.currentLang, this.getSelectedZone.municipality)
                .then(() => {
                    this.cartService.blockAddToCardEvent(false);
                    this.addItemToCart(data_extras, isCartUrl);
                })
                .catch(() => {
                    this.cartService.blockAddToCardEvent(false);
                    this.isLoadingCart = false;
                });
        }
    }

    private addItemToCart(data_extras?: ExtraData, isCartUrl: boolean = false) {
      if (this.currentUser?.id) {
        this.newCartService.addItemToCart({
          variantId: String(this.product?.id),
          count: this.quantity,
          zoneId: `${this.getSelectedZone.zone}`,
          municipalityId: `${this.getSelectedZone.municipality}`,
          extraData: data_extras ? [data_extras] : [],
          queryId: this.queryId,
          queryPosition: this.queryPosition,
        })
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response) => {
              this.isLoadingCart = false;
              this.quantity = 1
              this.newCartService.blockAddToCardEvent(false);
              if (!response.data && response.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                this.showPendingOrderModal();
              } else {
                if (isCartUrl) {
                  this.navigateToNewCart();
                }
              }
            },
            error: (error) => {
              this.isLoadingCart = false;
              this.newCartService.blockAddToCardEvent(false);
              if (error?.error?.responseCode === ErrorsEnum.PENDING_STORE_ORDER) {
                return this.showPendingOrderModal();
              }
              return this.apiService.handleErrors(error, true);
            },
            complete: () => {
              this.isLoadingCart = false;
              this.newCartService.blockAddToCardEvent(false);
            }
          });
        // if (this.currentUser?.useNewCart) {
        //
        // }
        // else {
        //     this.cartService.addItemToCart({
        //         variant_id: this.product?.id,
        //         quantity: this.quantity,
        //         zone_id: this.getSelectedZone.zone || 6,
        //         data_extras
        //     }, this.translateService.currentLang)
        //         .pipe(takeUntil(this.destroy$))
        //         .subscribe({
        //             next: () => {
        //                 this.isLoadingCart = false;
        //                 this.quantity = 1;
        //                 if (isCartUrl) {
        //                     this.navigateToOldCart();
        //                 }
        //             },
        //             complete: () => {
        //                 this.isLoadingCart = false;
        //             }
        //         });
        // }
      }
    }

    showPendingOrderModal() {
        let dialoRef = this.dialog.open(PendingOrdersConfirmationComponent, {
            disableClose: false,
            // position: { top: '60px' }
        });
        dialoRef.afterClosed()
            // .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ((data: any) => {
                    if (data?.success && data?.type) {
                        switch (data.type) {
                            case 'payment':
                                // const paymentData = undefined;
                                // if (!paymentData) {
                                this.newCartService.removeCartValuesInStorage(() => {
                                    localStorage.setItem(NewCheckoutEnum.PAYMENT, JSON.stringify({ data: false }));
                                    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/checkout/payment`]);
                                });
                                // }
                                break;
                            case 'cart':
                                this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/new`]);
                                break;
                            case 'home':
                                // this.navigateToHome();
                                break;
                        }
                    }
                }),
                error: (err => {
                    throw new Error(err);
                })
            });
    }

    private navigateToHome() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/`]);
    }


    showPhoneNumberModal() {
        let dialoRef = this.dialog.open(PhoneRechargeSelectionComponent, {
            disableClose: false,
            position: { top: '60px' }
        });
        dialoRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ((data: any) => {
                    if (data?.modified) {
                        // @ts-ignore
                        this.generalAddToCart({ [this.defaultProduct?.extraData![0]?.id]: data.data });
                    }
                }),
                error: (err => {
                    throw new Error(err);
                })
            });
    }

    loadRelatedProducts(lang: string, taxons: any, page?: number) {
        this.skeletons = Array(this.perPage)
        return this.productsService.getRelatedProducts(
            { taxon: taxons || null },
            page || 1,
            this.perPage,
            this.getSelectedZones?.zone || 6,
            this.getSelectedZones?.municipality || 37,
            lang,
            'default_variant,variants,images,variants.images,variants.option_values,data_extra_types',
            '-updated_at'
        )
            .pipe(
                map((pdts): Product[] => {
                    this.skeletons = [];
                    this.totalPages = pdts.meta.total_pages;
                    return this.productsService.mapProductsFromResponse(pdts).filter((p: Product) => p.id !== this.defaultProduct?.id);
                })
            ).subscribe((products: Product[]) => {
                this.relatedProducts = [...this.relatedProducts, ...products];
            })
    }

    loadMore() {
        this.loadRelatedProducts(this.translateService.currentLang, this.productTaxons, ++this.actualPage);
    }

    setCustomQuantity() {
        if (this.defaultProduct?.extraData?.length == 0 && this.getValidSelectedZone) {
            let dialoRef = this.dialog.open(CutomQuantityModalComponent, {
                disableClose: false,
                data: this.compare_top
                // position: { top: '60px' }
            });
            dialoRef.afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                    next: ((data: any) => {
                        (data !== null && data !== undefined) ? this.quantity = data : null;
                    }),
                    error: (err => {
                        throw new Error(err);
                    })
                });
        }
    }

    showAgeModal() {
        let dialoRef = this.dialog.open(AgeModalComponent, {
            disableClose: false,
            // position: { top: '60px' }
        });
        dialoRef.afterClosed()
            // .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: ((data: any) => {
                    if (data) this.cookieService.set('valid_age', 'true', { path: '/' });
                }),
                error: (err => {
                    throw new Error(err);
                })
            });
    }

    private navigateToNewCart() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart/new`]);
    }

    private navigateToOldCart() {
        this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/cart`]);
    }

    loadDynamicQueriesByKey(key: string) {
        this.skeletons = [1, 2, 3, 4, 5]
        const languageAux = localStorage.getItem("language");
        let language = languageAux ? JSON.parse(languageAux) : null;
        const VARIABLES: any = {
            locale: language, countryCode: this.getSelectedZone?.area_selected?.toLowerCase() || 'cu',
            env: this.dynamicConfigService.getEnv(),
            zoneId: this.getSelectedZone?.zone,
            municipalityId: this.getSelectedZone?.municipality,
            productId: this.defaultProduct?.objectID
        };
        let DYNAMIC_QUERY_VARIABLES: any = [];
        let global_settings: any = {};

        this.dynamicConfigService.getFrontendSettings(language).subscribe((data: any) => {
            global_settings.SETTINGS = data;
            console.log(data);

            let query = global_settings?.SETTINGS?.data[0]?.attributes?.query_discoveries?.data
                .find((element: any) => element?.attributes?.Key == key);
            console.log(query);
            const DYNAMIC_QUERY = query?.attributes?.Query?.Uri;
            console.log(DYNAMIC_QUERY);

            query?.attributes?.Query?.Variables.map((element: any) => {
                DYNAMIC_QUERY_VARIABLES.push({ key: element, value: VARIABLES[`${element}`] });
            });
            DYNAMIC_QUERY_VARIABLES.push({ key: 'query_discovery_key', value: key });
            this.searchQuery(DYNAMIC_QUERY, DYNAMIC_QUERY_VARIABLES, key);
        })
    }

    private searchQuery(HOME_SETTINGS_QUERY: any, HOME_SETTINGS_QUERY_VARIABLES: any, key: any) {
        this.dynamicConfigService.doDynamicQuery(HOME_SETTINGS_QUERY, HOME_SETTINGS_QUERY_VARIABLES)
            .subscribe((data: any) => {
                this.skeletons = [];
                if (key == 'bought-together') {
                    this.bought_together_products = data?.data.items.map((product: any) => {
                        return {
                            ...product,
                            store: product?.stores ?
                                (product?.stores[0]?.id) ?
                                    this.storeService.getStoreById(product?.stores[0]?.id)
                                    : of(undefined)
                                : of(undefined)
                        }
                    });
                } else {
                    this.relatedProducts = data?.data.items.map((product: any) => {
                        return {
                            ...product,
                            store: product?.stores ?
                                (product?.stores[0]?.id) ?
                                    this.storeService.getStoreById(product?.stores[0]?.id)
                                    : of(undefined)
                                : of(undefined)
                        }
                    });
                }
            });
    }

}
