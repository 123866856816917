import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {SimpleContentService} from "../../../../shared/services/simple-content.service";
import {LanguageService} from "../../../../shared/services/language.service";
import {GiveawayService} from "../../services/giveaway.service";
import {NotificationService} from "../../../../shared/services/notification.service";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: 'app-giveaway',
    templateUrl: './giveaway.component.html',
    styleUrls: ['./giveaway.component.scss']
})
export class GiveawayComponent implements OnInit {
    title: string = '';
    content: string = '';
    isLoading: boolean = true;
    isNavigated: boolean = false;
    faSpinner = faSpinner;

    constructor(
        private readonly _router: Router,
        private readonly _simpleContentService: SimpleContentService,
        private readonly _languageService: LanguageService,
        private readonly _giveawayService: GiveawayService,
        private readonly _notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this._languageService.selectedLanguage.subscribe(lang => {
            this._simpleContentService.getSimpleContent('katapulk_decancio_giveaway', lang).subscribe((resp: any) => {
                const aux = resp?.data?.find((it: any) => it?.attributes?.Key === "katapulk_decancio_giveaway");
                this.title = aux?.attributes?.Title;
                this.content = aux?.attributes?.Content;
                this.isLoading = false;
            });
        })
    }

    navigate() {
      const reference = 'Giveaway';
      this.isNavigated = true;
      this._giveawayService.getGiveawayEventAvailable(reference).subscribe({
        next: (res: any) => {
          if (res.success) {
            this._router.navigate(['/giveaway'], { queryParams: { giveawayEventId: res.data } });
          } else{
            this._notificationService.showAndSubscribe('GIVEAWAY_EVENT_NOT_FOUND', 'CLOSE').afterClosed().subscribe(() => {
              this._router.navigate(['/cu/home']);
            });
          }
          this.isNavigated = false;
        }, error: (error) => {
          this._notificationService.showAndSubscribe('GENERIC_ERROR', 'CLOSE').afterClosed().subscribe(() => {
            this._router.navigate(['/cu/home']);
          });
          this.isNavigated = false;
        }
      })
    }
}
