import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AuthService} from "./auth.service";

const URL = `${environment.strapi_route}/api/simple-content-pages`;

@Injectable({
  providedIn: 'root'
})
export class SimpleContentService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authService: AuthService,
  ) {
  }

  getSimpleContent(filter: string, locale: string) {
    const params = new HttpParams()
      .set('filters[Key][$eq]', filter)
      .set('locale', locale);
    return this._httpClient.get(URL, {params: params, headers: this._authService.getHeader()});
  }
}
