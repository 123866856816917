<section class="relative w-full h-auto flex justify-center items-start bg-gray-#f2f2f2 pb-5">
    <div class="relative overflow-hidden
    xl:w-1291px xl:pl-60px xl:pr-60px xl:pt-7">
        <section (click)="back()" class="relative w-24 mb-1 flex cursor-pointer mt-2 mb-2
        us:px-5.3vw
        xl:px-0">
            <fa-icon class="relative flex justify-center items-center
        text-gray-#252525 text-sm mr-3" [icon]="faArrowLeft"></fa-icon>
            <p class="relative text-gray-#252525 font-Lato-Regular font-semibold
            us:text-xs
            md-landscape:text-sm
            lg-landscape:text-sm">
                {{'BACK' | translate}}
            </p>
        </section>
        <div class="relavite w-full h-auto bg-white flex justify-start items-start
        us:flex-col us:pb-5
        md-landscape:pt-9 md-landscape:pb-9 md-landscape:px-5.3vw md-landscape:flex-row
        lg:pt-9 lg:pb-9 lg:pl-5 lg:pr-9 lg:flex-row
        xl:pt-9 xl:pb-9 xl:pl-5 xl:pr-9 xl:flex-row">
            <div *ngIf="!isLoadingProduct" class="relative overflow-hidden box-border
            us:hidden
            md-landscape:inline md-landscape:w-67px
            lg:inline lg:w-67px
            xl:w-67px xl:inline">
                <img class="relative h-67px w-full cursor-pointer object-contain
                md-landscape:mb-4
                lg:mb-4
                xl:mb-4" *ngFor="let imageUrl of productImages"
                    src="{{imageUrl || '../../../../../assets/imgs/product-card/no-product-image.png'}}" alt=""
                    [ngClass]="imageUrl === defaultProduct?.imageUrl ?
                     'border-2 border-yellow-#FFA101' : 'border-2 border-gray-#DEDEDE'"
                    (click)="changeSelectedImage(imageUrl)">
            </div>

            <div *ngIf="isLoadingProduct" class="relative overflow-hidden box-border
              us:hidden
              md-landscape:inline md-landscape:w-67px
              lg:inline lg:w-67px
              xl:w-67px xl:inline">
                <div class="relative h-67px w-full cursor-pointer object-contain border-2 border-gray-#DEDEDE bg-gray-#f2f2f2 animate-pulse
                  md-landscape:mb-4
                  lg:mb-4
                  xl:mb-4"></div>
            </div>

            <img *ngIf="!isLoadingProduct" class="relative object-contain
            us:hidden
            md-landscape:inline md-landscape:w-380px
            lg:inline lg:w-380px
            xl:w-380px xl:h-426px xl:ml-30px xl:inline"
                [src]="defaultProduct?.imageUrl ? defaultProduct?.imageUrl : '../../../../../assets/imgs/product-card/no-product-image.png'"
                alt="">

            <div *ngIf="isLoadingProduct" class="relative object-cover bg-gray-#f2f2f2 animate-pulse border-gray-#929292
              us:hidden
              md-landscape:inline md-landscape:w-380px
              lg:inline lg:w-380px
              xl:w-380px xl:h-426px xl:ml-30px xl:inline"></div>

            <app-produc-detail-imgs-slider *ngIf="productImages?.length !== 0" [imgs]="productImages" class="
                md-landscape:hidden
                lg:hidden"></app-produc-detail-imgs-slider>

            <div class="relative flex flex-col justify-start items-start
            us:w-full us:ml-0 us:px-5.3vw us:pt-2
            md-landscape:w-465px
            lg:w-465px
            xl:w-518px xl:ml-53px xl:px-0 xl:pt-0">
                <p *ngIf="!isLoadingProduct" class="realtive w-full font-Lato-Regular font-semibold text-blue-#18396C
                us:text-lg
                xl:text-22px">{{defaultProduct?.name}}</p>

                <p *ngIf="isLoadingProduct" class="realtive w-full font-Lato-Regular font-semibold text-blue-#18396C h-5 w-auto lg:w-96 animate-pulse bg-gray-#f2f2f2 rounded mb-2
                  us:text-lg
                  xl:text-22px"></p>

                <p class="relative w-full font-Lato-Regular
                us:text-13px us:mt-1
                xl:text-13px xl:mt-0" [style.color]="defaultProduct?.store?.color">
                    {{'SOLD_BY' | translate}}
                    <a class="font-Lato-Regular font-semibold"
                        [routerLink]="['/', getSelectedZone?.area_selected?.toLowerCase() || 'cu', defaultProduct?.store?.code]">
                        {{defaultProduct?.store?.name}}</a>
                </p>

                <div class="relative w-full h-1px bg-gray-#dfdfdf
                us:mt-3 us:mb-4
                xl:mt-3 xl:mb-4"></div>

                <p *ngIf="!isLoadingProduct" class="relative w-full uppercase font-Lato-Regular font-semibold tracking-0.75px text-gray-#5d5d5d
                us:text-13px
                xl:text-13px">{{ 'OPTIONS' | translate }}</p>

                <p *ngIf="isLoadingProduct" class="relative w-full uppercase font-Lato-Regular font-semibold tracking-0.75px text-gray-#5d5d5d
                  us:text-13px bg-gray-#f2f2f2 h-5 w-40 rounded
                  xl:text-13px"></p>

                <div *ngIf="!isLoadingProduct" class="relative w-full h-auto flex flex-wrap justify-start items-center
                  us:mt-1
                  xl:mt-3">
                    <div class="relative overflow-hidden cursor-pointer
                    us:min-w-108px us:min-h-88px us:mr-14px
                    xl:min-w-108px xl:min-h-88px xl:rounded-3px xl:mr-14px" *ngFor="let prod of products; index as i"
                        (click)="changeProductVariant(prod)">
                        <div class="relative w-full h-full border border-solid
                        us:p-8px
                        xl:p-8px"
                            [ngClass]="prod.id === product?.id ? 'bg-gray-#f2f2f2 border-yellow-#FFA101' : 'bg-white border-gray-#DEDEDE'">
                            <p [ngClass]="prod.id === product?.id ? 'font-black' : ''" class="relative font-Lato-Regular text-gray-#3a3a3a
                            us:text-sm
                            xl:text-sm">{{prod?.optionsText || prod.name}}</p>
                            <p [ngClass]="prod.id === product?.id ? 'font-semibold' : ''" class="relative text-gray-#3a3a3a font-Lato-Regular
                            us:text-xs us:mt-3
                            xl:text-xs xl:mt-3">{{prod.displayDefaultPrice | currency: prod.currency}}</p>
                            <!-- <p [ngClass]="prod.id === product?.id ? 'font-semibold' : ''" class="relative text-gray-#3a3a3a font-Lato-Regular
                            us:text-xs
                            xl:text-xs">($0.18 / Fl Oz)</p> -->
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoadingProduct" class="relative w-full h-auto flex flex-wrap justify-start items-center
                    us:mt-1
                    xl:mt-3">
                    <div class="relative overflow-hidden cursor-pointer
                      us:min-w-108px us:min-h-88px us:mr-14px
                      xl:min-w-108px xl:min-h-88px xl:rounded-3px xl:mr-14px">
                        <div class="relative w-full bg-gray-#f2f2f2 animate-pulse h-16
                          us:p-8px
                          xl:p-8px">
                        </div>
                    </div>
                </div>

                <p *ngIf="!isLoadingProduct" class="relative text-gray-#212121 font-bold
                    us:text-2xl us:mt-6
                    xl:text-2xl xl:mt-6">{{product?.displayDefaultPrice | currency: product?.currency}}
                    <span class="relative text-xl ml-2 text-red-400 line-through font-normal"
                        *ngIf="product.discount?.hasDiscount">
                        ${{product.discount?.labelLineThrough}}
                    </span>
                    <!-- <span class="relative font-semibold text-gray-#3a3a3a font-Lato-Regular
                    us:text-xs
                    xl:text-xs">($0.18 / Fl Oz)</span> -->
                </p>
                <p *ngIf="isLoadingProduct" class="relative h-7 text-gray-#212121 font-bold bg-gray-#f2f2f2 animate-pulse w-20 rounded mb-8
                    us:text-2xl us:mt-6
                    xl:text-2xl xl:mt-6">
                </p>

                <div *ngIf="!isLoadingProduct" class="relative w-full flex justify-start items-center
                    us:h-5 us:mt-1
                    xl:h-5 xl:mt-2">
                    <!-- <p *ngIf="product?.isLowStock" class="relative font-Lato-Regular font-medium bg-green-#78840D text-white h-full
                        us:text-13px us:pl-2 us:pr-2 us:rounded-10px
                        xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px">low stock
                    </p> -->
                    <p *ngIf="product.discount?.hasDiscount" class="relative font-Lato-Regular font-medium bg-yellow-#FFA101 text-white h-full
                        us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:m-1
                        xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px xl:m-1">
                        -{{product.discount?.labelPercent | number:'1.0-0'}}%</p>
                    <p *ngIf="false" class="relative font-Lato-Regular font-medium bg-blue-#089CE3 text-white h-full
                        us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:m-1
                        xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px xl:m-1">best
                        seller</p>
                </div>

                <div class="relative w-full h-1px bg-gray-#dfdfdf
                us:mt-4 us:mb-4
                xl:mt-4 xl:mb-4"></div>

                <p class="relative uppercase font-Lato-Regular font-bold text-blue-#089CE3 bg-gray-#f2f2f2
                us:text-13px
                xl:text-13px xl:tracking-0.4px" *ngIf="product?.isOutStock == false && !isLoadingProduct">
                    {{'AVAILABLE' | translate}}</p>

                <p *ngIf="isLoadingProduct" class="relative uppercase font-Lato-Regular font-bold text-blue-#089CE3 h-4 w-12 mb-8 rounded bg-gray-#f2f2f2 animate-pulse
                  us:text-13px
                  xl:text-13px xl:tracking-0.4px"></p>

                <p class="relative uppercase font-Lato-Regular font-bold text-red-400
                us:text-13px
                xl:text-13px xl:tracking-0.4px"
                    *ngIf="product?.id && product?.isOutStock == true && !isLoadingProduct">
                    {{'NOT_AVAILABLE_NOW' | translate}}
                </p>

                <!-- <p class="relative uppercase font-Lato-Regular font-bold text-gray-#252525
                us:text-13px us:tracking-0.7px us:mt-3
                xl:text-13px xl:tracking-0.7px xl:mt-3">shipping</p> -->

                <!-- <p class="relative text-gray-#5d5d5d
                us:text-13px
                xl:text-13px">$9.99 | up to 5 business day</p> -->

                <p *ngIf="!isLoadingProduct && defaultProduct?.shippingLabel" class="relative text-gray-#252525 font-bold uppercase
                us:text-13px us:tracking-0.7px us:mt-22px
                xl:text-13px xl:tracking-0.7px xl:mt-22px">{{'SHIPPING' | translate}}</p>

                <div *ngIf="!isLoadingProduct" class="relative w-full flex justify-start items-center
                us:h-5 us:mt-0
                xl:h-5 xl:mt-0">
                    <p *ngIf="defaultProduct?.shippingLabel" class="relative font-Lato-Regular font-medium text-gray-#9D9D9D h-full
                    us:text-13px us:pr-2 us:rounded-10px us:my-1
                    xl:text-13px xl:pr-2 xl:rounded-10px xl:my-1">
                        {{defaultProduct?.shippingLabel}}</p>
                </div>

                <p *ngIf="!isLoadingProduct && defaultProduct?.categories?.length !== 0" class="relative text-gray-#252525 font-bold uppercase
                us:text-13px us:tracking-0.7px us:mt-22px
                xl:text-13px xl:tracking-0.7px xl:mt-22px">categorias</p>

                <p *ngIf="isLoadingProduct" class="relative text-gray-#252525 font-bold uppercase h-5 w-28 bg-gray-#f2f2f2 animate-pulse rounded
                  us:text-13px us:tracking-0.7px us:mt-22px
                  xl:text-13px xl:tracking-0.7px xl:mt-22px"></p>

                <div *ngIf="!isLoadingProduct && defaultProduct?.categories?.length !== 0" class="relative flex justify-start flex-wrap
                us:mt-1
                xl:mt-1">
                    <p *ngFor="let category of defaultProduct?.categories" class="relative font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full
                    us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:h-5 us:mr-2
                    xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px xl:h-5 xl:mr-2">
                        {{category.name}}</p>
                </div>

                <div *ngIf="isLoadingProduct" class="relative flex justify-start flex-wrap animate-pulse
                  us:mt-1
                  xl:mt-1">
                    <p class="relative font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full bg-gray-#f2f2f2 rounded h-3 w-28
                      us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:h-5 us:mr-2
                      xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px xl:h-5 xl:mr-2"></p>
                    <p class="relative font-Lato-Regular font-semibold bg-gray-#DFE1E0 text-gray-#5d5d5d h-full bg-gray-#f2f2f2 rounded h-3 w-32
                      us:text-13px us:pl-2 us:pr-2 us:rounded-10px us:h-5 us:mr-2
                      xl:text-13px xl:pl-2 xl:pr-2 xl:rounded-10px xl:h-5 xl:mr-2"></p>
                </div>

                <p class="relative text-gray-#252525 font-bold uppercase
                us:text-13px us:tracking-0.7px us:mt-22px
                xl:text-13px xl:tracking-0.7px xl:mt-22px" *ngIf="defaultProduct?.notes && !isLoadingProduct">
                    {{'DETAILS' | translate}}</p>

                <p *ngIf="!isLoadingProduct" class="relative font-Lato-Regular font-medium
                us:text-13px us:leading-4
                xl:text-13px xl:leading-4" [innerHtml]="defaultProduct?.notes | escape"></p>

                <div class="relative w-full h-1px bg-gray-#dfdfdf
                us:mb-4 us:mt-4
                xl:mt-4 xl:mb-4"></div>

                <div *ngIf="!isLoadingProduct" class="relative flex justify-start items-center
                     xl:">
                    <div class="relative flex justify-center items-center border border-gray-#929292 overflow-hidden box-border
                        us:rounded-l-20px us:rounded-r-20px us:h-9
                        xl:rounded-l-20px xl:rounded-r-20px xl:h-41px">
                        <button (click)="decreaseQuantity()"
                                [disabled]="defaultProduct?.extraData?.length !== 0"
                                [ngClass]="this.quantity <= compare_min && compare_min !== null && compare_min !== undefined ? 'cursor-not-allowed':'cursor-pointer'"
                                class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
                        us:text-25px us:w-38px
                        xl:text-25px xl:w-38px">
                            -
                        </button>
                        <p (click)="setCustomQuantity()" class="relative overflow-hidden cursor-pointer h-full flex justify-center items-center font-Lato-Regular text-gray-#5d5d5d
                           us:w-38px us:text-17px
                           xl:w-38px xl:text-17px">{{quantity}}</p>
                        <button (click)="increaseQuantity()" [disabled]="defaultProduct?.extraData?.length !== 0"
                            [ngClass]="this.quantity >= compare_top && compare_top !== null && compare_top !== undefined ? 'cursor-not-allowed':'cursor-pointer'"
                            class="relative cursor-pointer h-full bg-gray-#f2f2f2 flex justify-center items-center font-Lato-Regular text-gray-#979797
                        us:text-25px us:w-38px
                        xl:text-25px xl:w-38px">
                            +
                        </button>
                    </div>
                    <button class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                    us:h-41px us:w-auto us:text-17px us:ml-3 us:px-4
                    xl:h-41px xl:w-auto xl:text-19px xl:ml-3 xl:px-4" (click)="addToCart()"
                        [disabled]="quantity === 0 || isLoadingCart || product.isOutStock == true"
                        [ngClass]="{'opacity-50':  isLoadingCart || product.isOutStock == true, 'cursor-not-allowed ': isLoadingCart}">
                        <fa-icon class="relative flex justify-center items-center rounded-full text-white
                        us:w-9 us:h-9 us:mr-1
                        xl:w-9 xl:h-9 xl:mr-1" [icon]="cart_icon">
                        </fa-icon>
                        {{'ADD_TO_CART' | translate}}
                        <fa-icon *ngIf="isLoadingCart"
                            class="relative flex justify-center items-center animate-spin  bg-yellow-#FF6D03 rounded-full w-9 h-9 text-xl text-white"
                            [icon]="faSpinner">
                        </fa-icon>
                    </button>
                </div>

                <div *ngIf="isLoadingProduct" class="relative flex justify-start items-center animate-pulse">
                    <div class="relative flex justify-center items-center overflow-hidden bg-gray-#f2f2f2 w-28
                          us:rounded-l-20px us:rounded-r-20px us:h-9
                          xl:rounded-l-20px xl:rounded-r-20px xl:h-41px">
                    </div>
                    <div class="relative font-Lato-Regular font-medium bg-gray-#f2f2f2 rounded-full flex justify-center items-center text-white w-56
                      us:h-41px us:text-17px us:ml-3 us:px-4
                      xl:h-41px xl:text-19px xl:ml-3 xl:px-4">
                    </div>
                </div>

            </div>
        </div>

        <section class="realtive w-full flex justify-between items-start
        us:flex-col us:gap-4 xl:gap-0 us:pt-5
        xl:mt-10 xl:flex-row xl:pt-0">
            <div class="relative bg-white
              us:p-5.3vw
              w-full xl:w-568px xl:p-30px xl:pl-8 xl:pr-8 xl:pt-9 xl:pb-9"
                *ngIf="defaultProduct?.description && !isLoadingProduct">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                xl:text-19px">{{'DESCRIPTION' | translate}}</p>
                <p class="relative font-Lato-Regular text-gray-#252525
                xl:text-13px" [innerHtml]="defaultProduct?.description! | escape">

                </p>
            </div>
            <div class="relative bg-white
                us:p-5.3vw
                w-full xl:w-568px xl:p-30px xl:pl-8 xl:pr-8 xl:pt-9 xl:pb-9" *ngIf="isLoadingProduct">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525 h-6 w-40 rounded bg-gray-#f2f2f2 mb-2
                  xl:text-19px"></p>
                <div class="flex flex-col gap-2">
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                  xl:text-13px"></p>
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                  xl:text-13px"></p>
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                  xl:text-13px"></p>
                </div>
            </div>

            <div class="relative bg-white
                  us:p-5.3vw
                  w-full xl:w-568px xl:p-30px xl:pl-8 xl:pr-8 xl:pt-9 xl:pb-9" *ngIf="isLoadingProduct">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525 h-6 w-40 rounded bg-gray-#f2f2f2 mb-2
                    xl:text-19px"></p>
                <div class="flex flex-col gap-2">
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                    xl:text-13px"></p>
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                    xl:text-13px"></p>
                    <p class="relative font-Lato-Regular text-gray-#252525 bg-gray-#f2f2f2 rounded h-4 w-auto
                    xl:text-13px"></p>
                </div>
            </div>

            <div class="relative bg-white
                us:p-5.3vw
                w-full xl:w-568px xl:p-30px xl:pl-8 xl:pr-8 xl:pt-9 xl:pb-9"
                *ngIf="(product?.weight || product?.sku || product?.height || product?.width) && !isLoadingProduct">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                          xl:text-19px">{{'PRODUCT_INFORMATION' | translate}}</p>
                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF
                us:min-h-40px
                xl:h-10 xl:pl-3" *ngIf="product.weight">
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold
                    xl:text-13px">{{'WEIGHT' | translate}}</p>
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d
                    xl:text-13px xl:w-225px">{{product.weight}}</p>
                </div>
                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF
                us:min-h-40px
                xl:h-10 xl:pl-3" *ngIf="product.width">
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold
                    xl:text-13px">{{'WIDTH' | translate}}</p>
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d
                    xl:text-13px xl:w-225px">{{product.width}}</p>
                </div>
                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF
                us:min-h-40px
                xl:h-10 xl:pl-3" *ngIf="product.height">
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold
                    xl:text-13px">{{'HEIGHT' | translate}}</p>
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d
                    xl:text-13px xl:w-225px">{{product.height}}</p>
                </div>
                <!--                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF-->
                <!--                us:min-h-40px us:pl-0-->
                <!--                xl:h-10 xl:pl-3">-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold-->
                <!--                    xl:text-13px">Volume</p>-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d-->
                <!--                    xl:text-13px xl:w-225px">500 Mililiters</p>-->
                <!--                </div>-->
                <!--                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF-->
                <!--                us:min-h-40px us:pl-0-->
                <!--                xl:h-10 xl:pl-3">-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold-->
                <!--                    xl:text-13px">Product Dimensions</p>-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d-->
                <!--                    xl:text-13px xl:w-225px">2.37 x 2.53 x 6.5 inches; 1.06 puonds</p>-->
                <!--                </div>-->
                <!--                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF-->
                <!--                us:min-h-40px us:pl-0-->
                <!--                xl:h-10 xl:pl-3">-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold-->
                <!--                    xl:text-13px">Manufacturer</p>-->
                <!--                    <p class="relative font-Lato-Regular text-gray-#5d5d5d-->
                <!--                    xl:text-13px xl:w-225px">La Vitta</p>-->
                <!--                </div>-->
                <div class="relative w-full flex justify-between items-center even:bg-white odd:bg-gray-#EFEFEF
                us:min-h-40px us:pl-0
                xl:h-10 xl:pl-3" *ngIf="product.sku">
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d font-bold
                    xl:text-13px">SKU</p>
                    <p class="relative font-Lato-Regular text-gray-#5d5d5d
                    xl:text-13px xl:w-225px">{{product.sku}}</p>
                </div>
            </div>
        </section>

        <section class="relative w-full xl:mt-10 us:p-5.3vw xl:p-0"
            *ngIf="this.bought_together_products?.length !== 0 || skeletons.length !== 0">
            <div class="relative w-full flex justify-between items-center">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                us:text-base us:w-2/3
                xl:text-19px xl:w-auto">{{'BOUGHT_TOGETHER_PRODUCTS_TEXT' | translate}}
                    <!--                  <span-->
                    <!--                        class="text-yellow-#FF6D03">Mercado-->
                    <!--                        Único</span></p>-->
                <p class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold
                us:text-xs
                    xl:text-sm"></p>
            </div>
            <!-- <app-product-slider></app-product-slider> -->

            <div class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 xl:gap-x-4
            mt-4">
                <app-product-card *ngFor="let product of bought_together_products" [productId]="product.id"
                    [imageUrl]="product.imagesDto['image600']" [name]="product.name" [price]="product.defaultPrice"
                    [compare_at_price]="product.previous_price" [currency]="product.currency"
                    [isLowStock]="product.isLowStock" [quantity]="product.quantity" [discount]="product.discount"
                    [variantId]="product.defaultVariantId" [slug]="product.slug!"
                    [shipping_category]="product?.deliveryTime!" [data_extra_types]="product.extraData!"
                    [store]="product?.store" [resize]="true" [turn]="true" [top]="product?.top"
                    [age_valid]="product.ageValid"></app-product-card>
                <app-product-card-skeleton *ngFor="let skeleton of skeletons" [resize]="true" [turn]="true">
                </app-product-card-skeleton>
            </div>
        </section>
        <section class="relative w-full xl:mt-10 us:p-5.3vw xl:p-0"
            *ngIf="this.relatedProducts?.length !== 0 || skeletons?.length !== 0">
            <div class="relative w-full flex justify-between items-center">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                us:text-base us:w-2/3
                xl:text-19px xl:w-auto">{{'RELATED_PRODUCTS_TEXT' | translate}}
                    <!--                  <span-->
                    <!--                        class="text-yellow-#FF6D03">Mercado-->
                    <!--                        Único</span></p>-->
                <p class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold
                us:text-xs
                    xl:text-sm"></p>
            </div>
            <!-- <app-product-slider></app-product-slider> -->

            <div class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 xl:gap-x-4
            mt-4">
                <app-product-card *ngFor="let product of relatedProducts" [productId]="product.id"
                    [imageUrl]="product.imagesDto['image600']" [name]="product.name" [price]="product.defaultPrice"
                    [compare_at_price]="product.previous_price" [currency]="product.currency"
                    [isLowStock]="product.isLowStock" [quantity]="product.quantity" [discount]="product.discount"
                    [variantId]="product.defaultVariantId" [slug]="product.slug!"
                    [shipping_category]="product?.deliveryTime!" [data_extra_types]="product.extraData!"
                    [store]="product?.store" [resize]="true" [turn]="true" [top]="product?.top"
                    [age_valid]="product.ageValid"></app-product-card>
                <app-product-card-skeleton *ngFor="let skeleton of skeletons" [resize]="true" [turn]="true">
                </app-product-card-skeleton>
            </div>
            <!-- <div class="realtive w-full flex justify-center" *ngIf="this.actualPage !== this.totalPages">
                <button class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:mt-5
                xl:h-41px xl:w-250px xl:text-19px xl:mt-10 xl:ml-0 xl:px-4" (click)="loadMore()"
                    [disabled]="skeletons.length !== 0">
                    {{'LOAD_MORE' | translate}}
                </button>
            </div> -->
        </section>

        <!-- <section class="relative w-full xl:mt-10 us:p-5.3vw xl:p-0">
            <div class="relative w-full flex justify-between items-center">
                <p class="relative font-Lato-Regular font-semibold text-gray-#252525
                            xl:text-19px">Especiales</p>
                <p class="relative text-yellow-#FF6D03 font-Lato-Regular font-semibold
                    xl:text-sm">Ver más ></p>
            </div>
            <div class="grid us:grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 items-start flex-wrap gap-y-4 gap-x-2 sm:gap-x-4 md:gap-x-2 xl:gap-x-4
            mt-4">
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
                <app-product-card [resize]="true" [turn]="true"> </app-product-card>
            </div>
            <div class="realtive w-full flex justify-center">
                <button class="relative font-Lato-Regular font-medium bg-yellow-#FF6D03 rounded-full flex justify-center items-center text-white
                us:h-41px us:w-auto us:text-17px us:ml-3 us:px-6 us:mt-5
                xl:h-41px xl:w-250px xl:text-19px xl:mt-10 xl:ml-0 xl:px-4">
                    Load More
                </button>
            </div>
        </section> -->
    </div>
</section>
