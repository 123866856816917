import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { BehaviorSubject, empty, EMPTY, Observable, switchMap, take, throwError } from "rxjs";
import { catchError, filter } from "rxjs/operators";
import { Token } from "../models";
import { Router } from "@angular/router";
import { CONFIG } from "../../../assets/config";
import {MatDialog} from "@angular/material/dialog";
import {
  NotificationModalComponent
} from "../../modules/shared/components/notification-modal/notification-modal.component";
import {CurrencyService} from "../services/currency.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  excludedResources = [
    '/storefront/products',
    '/multi_stores',
    '/banners.json',
    '/storefront/countries',
    '/zones/zones_with_countries',
    '/municipality/get_all_municipalities',
    '/storefront/grouped_stores',
    '/strapi.katapulk.com/api',
    '/Catalog/Search/Products2',
    '/GetBoughtTogether2',
    '/GetRelatedProducts2',
    '/frontend-settings',
    '/country-homes'
  ];

  excludedResourcesFor401 = ['/spree_oauth/token'];

  constructor(public authService: AuthService,
              private currencyService: CurrencyService,
              private router: Router,
              private dialog: MatDialog) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excluded = this.excludedResources.some((re) => request.url.includes(re));

    if (this.authService.getCurrentToken() && !excluded) {
      request = this.addToken(request, this.authService.getCurrentToken());
    }

    request = this.setWebHeaders(request);

    request = this.addCurrency(request);

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && (error.headers.get('reason') === 'Invalid/Expired Token' || error.headers.get('reason') === 'ExpiredToken')) {
        return this.handleAuthenticationError(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private setWebHeaders(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'X-K-App': '1',
        'X-K-Ver': CONFIG.version
      }
    });
  }

  private addCurrency(req: HttpRequest<any>) {
    let modifiedReq = req;
    const currency = this.currencyService.selectedCurrency.getValue();
    if (req.method === 'GET') {
      const clonedParams = req.params.set('Currency', currency);
      modifiedReq = req.clone({ params: clonedParams });
    } else {
      // Si la solicitud no es GET, se le añade la propiedad 'currency' al body
      modifiedReq = req.clone({
        body: {
          ...req.body,
          currency: currency
        }
      });
    }
    return modifiedReq;
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((resp) => {
          this.isRefreshing = false;
          const access_token = resp.data?.tokens?.accessToken;
          this.refreshTokenSubject.next(access_token!);
          return next.handle(this.addToken(request, access_token!));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if ( error?.status == 400 && error?.error?.responseCode == 'NotFound') {
            return EMPTY;
          } else if (error?.status !== 404) {
            this.authService.logout();
            this.dialog.closeAll();
            this.router.navigate(['/auth/signIn']);
          }
          this.dialog.open(NotificationModalComponent, {
            width: 'auto',
            data: {
              msg: 'GENERIC_ERROR',
              btn_ok_text: 'ACCEPT',
              btn_cancel_txt: 'CANCEL',
            },
          })
          return EMPTY;
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
