import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import KeenSlider, { KeenSliderInstance } from "keen-slider";

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit, AfterViewInit {
  @ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>;
  slider: any = {};
  currentSlide: number = 1
  constructor() {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      loop: true,
      slides: {
        perView: 4,
        spacing: 30,
      },
      breakpoints: {
        "(min-width: 300px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 400px)": {
          slides: { perView: 1, spacing: 5 },
        },
        "(min-width: 500px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 550px)": {
          slides: { perView: 2, spacing: 20 },
        },
        "(min-width: 600px)": {
          slides: { perView: 3, spacing: 5 },
        },
        "(min-width: 750px)": {
          slides: { perView: 3, spacing: 18 },
        },
        "(min-width: 900px)": {
          slides: { perView: 3, spacing: 30 },
        },
        "(min-width: 1100px)": {
          slides: { perView: 4, spacing: 15 },
        }
      },
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s?.track?.details?.rel || 1;
      },
    })
  }

}
