import { Component, OnInit } from '@angular/core';
import {faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NewAddAddressComponent} from "../../../profile/components/new-add-address/new-add-address.component";
import {takeUntil} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {GiveawayDialogComponent} from "../giveaway-dialog/giveaway-dialog.component";

@Component({
  selector: 'app-giveaway-page',
  templateUrl: './giveaway-page.component.html',
  styleUrls: ['./giveaway-page.component.scss']
})
export class GiveawayPageComponent implements OnInit {

  form: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    email: new FormControl(null, {
      validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
    }),
  });
  formMarkAsTouched: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    window.scroll({ top: 0 });
    // this.openModal();
  }

  openModal() {
    this.dialog.open(GiveawayDialogComponent, {
      disableClose: true,
      // position: { top: '30px' },
    }).afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((response: any) => {
          if (response?.modified) {
            // this.getBillingAddresses();
          }
        }),
        error: (err => {
          throw new Error(err);
        })
      })
  }

  submit(): void {
    this.formMarkAsTouched = true;
  }

  get name () {
    return this.form.get('name');
  }

  protected readonly faEyeSlash = faEyeSlash;
}
