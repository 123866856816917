import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  selectedCurrency: BehaviorSubject<string> = new BehaviorSubject<string>(
    JSON.parse(localStorage.getItem("currency") ?? '"USD"')
  );
  constructor() {
  }
}
