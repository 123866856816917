import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

if(!environment.isLocal) {
  Sentry.init({
    dsn: environment.sentry_dsn,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment.name,
    ignoreErrors: [
      "TurnstileError",
      "[Cloudflare Turnstile]",
      "Can't find variable: _AutofillCallbackHandler",
      "ReferenceError global code",
      "track.details.abs",
      "Cannot read properties of null (reading 'abs')",
      "Non-Error exception captured with keys",
      "Failed to load the JS script of the agent",
      "t.track.details is null",
      "undefined is not an object (evaluating 'n[ro(n,t)]')",
      "Uncaught (in promise): Error: Failed to load the JS script of the agent",
      "_e.reduceRight is not a function"
    ],
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
