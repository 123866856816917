import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { SquareCardComponent } from './components/square-card/square-card.component';
import { ProvinceSelectionComponent } from './components/province-selection/province-selection.component';
import { SharedRoutingModule } from './shared-routing.module';
import { ProductPageComponent } from './components/product-page/product-page.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { MaterialModule } from "../../material.module";
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatRadioModule } from '@angular/material/radio';

import { TranslateModule } from "@ngx-translate/core";
import { ThinProductCardComponent } from './components/thin-product-card/thin-product-card.component';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { SingleImgSliderComponent } from './components/single-img-slider/single-img-slider.component';
import { ProducDetailImgsSliderComponent } from './components/produc-detail-imgs-slider/produc-detail-imgs-slider.component';
import { SaveToDefaultListComponent } from "./notifications/save-to-default-list/save-to-default-list.component";
import { ProductCardSkeletonComponent } from './components/product-card-skeleton/product-card-skeleton.component';
import { DeliveryPolicyAndFeesComponent } from './components/delivery-policy-and-fees/delivery-policy-and-fees.component';
import { ReturnPolicyComponent } from './components/return-policy/return-policy.component';
import { CustomerSupportComponent } from './components/customer-support/customer-support.component';
import { AboutKatapulkComponent } from './components/about-katapulk/about-katapulk.component';
import { FaqComponent } from './components/faq/faq.component';
import { AffidavitComponent } from './components/affidavit/affidavit.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import {
  PhoneRechargeSelectionComponent
} from "./components/phone-recharge-selection/phone-recharge-selection.component";
import { GlobalSearchGridComponent } from './components/global-search-grid/global-search-grid.component';
import { CategoriesSearchGridComponent } from './components/categories-search-grid/categories-search-grid.component';
import { PassportIframeComponent } from './components/passport-iframe/passport-iframe.component';
import { He11Component } from './components/he11/he11.component';
import { VisaIframeComponent } from './components/visa-iframe/visa-iframe.component';
import { EscapePipe } from "../../shared/directives/escape.pipe";
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { TravelServicesComponent } from './components/travel-services/travel-services.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MercadoUnicoGridComponent } from './components/mercado-unico-grid/mercado-unico-grid.component';
import { PriceRangeComponent } from './components/price-range/price-range.component';
import { CustomDesplegableComponent } from './components/custom-desplegable/custom-desplegable.component';
import { CutomQuantityModalComponent } from './components/cutom-quantity-modal/cutom-quantity-modal.component';
import { MigratoryProcessesComponent } from './components/migratory-processes/migratory-processes.component';
import { ListProductCardComponent } from './components/list-product-card/list-product-card.component';
import { ListProductCardSkeletonComponent } from './components/list-product-card-skeleton/list-product-card-skeleton.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { CreatePaymentMethodComponent } from "./components/create-payment-method/create-payment-method.component";
import { PaymentsCardsComponent } from "./components/payments-cards/payments-cards.component";
import { QrGeneratorComponent } from './components/qr-generator/qr-generator.component';
import { QrCodeModule } from "ng-qrcode";
import { AgeModalComponent } from './components/age-modal/age-modal.component';
import {
  CheckoutAddressManagerComponent
} from "./components/checkout-address-manager/checkout-address-manager.component";
import { TextInputComponent } from './components/generic-forms/controls/text-input/text-input.component';
import { DropdownComponent } from './components/generic-forms/controls/dropdown/dropdown.component';
import { GenericFormComponent } from './components/generic-forms/forms/generic-form/generic-form.component';
import { ControlComponent } from './components/generic-forms/controls/control/control.component';
import { DateInputComponent } from './components/generic-forms/controls/date-input/date-input.component';
// import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { YesNoInputComponent } from './components/generic-forms/controls/yes-no-input/yes-no-input.component';
import { MultilineInputComponent } from './components/generic-forms/controls/multiline-input/multiline-input.component';
import { BooleanInputComponent } from './components/generic-forms/controls/boolean-input/boolean-input.component';
import { FeaturedProductsGridComponent } from "./components/featured-products-grid/featured-products-grid.component";
import { LoginToAddCartDialogComponent } from "./components/login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import { TextSkeletonComponent } from './components/text-skeleton/text-skeleton.component';
import { ContentComponent } from "./components/content/content.component";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { environment } from "../../../environments/environment";
import { DropdownWithSelectionDependantComponent } from "./components/generic-forms/controls/dropdown-with-selection-dependant/dropdown-with-selection-dependant.component";
import { ConfirmDialogWithTitleImageDescriptionComponent } from "./components/confirm-dialog-with-title-image-description/confirm-dialog-with-title-image-description.component";
import { CaptchaComponent } from './components/captcha/captcha.component';
import { SharedPaymentMethodsComponent } from './components/shared-payment-methods/shared-payment-methods.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ClipboardModule } from '@angular/cdk/clipboard';
import { LoadingWithImageModalComponent } from './components/loading-with-image-modal/loading-with-image-modal.component';
import { PaymentCaptchaComponent } from "./components/payment-captcha/payment-captcha.component";
import {
  PendingOrdersConfirmationComponent
} from "./components/pending-orders-confirmation/pending-orders-confirmation.component";
import { NewCheckoutAddressManagerComponent } from './components/new-checkout-address-manager/new-checkout-address-manager.component';
import { ProductsGridComponent } from './components/products-grid/products-grid.component';
import {GetOptionSelectedPipe} from "../../shared/directives/get-option-selected.pipe";
import {InternalNotificationsComponent} from "./notifications/internal-notifications/internal-notifications.component";
import {ScrollingModule} from "@angular/cdk/scrolling";
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { NotificationTrayComponent } from './notifications/notification-tray/notification-tray.component';
import { NotificationCardComponent } from './notifications/notification-card/notification-card.component';
import { NotificationSnackComponent } from './notifications/notification-snack/notification-snack.component';
import { CreateShopListComponent } from './components/shopping-list/create-shop-list/create-shop-list.component';
import { ShoppingListCardComponent } from './components/shopping-list/shopping-list-card/shopping-list-card.component';
import { ShoppingListDetailComponent } from './components/shopping-list/shopping-list-detail/shopping-list-detail.component';
import {FilterPipe} from "../../shared/directives/filter.pipe";
import {AddToShopListComponent} from "./components/shopping-list/add-to-shop-list/add-to-shop-list.component";
import {
  ProductCardShoppingListComponent
} from "./components/shopping-list/product-card-shopping-list/product-card-shopping-list.component";
import { GiveawayComponent } from './components/giveaway/giveaway.component';
import { GiveawayPageComponent } from './components/giveaway-page/giveaway-page.component';
import {GiveawayDialogComponent} from "./components/giveaway-dialog/giveaway-dialog.component";
const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  hostname: environment.mqtt.server,
  port: environment.mqtt.port,
  protocol: (environment.mqtt.protocol === "wss") ? "wss" : "ws",
  path: environment.mqtt.path,

};

@NgModule({
  declarations: [
    ProductCardComponent,
    HomeSliderComponent,
    SquareCardComponent,
    ProvinceSelectionComponent,
    ProductPageComponent,
    ProductSliderComponent,
    BreadCrumbsComponent,
    ThinProductCardComponent,
    SingleImgSliderComponent,
    ProducDetailImgsSliderComponent,
    SaveToDefaultListComponent,
    ProductCardSkeletonComponent,
    DeliveryPolicyAndFeesComponent,
    ReturnPolicyComponent,
    CustomerSupportComponent,
    AboutKatapulkComponent,
    FaqComponent,
    AffidavitComponent,
    NotificationModalComponent,
    PhoneRechargeSelectionComponent,
    FeaturedProductsGridComponent,
    GlobalSearchGridComponent,
    CategoriesSearchGridComponent,
    PassportIframeComponent,
    He11Component,
    VisaIframeComponent,
    EscapePipe,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    TravelServicesComponent,
    NotFoundComponent,
    MercadoUnicoGridComponent,
    ConfirmDialogComponent,
    ConfirmDialogWithTitleImageDescriptionComponent,
    PriceRangeComponent,
    CustomDesplegableComponent,
    CutomQuantityModalComponent,
    MigratoryProcessesComponent,
    ListProductCardComponent,
    ListProductCardSkeletonComponent,
    CreatePaymentMethodComponent,
    PaymentsCardsComponent,
    QrGeneratorComponent,
    AgeModalComponent,
    CheckoutAddressManagerComponent,
    TextInputComponent,
    DropdownComponent,
    GenericFormComponent,
    ControlComponent,
    DateInputComponent,
    YesNoInputComponent,
    MultilineInputComponent,
    BooleanInputComponent,
    CheckoutAddressManagerComponent,
    LoginToAddCartDialogComponent,
    TextSkeletonComponent,
    ContentComponent,
    DropdownWithSelectionDependantComponent,
    ContentComponent,
    CaptchaComponent,
    PaymentCaptchaComponent,
    SharedPaymentMethodsComponent,
    LoadingWithImageModalComponent,
    PendingOrdersConfirmationComponent,
    NewCheckoutAddressManagerComponent,
    ProductsGridComponent,
    InternalNotificationsComponent,
    GetOptionSelectedPipe,
    TimeAgoPipe,
    NotificationTrayComponent,
    NotificationCardComponent,
    NotificationSnackComponent,
    CreateShopListComponent,
    ShoppingListCardComponent,
    ShoppingListDetailComponent,
    FilterPipe,
    AddToShopListComponent,
    ProductCardShoppingListComponent,
    GiveawayComponent,
    GiveawayPageComponent,
    GiveawayDialogComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MaterialModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgxMatIntlTelInputModule,
    QrCodeModule,
    MatChipsModule,
    MatIconModule,
    RecaptchaV3Module,
    ClipboardModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ScrollingModule,
  ],
  exports: [
    ProductCardComponent,
    HomeSliderComponent,
    SquareCardComponent,
    ProductPageComponent,
    ProductSliderComponent,
    BreadCrumbsComponent,
    ThinProductCardComponent,
    FontAwesomeModule,
    SingleImgSliderComponent,
    MaterialModule,
    ReactiveFormsModule,
    // MatDatepickerModule,
    FormsModule,
    TranslateModule,
    ProducDetailImgsSliderComponent,
    SaveToDefaultListComponent,
    ProductCardSkeletonComponent,
    FaqComponent,
    AffidavitComponent,
    NotificationModalComponent,
    FeaturedProductsGridComponent,
    GlobalSearchGridComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    EscapePipe,
    MercadoUnicoGridComponent,
    ConfirmDialogComponent,
    ConfirmDialogWithTitleImageDescriptionComponent,
    PriceRangeComponent,
    CustomDesplegableComponent,
    CutomQuantityModalComponent,
    ListProductCardComponent,
    ListProductCardSkeletonComponent,
    CategoriesSearchGridComponent,
    NgxMatIntlTelInputModule,
    CreatePaymentMethodComponent,
    PaymentsCardsComponent,
    AgeModalComponent,
    CheckoutAddressManagerComponent,
    TextInputComponent,
    DropdownComponent,
    GenericFormComponent,
    ControlComponent,
    DateInputComponent,
    // MatNativeDateModule,
    MomentDateModule,
    // MatRadioModule,
    YesNoInputComponent,
    MultilineInputComponent,
    BooleanInputComponent,
    CheckoutAddressManagerComponent,
    TextSkeletonComponent,
    ContentComponent,
    DropdownWithSelectionDependantComponent,
    MatChipsModule,
    MatIconModule,
    CaptchaComponent,
    PaymentCaptchaComponent,
    SharedPaymentMethodsComponent,
    RecaptchaV3Module,
    ClipboardModule,
    LoadingWithImageModalComponent,
    PendingOrdersConfirmationComponent,
    NewCheckoutAddressManagerComponent,
    ProductsGridComponent,
    InternalNotificationsComponent,
    GetOptionSelectedPipe,
    ProductsGridComponent,
    ShoppingListCardComponent,
    FilterPipe,
    ProductCardShoppingListComponent,
    GiveawayComponent,
  ],
  providers: [
    // AuthService, ApiService, UserService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey }
  ]
})
export class SharedModule {
  constructor() {
    // console.log('SHARED MODULE LOADED');
  }
}
