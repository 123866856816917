import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SelectedZone} from 'src/app/shared/models';
import {Subject, Subscription} from 'rxjs';
import {ProductsService} from 'src/app/shared/services/products.service';
import {LanguageService} from 'src/app/shared/services/language.service';
import {ZonesService} from 'src/app/shared/services/zones.service';
import {takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {DynamicCOnfigService} from "../../../../shared/services/dynamic-config.service";

@Component({
    selector: 'app-mercado-unico-grid',
    templateUrl: './mercado-unico-grid.component.html',
    styleUrls: ['./mercado-unico-grid.component.scss']
})
export class MercadoUnicoGridComponent implements OnInit, OnDestroy {
    languageServiceSubscription!: Subscription;
    skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    destroy$: Subject<boolean> = new Subject<boolean>();
    hide_featured = true;

    // @Input() grid: any;
    grid: any;

    facets: any;
    variables: any;

    loadedProducts: any[] = [];
    language: any;

    constructor(
        private languageService: LanguageService,
        private productsService: ProductsService,
        private router: Router,
        private zonesService: ZonesService,
        private dynamicService: DynamicCOnfigService,
    ) {
    }

    ngOnInit(): void {
        window.scroll({top: 0});

        if (localStorage.getItem('GLOBAL_CONFIGS')) {
            let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
            let recommendedOferts = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.MiscQueries?.data.filter(( obj:any )=> obj.attributes.Key == "feature_cart" )
            this.grid = recommendedOferts?.length && recommendedOferts[0]?.attributes;
        }
        this.dynamicService.dynamic_settings_subject
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
                let aux: any = response['HOME_SETTINGS'];
                this.grid = aux?.data[0]?.attributes?.MiscQueries?.data[0].attributes;
            });

        this.variables = this.grid.Query.Variables;
        this.languageService.selectedLanguage
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (lang) => {
                this.language =  lang;
                this.zonesService.subjectSelectedZones$
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(async(selectedZone)=> {
                        this.loadedProducts = [];
                        this.loadedProducts = await this.productsService.fetch_products(null, this.language, this.grid,
                            selectedZone?.zone || 6, this.variables, this.getSelectedZone?.municipality || 48, false,);
                        this.hideSkeletons();
                    });
            });
    }

    ngOnDestroy(): void {
        this.languageServiceSubscription?.unsubscribe();
        this.destroy$.next(true);
        this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well
    }

    get getSelectedZone(): SelectedZone {
        return this.zonesService.getZonesValuesStorage();
    }
    hideSkeletons() {
        this.skeletons = [];
    }

    showSkeletons() {
        this.skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
    navegateTo(url:string) {
        this.router.navigateByUrl(url);
    }
}
