import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { GroupedStore, SelectedZone, User, Cart } from "../../shared/models";
import { Router, Event, NavigationEnd, NavigationError, ActivatedRoute } from "@angular/router";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import {
  ProvinceSelectionComponent
} from '../../modules/shared/components/province-selection/province-selection.component';
import { categories } from '../../shared/navigation/categories';
import { stores } from '../../shared/navigation/stores';
import { travels } from '../../shared/navigation/travels';
import { profile } from '../../shared/navigation/profile';
import { faBell, faClose, faGift, faShoppingBag, faShoppingCart, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from "../../shared/services/store.service";
import * as moment from "moment";
import { AUTO_STYLE, animate, state, style, transition, trigger } from '@angular/animations';
import { interval, of, Subject, Subscription, takeWhile, tap } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from "../../shared/services/language.service";
import { ZonesService } from "../../shared/services/zones.service";
import { MenuService } from 'src/app/shared/services/menu.service';
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { CartPreviewComponent } from 'src/app/modules/payment/components/cart-preview/cart-preview.component';
import { DynamicCOnfigService } from 'src/app/shared/services/dynamic-config.service';
import { ContinueBuyingDialogComponent } from "../continue-buying-dialog/continue-buying-dialog.component";
import { LoginToAddCartDialogComponent } from "../../modules/shared/components/login-to-add-cart-dialog/login-to-add-cart-dialog.component";
import { GiftPreviewComponent } from 'src/app/modules/gift/components/gift-preview/gift-preview.component';
import { NewCart } from "../../shared/models/new-cart.model";
import { NewCartService } from "../../shared/services/newcart.service";
import { CubacelService } from 'src/app/modules/cubacel/services/cubacel.service';
import {FrontendFeatureService} from "../../shared/services/frontend-feature.service";
import { AlgoliaService } from 'src/app/shared/services/algolia.service';
import {InternalNotificationService} from "../../modules/shared/services/internal-notification.service";
import {
  NotificationTrayComponent
} from "../../modules/shared/notifications/notification-tray/notification-tray.component";
import {PusherService} from "../../shared/services/pusher.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  NotificationSnackComponent
} from "../../modules/shared/notifications/notification-snack/notification-snack.component";
import {CurrencyService} from "../../shared/services/currency.service";

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ]),
  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  close = faClose;
  menu_categories = categories;
  menu_stores = stores;
  menu_travels = travels;
  menu_profile = profile;
  selected_link: any = '';
  showcat = false;
  showstores = false;
  showtravels = false;
  showprofile = false;
  showsubcat = false;
  showtsubcat = false;
  showsubcattiendas = false;
  showcatTravels = false;
  @ViewChild('searchInput', { static: true }) search_input: any;
  @ViewChild('searchInputRes', { static: true }) search_input_res: any;
  extra_items: any;
  trailing_items: any;
  others_label: any = null;
  others: any = null;

  haveCart: boolean = false;
  cartTime: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
  cartFinishDate: Date | null;
  counterTimer$: Subscription | undefined;

  languages = [
    { id: "es", title: "LANGUAGES.ES", flag: "es" },
    { id: "en", title: "LANGUAGES.EN", flag: "us" }
  ];

  currencies: any[] = [
    { id: 1, name: 'USD' },
    { id: 4, name: 'EUR' }
  ];

  selectedLanguage!: { id: string; title: string; flag: string };
  selectedCurrency!: string;

  destroy$: Subject<boolean> = new Subject<boolean>();
  finishCartSubs$: Subject<boolean> = new Subject<boolean>();
  cart: any = undefined;

  hideDefaultStore = false;
  cart_icon = faShoppingCart;
  bag_icon = faShoppingBag;
  faCartShopping = faCreditCard;

  matDialogRefCB: MatDialogRef<any> | undefined;
  hide_stores = false;
  fagift = faGift;
  showcatnave = false;

  user: User = this.authService.getCurrentUser();
  emailNoDomain: any;
  cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
  actual_route: string = '';
  cubacel_route = false;
  valid_bag = false;
  suggestions: string[] = [];
  private readonly searchSubject = new Subject<string>();
  selected_sugestion = null;
  userId: string | undefined = undefined;
  notificationAddedSubscription!: Subscription;
  userChangeSubscription!: Subscription;

  isFocused: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly storeService: StoreService,
    private readonly newCartService: NewCartService,
    private readonly pusherService: PusherService,
    private readonly translateService: TranslateService,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly menuService: MenuService,
    private readonly zonesService: ZonesService,
    public readonly cookieService: CookieService,
    private readonly dynamicService: DynamicCOnfigService,
    private readonly activeRoute: ActivatedRoute,
    private readonly cubacelService: CubacelService,
    private readonly frontendFeatureService: FrontendFeatureService,
    private readonly internalNotificationService: InternalNotificationService,
    private readonly algoliaService: AlgoliaService,
    private readonly snackBar: MatSnackBar
  ) {
    this.zonesService.subjectSelectedZones$
      .pipe(takeUntil(this.destroy$))
      .subscribe((zone) => {
        if (zone) {
          this.storeService.getStoresByZoneId(zone.zone)
            .pipe(takeUntil(this.destroy$))
            .subscribe((groupedStore: GroupedStore | undefined) => {
              const stores = groupedStore?.stores;
              this.hideDefaultStore = !stores?.some((store) => store.id === this.defaultStore) || false;
              this.menu_stores.links = stores?.slice(0, stores.length / 2).map((store) => {
                return {
                  id: store.id,
                  text: store.name,
                  route: `/${store.code}`,
                  links: []
                };
              });
              this.menu_stores.links2 = stores?.slice(stores.length / 2).map((store) => {
                return {
                  text: store.name,
                  route: `/${store.code}`,
                  links: []
                };
              });
            });
        }
      });

    this.cartTime = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    this.cartFinishDate = null;
    this.dynamicService.dynamic_settings_subject.subscribe(() => {
      this.getGLobalConfig();
    });
    this.authService.currentUserSubject.subscribe((user) => {
      this.user = user;
      this.emailNoDomain = this.user?.email?.split('@')[0];
      if (this.user?.id && this.user?.readyToUse) {
        this.getCartValues();
        this.initCartTime();
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart', 'new'];
      } else {
        this.cartRoute = ['/', this.getSelectedZone?.area_selected?.toLowerCase() || 'cu', 'cart'];
      }
    });

    this.internalNotificationService.notificationsRead.subscribe({next: (count: number) => {
        this.unreadCount -= count;
    }})
  }

  ngOnInit(): void {
    this.initLanguage();
    this.initCurrency();
    this.getGLobalConfig();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (!this.router.url.includes('/global-search')) {
          this.search_input.nativeElement.value = '';
          this.search_input_res.nativeElement.value = '';
        } else {
          this.search_input.nativeElement.value = this.getQueryParams() ?? null;
          this.search_input_res.nativeElement.value = this.getQueryParams() ?? null;
        }
        this.closeAll();
        this.actual_route = this.router.url;
        if (this.router.url.includes('/cubacel')) {
          this.cubacel_route = true;
          this.valid_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag'])?.length : false;
          this.cubacelService.update_cubacel_bag.subscribe({
            next: () => {
              this.valid_bag = localStorage['cubacel_shopping_bag'] ? JSON.parse(localStorage['cubacel_shopping_bag'])?.length : false;
            }
          })
        } else {
          this.cubacel_route = false;
        }
      }

      if (event instanceof NavigationError) {
        this.closeAll();
      }
    });

    this.menu_categories = this.menuService.getFinalCategories(this.selectedLanguage?.id);
    // localStorage.setItem('global_categories')
    this.searchSubject.pipe(debounceTime(400)).subscribe((searchValue) => {
      this.algoliaService.searchSuggestions(searchValue, this.selectedLanguage?.id).subscribe((data: any) => {
        this.suggestions = data?.data;
      });
    });

    document.documentElement.addEventListener('click', (event: any) => {
      const box: any = document.querySelectorAll('.search-suggestion-box');
      box?.forEach((element: any) => {
        if (!element?.contains(event?.target)) this.suggestions = [];
      });
    },
    );

    this.moveDownAndUpThrowSuggestionsWithArrows();

    this.userChangeSubscription = this.authService.currentUserSubject.subscribe((user) => {
      this.userId = user?.id?.toString();
      if (this.userId) {
        this.pusherService.bindEvent(this.userId, (data: any) => {
          this.internalNotificationService.notificationAdded.emit(data);
        });
        this.internalNotificationService.getUnreadCount().subscribe({next: result => {
            this.unreadCount = result?.data?.unreadCount ?? 0
          }
        })
      } else {
        this.unreadCount = 0
      }
    })

    this.notificationAddedSubscription = this.internalNotificationService.notificationAdded.subscribe((data: any) => {
      if(!this.newNotifications.some((notification: any) => notification.Id === data.Id)){
        this.unreadCount++;
        this.newNotifications.push(data)
        this.notificationSnack(data.Title)
      }
    })
  }

  notificationSnack(title: string): void {
    this.snackBar.openFromComponent(NotificationSnackComponent, {
      data: { title },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'bg-white'
    });
  }

  mercadoUnicoNavigate() {
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/mercado-unico`]);
  }

  navigate(id: string, link: string) {
    this.cookieService.delete('STORAGE_FILTER_BY_STORE_ID_' + 1);
    this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}${link}`]);
  }

  private getQueryParams() {
    let queryparams: any = null;
    this.activeRoute.queryParams.subscribe((data: any) => {
      queryparams = data?.global_search;
    })
    return queryparams;
  }

  getGLobalConfig() {
    if (localStorage.getItem('GLOBAL_CONFIGS')) {
      let aux: any = localStorage.getItem('GLOBAL_CONFIGS');
      this.extra_items = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.ExtraItems?.data;
      this.trailing_items = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.TrailingItems?.data;
      this.hide_stores = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.HideStores ?? false;
      this.others_label = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.OtherLabel;
      this.others = JSON.parse(aux)['HOME_SETTINGS']?.data[0]?.attributes?.TopBar?.data?.attributes?.Others?.data;
    }
  }

  get getSelectedZones(): string {
    return this.zonesService.getZonesValuesStorage()?.municipality_name || this.zonesService.getZonesValuesStorage()?.zone_name || 'DELIVERY_ZONE';
  }

  get getFirstAction() {
    return localStorage.getItem('first_action');
  }

  setShowCat() {
    this.showcat = (!this.showcat && !!this.getSelectedZone);
    this.showstores = false;
    this.showtravels = false;
    this.showsubcat = false;
    this.showtsubcat = false;
    this.showprofile = false;
    this.showcatnave = false;
  }

  onClickOutside(): void {
    // Lógica para cerrar el menú o realizar otra acción
    this.showcat = false;
  }

  initCurrency() {
    this.currencyService.selectedCurrency.subscribe((currency) => {
      this.selectedCurrency = currency ?? this.currencies[0].name;
      localStorage.setItem("currency", JSON.stringify(currency));
    });
    // const currencyAux = localStorage.getItem("currency");
    // let currency = currencyAux ? JSON.parse(currencyAux) : null;
    // if (!currency) {
    //   currency = this.currencies[0].name;
    //   localStorage.setItem("currency", JSON.stringify(currency));
    // }
    // this.selectedCurrency = currency ?? this.currencies[0].name;
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  initLanguage() {
    const languageAux = localStorage.getItem("language");
    let language = languageAux ? JSON.parse(languageAux) : null;
    if (!language) {
      language = this.translateService.getBrowserLang();
      localStorage.setItem("language", JSON.stringify(language));
      this.translateService.use(language);
    }
    const findLanguage = this.languages.find(it => it.id === language);
    this.selectedLanguage = findLanguage || this.languages[0];
  }

  setLanguage(lang: { id: string; title: string; flag: string }) {
    if (this.selectedLanguage.id !== lang.id) {
      this.selectedLanguage = lang;
      this.translateService.use(lang.id);
      localStorage.setItem("language", JSON.stringify(lang.id));
      this.languageService.selectedLanguage.next(lang.id);
      this.menu_categories = this.menuService.getFinalCategories(this.selectedLanguage?.id);
    }
  }

  setCurrency(currency: string) {
    if (this.selectedCurrency !== currency) {
      // this.selectedCurrency = currency;
      // localStorage.setItem("currency", JSON.stringify(currency));
      this.currencyService.selectedCurrency.next(currency);
      this.languageService.selectedLanguage.next(this.selectedLanguage?.id);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe(); //  You can replace this with this.destroy$.complete() as well

    this.finishCartSubs$.next(true);
    this.finishCartSubs$.unsubscribe();
    this.cubacelService.update_cubacel_bag.unsubscribe();

    if(this.userId){
      this.pusherService.unbindEvent(this.userId);
    }

    this.notificationAddedSubscription.unsubscribe();
    this.userChangeSubscription.unsubscribe()
  }

  logout(): void {
    this.showprofile = false;
    if (this.user?.id && this.user?.readyToUse) {
      if (this.newCartService.haveCart()) {
        this.newCartService.removeCartValuesInStorage();
      }
    }
    this.authService.logout();
    this.router.navigate(['']);
  }

  showCountryProvinceModal() {
    if (this.getFirstAction === 'done') {
      let dialoRef = this.dialog.open(ProvinceSelectionComponent, {
        disableClose: false,
        // position: { top: '60px' }
      });
      dialoRef.afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (() => {}),
          error: (err => {
            throw new Error(err);
          })
        });
    }
  }

  updateTime() {
    if (this.cartFinishDate) {
      const now = new Date();
      const expirationCartDate = this.cartFinishDate;
      const diff = expirationCartDate.getTime() - now.getTime();
      if (diff <= 0) {
        this.stopCartTime(true, true);
        return;
      }
      // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const mins = Math.floor(diff / (1000 * 60));
      const secs = Math.floor(diff / 1000);

      // La diferencia que se asignará para mostrarlo en la pantalla
      this.cartTime.days = days;
      this.cartTime.hours = hours - days * 24;
      this.cartTime.minutes = mins - hours * 60;
      this.cartTime.seconds = secs - mins * 60;
    } else {
      this.stopCartTime(false, true);
    }
  }

  // Ejecutamos la acción cada segundo, para obtener la diferencia entre el momento actual y el objetivo
  start() {
    return interval(1000).pipe(
      takeWhile(() => this.cartFinishDate !== null && this.cartTime.minutes >= 0 && this.cartTime.seconds >= 0),
      tap(() => {
        this.updateTime();
      }),
    );
  }

  closeAll() {
    this.showcat = false;
    this.showstores = false;
    this.showtravels = false;
    this.showprofile = false;
    this.showsubcat = false;
    this.showtsubcat = false;
    this.showsubcattiendas = false;
    this.showcatTravels = false;
  }

  getCartValues() {
    if (this.user?.id && this.user?.readyToUse) {
      this.newCartService.getShoppingCart(this.getSelectedZone?.zone || 6, this.getSelectedZone?.municipality || 37)
        .pipe(takeUntil(this.destroy$))
        .subscribe(cart => {
          this.cart = <NewCart>cart;
        });
    }
  }

  initCartTime() {
    if (this.user?.id && this.user?.readyToUse) {
      this.newCartService.newCartSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((cart) => {
          this.cartTimesInit(cart, this.newCartService.getCartExpirationDate())
        });
    }

  }

  cartTimesInit(cart: Cart | NewCart | undefined | null, expirationDate: string) {
    setTimeout(() => {
      this.cart = cart;
      if (cart !== null && cart !== undefined) {
        this.finishCartSubs$ = new Subject<boolean>();
        // Inicializando fecha de expiracion del carrito a partir de los datos de session storage
        const cartExpirationDate = new Date(expirationDate);
        const cartUpdateDate = new Date(this.cart?.updated_at! || this.cart?.lastFetch!);
        const timeLeft = moment(new Date()).diff(cartExpirationDate);
        const totalTime = moment(cartUpdateDate).diff(cartExpirationDate);
        if (timeLeft < 0) {
          const third = Math.abs(Math.floor(totalTime / 3));
          this.cartFinishDate = moment(cartExpirationDate).subtract(third, 'millisecond').toDate();
          this.counterTimer$ = this.start()
            .pipe(takeUntil(this.finishCartSubs$))
            .subscribe((_) => {
              if (this.cartTime.minutes === 0 && this.cartTime.seconds === 0) {
                this.stopCartTime(true, true);
              }
            });
        } else {
          this.stopCartTime(false, true);
        }
      } else {
        this.stopCartTime(false, true);
      }
    }, 0);
  }

  initCartVariables() {
    this.cartTime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      days: 0
    };
    this.haveCart = false;
  }

  stopCartTime(withMessage: boolean = false, withSet = false) {
    if (withSet) {
      this.initCartVariables();
    }

    this.cartFinishDate = null;
    this.counterTimer$?.unsubscribe();
    if (!this.finishCartSubs$.closed) {
      this.finishCartSubs$.next(true);
      this.finishCartSubs$.complete();
      this.finishCartSubs$.unsubscribe();
      if (withMessage) {
        if (this.matDialogRefCB?.getState() !== MatDialogState.OPEN) {
          this.matDialogRefCB = this.dialog.open(ContinueBuyingDialogComponent, {
            disableClose: true,
            data: {
              messageUppercase: true,
              action: of({ success: true }),
              cancelAction: 'NO_EMPTY_CART',
              confirmAction: 'CONTINUE_BUY',
              message: "CONTINUE_BUYING_QUESTION",
              cancelActionFunction: of({ success: true }),
            }
          });

          this.matDialogRefCB.afterClosed()
            .pipe(filter((name) => name))
            .subscribe((response) => {
              if (response.closed) {
                this.stopCartTime(false, true);
              }
              this.matDialogRefCB = undefined;
            });
        }
      }
    }
  }

  async navigateToWallet() {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    if (this.user?.id && this.user?.readyToUse) {
      if (this.user?.useNewPayment) {
        this.goToPaymentsProfile("");
      } else {
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/payments/1`);
      }
    } else {
      this.dialog.open(LoginToAddCartDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          messageUppercase: true,
          isRedirectToWallet: true,
          cancelAction: 'CANCEL',
          confirmAction: 'LOG_IN',
          message: "TO_GO_TO_YOUR_K_WALLET_YOU_MUST_LOGIN",
        }
      });
    }
  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  async gotoRoute(link: string) {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    if (link === '/profile/payments/cards' && this.user?.useNewPayment) {
      this.goToPaymentsProfile(link, '2');
    } else if (link === '/profile/payment-requests' && this.user?.useNewPayment) {
      this.goToPaymentsProfile(link);
    } else if (link === '/profile/user-payments') {
      this.goToPaymentsProfile(link);
    } else {
      link == '/contents/customer-support' || link.includes('/profile') ?
        this.router.navigateByUrl(`${link}`) :
        this.router.navigateByUrl(`/${this.getSelectedZone?.area_selected?.toLowerCase()}${link}`);
      setTimeout(() => {
        this.showprofile = false;
      }, 50);
    }
  }

  get defaultStore() {
    return environment.default_store;
  }

  goToCategory(route: string, id: number) {
    if (route) {
      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}${route}`], {
        queryParams: { taxon: id }
      })
    }
  }

  showSubElement(e: any) {
    let self_element = e?.target as HTMLElement;
    let element = e?.target?.parentElement?.nextSibling as HTMLElement;
    self_element?.classList?.contains('rotate-90') ?
      self_element?.classList?.remove('rotate-90') :
      self_element?.classList?.add('rotate-90');
    if (element?.classList?.contains('h-0')) {
      element?.classList?.remove('h-0');
      element?.classList?.add('h-auto');
    } else {
      element?.classList?.remove('h-auto');
      element?.classList?.add('h-0');
    }
  }

  searchGlobalProduct(text: string) {
    let params: any = {};
    if (text && text.length >= 2 && text.trim() !== '' && text.trim() !== 'undefined') {
      params.global_search = text;
      params.exclude = false;
      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/global-search`],
        {
          queryParams: params,
          // queryParamsHandling: 'merge'
        }
      );
    }
  }

  enterPressed(event: any) {
    let text = null;
    if (this.selected_sugestion) {
      text = this.selected_sugestion;
      this.selected_sugestion = null;
      if (event?.keyCode == 13) this.searchGlobalProduct(text);
    } else {
      this.selected_sugestion = null;
      if (event?.keyCode == 13) this.searchGlobalProduct(event?.target?.value);
    }
  }

  navigateToGiftCards() {
    this.router.navigateByUrl(`/gift-cards`);
  }

  seeGiftPreview() {
    if (this.authService.isAuthenticated()) {
      let dialoRef = this.dialog.open(GiftPreviewComponent, {
        disableClose: false,
        position: { right: '0px' },
        width: '384px',
        closeOnNavigation: true
      });
      dialoRef.afterClosed().subscribe({
        next: () => {},
        error: () => {}
      })
    } else {
      this.router.navigate([`/auth/signIn`],
        {
          queryParams: {
            redirectURL: '/gift-cards'
          },
        }
      );
    }
  }

  seeCartPreview() {
    if (!this.actual_route.includes('cart')) {
      let dialoRef = this.dialog.open(CartPreviewComponent, {
        disableClose: false,
        position: { right: '0px' },
        width: '384px'
      });
      dialoRef.afterClosed().subscribe({
        next: (data) => {
          console.log(data);
        },
        error: (error: any) => {
          console.log(error);
        }
      })
    } else {
      this.router.navigate(this.cartRoute);
    }
  }
  async goToPaymentsProfile( link: string, tab?: string) {
    this.authService.goToPaymentsProfile(link, tab);
  }

  getSuggestions(e: any) {
    if (e?.target?.value?.trim() == '' || e?.target?.value?.trim().length < 2) {
      this.suggestions = [];
      return;
    }
    if (e?.inputType == 'deleteContentBackward') return null;
    return this.searchSubject.next(e?.target?.value);
  }

  moveDownAndUpThrowSuggestionsWithArrows() {
    let inp: any = document.getElementById("inp");
    let liSelected: any;
    let index = -1;
    let next: any;

    inp.addEventListener('keydown', (event: any) => {
      let ul: any = document.getElementById('sug-box');
      if (!ul) return;
      let len = ul?.getElementsByTagName('li').length - 1;
      if (event.which === 40) {
        index++;
        //down
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== 'undefined' && index <= len) {
            liSelected = next;
            this.selected_sugestion = liSelected?.innerText;
          } else {
            index = 0;
            liSelected = ul.getElementsByTagName('li')[0];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[0];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      } else if (event.which === 38) {

        //up
        if (liSelected) {
          this.removeClass(liSelected, 'bg-gray-#dfdfdf');
          index--;
          next = ul.getElementsByTagName('li')[index];
          if (typeof next !== 'undefined' && index >= 0) {
            liSelected = next;
            this.selected_sugestion = liSelected?.innerText;
          } else {
            index = len;
            liSelected = ul.getElementsByTagName('li')[len];
            this.selected_sugestion = liSelected?.innerText;
          }
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        } else {
          index = 0;
          liSelected = ul.getElementsByTagName('li')[len];
          this.selected_sugestion = liSelected?.innerText;
          this.addClass(liSelected, 'bg-gray-#dfdfdf');
        }
      } else if (event.which === 13) {
        if (liSelected) {
          this.enterPressed({ keyCode: 13 });
          this.suggestions = [];
        }
      }
    }, false);
  }

  removeClass(el: any, className: any) {
    if (el.classList) {
      el.classList.remove(className);
    } else {
      el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  };

  addClass(el: any, className: any) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  };

  // Notifications
  public faBell = faBell;
  public unreadCount: number = 0;

  private readonly newNotifications: string[] = [];

  openNotificationTray(){
    if (!this.user?.id) {
      this.dialog.open(LoginToAddCartDialogComponent, {
        width: '400px',
        disableClose: true,
        data: {
          messageUppercase: true,
          cancelAction: 'CANCEL',
          confirmAction: 'LOG_IN',
          message: "TO_SEE_YOUR_NOTIFICATIONS_MUST_LOGIN",
        }
      });
    } else {
      this.dialog.open(NotificationTrayComponent, {
        disableClose: false,
        position: { right: '0px' },
        width: '384px',
      });
    }
  }
}
