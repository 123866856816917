import { Component, OnInit } from '@angular/core';
import {Subject} from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {REGULAR_EXPRESSION} from "../../../../shared/enum/regular-expression.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../../shared/services/notification.service";
import {GiveawayService} from "../../services/giveaway.service";

@Component({
  selector: 'app-giveaway-dialog',
  templateUrl: './giveaway-dialog.component.html',
  // styleUrls: ['./add-address.component.scss']
})
export class GiveawayDialogComponent implements OnInit {
  form: FormGroup = new FormGroup({
    first_name: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    last_name: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    email_receiver: new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    country: new FormControl(null, Validators.required),
    phone: new FormControl(null, Validators.required),
    recipient_first_name: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    recipient_last_name: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(35)
    ]),
    recipient_phone: new FormControl(null, Validators.required),
    recipient_email: new FormControl(null, [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    recipient_address: new FormControl(null, [
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSION.CHECK_WORK_SPACING),
      Validators.maxLength(100)
    ]),
    sign_up_checkbox: new FormControl(false)
  });

  isLoading: boolean = true;
  isSaving = false;
  faSpinner = faSpinner;

  formMarkAsTouched: boolean = false;
  giveawayEventId: string | null = null;
  destroy$: Subject<boolean> = new Subject<boolean>();
  countries$ = this.giveawayService.getCountriesFromResource();

  constructor(
    private readonly router: Router,
    private readonly activeRoute: ActivatedRoute,
    private readonly giveawayService: GiveawayService,
    private readonly notificationService: NotificationService,
  ) {
  }

  get firstname() { return this.form.get('first_name'); }
  get lastname() { return this.form.get('last_name'); }
  get email_receiver() { return this.form.get('email_receiver'); }
  get recipient_email() { return this.form.get('recipient_email'); }
  get recipientFirstName() { return this.form.get('recipient_first_name'); }
  get recipientLastName() { return this.form.get('recipient_last_name'); }
  get recipientAddress() { return this.form.get('recipient_address'); }
  get country() { return this.form.get('country'); }
  get phone() { return this.form.get('phone'); }
  get recipientPhone() { return this.form.get('recipient_phone'); }

  ngOnInit(): void {
    const paramMap = this.activeRoute.snapshot.queryParamMap;
    this.giveawayEventId = paramMap.get('giveawayEventId');
    if(!this.giveawayEventId || this.giveawayEventId === '') {
      this.giveawayService.getGiveawayEventAvailable('Giveaway').subscribe({
        next: (res: any) => {
          if (res.fails) {
            this.giveawayEventId = res.data;
            this.isLoading = false;
          }else {
            this.notificationService.showAndSubscribe('GIVEAWAY_EVENT_NOT_FOUND', 'CLOSE').afterClosed().subscribe(() => {
              this.router.navigate(['/cu/home']);
            });
          }
        },
        error: () => {
          this.notificationService.showAndSubscribe('GENERIC_ERROR', 'CLOSE').afterClosed().subscribe(() => {
            this.router.navigate(['/cu/home']);
          });
        }
      });
    }
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    this.formMarkAsTouched = true;
    if (this.form.valid) {
      this.isSaving = true;
      this.setDisableForm();

      const email = this.form.getRawValue().email_receiver;
      const formData = Object.keys(this.form.controls).map(key => {
        return { Key: key, Value: this.form.get(key)?.value?.toString() };
      });

      const data = {
        userEmail: email,
        giveawayEventId: this.giveawayEventId,
        formData: formData
      }

      this.giveawayService.submitGiveaway(data).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.isSaving = false;
            this.setEnableForm();
            this.form.reset();
            this.notificationService.showAndSubscribe('THANKS_FOR_PARTICIPATING', 'CLOSE');
            this.close();
            this.cancel();
          }
        }, error: (error: any) => {
          this.setEnableForm();
          this.isSaving = false;
          this.notificationService.showAndSubscribe(error.error.message, 'CLOSE');
        }
      });
    }
  }

  cancel() {
    this.close();
    this.router.navigate(['/cu/home']);
  }

  close(): void {
    // this.dialogRef.close(null);
  }

  setDisableForm() {
    this.form.get('first_name')?.disable();
    this.form.get('last_name')?.disable();
    this.form.get('email_receiver')?.disable();
    this.form.get('recipient_email')?.disable();
    this.form.get('recipient_first_name')?.disable();
    this.form.get('recipient_last_name')?.disable();
    this.form.get('recipient_address')?.disable();
    this.form.get('country')?.disable();
    this.form.get('phone')?.disable();
    this.form.get('recipient_phone')?.disable();
    this.form.get('sign_up_checkbox')?.disable();

  }

  setEnableForm() {
    this.form.get('first_name')?.enable();
    this.form.get('last_name')?.enable();
    this.form.get('email_receiver')?.enable();
    this.form.get('recipient_email')?.enable();
    this.form.get('recipient_first_name')?.enable();
    this.form.get('recipient_last_name')?.enable();
    this.form.get('recipient_address')?.enable();
    this.form.get('country')?.enable();
    this.form.get('phone')?.enable();
    this.form.get('recipient_phone')?.enable();
    this.form.get('sign_up_checkbox')?.enable();
  }

}
