<section class="relative w-full bg-white p-5 space-y-3">
    <div class="relative w-full flex gap-y-3
                us:flex-col us:justify-start
                lg-landscape:justify-between lg-landscape:flex-row">
        <p class="relative uppercase font-Lato-Regular font-bold text-gray-#5d5d5d
                        us:text-11px us:tracking-0.59px
                        lg-landscape:tracking-0.59px lg-landscape:text-13px">{{shopList.name}}<br>
            <span class="tracking-normal lowercase text-sm font-normal">
                {{'LIST_ARTICLES' | translate: {amount: shopList.totalCount || 0} }}</span>
        </p>
        <p class="relative uppercase font-Lato-Regular font-bold text-gray-#5d5d5d
                        us:tracking-0.59px us:text-11px us:ml-0
                        lg-landscape:tracking-0.59px lg-landscape:text-13px lg-landscape:ml-14">{{'TOTAL' |
            translate}}<br>
            <span class="tracking-normal lowercase text-sm font-normal">
                {{shopList.total || 0 | currency}}</span>
        </p>
    </div>

    <p class="relative font-Lato-Regular text-sm text-gray-#5d5d5d w-full text-left break-words leading-4">
      {{shopList.description}}
    </p>

    <div class="relative w-full flex gap-4 overflow-x-auto pt-2">
      <div class="relative w-auto h-auto" *ngFor="let groupedItem of groupedLineItems" [routerLink]="shopList.id">
        <div [ngClass]="groupedItem.count > 99 ? 'w-6 h-6' : 'w-5 h-5'"
             class="absolute z-10 rounded-full flex justify-center items-center bg-gray-#5d5d5d -right-1 -top-1">
          <p [ngClass]="groupedItem.count > 99 ? 'text-10px' : 'text-xs'"
             class="relative font-Lato-Regular font-semibold text-white">{{groupedItem.count}}</p>
        </div>
        <div class="w-100px h-100px">
          <img class="relative w-100px h-100px border-2" [src]="groupedItem.item.image" [alt]="groupedItem.item[nameLang]">
        </div>
      </div>
      <p *ngIf="!shopList.lineItems.length" class="relative w-full items-center text-center py-4">
        {{'SHOP_LIST_NO_PRODUCTS_AVAILABLE' | translate}}
      </p>
    </div>

    <div class="relative w-full flex justify-between flex-wrap gap-y-4 items-center">
        <button
            class="relative flex justify-center items-center font-Lato-Bold text-sm rounded-full border-2 bg-yellow-#FF6D03 border-white text-white px-3 py-2
            w-178px h-38px"
            (click)="createCart()"
            [disabled]="!shopList.lineItems.length"
            [ngClass]="{'opacity-50 cursor-not-allowed': !shopList.lineItems.length}"
        >
          {{'BUY' | translate}}
          <fa-icon *ngIf="isCreatingCart"
                   class="relative flex justify-center items-center animate-spin bg-transparent rounded-full w-9 h-9 text-xl text-white"
                   [icon]="faSpinner"></fa-icon>
        </button>

        <div class="relative flex justify-start gap-4 flex-wrap items-center font-Lato-Bold text-sm text-blue-#18396C">
            <button class="relative cursor-pointer underline" [routerLink]="shopList.id">{{'VIEW_LIST_DETAILS' | translate}}</button>
            <span class="relative">|</span>
            <button class="relative cursor-pointer underline" (click)="editShopList(shopList)">{{'MODIFY_LIST' | translate}}</button>
            <span class="relative">|</span>
            <button class="relative cursor-pointer underline" (click)="editShopList(shopList, true)">{{'SHOP_LIST_CHANGE_LOCATION' | translate}}</button>
            <span class="relative">|</span>
            <button class="relative cursor-pointer underline" (click)="removeList(shopList.id)">{{'REMOVE_LIST' | translate}}</button>
        </div>
    </div>
</section>
