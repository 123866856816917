<section class="relative bg-white
md:w-432px md:h-515px flex flex-col gap-6 justify-center items-center py-6 border rounded-2xl">
  <div class="relative w-full flex flex-col justify-center items-center p-4">
    <img class="relative w-246px h-232px" [src]='img' alt="">
  </div>


  <div class="relative w-full flex flex-col gap-5 px-9">
    <p class="relative w-full font-Lato-Regular font-semibold text-center text-19px leading-21px">
      {{ title | translate }}
    </p>
    <p class="relative w-full text-center">{{ message | translate }}</p>
    <div class="relative w-full flex justify-center items-center mt-5">
      <fa-icon
        class="relative flex justify-center items-center animate-spin  bg-transparent rounded-full text-4xl text-gray-#252525 text-orange-600"
        [icon]="faSpinner">
      </fa-icon>
    </div>
  </div>

  <div class="relative w-full flex flex-col
        justify-center items-center">
  </div>


</section>
