import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CheckoutService} from "../../../../shared/services/checkout.service";
import {faAdd, faCheck, faCheckCircle, faCreditCard, faSpinner, faWarning} from '@fortawesome/free-solid-svg-icons';
import {
  Address,
  CardNewIntegration,
  Country,
  PaymentMethodConfiguration,
  PaymentMethodConfigurationVariant,
  SelectedZone,
  User,
  WalletAccount
} from "../../../../shared/models";
import {Subject, Subscription} from "rxjs";
import {LanguageService} from "../../../../shared/services/language.service";
import {ZonesService} from "../../../../shared/services/zones.service";
import {CheckoutEnum} from "../../../../shared/enum/checkout.enum";
import {CardService} from "../../../../shared/services/card.service";
import {CARD_PREDICTORS_INFO, CARD_TYPES} from "../../../../shared/enum/card-info.enum";
import {takeUntil} from "rxjs/operators";
import {WalletService} from "../../../../shared/services/wallet.service";
import {AuthService} from "../../../../shared/services/auth.service";
import {NotificationService} from "../../../../shared/services/notification.service";
import {
  AcceptTermsAndConditionsComponent
} from "../../../profile/components/accept-terms-and-conditions/accept-terms-and-conditions.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {OnfidoService} from "../../../../shared/services/onfido.service";
import {ErrorsEnum} from "../../../../shared/enum/errors.enum";
import {
  CreatePaymentMethodComponent
} from "../../../shared/components/create-payment-method/create-payment-method.component";
import {PaymentRoutesEnum} from "../../../../shared/enum/payment-routes.enum";
import {CartService} from "../../../../shared/services/cart.service";
import {AddressService} from "../../../../shared/services/address.service";
import {PaymentMethodsEnum} from "../../../../shared/enum/payment-methods.enum";
import {AgreementDialogComponent} from "../../../payment/components/agreement-dialog/agreement-dialog.component";
import {FRONTEND_FEATURE, FrontendFeatureService} from "../../../../shared/services/frontend-feature.service";

const K_WALLET_TYPE = 'Spree::Gateway::KwalletGateway2';

@Component({
  selector: 'app-shared-payment-methods',
  templateUrl: './shared-payment-methods.component.html',
  styleUrls: ['./shared-payment-methods.component.scss']
})
export class SharedPaymentMethodsComponent implements OnInit {

  @Input() item_price: number = 0;
  @Input() display_item_price: number = 0;
  @Input() payment_route: string | undefined;
  @Input() payment_client: string | undefined;
  @Input() id: string | undefined;
  @Input() orderId: any;
  @Output() next = new EventEmitter<boolean>();
  @Output() isBolsaTransfer = new EventEmitter<boolean>();
  faCreditCard = faCreditCard;
  formGroup: FormGroup = new FormGroup({
    wallet_amount: new FormControl(0
      // {
      //   validators: [
      //     Validators.required,
      //     Validators.min(this.minAmount),
      //     Validators.max(this.maxAmount),
      //   ]
      // }
    ),
  });
  minAmount: number = 0.1;
  maxAmount: number = 0;
  languageServiceSubscription!: Subscription;
  formCard: FormGroup = new FormGroup({
    expiration: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    number: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9\s]*$/)
    ]),
    verification_value: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
      Validators.minLength(3),
      Validators.maxLength(4)
    ]),
    cc_type: new FormControl(null, Validators.required),
    bill_address_attributes: new FormGroup({
      firstname: new FormControl(null, Validators.required),
      lastname: new FormControl(null, Validators.required),
      address1: new FormControl(null, Validators.required),
      address2: new FormControl(null),
      city: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      zipcode: new FormControl(null, Validators.required),
      state_name: new FormControl('FL', Validators.required),
      country_iso: new FormControl('US', Validators.required),
    }),
  });

  formMarkAsTouched: boolean = false;
  isRunningGetAllAddresses: boolean = false;

  isLoadingPaymentMethod: boolean = true;
  isLoadingChangePaymentMethod: boolean = false;
  // isLoadingCreditCard: boolean = true;
  isLoadingCard: boolean = true;
  selectedNewCard: number = 0;
  isLoadingSaving: boolean = false;
  availablePaymentMethods: any[] = [];
  selectedPayMethodIndex: number = 0;
  faSpinner = faSpinner;
  faCheck = faCheck;
  wrongDate: boolean = false;
  lang: string | undefined = 'es';
  creditCards: CardNewIntegration[] = [];
  selectedCard: CardNewIntegration | null = null;
  cardPredictors = CARD_PREDICTORS_INFO;
  cardLogo = '';
  largeMaxLength: number = 19;


  isLoadingWalletAccount: boolean = true;
  isInitWalletAccount: boolean = true;
  walletAccountData: WalletAccount = {
    isActive: false,
    publicAddress: '',
    userId: '',
    isBlocked: false,
    balance: 0,
    pendingBalance: 0,
    reservedBalance: 0
  };
  isKWalletFundsApplied: boolean = false;
  isNotShowKwallet: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  showBillingAddress = false;
  isLoadingAddress: boolean = true;
  isLoadingBillingAddress: boolean = false;
  selectedBillingAddress: Address | undefined;
  faAdd = faAdd;
  addresses: Address[] = [];
  billingAddresses: Address[] = [];
  billableCountries: Country[] = [];

  isLoadingPaymentMethodConfiguration: boolean = true;
  paymentMethodConfiguration: PaymentMethodConfiguration | undefined = undefined;
  paymentMethodConfigurationVariants: PaymentMethodConfigurationVariant[] | undefined;
  paymentMethodEnum = PaymentMethodsEnum;
  isLoadingPaymentVariantFee: boolean = true;

  transactionPaymentActive: boolean = false;
  walletPaymentActive: boolean = false;
  combinedPaymentActive: boolean = false;

  transferMovilActive: boolean = false;
  isTransferMovilApplied: boolean = false;
  transferMovilShowAddress: boolean = false;

  transactionPaymentShowAddress: boolean = false;
  walletPaymentShowAddress: boolean = false;
  combinedPaymentShowAddress: boolean = false;

  paymentToken: string = '';
  insufficientBalance: boolean = false;

  selectedPaymentVariant: number = -1;
  selectedPaymentVariantObject: PaymentMethodConfigurationVariant | undefined;
  isUpdatingCard: boolean = false;
  faCheckCircle = faCheckCircle;
  faWarning = faWarning;
  obs: Subscription = new Subscription();
  isConfirming: boolean = false;
  user: User = this.authService.getCurrentUser();

  kwalletFounds: number = 0;
  creditCard: number = 0;

  constructor(
    private checkoutService: CheckoutService,
    private zonesService: ZonesService,
    private languageService: LanguageService,
    private cardService: CardService,
    private cartService: CartService,
    private walletService: WalletService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
    private onfidoService: OnfidoService,
    private addressService: AddressService,
    private route: ActivatedRoute,
    private frontendFeatureService: FrontendFeatureService
  ) {

  }

  get getSelectedZone(): SelectedZone {
    return this.zonesService.getZonesValuesStorage();
  }

  async ngOnInit(): Promise<void> {
    await this.frontendFeatureService.updatePaymentFeature(this.user);
    this.isQueryParams();
    this.authService.currentUserSubject.subscribe((user) => {
        this.user = user;
    });
    window.scroll({ top: 0 });
    this.lang = this.languageService.selectedLanguage.getValue();
    this.languageService.selectedLanguage.subscribe((language: string) => {
      if (language !== this.lang) {
        // this.lang = language;

        const paymentMethod = JSON.parse(<string>localStorage.getItem(CheckoutEnum.PAYMENT));
        const paymentCardsStorage = JSON.parse(<string>localStorage.getItem(CheckoutEnum.PAYMENT_CARDS));

        if (paymentMethod && paymentMethod != '' && (language === this.lang || !this.lang)) {
          this.selectedPayMethodIndex = paymentMethod.selectedPayMethodIndex;
          if (paymentCardsStorage && paymentCardsStorage != '') {
            this.creditCards = paymentCardsStorage;
            this.selectedCard = paymentMethod.selectedCard;
            this.selectedNewCard = paymentMethod.selectedNewCard;
          }

          this.transferMovilActive = paymentMethod.transferMovilActive;
          this.isTransferMovilApplied = paymentMethod.isTransferMovilApplied;
          this.transferMovilShowAddress = paymentMethod.transferMovilShowAddress;
          this.insufficientBalance = paymentMethod.insufficientBalance;
          this.isLoadingCard = false;
          this.availablePaymentMethods = paymentMethod.availablePaymentMethods;
          this.isLoadingPaymentMethod = false;
          this.isKWalletFundsApplied = paymentMethod.isKWalletFundsApplied;
          if (paymentMethod.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
            // if (this.cart.total > 1) {
            //   this.maxAmount = Number(Number(this.cart.total - 1).toFixed(2));
            //   if (this.maxAmount > 1) {
            //     this.minAmount = 1;
            //   } else {
            //     this.minAmount = 0.1;
            //   }
            // } else {
            //   this.minAmount = 0.1;
            //   this.maxAmount = this.cart.total;
            // }
            // this.formGroup.get('wallet_amount')?.patchValue(this.cart.kwalletFounds);
            this.formGroup.get('wallet_amount')?.setValidators([
              Validators.required,
              Validators.min(this.minAmount),
              Validators.max(this.maxAmount)
            ]);
          }

          this.getWalletAccount(false);
          this.walletAccountData = paymentMethod.walletAccountData;
          this.isLoadingWalletAccount = paymentMethod.isLoadingWalletAccount;
          this.formCard.patchValue({
            expiration: paymentMethod?.formCard?.expiration,
            name: paymentMethod?.formCard?.name,
            number: paymentMethod?.formCard?.number,
            verification_value: paymentMethod?.formCard?.verification_value,
            cc_type: paymentMethod?.formCard?.cc_type
          });

          this.isLoadingPaymentMethodConfiguration = paymentMethod.isLoadingPaymentMethod;
          this.paymentMethodConfiguration = paymentMethod.paymentMethodConfiguration;
          this.paymentMethodConfigurationVariants = paymentMethod.paymentMethodConfigurationVariants;
          this.selectedBillingAddress = paymentMethod.selectedBillingAddress;
          this.billableCountries = paymentMethod.billableCountries;
          this.addresses = paymentMethod.addresses;
          this.combinedPaymentActive = paymentMethod.combinedPaymentActive;
          this.combinedPaymentShowAddress = paymentMethod.combinedPaymentShowAddress;
          this.selectedPaymentVariant = paymentMethod.selectedPaymentVariant;
          this.selectedPaymentVariantObject = paymentMethod.selectedPaymentVariantObject;

          this.lang = language;
        } else {
          // this.isLoadingPaymentMethod = true;
          // this.isLoadingPaymentMethodConfiguration = true;
          if (this.lang) {
            this.getPaymentMethodConfiguration(language, false);
          } else {
            this.getPaymentMethodConfiguration(language);
          }
          this.lang = language;
        }

        // if (language !== this.lang) {
        //   this.lang = language;
        // this.isLoadingPaymentVariantFee = true;
        // this.paymentMethodConfigurationVariants = undefined;
        // this.getPaymentMethodConfiguration(language);
      }
    })

    this.route.queryParams.subscribe((data: any) => {
      if (data?.pt) {
        this.redirectTo(data?.pt, data?.pvid);
      }
    });

    this.getPaymentMethodConfiguration(this.lang, false);
  }

  setStorage() {

  }

  setSelectedCard(data: any) {
    this.selectedCard = data.selectedCard;
  }

  // getCart() {
  //   this.checkoutService.getCheckoutNext(this.getSelectedZone.zone || 6, this.lang!).then((response: CartCheckoutInterface) => {
  //     this.cart = response;
  //     this.originalCart = { ...this.cart };
  //     this.isLoadingCard = false;
  //     this.setStorage();
  //   }, (error) => {
  //     if (error && error.status && error.status === 404) {
  //       this.checkoutService.shoppingCartExpired();
  //     }
  //   });
  // }


  getPaymentMethodConfiguration(language?: string, activateMethodVariables: boolean = true) {
    this.isLoadingPaymentMethodConfiguration = true;
    this.checkoutService.getPaymentMethodConfiguration(
      language || this.languageService.selectedLanguage.value, this.payment_route
    ).subscribe((response: PaymentMethodConfiguration) => {
      response.allowedPaymentVariants = response.allowedPaymentVariants.filter((el: any) => {
        return el?.visible == true;
      })
      this.paymentMethodConfiguration = response;
      this.paymentMethodConfiguration.allowedPaymentVariants = response.allowedPaymentVariants.filter((data: any) => data.walletBehaviour !== 3);
      this.isLoadingPaymentMethodConfiguration = false;
      this.getWalletAccount(false);
      this.getCards();
      this.selectPaymentMethodConfigurationVariant(response.allowedPaymentVariants[0], true, false);
    });
  }

  // applyFunds(applyFund: boolean, selectedPaymentVariant: number) {
  //   if (applyFund) {
  //     this.isTransferMovilApplied = false;
  //     if (this.combinedPaymentActive && selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {


  //       const formData = this.formGroup.getRawValue();
  //       this.applyWalletFounds(formData.wallet_amount);
  //       this.walletFundsApplied(applyFund);
  //       this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TRANSACTION_AND_WALLET);
  //     } else {
  //       if (this.walletPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance >= this.cart.total) {
  //         this.insufficientBalance = false;
  //         this.applyWalletFounds();
  //         this.walletFundsApplied(applyFund);
  //       } else if (this.walletPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance < this.cart.total) {
  //         this.insufficientBalance = true;
  //       }
  //     }
  //   } else {
  //     if (this.combinedPaymentActive && selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
  //       this.cart.creditCard = this.cart.creditCard && this.cart.creditCard != 0 ? Number(this.cart.creditCard) + (this.cart.kwalletFounds ? Number(this.cart.kwalletFounds) : 0) : this.cart.total;
  //       this.showBillingAddress = false;
  //       this.setShowBillingAddressByPaymentMethod(this.paymentMethodEnum.TRANSACTION);
  //     }
  //     this.cart.kwalletFounds = 0;
  //     this.cart.creditCard = 0;
  //     this.originalCart = { ...this.cart };
  //     this.walletFundsApplied(applyFund);
  //   }
  //   this.setStorage();
  // }

  private walletFundsApplied(applyFund: boolean) {
    this.isKWalletFundsApplied = applyFund;
    this.setStorage();
  }

  private transfermovilFundsApplied(applyFund: boolean) {
    this.isTransferMovilApplied = applyFund;
    this.setStorage();
  }

  activateWallet() {
    if (!this.walletAccountData.isActive) {
      this.setStorage();
      this.dialog.open(AcceptTermsAndConditionsComponent, {
        disableClose: false,
      }).afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: ((data: any) => {
            if (data.modified) {
              this.getWalletAccount(true);
            } else if (!data.modified && data.error) {
              this.notificationService.showAndSubscribe(data.error, 'VERIFY', 'CLOSE')
                .afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe({
                  next: ((data: any) => {
                    if (data) {
                      this.onfidoService.pushValuesInStack(this.router.url);
                      this.router.navigate([`/${this.getSelectedZone?.area_selected?.toLowerCase()}/profile/verify-user-identity`]);
                    }
                  }),
                  error: (err => {
                    throw new Error(err);
                  })
                });
            }
          }),
          error: (err => {
            throw new Error(err);
          })
        })
    }
  }

  calculateRest(): number {
    if (this.walletAccountData?.balance > this.kwalletFounds) {
      return this.walletAccountData?.balance - this.kwalletFounds;
    } else {
      return 0;
    }
  }

  isWalletSelected(): boolean {
    return this.availablePaymentMethods[this.selectedPayMethodIndex]?.type == K_WALLET_TYPE;
  }

  changeSelectedPayMethod(index: number) {
    if (!this.isLoadingSaving) {
      this.selectedPayMethodIndex = index;
      if (this.isWalletSelected()) {
        if (this.isInitWalletAccount) {
          this.getWalletAccount();
        }
      } else {
        this.isKWalletFundsApplied = false;
      }
    }
  }

  getWalletAccount(isApply = true) {
    this.isLoadingWalletAccount = true;
    this.walletService.getAccount().subscribe((walletData: WalletAccount | null) => {
      if (walletData) {
        this.walletAccountData = walletData;
        if (isApply) {
          this.applyFunds(true, this.selectedPaymentVariant!);
        }
      } else {
        this.isNotShowKwallet = true;
      }
      this.isInitWalletAccount = false;
      this.isLoadingWalletAccount = false;
      this.isLoadingChangePaymentMethod = false;
      this.setStorage();
    }, (error) => {
      this.isLoadingWalletAccount = false;
      this.isLoadingChangePaymentMethod = false;
    });
  }

  backStepper() {
    this.next.emit(false);
  }

  // async submit() {
  //   this.formMarkAsTouched = true;
  //   if (this.isLoadingSaving || this.isLoadingPaymentMethod || !this.availablePaymentMethods.length || (!this.selectedCard && this.isLoadingWalletAccount) ||
  //     (!this.selectedCard && !this.isKWalletFundsApplied && !this.isTransferMovilApplied) || (!this.selectedCard && this.isKWalletFundsApplied && this.walletAccountData.balance < this.cart.total) ||
  //     this.isLoadingBillingAddress || (this.showBillingAddress && !this.selectedBillingAddress) || this.isLoadingPaymentMethodConfiguration && this.isLoadingPaymentVariantFee || !this.originalCart)
  //   let selectedPaymentMethod;
  //   if (this.paymentMethodConfigurationVariants && this.paymentMethodConfigurationVariants?.length) {
  //     selectedPaymentMethod = this.paymentMethodConfigurationVariants.find(it => it.paymentVariantId === this.selectedPaymentVariant);
  //   }

  //   if (!this.isLoadingSaving && !this.isLoadingPaymentMethod && !this.isLoadingBillingAddress && !this.isLoadingPaymentMethodConfiguration && !this.isLoadingPaymentVariantFee && !this.isLoadingWalletAccount &&
  //     selectedPaymentMethod && (!selectedPaymentMethod.showBillingToSelect || selectedPaymentMethod.showBillingToSelect && this.selectedBillingAddress) &&
  //     (
  //       (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.WALLET && this.isKWalletFundsApplied && this.walletAccountData.balance >= this.cart.total) ||
  //       (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSACTION_AND_WALLET && this.isKWalletFundsApplied && this.selectedCard) ||
  //       (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSACTION && this.selectedCard) ||
  //       (selectedPaymentMethod.paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL && this.isTransferMovilApplied)
  //     )
  //   ) {
  //     this.isLoadingSaving = true;
  //     const total: number = Math.round(Number(this.cart.total.toFixed(2)) * 100);
  //     const walletAmount: number = this.isKWalletFundsApplied ? Number(Number(this.cart.kwalletFounds! * 100).toFixed(2)) : 0;
  //     const externalAmount: number = this.isTransferMovilApplied ? Number(Number(this.cart.externalAmount! * 100).toFixed(2)) : 0;

  //     let billingAddress: Address;

  //     try {
  //       if (this.transferMovilActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSFER_MOVIL) {
  //         billingAddress = this.selectedBillingAddress!;
  //       } else {
  //         if (this.combinedPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
  //           if (this.cart.creditCard) {
  //             billingAddress = await this.cardService.getCardBillingAddress(this.selectedCard!.id);
  //           } else {
  //             billingAddress = this.selectedBillingAddress!;
  //           }
  //           billingAddress = await this.cardService.getCardBillingAddress(this.selectedCard!.id);
  //         } else {
  //           if (this.transactionPaymentActive && this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION) {
  //             billingAddress = await this.cardService.getCardBillingAddress(this.selectedCard!.id);
  //           }
  //           if (this.walletPaymentActive && this.cart.kwalletFounds && this.selectedPaymentVariant === this.paymentMethodEnum.WALLET) {
  //             billingAddress = this.selectedBillingAddress!;
  //           }
  //         }
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }

  //     const paymentIntentionData: any = {
  //       paymentCardId: this.selectedCard?.id,
  //       totalAmount: total,
  //       walletAmount: walletAmount,
  //       externalAmount: externalAmount,
  //       paymentRoute: PaymentRoutesEnum.PAYMENT_INTENTION,
  //     };
  //     if (this.isTransferMovilApplied) {
  //       this.isBolsaTransfer.emit(true);
  //       paymentIntentionData['externalAmount'] = total;
  //     } else {
  //       this.isBolsaTransfer.emit(false);
  //     }
  //     const fingerPrint = this.cartService.getFingerPrintFromSession();
  //     if (fingerPrint) {
  //       paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
  //         visitorId: fingerPrint ? fingerPrint.visitorId : null,
  //         requestId: fingerPrint ? fingerPrint.requestId : null
  //       } : null;
  //       this.paymentIntention(paymentIntentionData, billingAddress!);
  //     } else {
  //       this.checkoutService.getFingerPrint().then((fingerPrint: any) => {
  //         paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
  //           visitorId: fingerPrint ? fingerPrint.visitorId : null,
  //           requestId: fingerPrint ? fingerPrint.requestId : null
  //         } : null;
  //         this.paymentIntention(paymentIntentionData, billingAddress);
  //       });
  //     }
  //   }
  // }

  // paymentIntention(paymentIntentionData: any, billingAddress: Address | AddressInterfaceModel) {
  //   const paymentIntData: any = {
  //     paymentCardId: this.selectedCard?.id,
  //     totalAmount: total,
  //     walletAmount: walletAmount,
  //     externalAmount: externalAmount,
  //     paymentRoute: PaymentRoutesEnum.PAYMENT_INTENTION,
  //   };
  //   if (this.isTransferMovilApplied) {
  //     this.isBolsaTransfer.emit(true);
  //     paymentIntentionData['externalAmount'] = total;
  //   } else {
  //     this.isBolsaTransfer.emit(false);
  //   }
  //   const fingerPrint = this.cartService.getFingerPrintFromSession();
  //   if (fingerPrint) {
  //     paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
  //       visitorId: fingerPrint ? fingerPrint.visitorId : null,
  //       requestId: fingerPrint ? fingerPrint.requestId : null
  //     } : null;
  //     this.checkoutService.paymentIntention(paymentIntData).subscribe({
  //       next: (data: any) => {
  //         console.log(data);
  //       },
  //       error: (e) => {
  //         console.log(e);
  //       }
  //     })
  //   }
  // }

  setNumberWithoutSpace(targetValue: string): string {
    let result = targetValue?.split(' ').join('');
    return result;
  }

  setCardType(targetValue: string) {
    let num = targetValue[0];
    if (targetValue.length == 1) {
      this.verifyCard(num);
    } else {
      for (let k = 1; k < targetValue.length; k++) {
        num += targetValue[k];
        this.verifyCard(num);
      }
    }
  }

  verifyCard(digits: any): any {
    let rangeStart;
    let rangeEnd;
    let number;
    for (let index = 0; index < this.cardPredictors.length; index++) {
      for (let k = 0; k < this.cardPredictors[index].startWith.length; k++) {
        if (this.cardPredictors[index].startWith[k].includes('-')) {
          rangeStart = this.cardPredictors[index].startWith[k]?.split('-')[0];
          rangeEnd = this.cardPredictors[index].startWith[k]?.split('-')[1];
          if (digits >= rangeStart && digits <= rangeEnd) {
            return this.setData(index);
          }
        }
      }
      for (let j = 0; j < digits.length; j++) {
        if (j == 0) {
          number = digits[j];
        } else {
          number += digits[j];
        }
        if (this.cardPredictors[index].startWith.indexOf(number) !== -1) {
          return this.setData(index);
        }
      }
    }
  }

  setData(index: number) {
    const type = this.cardPredictors[index].type;
    // @ts-ignore
    const i = CARD_TYPES[`${type}`];
    const maxLength = Math.max(...this.cardPredictors[index].lenghts);
    this.largeMaxLength = maxLength % 4 == 0 ? maxLength + (Math.floor(maxLength / 4) - 1) : maxLength + Math.floor(maxLength / 4);
    this.formCard.patchValue({
      cc_type: CARD_TYPES[i]
    });
    this.cardLogo = `../../../../assets/imgs/create-card/${CARD_TYPES[i]}.svg`;
  }

  keyupNumberCard(e: any) {
    const numberWithoutSpace = this.setNumberWithoutSpace(e.target.value);
    this.setCardType(numberWithoutSpace);
    const data = this.validateFormat(e.target.value);
    if (data.length && data.length % 5 == 0) {
      const chartIndex = data.length - 1;
      if (data.charAt(chartIndex) != ' ') {
        const chartData = data.charAt(chartIndex);
        const firstFours = data.substr(0, chartIndex);
        e.target.value = firstFours + ' ' + chartData;
      } else {
        e.target.value = data.substr(0, chartIndex);
      }
    } else {
      e.target.value = data;
    }
  }

  validateFormat(data: string): string {
    let result = data?.split(' ').join('');

    if (result.length > 4) {
      const chopInto = Math.ceil(result.length / 4);

      const arraySubstr = [];
      for (let i = 0; i < chopInto; i++) {
        if (i == chopInto - 1) {
          arraySubstr.push(result.substr(i * 4));
        } else {
          arraySubstr.push(result.substr(i * 4, 4));
        }
      }
      let dataResult = '';
      arraySubstr.forEach((it, index) => {
        if (index == 0) {
          dataResult = it;
        } else {
          dataResult = dataResult + ' ' + it;
        }
      });
      return dataResult;
    }

    return result;
  }

  keyupExpirationCard(e: any) {
    const date = this.formCard.get('expiration')?.value;
    const month = date?.split('/')[0];
    const year = date?.split('/')[1];
    const actualMonth = new Date().getUTCMonth() + 1;
    const actualYear = new Date().getUTCFullYear().toString().substring(2);

    this.wrongDate = (month > 12 || month < actualMonth && year <= actualYear) || year < actualYear;

    if (e.target.value.length == 2 && e?.key?.toString() !== 'Backspace') {
      e.target.value += '/';
    }
  }

  allowNumbersAndSlash(e: any): boolean {
    let result = false;
    const charCode = e.charCode;
    if (charCode != 0) {
      if (charCode < 48 || charCode > 57) {
        e.preventDefault();
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  get name() { return this.formCard.get('name'); }
  get number() { return this.formCard.get('number'); }
  get verification_value() { return this.formCard.get('verification_value'); }
  get expiration() { return this.formCard.get('expiration'); }

  ngOnDestroy(): void {
    this.languageServiceSubscription?.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  openRevalidateCardModal() {
    this.isConfirming = false;
    let dialoRef = this.dialog.open(CreatePaymentMethodComponent, {
      disableClose: true,
      // position: { top: '30px' },
      data: { paymentCardId: this.selectedCard?.id, isRevalidate: true, isRevalidateCheckout: true },
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        if (response !== 'close') {
          return this.performPaymentIntention();
        }
      })
  }

  selectPaymentMethodConfigurationVariant(paymentVariant: PaymentMethodConfigurationVariant, loading: boolean = true, cleanPaymentValues: boolean = true) {
    // console.log(paymentVariant);

    if (paymentVariant && !this.isUpdatingCard) {
      this.isLoadingChangePaymentMethod = true;
      this.kwalletFounds = 0;
      this.isKWalletFundsApplied = false;
      this.insufficientBalance = false;
      this.formMarkAsTouched = false;
      this.isUpdatingCard = true;
      this.isLoadingPaymentVariantFee = loading;
      this.selectedPaymentVariantObject = paymentVariant;
      const paymentVariantId: number = paymentVariant.paymentVariantId;
      this.selectedPaymentVariant = paymentVariant.paymentVariantId;
      console.log(this.selectedPaymentVariant);

      // this.originalCart = undefined;

      if (paymentVariantId === this.paymentMethodEnum.TRANSACTION) {
        this.isUpdatingCard = false;
        this.getCards();
      }

      if (paymentVariantId === this.paymentMethodEnum.WALLET) {
        this.isUpdatingCard = false;
        this.getWalletAccount(true);
      }
      if (paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL) {
        this.isUpdatingCard = false;

      }
      if (paymentVariantId === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
        this.isUpdatingCard = false;
        this.getCards();
        this.getWalletAccount(false);
        if (this.item_price > 1) {
          this.maxAmount = Number(Number(this.display_item_price - 1).toFixed(2));
          if (this.maxAmount > 1) {
            this.minAmount = 1;
          } else {
            this.minAmount = 0.1;
          }
        } else {
          this.minAmount = 0.1;
          this.maxAmount = this.display_item_price;
        }
      }

      // this.checkoutService.getPaymentVariantFee(paymentVariantId, this.languageService.selectedLanguage.value)
      //   .then((response: CartCheckoutInterface) => {
      //     // this.cart.total = response.total;
      //     // this.cart.adjustment_payment_method = response.adjustment_payment_method;
      //     // this.cart.adjustment_payment_method_total = response.adjustment_payment_method_total;
      //     // this.cart.payment_variant_id = response.payment_variant_id;
      //     // this.originalCart = { ...this.cart };
      if (cleanPaymentValues) {
        this.cleanPaymentValues(paymentVariantId);
      }

      //     if (paymentVariantId === this.paymentMethodEnum.TRANSFER_MOVIL) {

      //     }
      //     if (paymentVariantId === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
      //       // if (this.cart.total > 1) {
      //       //   this.maxAmount = Number(Number(this.cart.total - 1).toFixed(2));
      //       //   if (this.maxAmount > 1) {
      //       //     this.minAmount = 1;
      //       //   } else {
      //       //     this.minAmount = 0.1;
      //       //   }
      //       // } else {
      //       //   this.minAmount = 0.1;
      //       //   this.maxAmount = this.cart.total;
      //       // }
      //       this.formGroup.get('wallet_amount')?.setValidators([
      //         Validators.required,
      //         Validators.min(this.minAmount),
      //         Validators.max(this.maxAmount)
      //       ]);
      //     } else {
      //       // this.formGroup.get('wallet_amount')?.setValidators(null);
      //       this.formGroup.get('wallet_amount')?.reset(0);
      //     }
      //     this.isLoadingPaymentVariantFee = false;
      //     this.isLoadingChangePaymentMethod = false;
      //     this.isUpdatingCard = false;
      //   }, (error) => {
      //     if (error && error.status && error.status === 404) {
      //       // this.checkoutService.shoppingCartExpired();
      //     }
      //   });
    }
  }

  private cleanPaymentValues(paymentVariantId: number | undefined) {
    switch (paymentVariantId) {
      case this.paymentMethodEnum.TRANSACTION:
        // this.cart.kwalletFounds = 0;
        // this.cart.externalAmount = 0;
        this.isTransferMovilApplied = false;
        this.isKWalletFundsApplied = false;
        // this.cart.creditCard = 0;
        //this.cart.total;
        break;
      case this.paymentMethodEnum.WALLET:
        // this.cart.externalAmount = 0;
        // this.cart.creditCard = 0;
        this.isTransferMovilApplied = false;
        this.isKWalletFundsApplied = false;
        // this.cart.kwalletFounds = 0;
        // this.applyFunds(true, paymentVariantId);
        // if (this.isKWalletFundsApplied && this.walletAccountData.balance! >= this.cart.total) {
        //   this.cart.kwalletFounds = this.cart.total;
        // } else { this.cart.kwalletFounds = 0; }
        break;
      case this.paymentMethodEnum.TRANSACTION_AND_WALLET:
        // this.cart.externalAmount = 0;
        // this.cart.kwalletFounds = 0;
        // this.cart.creditCard = 0;
        this.isKWalletFundsApplied = false;
        this.isTransferMovilApplied = false;
        // if (!this.isKWalletFundsApplied) {
        //   this.cart.creditCard = this.cart.total;
        // }
        break;
      case this.paymentMethodEnum.TRANSFER_MOVIL:
        // this.cart.kwalletFounds = 0;
        this.isKWalletFundsApplied = false;
        // this.cart.creditCard = 0;
        break;
    }
    // this.originalCart = { ...this.cart };
  }

  openAgreementModal() {
    let dialoRef = this.dialog.open(AgreementDialogComponent, {
      disableClose: false,
      data: { agreementQuery: this.selectedPaymentVariantObject?.agreementQuery }
      // position: { top: '60px' }
    });
    dialoRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ((data: any) => {
        }),
        error: (err => {
          throw new Error(err);
        })
      })
  }

  get walletAmount() { return this.formGroup.get('wallet_amount'); }

  getCards() {
    this.isLoadingCard = true;
    this.cardService.getCards().subscribe({
      next: (data) => {
        this.isLoadingCard = false;
        this.isLoadingChangePaymentMethod = false
        // console.log(data);
      },
      error: (e) => {
        this.isLoadingCard = false;
        this.isLoadingChangePaymentMethod = false
        console.log(e);
      }
    })
  }

  applyFunds(applyFund: boolean, selectedPaymentVariant: number) {
    if (applyFund) {
      this.isTransferMovilApplied = false;
      if ( selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
        const formData = this.formGroup.getRawValue();
        this.applyWalletFounds(formData.wallet_amount);
        this.walletFundsApplied(applyFund);
      } else {
        if ( this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance >= this.display_item_price) {
          this.insufficientBalance = false;
          this.applyWalletFounds();
          this.walletFundsApplied(applyFund);
        } else if (this.selectedPaymentVariant === this.paymentMethodEnum.WALLET && this.walletAccountData?.balance < this.display_item_price) {
          this.insufficientBalance = true;
        }
      }
    } else {
      if (selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
        this.creditCard = this.creditCard && this.creditCard != 0 ? Number(this.creditCard) + (this.kwalletFounds ? Number(this.kwalletFounds) : 0) : this.item_price;
        this.showBillingAddress = false;
      }
      this.kwalletFounds = 0;
      this.creditCard = 0;
      this.walletFundsApplied(applyFund);
    }
    this.setStorage();
  }

  private applyWalletFounds(funds?: number) {
    this.kwalletFounds = 0;
    this.creditCard = 0;
    if (this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
      if (funds) {
        this.kwalletFounds = funds;
      }
      this.creditCard = this.creditCard ? this.creditCard - this.kwalletFounds : this.display_item_price - this.kwalletFounds;
    } else {
      if (this.selectedPaymentVariant === this.paymentMethodEnum.WALLET) {
        this.kwalletFounds = this.walletAccountData?.balance >= this.display_item_price ? this.display_item_price : this.walletAccountData?.balance;
      }
      if (
        this.transactionPaymentActive &&
        (this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET || this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION)
      ) {
        this.creditCard = this.creditCard ? this.creditCard - this.kwalletFounds! : 0;
      }
    }
    this.setStorage();
  }

  isModal() {

  }

  isQueryParams() {
    this.obs = this.route.queryParams.subscribe({
      next: (data: any) => {
        if (atob(data?.pr) == PaymentRoutesEnum.LA_NAVE) {
          this.item_price = Number(atob(data?.pa));
          this.payment_route = PaymentRoutesEnum.LA_NAVE;
          this.display_item_price = Number(atob(data?.du));
        }
        if (atob(data?.pr) == PaymentRoutesEnum.GIFT_CARDS) {
          this.item_price = Number(atob(data?.pa));
          this.payment_route = PaymentRoutesEnum.GIFT_CARDS;
          this.display_item_price = Number(atob(data?.du));
          this.id = atob(data?.id)
          this.orderId = data.orderId;
        }
        if (atob(data?.pr) == PaymentRoutesEnum.EMAIL_PAYMENT_REQUEST) {
          this.item_price = Number(atob(data?.pa));
          this.payment_route = PaymentRoutesEnum.EMAIL_PAYMENT_REQUEST;
          this.display_item_price = Number(atob(data?.du));
        }
        if (atob(data?.pr) == PaymentRoutesEnum.CUBACEL) {
          this.item_price = Number(atob(data?.pa));
          this.payment_route = PaymentRoutesEnum.CUBACEL;
          this.display_item_price = Number(atob(data?.du));
          this.orderId = data.orderId;
          // console.log(this.item_price);
          // console.log(this.display_item_price);


          // this.id = atob(data?.id)
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  performPaymentIntention() {
    if (this.selectedPaymentVariant === this.paymentMethodEnum.TRANSACTION_AND_WALLET) {
      this.formMarkAsTouched = true;
      if (this.isKWalletFundsApplied && this.selectedCard ) {
        this.doPaymentIntention();
      }
    } else {
      this.doPaymentIntention();
    }
  }

  doPaymentIntention(){
    let walletAmount = this.isKWalletFundsApplied ? Number(Number(this.kwalletFounds! * 100).toFixed(2)) : 0;

    if (this.isLoadingWalletAccount || this.isLoadingPaymentMethodConfiguration ||
      this.isLoadingCard || this.isConfirming || this.selectedPaymentVariant == -1) return;

    this.isConfirming = true;
    let paymentIntentionData: any = {
      paymentCardId: this.selectedCard?.id ?? 'null',
      totalAmount: this.item_price,
      walletAmount: this.selectedPaymentVariant == this.paymentMethodEnum.WALLET ? this.item_price : walletAmount,
      paymentRoute: this.payment_route,
      paymentVariantId: this.selectedPaymentVariant
    };

    const fingerPrint = this.cartService.getFingerPrintFromSession();

    if (fingerPrint) {
      paymentIntentionData['fingerprintTraceDto'] = {
        visitorId: fingerPrint.visitorId,
        requestId: fingerPrint.requestId
      };
      this.doPaymentIntentionAux(paymentIntentionData, fingerPrint);
    } else {
      this.checkoutService.getFingerPrint().then((fingerPrint: any) => {
        paymentIntentionData['fingerprintTraceDto'] = fingerPrint ? {
          visitorId: fingerPrint ? fingerPrint.visitorId : null,
          requestId: fingerPrint ? fingerPrint.requestId : null
        } : null;
        this.doPaymentIntentionAux(paymentIntentionData, fingerPrint);
      });
    }
  }

  private doPaymentIntentionAux(paymentIntentionData: any, fingerPrint: any) {
    this.checkoutService.paymentIntention(paymentIntentionData).subscribe({
      next: (data: any) => {
        if (data?.success) {
          this.isConfirming = false;
          let params = {
            pt: btoa(data?.data?.paymentToken),
            pvid: btoa(this.selectedPaymentVariant?.toString()),
            vid: btoa(fingerPrint.visitorId),
            rid: btoa(fingerPrint.requestId),
            orderId: this.orderId
          };
          this.router.navigate([`${this.getRoute()}`],
            {
              // relativeTo: this.activeRoute,
              queryParams: params,
              queryParamsHandling: 'merge'
            }
          );
        } else {
          if (data.responseCode === ErrorsEnum.PAYMENT_CARD_NEEDS_REVALIDATION) {
            this.openRevalidateCardModal();
          } else if (data.responseCode === ErrorsEnum.INSUFFICIENT_WALLET_FOUNDS) {
            this.notificationService.showAndSubscribe(data.message, 'CLOSE');
            this.isConfirming = false;
          } else {
            this.notificationService.showAndSubscribe(data?.message, 'ACCEPT', 'CANCEL').afterClosed()
              .subscribe({
                next: (e) => {
                  this.isConfirming = false;
                  this.router.navigate([`${this.getRoute()}`],
                    {
                      // relativeTo: this.activeRoute,
                      queryParams: {},
                      queryParamsHandling: ''
                    }
                  );
                },
                error: (e) => {
                  this.isConfirming = false;
                }
              })
          }
        }
      },
      error: (e) => {
        this.isConfirming = false;
        this.notificationService.showAndSubscribe(e?.error?.message, 'ACCEPT', 'CANCEL').afterClosed()
          .subscribe({
            next: (e) => {
              this.isConfirming = false;
              this.router.navigate([`${this.getRoute()}`],
                {
                  // relativeTo: this.activeRoute,
                  queryParams: {},
                  queryParamsHandling: ""
                }
              );
            },
            error: (e) => {
              this.isConfirming = false;
            }
          })
      }
    });
  }

  getRoute(): string {
    if (this.payment_route == PaymentRoutesEnum.LA_NAVE) return `/${this.getSelectedZone?.area_selected?.toLowerCase()}/la-nave`;
    if (this.payment_route == PaymentRoutesEnum.GIFT_CARDS) return '/gift-cards';
    if (this.payment_route == PaymentRoutesEnum.CUBACEL) return `/cubacel`;
    if (this.payment_route == PaymentRoutesEnum.EMAIL_PAYMENT_REQUEST) return 'profile/payment-requests';
    return '/';
  }

  private redirectTo(paymentToken: any, paymentVariant: any) {
    this.isConfirming = true;
    let fingerPrint = this.cartService.getFingerPrintFromSession();

    if (!fingerPrint) {
      this.checkoutService.getFingerPrint().then((fgPrint: any) => {
        fingerPrint = fgPrint;
      });
    }
    let params = {
      vid: btoa(fingerPrint.visitorId),
      rid: btoa(fingerPrint.requestId)
    };
    const url = new URL(location.href);
    url.searchParams.delete('code');
    for (const [key, value] of Object.entries(params)){
      url.searchParams.append(key, value);
    }
    url.pathname = this.getRoute();
    location.href = url.href;
  }

  async goToPayment() {
    this.isConfirming = true;
    const redirect = new URL(`${location.origin + this.router.url}`);
    if (redirect.searchParams.get('code')) {
      redirect.searchParams.delete('code');
    }
    this.authService.getPaymentLink({
      redirectUrl: redirect.toString(),
      totalAmount: this.item_price,
      paymentRoute: this.payment_route
    }).subscribe(resp =>
    {
      if (resp.success){
        window.location.href = resp.data;
      }else{
        this.notificationService.showAndSubscribe(resp.error.message, 'CLOSE');
      }
    });
  }

  protected readonly undefined = undefined;
}
