<form class="w-full relative bg-gray-#f2f2f2 rounded-3px overflow-y-auto pb-6 mb-4" [formGroup]="form">
  <!-- h-37.86vw -->
  <div class="flex relative w-full flex-col items-center pl-23px pr-23px">
    <img class="relative h-32" src="../../../../../assets/lottery/Sorteo100x1080px1200dpi.webp" alt="">
    <p class="relative font-Lato-Regular font-bold text-gray-#252525 text-17px">
      {{'TRY_YOUR_LUCK_NOW' | translate}}:
    </p>
  </div>
  <div class="relative w-full pl-23px pr-23px pt-18px">
    <div class="relative w-full flex flex-col">

      <div class="grid gap-2
      us:grid-cols-1
      us-landscape:grid-cols-2
      md:grid-cols-2
      md-landscape:grid-cols-2">
        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'NAME' | translate }}*" formControlName="first_name" id="firstname"
                 [ngClass]="{
                  'border-none': firstname?.valid || (firstname?.invalid && !(firstname?.dirty || firstname?.touched)),
                  'border-red-500': (firstname?.invalid && formMarkAsTouched) || (firstname?.invalid && (firstname?.dirty || firstname?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="firstname?.invalid && (firstname?.dirty || firstname?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="firstname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="firstname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="firstname?.hasError('pattern') && !firstname?.hasError('maxlength')">
              {{ 'CHECK_WORK_SPACING' | translate }}
            </p>
          </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'SURNAMES' | translate }}*" formControlName="last_name" id="lastname"
                 [ngClass]="{
                  'border-none': lastname?.valid || (lastname?.invalid && !(lastname?.dirty || lastname?.touched)),
                  'border-red-500': (lastname?.invalid && formMarkAsTouched) || (lastname?.invalid && (lastname?.dirty || lastname?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                        focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="lastname?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="lastname?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="lastname?.hasError('pattern') && !lastname?.hasError('maxlength')">{{ 'CHECK_WORK_SPACING' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="flex flex-1 flex-col relative pb-6">
        <input placeholder="{{ 'EMAIL_ADDRESS' | translate }}*" formControlName="email_receiver" id="email_receiver"
               [ngClass]="{
                  'border-none': email_receiver?.valid || (email_receiver?.invalid && !(email_receiver?.dirty || email_receiver?.touched)),
                  'border-red-500': (email_receiver?.invalid && formMarkAsTouched) || (email_receiver?.invalid && (email_receiver?.dirty || email_receiver?.touched))
               }"
               class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
        <div *ngIf="email_receiver?.invalid && (email_receiver?.dirty || email_receiver?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="email_receiver?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          <p *ngIf="email_receiver?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>
        </div>
      </div>

      <div class="us:grid md:flex gap-2
        us:grid-cols-1
        us-landscape:grid-cols-2
        md:grid-cols-2
        md-landscape:grid-cols-2">
        <div class="flex flex-1 flex-col relative pb-6">
          <mat-select placeholder="{{ 'COUNTRY' | translate }}*" formControlName="country" required
                      [ngClass]="{
                          'border-none': country?.valid || (country?.invalid && !(country?.dirty || country?.touched)),
                          'border-red-500': (country?.invalid && formMarkAsTouched) || (country?.invalid && (country?.dirty || country?.touched))
                      }"
                      class="border px-4 py-3 relative bg-white font-Lato-Regular active:ring-0 active:border-none active:outline-none focus:ring-0 focus:border-none
                        focus:outline-none outline-none ring-0 h-47px text-base col-span-1">
            <mat-option *ngFor="let country of (countries$ | async)" [value]="country.name">{{country.name}}</mat-option>
          </mat-select>
          <div *ngIf="country?.invalid && (country?.dirty || country?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="country?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'PHONE' | translate }}*" formControlName="phone" id="phone"
                 [ngClass]="{
                      'border-none': phone?.valid || (phone?.invalid && !(phone?.dirty || phone?.touched)),
                      'border-red-500': (phone?.invalid && formMarkAsTouched) || (phone?.invalid && (phone?.dirty || phone?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="phone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="grid gap-2
      us:grid-cols-1
      us-landscape:grid-cols-2
      md:grid-cols-2
      md-landscape:grid-cols-2">
        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'RECIPIENT_NAME' | translate }}*" formControlName="recipient_first_name" id="recipientFirstName"
                 [ngClass]="{
                  'border-none': recipientFirstName?.valid || (recipientFirstName?.invalid && !(recipientFirstName?.dirty || recipientFirstName?.touched)),
                  'border-red-500': (recipientFirstName?.invalid && formMarkAsTouched) || (recipientFirstName?.invalid && (recipientFirstName?.dirty || recipientFirstName?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="recipientFirstName?.invalid && (recipientFirstName?.dirty || recipientFirstName?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="recipientFirstName?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="recipientFirstName?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="recipientFirstName?.hasError('pattern') && !recipientFirstName?.hasError('maxlength')">
              {{ 'CHECK_WORK_SPACING' | translate }}
            </p>
          </div>
        </div>

        <div class="flex flex-1 flex-col relative pb-6">
          <input placeholder="{{ 'RECIPIENT_LAST_NAME' | translate }}*" formControlName="recipient_last_name" id="recipientLastName"
                 [ngClass]="{
                  'border-none': recipientLastName?.valid || (recipientLastName?.invalid && !(recipientLastName?.dirty || recipientLastName?.touched)),
                  'border-red-500': (recipientLastName?.invalid && formMarkAsTouched) || (recipientLastName?.invalid && (recipientLastName?.dirty || recipientLastName?.touched))
                  }"
                 class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                        focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
          <div *ngIf="recipientLastName?.invalid && (recipientLastName?.dirty || recipientLastName?.touched)"
               class="absolute bottom-0.5 text-sm text-red-500">
            <p *ngIf="recipientLastName?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
            <p *ngIf="recipientLastName?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 35} }}</p>
            <p *ngIf="recipientLastName?.hasError('pattern') && !recipientLastName?.hasError('maxlength')">{{ 'CHECK_WORK_SPACING' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="flex flex-1 flex-col relative pb-6">
        <input placeholder="{{ 'RECIPIENT_PHONE_NUMBER' | translate }}*" formControlName="recipient_phone" id="recipientPhone"
               [ngClass]="{
                      'border-none': recipientPhone?.valid || (recipientPhone?.invalid && !(recipientPhone?.dirty || recipientPhone?.touched)),
                      'border-red-500': (recipientPhone?.invalid && formMarkAsTouched) || (recipientPhone?.invalid && (recipientPhone?.dirty || recipientPhone?.touched))
                  }"
               class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
        <div *ngIf="recipientPhone?.invalid && (recipientPhone?.dirty || recipientPhone?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="recipientPhone?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
        </div>
      </div>

      <div class="flex flex-1 flex-col relative pb-6">
        <input placeholder="{{ 'RECIPIENT_EMAIL_ADDRESS' | translate }}" formControlName="recipient_email" id="recipientEmailReceiver"
               [ngClass]="{
                  'border-none': recipient_email?.valid || (recipient_email?.invalid && !(recipient_email?.dirty || recipient_email?.touched)),
                  'border-red-500': (recipient_email?.invalid && formMarkAsTouched) || (recipient_email?.invalid && (recipient_email?.dirty || recipient_email?.touched))
               }"
               class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
        <div *ngIf="recipient_email?.invalid && (recipient_email?.dirty || recipient_email?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="recipient_email?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          <p *ngIf="recipient_email?.hasError('pattern')">{{ 'WRONG_FORMAT' | translate }}</p>
        </div>
      </div>

      <div class="flex flex-1 flex-col relative pb-6">
        <input placeholder="{{ 'RECIPIENT_ADDRESS' | translate }}*" formControlName="recipient_address" id="recipientAddress"
               [ngClass]="{
                  'border-none': recipientAddress?.valid || (recipientAddress?.invalid && !(recipientAddress?.dirty || recipientAddress?.touched)),
                  'border-red-500': (recipientAddress?.invalid && formMarkAsTouched) || (recipientAddress?.invalid && (recipientAddress?.dirty || recipientAddress?.touched))
               }"
               class="relative text-gray-#727272 bg-white font-Lato-Regular focus:outline-none active:outline-none
                      focus:ring-0 active:ring-0 h-47px rounded-3px pl-4 text-base" type="text">
        <div *ngIf="recipientAddress?.invalid && (recipientAddress?.dirty || recipientAddress?.touched)"
             class="absolute bottom-0.5 text-sm text-red-500">
          <p *ngIf="recipientAddress?.hasError('required')">{{ 'REQUIRED_FIELD' | translate }}</p>
          <p *ngIf="recipientAddress?.hasError('maxlength')">{{ 'MAX_LENGTH' | translate: {maxLength: 100} }}</p>
          <p *ngIf="recipientAddress?.hasError('pattern') && !recipientAddress?.hasError('maxlength')">{{ 'CHECK_WORK_SPACING' | translate }}</p>
        </div>
      </div>
    </div>

    <div class="realtive flex justify-start items-center">
      <input formControlName="sign_up_checkbox"
        class="focus:ring-offset-0 relative cursor-pointer border border-gray-#9D9D9D focus:outline-none active:outline-none focus:ring-0 active:ring-0"
        type="checkbox">
      <label class="relative text-gray-#5d5d5d font-Lato-Regular text-base leading-4
            ml-3">{{'CHECKBOX_LOTTERY' | translate}}</label>
    </div>
  </div>

  <div class="relative w-full flex justify-center items-center
    mt-7 pl-23px pr-23px">
    <button
      class="relative bg-yellow-#FFC35D flex justify-center items-center cursor-pointer w-auto h-41px rounded-3px px-4"
      type="button" (click)="onSubmit()" [disabled]="isSaving"
      [ngClass]="{'opacity-50': isSaving, 'cursor-not-allowed ': isSaving}">
      <p class="relative font-Lato-Bold text-15px leading-4">
        {{'PARTICIPATE' | translate}}
      </p>
      <fa-icon *ngIf="isSaving"
               class="relative flex justify-center items-center animate-spin bg-yellow-#FFC35D rounded-full w-9 h-9 text-xl text-white"
               [icon]="faSpinner">
      </fa-icon>
    </button>
    <button (click)="cancel()"
      class="relative flex justify-center items-center cursor-pointer border border-gray-#9D9D9D hover:bg-gray-#EEEEEE hover:border-gray-500
        w-40 h-41px rounded-3px ml-2" type="button" mat-dialog-close="close">
      <p class="relative font-Lato-Bold
         text-15px leading-4">{{'NOT_INTERESTED' | translate}}</p>
    </button>
  </div>
</form>
