// src/app/modules/shared/notifications/internal-notifications/notification-actions.ts
import {InternalNotificationDto} from "./internalNotificationDto.interface";
import {IconDefinition} from "@fortawesome/free-brands-svg-icons";
import {faCaravan, faExternalLink, faShoppingCart, faTowerBroadcast} from "@fortawesome/free-solid-svg-icons";

export interface NotificationAction {
  icon: IconDefinition;
  label: string;
  color: string;
  isLink: boolean;
  handler: (notification: InternalNotificationDto) => string;
}

export const notificationActions: { [key: string]: NotificationAction[] } = {
  Shipment: [
    {
      label: 'ORDER',
      icon: faShoppingCart,
      color: 'orange-button',
      isLink: true,
      handler: (notification) => {
        const orderId = notification.metadata.OrderId;
        return `/profile/order/details/${orderId}`;
      }
    },
  ],
  CubacelTopUp: [
    {
      label: 'CUBACEL_TOP_UPS',
      icon: faTowerBroadcast,
      color: 'orange-button',
      isLink: true,
      handler: (notification) => {
        return `/profile/cubacel`;
      }
    }
  ],
  Payment: [],
  Default: []
};
